/*HR Home - Performance Upload*/
import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Select,
  Button,
  Space,
  notification,
  Popconfirm,
  Modal,
  Form,
  searchQuery,
  Tooltip,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { decryptRole } from "../../encrypt";
import axios from "axios";
import { url } from "../../url/url";
import { NumberRowFilled } from "@fluentui/react-icons";
import ReviewForm from "./Review Form";
const { Option } = Select;
const statusOptions = [
  "Goals Set",
  "Performance Reviewed",
  "Comments captured",
  "Rating Done",
];
const ratingOptions = [
  { label: "5- Outstanding", value: 5 },
  { label: "4- Exceeds Expectations", value: 4 },
  { label: "3- Meets Expectations", value: 3 },
  { label: "2- Needs Improvement", value: 2 },
  { label: "1- Unacceptable", value: 1 },
];
const ReviewTable = (props) => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [employees, setEmployees] = useState([]);
  const [years, setYears] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [selectedData, setSelectedData] = useState([]);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  // Define a ref for the first input field
  const firstInputRef = useRef();
  // State variables for modal
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editedRecord, setEditedRecord] = useState(null);
  const [count, setcount] = useState([]);
  const formRef = useRef();
  const [formData, setFormData] = useState({
    empCode: "",
    empName: "",
    year: "",
    period: "",
    goals: "",
    performanceReview: "",
    trainingNeeds: "",
    employeeComments: "",
    status: "",
    rating: "",
  });
  // State variable to control the visibility of the add form
  const [showReviewForm, setShowReviewForm] = useState(false);
  const status = localStorage.getItem("status");

  const handleFormSubmit = () => {
    form.validateFields().then((values) => {
      handleAddRow(values, employees);
      form.resetFields();
    });
  };

  const handleCancel = () => {
    setShowReviewForm(false);
  };

  function validator(rule, value, callback) {
    if (!value) {
      callback("Please input goals!..");
    }
    const specialCharactersRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    // Check if the value starts with a space or contains special characters
    if (value.startsWith(" ")) {
      callback("Goals should not start with a space");
    } else if (specialCharactersRegex.test(value)) {
      callback("Goals should not contain special characters");
    } else {
      callback();
    }
  }

  const toggleReviewForm = () => {
    setShowReviewForm(true);
    const review = document.getElementById("reviewSection");
    const header = document.getElementById("menuHeaderId");

    if (review && header) {
      const headerHeight = header.offsetHeight; // Replace "menuHeaderId" with the actual id of your sticky header
      const yOffset = -headerHeight; // Adjust the offset according to your layout
      const y =
        review.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    } else if (review) {
      // If header is not found, just scroll to the review section without offset
      review.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("Review section not found");
    }
  };

  useEffect(() => {
    setSelectedData(data);
    // Fetch the list of employees from your backend API
    const currentYear = new Date().getFullYear();
    const yearOptions = Array.from(
      { length: 5 },
      (_, i) => currentYear - 1 + i
    );
    setYears(yearOptions);
    fetchUserData();
    fetchUser();
  }, [count]);

  const fetchUser = async () => {
    // Fetch employee reviews from the API
    const dbName = localStorage.getItem("dbName");
    await axios
      .get(`${url}/api/get/reviewschema/${dbName}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching employee reviews:", error);
      });
  };
  const fetchUserData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const encryptedRoleFromStorage = localStorage.getItem("role");
      const jobTitle = decryptRole(encryptedRoleFromStorage);

      // Modify the API endpoint based on your application's requirements
      const endpoint = (() => {
        if (jobTitle === "admin") {
          return `${url}/api/get/allemplist/${dbName}`;
        } else if (jobTitle === "hr") {
          return `${url}/api/get/emplist/${user.displayName}/hr/${dbName}`;
        }
        return "";
      })();

      const response = await axios.get(endpoint);

      if (response.status === 200) {
        setEmployees(response.data);
      } else {
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEdit = (record) => {
    setEditedRecord(record);
    form1.setFieldsValue({
      ...record,
    });
  };
  const uniqueName = Array.from(
    new Set(data.map((employee) => employee.displayName))
  );
  const uniqueYear = Array.from(new Set(data.map((employee) => employee.year)));
  const uniqueperiod = Array.from(
    new Set(data.map((employee) => employee.periods))
  );
  const uniqueStatus = Array.from(
    new Set(data.map((employee) => employee.status))
  );
  const uniqueRating = Array.from(
    new Set(data.map((employee) => employee.rating))
  );

  const columns = [
    {
      title: "Emp ID",
      dataIndex: "empCode",
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Emp Name",
      dataIndex: "displayName",
      filters: uniqueName.map((displayName) => ({
        text: displayName,
        value: displayName,
      })),
      onFilter: (value, record) => record.displayName === value,
      width: 300,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Year",
      dataIndex: "year",
      filters: uniqueYear.map((year) => ({
        text: year,
        value: year,
      })),
      onFilter: (value, record) => record.year === value,
      width: 50,
    },
    {
      title: "Period",
      dataIndex: "periods",
      filters: uniqueperiod.map((period) => ({
        text: period,
        value: period,
      })),
      onFilter: (value, record) => record.periods === value,
      width: 250,
    },
    {
      title: "Goals",
      dataIndex: "goals",
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Review",
      dataIndex: "performanceReview",
      width: 50,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Training Needs",
      dataIndex: "trainingNeeds",
      width: 350,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },

    {
      title: "Emp Comments",
      dataIndex: "employeeComments",
      width: 400,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: uniqueStatus.map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => record.status === value,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Rating",
      dataIndex: "rating",
      align: "center",
      filters: uniqueRating.map((rating) => ({
        text: rating,
        value: rating,
      })),
      onFilter: (value, record) => record.rating === value,
      width: 400,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDeleteRow(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              size="small"
              icon={<DeleteOutlined />}
              danger
            />
          </Popconfirm>

          <Button
            type="primary"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              handleEdit(record);
              setEditedRecord(record); 
              setIsEditModalVisible(true);
            }}
          ></Button>
        </Space>
      ),
    },
  ];

  const handleAddRow = async (values, employees) => {
    try {
      const dbName = localStorage.getItem("dbName");
      // Include formData in the request body, including empCode

      // Helper function to find employee display name by empCode
      const findEmployeeDisplayName = (empCode, employees) => {
        const matchingEmployee = employees.find(
          (employee) => employee.empCode === empCode
        );
        return matchingEmployee ? matchingEmployee.displayName : "";
      };
      const requestBody = {
        ...values,
        empCode: values.empCode,
        displayName: findEmployeeDisplayName(values.empCode, employees),
      };

      await axios.post(`${url}/api/add-review/${dbName}`, requestBody);
      notification.success({
        message: "Added Successfully",
        description: "Review added successfully",
        placement: "bottomRight",
      });
      // Clear the form data
      setFormData({
        empCode: "",
        empName: "",
        year: "",
        period: "",
        goals: "",
        performanceReview: "",
        trainingNeeds: "",
        employeeComments: "",
        status: "OPEN",
        rating: 5,
      });
      // Refresh the table
      fetchUser();
      setShowReviewForm(false);
    } catch (error) {
      console.error("Error adding review:", error);
      notification.error({
        message: "Addition failed",
        description: "Check the details!",
        placement: "bottomRight",
      });
    }
  };
  const handleFieldChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleDeleteRow = (record) => {
    const dbName = localStorage.getItem("dbName");
    const empCode = record.empCode;
    const empName = record.empName;
    const year = record.year;
    const period = record.periods;

    // Send a DELETE request to the backend to delete the record

    axios
      .delete(`${url}/api/delete-review/${empCode}/${year}/${period}/${dbName}`)
      .then((response) => {
        // Handle success, e.g., show a success message or update state
        notification.success({
          message: "Deleted Successfully",
          description: "Record deleted successfully",
          placement: "bottomRight",
        });
        // Refresh the page after successful deletion
        window.location.reload();
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error("Error deleting record:", error);
        notification.error({
          message: "Deletion failed",
          description: "Check the details!",
          placement: "bottomRight",
        });
      });
  };

  const handleUpdateRecords = async (editedData) => {

    // Create an array of records to update
    const recordsToUpdate = (editedData) => ({
      // Include other fields you want to update here
      key: editedData._id,
      empCode: editedData.empCode,
      empName: editedData.empName,
      year: editedData.year,
      period: editedData.period,
      goals: editedData.goals,
      performanceReview: editedData.performanceReview,
      trainingNeeds: editedData.trainingNeeds,
      employeeComments: editedData.employeeComments,
      status: editedData.status,
      rating: editedData.rating,
    });
    const dbName = localStorage.getItem("dbName");
    // Send a PUT request to update records on the backend
    axios
      .put(`${url}/api/update-records/${dbName}`, editedData)
      .then((response) => {
        // Handle success, e.g., show a success message or update state
        notification.success({
          message: "Updated Successfully",
          description: "Records updated successfully",
          placement: "bottomRight",
        });
        setcount(count + 1);
      })
      .catch((error) => {
        // Handle error, e.g., show an error message or handle validation errors
        console.error("Error updating records:", error);
        notification.error({
          message: "Update failed",
          description: "Check the details!",
          placement: "bottomRight",
        });
      });
  };
  const EditModal = ({ visible, onCancel, record, onEdit }) => {
    const [editedData, setEditedData] = useState({ ...record });

    const handleSave = () => {
      onEdit(editedData);
      onCancel(); // Close the modal
    };

    return (
      <Modal
        title="Performance Review"
        visible={visible}
        onOk={handleSave}
        onCancel={onCancel}
        initialvalues={editedData}
        width={700}
      >
        <Form style={{ display: "flex", flexDirection: "column" }}>
          <Form.Item
            label="Employee ID"
            labelCol={{ span: 6 }} // Set the label width
            wrapperCol={{ span: 15 }} // Set the input width
          >
            <Input
              size="large"
              style={{ width: "100%" }}
              placeholder={editedData.empCode}
              disabled
            />
          </Form.Item>
          <Form.Item
            label="Year"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
          >
            <Input
              size="large"
              style={{ width: "100%" }}
              placeholder={editedData.year}
              disabled
            />
          </Form.Item>
          <Form.Item
            label="Goals"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
            rules={[
              {
                required: true,
                message: "Please Input Goals",
              },
            ]}
          >
            <Input
              label="Goals"
              size="large"
              style={{ width: "100%" }}
              placeholder="Goals"
              value={editedData.goals}
              onChange={(e) =>
                setEditedData({ ...editedData, goals: e.target.value })
              }
              rules={[
                {
                  required: true,
                  message: "Please Input Goals",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Performance Review"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
          >
            <Input
              size="large"
              style={{ width: "100%" }}
              placeholder="Performance Review"
              value={editedData.performanceReview}
              onChange={(e) =>
                setEditedData({
                  ...editedData,
                  performanceReview: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Training Needs"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
          >
            <Input
              size="large"
              style={{ width: "100%" }}
              placeholder="Training Needs"
              value={editedData.trainingNeeds}
              onChange={(e) =>
                setEditedData({ ...editedData, trainingNeeds: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Employee Comments"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
          >
            <Input
              size="large"
              style={{ width: "100%" }}
              placeholder="Employee Comments"
              value={editedData.employeeComments}
              onChange={(e) =>
                setEditedData({
                  ...editedData,
                  employeeComments: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Status"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
              placeholder="Status"
              value={editedData.status}
              onChange={(value) =>
                setEditedData({ ...editedData, status: value })
              }
            >
              {statusOptions.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Rating"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15 }}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
              placeholder="Rating"
              value={editedData.rating}
              onChange={(value) =>
                setEditedData({ ...editedData, rating: value })
              }
            >
              {ratingOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = data.filter((item) =>{
  const itemString = JSON.stringify(item).toLowerCase();
  return itemString.includes(searchQuery.toLowerCase());
});

  return (
    <div
      style={{
        transform: "scale(0.9)",
        transformOrigin: "0 0",
      }}
    >
      <h4> Performance Review</h4>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button
            type="primary"
            style={{ marginBottom: 16, float: "left" }}
            onClick={toggleReviewForm}
            icon={<PlusOutlined />}
          >
            Add Review
          </Button>
        </div>
        <div>
          <Input
            type="text"
            size="large"
            style={{ height: "40px", fontSize: "15px" }}
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchQueryChange}
          />
        </div>
      </div>
      <div
        style={{
          width: "110%",
          overflowX: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Table
          columns={columns}
          dataSource={filteredData}
          scroll={{ x: true, y: true }}
          className="custom-table col-lg-12 col-md-12 col-sm-12"
        />
      </div>
      {/* EditModal */}
      <EditModal
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        record={editedRecord}
        onEdit={(editedData) => {
          handleUpdateRecords(editedData);
          setIsEditModalVisible(false); // Close the modal
        }}
      />

      <div id="reviewSection">
        {showReviewForm && (
          <>
            <h4>Review Form</h4>
            <Form
              form={form}
              onFinish={handleFormSubmit}
              style={{ display: "flex", flexDirection: "column" }}
              ref={firstInputRef}
            >
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, marginRight: "20px" }}>
                  <Form.Item
                    name="empCode"
                    label="Employee ID"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select an employee ID",
                      },
                    ]}
                  >
                    <Select placeholder="Select an employee ID" size="large">
                      {employees.map((employee) => (
                        <Option key={employee.empCode} value={employee.empCode}>
                          {`${employee.empCode} - ${employee.firstName} ${employee.lastName}`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="year"
                    label="Year"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select a year",
                      },
                    ]}
                  >
                    <Select placeholder="Select a year" size="large">
                      {years.map((year) => (
                        <Option key={year} value={year.toString()}>
                          {year}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="periods"
                    label="Period"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select an Period",
                      },
                    ]}
                  >
                    <Select placeholder="Select an Period" size="large">
                      <Option value="Q1">Q1</Option>
                      <Option value="Q2">Q2</Option>
                      <Option value="Q3">Q3</Option>
                      <Option value="Q4">Q4</Option>
                      <Option value="H1">H1</Option>
                      <Option value="H2">H2</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="goals"
                    label="Goals"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        validator: validator, // Use the custom validator function
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="Enter goals" />
                  </Form.Item>
                  <Form.Item
                    name="performanceReview"
                    label="Performance Review"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Input.TextArea placeholder="Enter performance review" />
                  </Form.Item>
                </div>
                <div style={{ flex: 1, marginLeft: "20px" }}>
                  <Form.Item
                    name="trainingNeeds"
                    label="Training Needs"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Input.TextArea placeholder="Enter training needs" />
                  </Form.Item>
                  <Form.Item
                    name="employeeComments"
                    label="Employee Comments"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Input.TextArea placeholder="Enter employee comments" />
                  </Form.Item>
                  <Form.Item
                    name="status"
                    label="Status"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Select placeholder="Select a status" size="large">
                      {statusOptions.map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="rating"
                    label="Rating"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Select placeholder="Select a rating" size="large">
                      {ratingOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Form.Item style={{ flex: 1 }}>
                    <Button danger onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item style={{ flex: 1 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={status === "disable"}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default ReviewTable;
