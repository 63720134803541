// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-form-item .ant-form-item-label {
    padding-right: 20px; /* Adjust the value to add white space */
  }`, "",{"version":3,"sources":["webpack://./src/pages/BGV/selfdata.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB,EAAE,wCAAwC;EAC/D","sourcesContent":[".custom-form-item .ant-form-item-label {\n    padding-right: 20px; /* Adjust the value to add white space */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
