import React, { useEffect, useState } from 'react';
import './selfdata.css';
import { Form, Input, Button, DatePicker, Row, Col, Card, Radio, Menu, Layout, message } from 'antd';
const { Sider, Content } = Layout;
const SelfData = ({ onSave, initialValues }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);
    const onFinish = (values) => {
        console.log('Received values:', values);
        onSave(values);
        message.success('Saved your Details, You can move to the next step');
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            <Layout style={{ minHeight: '50vh' }}>
                <Sider trigger={null} collapsible style={{ backgroundColor: "white", width: "100%", minWidth: "100%" }}>
                    <Menu mode="inline" defaultSelectedKeys={['1']} style={{ borderRight: 0, marginTop: "80%" }}
                        onClick={({ key }) => {
                            if (key === '1') scrollToSection('basic-details');
                            if (key === '2') scrollToSection('contact-details');
                        }}>
                        <Menu.Item key="1">Basic Details</Menu.Item>
                        <Menu.Item key="2">Contact Details</Menu.Item>
                    </Menu>
                </Sider>
                <Content style={{ padding: 24, margin: 0 }}>
                    <div style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', overflowX: 'hidden' }}>
                        <Form form={form} name="BasicDetails" onFinish={onFinish} onFinishFailed={onFinishFailed} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}>
                            <Row gutter={16}>
                                <Col span={10}>
                                    <Card id="basic-details" title="Basic Details" style={{ marginBottom: 16, width: "200%", marginLeft: "20%" }}>
                                        <Form.Item label="First Name" name="FName" labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} rules={[{ required: true, message: 'Please enter your first name' }]} className="custom-form-item">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Middle Name" name='MName' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Last Name" name='LName' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter your last Name' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Father's First Name" name='FatherFName' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: `Please enter your Father's First Name` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Father's Last Name" name='FatherLName' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: `Please enter your Father's Last Name` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Nationality" name='Nationality' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: `Please enter your Nationality` }]}>
                                            <Input />
                                        </Form.Item>

                                        <Form.Item label="Date of birth" name='DOB' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please select your date of birth' }]}>
                                            <DatePicker style={{ width: "60%" }} />
                                        </Form.Item>
                                        <Form.Item label="StandardFormat(Name)" name='StandardFormat' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter your Name in Standard Format' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Gender" name='Gender' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please select a gender' },]}>
                                            <Radio.Group>
                                                <Radio value="Male">Male</Radio>
                                                <Radio value="Female">Female</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item label="Marital status" name='MaritalStatus' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item">
                                            <Radio.Group>
                                                <Radio value="Single">Single</Radio>
                                                <Radio value="Married">Married</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item label="Changed Name?" name="ChangedFormat" labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" initialValue="No">
                                            <Radio.Group>
                                                <Radio value="Yes">Yes</Radio>
                                                <Radio value="No">No</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item noStyle
                                            shouldUpdate={(prevValues, currentValues) => prevValues.ChangedFormat !== currentValues.ChangedFormat}>
                                            {({ getFieldValue }) => {
                                                return getFieldValue('ChangedFormat') === 'Yes' ? (
                                                    <>
                                                        <Form.Item
                                                            label="If Yes, Enter Name"
                                                            name="ChangedName"
                                                            labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item"
                                                            rules={[{ required: true, message: 'Please enter your changed Name' }]}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="Date of change"
                                                            name="DChanged"
                                                            labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item">
                                                            <DatePicker />
                                                        </Form.Item>
                                                    </>
                                                ) : null;
                                            }}
                                        </Form.Item>
                                    </Card>
                                    <Card id="contact-details" title="Complete Current Address and Contact Details" style={{ marginBottom: 16, width: "200%", marginLeft: "20%" }}>
                                        <Form.Item label="Address & Postal Code" name='Address' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter your Current Address' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Land Mark" name='LandMark' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter your Current LandMark' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="LandLine/Telephone" name='LandLine' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter your LandLine/Telephone No.' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Email" name='BDEmail' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter your Email' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Mobile Number" name='BDMobile' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter your Mobile Number' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item style={{ textAlign: "right", marginTop: "1%", marginRight: "4%" }}>
                                            <Button type="primary" htmlType="submit">
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default SelfData