import {
  Card,
  Col,
  DatePicker,
  Row,
  Select,
  Tabs,
  Layout,
  Menu,
  theme,
  Input,
} from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import BirthdayImage from "../images/birthdayimage.webp";
import { url } from "../url/url";
import axios from "axios";
import Chart1 from "../pages/employee/orgChart";
import { useNavigate } from "react-router-dom";
const { Sider, Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;
const OrganizationTab = () => {
  const [activeTabKey, setActiveTabKey] = useState("employeeTree");
  const user = JSON.parse(localStorage.getItem("user"));

  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState(""); 
  const [searchTerm, setSearchTerm] = useState("");

  const [departments, setDepartment] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState("Today");
  const [filterEmployee, setFilterEmployee] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const navigate = useNavigate();

  const [dob, setDob] = useState(null);

  useEffect(() => {
    fetchDepartment();
  }, []);

  useEffect(() => {
    filterEmployees();
  }, [selectedFilter, dob]);

  useEffect(() => {
    if (employees.length > 0) {
      filterEmployees();
    }
  }, [employees]);

  useEffect(() => {
    if (departments.length > 0) {
      setSelectedKey(departments[0]);
    }
  }, [departments]);

  // Function to fetch departments and active employees
  const fetchDepartment = async () => {
    try {
      console.log("inside fetch department");
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
      const activeUsers = response.data.filter(
        (user) => user.empStatus !== "inactive"
      );

      const uniqueDepartments = [
        ...new Set(activeUsers.map((user) => user.department)),
      ];
      setDepartment(uniqueDepartments);
      console.log("active users",activeUsers);
      setEmployees(activeUsers);
      setFilterEmployee(activeUsers);
    } catch (error) {
      console.error("Error fetching departments and employees:", error);
    }
  };

  const filterEmployees = () => {
    console.log("inside filter employees",employees.length)
    if (!employees.length) return;

    const today = new Date();
    const currentMonth = today.getMonth(); // Current month (0-11)
    const day = today.getDate(); // Current day

    let filtered = employees;
    console.log("selectedFilter",selectedFilter);

    if (selectedFilter === "Today") {
      filtered = employees.filter((employee) => {
        const dob = new Date(employee.dob);
        return dob.getDate() === day && dob.getMonth() === currentMonth;
      });
    } else if (selectedFilter === "Current Month") {
      filtered = employees.filter((employee) => {
        const dob = new Date(employee.dob);
        return dob.getMonth() === currentMonth;
      });
    } else if (selectedFilter === "My Department") {
      const myDepartment = user.department;
      filtered = employees.filter(
        (employee) => employee.department === myDepartment
      );
    }

    if (dob !== null) {
      // If a specific month is selected from DatePicker
      filtered = filtered.filter((employee) => {
        const empDob = new Date(employee.dob);
        return empDob.getMonth() === dob; // Filter by the selected month
      });
    }

    setFilterUsers(filtered);
  };

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const handleDateChange = (date) => {
    if (date) {
      setDob(date.month()); // .month() returns the month (0-11)
    } else {
      setDob(null); // If no date is selected, reset dob
    }
  };

  const handleClick = (e) => {
    setSelectedKey(e.key);
    console.log(e.key, "keys");
  };

  const filteredEmployees = employees.filter(
    (employee) => employee.department === selectedKey
  );

  const departmentEmployeeCount = employees.reduce((acc, employee) => {
    acc[employee.department] = (acc[employee.department] || 0) + 1;
    return acc;
  }, {});

  
  const filteredDepartments = departments.filter((department) =>
    department.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
  };
  const handleSelectChange = (value) => {
    console.log(value, "emailvalue");

    // Navigate to the desired URL with the selected email
    navigate(`/home/orgchart/${value}`);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Handle cases where the string might be empty
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div>
      <Tabs
        type="card"
        defaultActiveKey="employeeTree"
        onChange={setActiveTabKey}
      >
        <TabPane tab="Employee Tree" key="employeeTree">
          <h5 className="text-center" style={{ color: "green" }}>
            Select the employee's Email to view their Employee tree
          </h5>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Select
              style={{ width: "50vh" }}
              onChange={handleSelectChange}
              placeholder="Select employee's email"
            >
              {employees.map((item) => (
                <Select.Option key={item.email} value={item.email}>
                  {item.email}
                </Select.Option>
              ))}
            </Select>
          </div>
        </TabPane>

        <TabPane tab="Department Directory" key="departmentDirectory">
          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={{
                backgroundColor: "white",
                width: "300px",
                minWidth: "300px",
              }}
            >
              <div className="demo-logo-vertical" />
              <Input
                placeholder="Search..."
                onSearch={handleSearch}
                onChange={(e) => handleSearch(e.target.value)}
                suffix={<SearchOutlined />}
                allowClear
              />
              <Menu
                mode="inline"
                selectedKeys={[selectedKey]}
                onClick={handleClick}
              >
                {filteredDepartments.map((department) => (
                  <Menu.Item key={department}>
                    {capitalizeFirstLetter(department)}{" "}
                    <span style={{ float: "right" }}>
                      {departmentEmployeeCount[department] || 0}
                    </span>
                  </Menu.Item>
                ))}
              </Menu>
            </Sider>
            <Layout>
              <Content
                style={{
                  margin: "12px 16px",
                  padding: 24,
                  minHeight: 280,
                  marginTop: "2vh",
                }}
              >
                {selectedKey && (
                  <div
                    style={{
                      height: "60vh",
                      overflowY: "auto",
                      paddingRight: "16px",
                    }}
                  >
                    <Row gutter={[16, 16]}>
                      {filteredEmployees.map((employee) => (
                        <Col xs={24} sm={12} md={8} key={employee._id}>
                          <Card
                            style={{
                              height: "30vh",
                              width: "43vh",
                              border: "1px solid #fcc00d",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                              marginTop: "9vh",
                            }}
                          >
                            {employee.profileImage ? (
                              <img
                                src={employee.profileImage}
                                alt={employee.displayName}
                                style={{
                                  width: "80px",
                                  borderRadius: "10%",
                                  objectFit: "cover",
                                  height: "80px",
                                  top: "-50px",
                                  left: "13vh",
                                  position: "absolute",
                                }}
                              />
                            ) : (
                              <img
                                src={
                                  employee.gender === "female"
                                    ? require("../images/femalegeneric.png")
                                    : require("../images/malegenric.jpg")
                                }
                                style={{
                                  width: "80px",
                                  borderRadius: "10%",
                                  objectFit: "cover",
                                  height: "80px",
                                  top: "-50px",
                                  left: "13vh",
                                  position: "absolute",
                                }}
                              />
                            )}
                            <p
                              style={{
                                padding: "1px",
                                padding: "0.5px",
                                margin: 0,
                                textAlign: "center",
                                marginTop: "3vh",
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={employee.displayName}
                            >
                              {employee.displayName}
                            </p>
                            <p
                              style={{
                                fontWeight: "lighter",
                                padding: "0.5px",
                                margin: 0,
                                textAlign: "center",
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={employee.email}
                            >
                              {employee.email}
                            </p>
                            <p
                              style={{
                                fontWeight: "lighter",
                                padding: 0,
                                margin: 0,
                                textAlign: "center",
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={employee.empDesignation}
                            >
                              {employee.empDesignation}
                            </p>
                            <p
                              style={{
                                fontWeight: "lighter",
                                padding: "0.5px",
                                margin: 0,
                                textAlign: "center",
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={employee.department}
                            >
                              {employee.department}
                            </p>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )}
              </Content>
            </Layout>
          </Layout>
        </TabPane>
        <TabPane tab="Birthday Folks" key="birthdayFolks">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Select
              style={{ width: "40vh" }}
              placeholder="Filter for peers birthday"
              onChange={handleFilterChange}
              value={selectedFilter}
            >
              <Select.Option value="Today">Today</Select.Option>
              <Select.Option value="Current Month">Current Month</Select.Option>
              <Select.Option value="My Department">My Department</Select.Option>
            </Select>
            {selectedFilter === "My Department" && (
              <DatePicker
                style={{ width: "30vh" }}
                picker="month"
                onChange={handleDateChange}
              />
            )}
          </div>

          {filterUsers.length > 0 ? (
            <Row justify="space-evenly" style={{ marginTop: "4vh" }}>
              {filterUsers.map((employee) => (
                <Col span={5} key={employee.id}>
                  <Card
                    style={{
                      width: "40vh",
                      height: "40vh",
                      backgroundImage: `url(${BirthdayImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      src={employee.profileImage}
                      style={{
                        width: "55px",
                        height: "55px",
                        borderRadius: "30%",
                        objectFit: "cover",
                        marginLeft: "7vh",
                      }}
                    />
                    <p
                      style={{
                        color: "#3503fc",
                        marginTop: "12vh",
                        marginLeft: "2vh",
                        fontStyle: "oblique",
                      }}
                    >
                      {employee.displayName}
                      <br />
                      {new Date(employee.dob).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                      })}
                    </p>
                  </Card>
                  <br />
                </Col>
              ))}
            </Row>
          ) : (
            <div style={{ textAlign: "center", marginTop: "4vh" }}>
              <img
                src={require("../images/No data.png")}
                style={{ width: "30vh", height: "30vh" }}
              />
            </div>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default OrganizationTab;
