import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, Checkbox, Radio, message, Row, Col, Card, Popconfirm, Layout, Menu } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import axios from 'axios';
import './selfdata.css';
const { Sider, Content } = Layout;
const Employment = ({ onSave, initialValues, onNext }) => {
    const [form] = Form.useForm();
    // const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const onFinish = (values) => {
        console.log('Received values:', values);
        onSave(values);
        message.success('Saved your Details, You can move to the next step');
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    // const handleTileClick = () => {
    //     setShowForm(true);
    // };


    const onDownloadPdfClick = async () => {
        const formData = form.getFieldsValue();

        try {
            const response = await axios.post('https://ems.finaran.com:5000/api/convert_pdf', {
                EmploymentRecords: formData.EmploymentRecords,
            }, {
                responseType: 'blob',
            });

            // Create a Blob from the PDF data
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Create a download link and trigger the download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'output.pdf';
            link.click();
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };
    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div>
            <Layout style={{ minHeight: '60vh' }}>
                <Sider trigger={null} collapsible style={{ backgroundColor: "white", width: "100%", minWidth: "100%" }}>
                    <Menu mode="inline" defaultSelectedKeys={['1']} style={{ borderRight: 0, marginTop: "80%" }}
                        onClick={({ key }) => {
                            if (key === '1') scrollToSection('old-details');
                            if (key === '2') scrollToSection('current-details');
                        }}>
                        <Menu.Item key="1">Previous Company</Menu.Item>
                        <Menu.Item key="2">Current Company</Menu.Item>
                    </Menu>
                </Sider>
                <Content style={{ padding: 24, margin: 0 }}>
                    <div style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', overflowX: 'hidden' }}>
                        <Form form={form} name="EmploymentRecords" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Card id="old-details" title="Previous Company" style={{ marginBottom: 16, width: "100%" }}>
                               <Form.List name="EmploymentRecords">

                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, fieldKey }) => (
                                                <div key={key}>
                                                    <Row gutter={16}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label="Company Name"
                                                                name={[name, 'CompanyName']}
                                                                fieldKey={[fieldKey, 'CompanyName']}
                                                                rules={[{ required: true, message: 'Please enter Company Name' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Company Address"
                                                                name={[name, 'CompanyAddress']}
                                                                fieldKey={[fieldKey, 'CompanyAddress']}
                                                                rules={[{ required: true, message: 'Please enter Company Address' }]}
                                                            >
                                                                <Input.TextArea />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Company Website"
                                                                name={[name, 'CompanyWeb']}
                                                                fieldKey={[fieldKey, 'CompanyWeb']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Company Number"
                                                                name={[name, 'CompanyPh']}
                                                                fieldKey={[fieldKey, 'CompanyPh']}
                                                                rules={[{ required: true, message: 'Please enter Company No' }]}
                                                            >
                                                                <Input placeholder="input number" style={{ fontSize: "16px" }} />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Date of Employment"
                                                                name={[name, 'EmpDateS']}
                                                                fieldKey={[fieldKey, 'EmpDateS']}
                                                                rules={[{ required: true, message: 'Please choose the date' }]}
                                                            >
                                                                <DatePicker style={{ width: "60%" }} />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Current employment?"
                                                                name={[name, 'isCurrentEmployment']}
                                                                fieldKey={[fieldKey, 'isCurrentEmployment']}
                                                                rules={[{ required: true, message: 'Please choose Current Employment' }]}
                                                            >
                                                                <Radio.Group>
                                                                    <Radio value="Yes">Yes</Radio>
                                                                    <Radio value="No">No</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {form.getFieldValue(['EmploymentRecords', name, 'isCurrentEmployment']) === 'Yes' && (
                                                                <Form.Item
                                                                    label="When verification Initiated?"
                                                                    name={[name, 'verificationInitiationDate']}
                                                                    fieldKey={[fieldKey, 'verificationInitiationDate']}
                                                                >
                                                                    <DatePicker style={{ width: "60%" }} />
                                                                </Form.Item>
                                                            )}
                                                            <Form.Item
                                                                label="Consultancy Name"
                                                                name={[name, 'ConsultancyName']}
                                                                fieldKey={[fieldKey, 'ConsultancyName']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Emp Type(Consultancy)"
                                                                name={[name, 'EmpTypeConsultancy']}
                                                                fieldKey={[fieldKey, 'EmpTypeConsultancy']}
                                                            >
                                                                <Select>
                                                                    <Select.Option value="Permanent">Permanent</Select.Option>
                                                                    <Select.Option value="Contract">Contract</Select.Option>
                                                                    <Select.Option value="NA">NA</Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Starting Base Salary"
                                                                name={[name, 'startingBaseSalary']}
                                                                fieldKey={[fieldKey, 'startingBaseSalary']}
                                                                rules={[{ required: true, message: 'Please enter your Starting Base Salary' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Other Compensation"
                                                                name={[name, 'otherCompensation']}
                                                                fieldKey={[fieldKey, 'otherCompensation']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Total"
                                                                name={[name, 'total']}
                                                                fieldKey={[fieldKey, 'total']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Final Base Salary"
                                                                name={[name, 'finalBaseSalary']}
                                                                fieldKey={[fieldKey, 'finalBaseSalary']}
                                                                rules={[{ required: true, message: 'Please enter Finalized Salary' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Other Compensation"
                                                                name={[name, 'finalOtherCompensation']}
                                                                fieldKey={[fieldKey, 'finalOtherCompensation']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label="Total"
                                                                name={[name, 'finalTotal']}
                                                                fieldKey={[fieldKey, 'finalTotal']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Additional Info"
                                                                name={[name, 'otherInformation']}
                                                                fieldKey={[fieldKey, 'otherInformation']}
                                                            >
                                                                <Input.TextArea />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Position Held"
                                                                name={[name, 'Position']}
                                                                fieldKey={[fieldKey, 'Position']}
                                                                rules={[{ required: true, message: 'Please enter your Position' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Employee Code"
                                                                name={[name, 'EmpCode']}
                                                                fieldKey={[fieldKey, 'EmpCode']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            
                                                            <Form.Item
                                                                label="Employment Type"
                                                                name={[name, 'EmpType']}
                                                                fieldKey={[fieldKey, 'EmpType']}
                                                            >
                                                                <Select>
                                                                    <Select.Option value="Permanent">Permanent</Select.Option>
                                                                    <Select.Option value="Temporary">Temporary</Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Reason for Leaving"
                                                                name={[name, 'reasonForLeaving']}
                                                                fieldKey={[fieldKey, 'reasonForLeaving']}
                                                            >
                                                                <Input.TextArea placeholder="Enter your reason" />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Manager Name"
                                                                name={[name, 'MName']}
                                                                fieldKey={[fieldKey, 'MName']}
                                                                rules={[{ required: true, message: 'Please enter your Manager Name' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Job Title"
                                                                name={[name, 'JobTitle']}
                                                                fieldKey={[fieldKey, 'JobTitle']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Department"
                                                                name={[name, 'Department']}
                                                                fieldKey={[fieldKey, 'Department']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Contact Number"
                                                                name={[name, 'MContact']}
                                                                fieldKey={[fieldKey, 'MContact']}
                                                                rules={[{ required: true, message: 'Please enter your Manager Contact Number' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Mail Address"
                                                                name={[name, 'Mail']}
                                                                fieldKey={[fieldKey, 'Mail']}
                                                                rules={[{ required: true, message: 'Please enter your Mail Address' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item wrapperCol={{ span: 24 }}>
                                                                <Popconfirm
                                                                    title="Are you sure you want to remove this Employment record?"
                                                                    onConfirm={() => remove(name)}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Button type="danger" icon={<MinusOutlined />}>
                                                                        Remove Employment Record
                                                                    </Button>
                                                                </Popconfirm>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                            <Form.Item wrapperCol={{ span: 24 }} >
                                                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                    Add Employment Record
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                    </Form.List>

                            </Card>


                            <Card id="current-details" title="Current Company Reference Details" style={{ marginBottom: 16, width: "100%" }}>
                                <Form.Item label="Reference Name" name={["RName"]} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} className="custom-form-item"
                                    rules={[{ required: true, message: "Please enter Contact Telephone" },]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Designation" name={["Designation"]} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} className="custom-form-item"
                                    rules={[{ required: true, message: "Please enter Contact Telephone" },]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Company Name" name={["Cname"]} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} className="custom-form-item"
                                    rules={[{ required: true, message: "Please enter Company Name" },]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Contact Telephone" name={["contactTelephone"]} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} className="custom-form-item"
                                    rules={[{ required: true, message: "Please enter Contact Telephone" },]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Company Email Address" name={["companyEmail"]} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} className="custom-form-item"
                                    rules={[{ required: true, message: "Please enter Company Email Address" },]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="How do you know this person?" name={["relationship"]} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} className="custom-form-item"
                                    rules={[{ required: true, message: "Please enter your relationship with this person" },]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Can the reference be contacted?" name={["canContact"]} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} className="custom-form-item">
                                    <Radio.Group onChange={(e) => form.setFieldsValue({ [`professionalReferences.canContact`]: e.target.value === "No" })}>
                                        <Radio value="Yes">Yes</Radio>
                                        <Radio value="No">No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {form.getFieldValue(["professionalReferences", "canContact",]) === "No" && (
                                    <>
                                        <Form.Item label="If No, please give the reason why and give an alternate reference" name={["alternateContact"]} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} className="custom-form-item">
                                            <Input />
                                        </Form.Item>
                                    </>
                                )}
                                <Form.Item label="Is the reference linked to current employment?" name={["linkedToCurrentEmployment"]} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }} className="custom-form-item">
                                    <Radio.Group onChange={(e) => form.setFieldsValue({ [`professionalReferences.linkedToCurrentEmployment`]: e.target.value === "Yes" })}>
                                        <Radio value="Yes">Yes</Radio>
                                        <Radio value="No">No</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {form.getFieldValue(["professionalReferences", "linkedToCurrentEmployment"]) === "Yes" && (
                                    <Form.Item label="Date reference can be contacted" name={["dateCanContact"]} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} className="custom-form-item"
                                        rules={[{ required: true, message: "Please select the date" },]}>
                                        <DatePicker style={{ width: "60%" }} />
                                    </Form.Item>
                                )}
                                <Form.Item label="Additional information" name={["additionalInformation"]} labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} className="custom-form-item">
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item wrapperCol={24}>
                                    <Button type="primary" htmlType="submit">Save</Button>
                                </Form.Item>
                            </Card>
                        </Form >
                    </div>
                </Content>
            </Layout>
        </div>

    )
}

export default Employment