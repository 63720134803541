import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Steps,
  Layout,
  Menu,
  Col,
  Row,
  Drawer,
  Select,
  Checkbox,
  DatePicker,
  Radio,
  Slider,
  message,
  Rate,
} from "antd";
import {
  CalendarOutlined,
  CiOutlined,
  CommentOutlined,
  DeleteOutlined,
  FieldNumberOutlined,
  FileAddFilled,
  StarOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../../url/url";
const { Option } = Select;
const styles = {
  content: {
    textAlign: "center",
  },
  image: {
    width: "100px",
    height: "auto",
    margin: "20px 0",
  },
};

const Question = ({ onNext, allValues, setAllValues, onPrevious }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [feedbackText, setFeedbackText] = useState(
    "We thank you immensely for your valuable feedback!"
  );
  const [visible, setVisible] = useState(false);
  const [enableComments, setEnableComments] = useState(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [selectedScore, setSelectedScore] = useState(null);
  const [choices, setChoices] = useState([""]);
  const [inputValue, setInputValue] = useState("");
  const [selectedStar, setSelectedStar] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [formvalues, setFormvalues] = useState([]);
  const [activeCard1, setActiveCard1] = useState("choice");
  const navigate = useNavigate();
  const [isEditingIntro, setIsEditingIntro] = useState(false);
  const [isEditingFeedback, setIsEditingFeedback] = useState(false);
  const [introText, setIntroText] = useState({});
  const [newIntroText, setNewIntroText] = useState([]);
  const [newFeedbackText, setNewFeedbackText] = useState("");

  useEffect(() => {
    fetchSurvey();
  }, []);

  const fetchSurvey = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/surveys/${dbName}`);
      const filter = response.data[0];

      // If there's no data, initialize editing mode and set default empty values
      if (!filter || !filter.introductionText?.length) {
        setIsEditingIntro(true);
        setNewIntroText(["No data available"]);
      }
      if (!filter || !filter.feedbackText) {
        setIsEditingFeedback(true);
        setNewFeedbackText("No data available");
      }

      // Set the introText state with either the fetched data or default empty values
      setIntroText(filter || {});
      setNewIntroText(filter?.introductionText || ["No data available"]);
      setNewFeedbackText(filter?.feedbackText || "No data available");
    } catch (error) {
      console.log("Error fetching survey data:", error);
    }
  };

  const handleSaveIntro = () => {
    setIntroText((prev) => ({
      ...prev,
      introductionText: [...newIntroText]
    }));
    setIsEditingIntro(false);
  };

  const handleSaveFeedback = () => {
    setIntroText((prev) => ({
      ...prev,
      feedbackText: newFeedbackText
    }));
    setIsEditingFeedback(false);
  };

  const handleTextChange = (index, value) => {
    const updatedText = [...newIntroText];
    updatedText[index] = value;
    setNewIntroText(updatedText);
  };

  const handleCancelIntro = () => {
    setNewIntroText(introText.introductionText || ["No data available"]);
    setIsEditingIntro(false);
  };

  const handleCancelFeedback = () => {
    setNewFeedbackText(introText.feedbackText || "No data available");
    setIsEditingFeedback(false);
  };

  useEffect(() => {
    if (allValues.questions) {
      setFormvalues(allValues.questions);
    }
  }, [allValues]);
  const handleEditClick = () => {
    setIsEditing(true);
  };



  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setChoices([""])
    form.resetFields();
    setActiveCard1("choice")
  };
  const addChoice = () => {
    setChoices([...choices, ""]);
  };
  const handleInput = (index, event) => {
    const newChoices = [...choices];
    newChoices[index] = event.target.value;
    setChoices(newChoices);
  };
  const deleteChoice = (index) => {
    const newChoices = choices.filter((_, i) => i !== index);
    setChoices(newChoices);
  };
  const handleEnableCommentsChange = (e) => {
    setEnableComments(e.target.checked);
  };
  const handleSelectChange1 = (value) => {
    const cardMap = {
      choice: "choice",
      comment: "comment",
      scale: "scale",
      rating: "rating",
    };
    console.log("changing", value);
    setActiveCard1(cardMap[value]);
  };


 

  const handleNext = () => {
    const updatedAllValues = { ...allValues, questions: formvalues };
    setAllValues(updatedAllValues);
    onNext(updatedAllValues);  // Pass updated values to the next step
  };

  const handlePrevious = () => {
    const updatedAllValues = { ...allValues, questions: formvalues };
    setAllValues(updatedAllValues);
    onPrevious();  // Call the previous step
  };

  const onFinish = (values) => {
    const questionType = activeCard1; // Store the type of the question
    let newQuestion = {
      type: questionType,
      question: values.question, // Assuming 'question' is a field in your form
    };

    // Conditionally add choices if the type is 'choice'
    if (questionType === 'choice') {
      newQuestion = {
        ...newQuestion,
        choices: choices.length ? choices : [], // Ensure choices are sent as an array only if type is 'choice'
      };
    }

    // Manually add the new question to allValues.questions array
    const updatedQuestions = [...(allValues.questions || []), newQuestion];

    // Update allValues state with the new list of questions
    setAllValues((prevValues) => ({
      ...prevValues,
      questions: updatedQuestions,
    }));

    console.log("New Question Added:", newQuestion);
    setVisible(false);
    setChoices([""])
    form.resetFields();
    setActiveCard1("choice")
    message.success("New question added");
  };


  const onFinish1 = () => {
    let finalAllValues = {};

    const questions = allValues.questions || [];

    finalAllValues.questions = questions.filter(q => typeof q === "object" && q.type);

    // Logging for debugging purposes
    console.log(allValues, "allValues");

    const finalIntroductionText = introText?.introductionText || [];
    const finalFeedbackText = introText?.feedbackText || "";

    console.log("Final Form Values to Submit:", finalAllValues);

    onNext({
      ...finalAllValues,
      introductionText: finalIntroductionText,
      feedbackText: finalFeedbackText
    });

    message.success("Form submitted, moving to the next step");
  };




  return (
    <div>
      <Layout style={{ minHeight: "90vh" }}>
        <div style={{ maxHeight: "100vh", overflowY: "auto" }}>
          <Card
            id="introduction-section"
            style={{
              marginTop: "1vh",
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",
            }}
          >
            <h4 style={{ textAlign: "left" }}>Introduction</h4>
            {isEditingIntro ? (
              <div>
                {newIntroText.map((text, index) => (
                  <Input.TextArea
                    key={index}
                    value={text === "No data available" ? "" : text} // Set empty string in the input if it says "No data available"
                    onChange={(e) => handleTextChange(index, e.target.value)}
                    rows={3}
                    placeholder="Enter introduction text here"
                    style={{ marginBottom: "10px" }}
                  />
                ))}
                <Button
                  type="default"
                  onClick={handleCancelIntro}
                  style={{ marginRight: "8px" }}
                >
                  Cancel
                </Button>
                <Button type="primary" onClick={handleSaveIntro}>
                  Save
                </Button>
              </div>
            ) : (
              <div>
                {introText?.introductionText?.length ? (
                  introText.introductionText.map((text, index) => (
                    <p key={index} style={{ textAlign: "left", fontSize: "15px" }}>
                      {text}
                    </p>
                  ))
                ) : (
                  <p style={{ textAlign: "left", fontSize: "18px" }}>
                    No data available
                  </p>
                )}
                <Button type="link" onClick={() => setIsEditingIntro(true)}>
                  Edit
                </Button>
              </div>
            )}
          </Card>
          <br />
          <Card
            id="question-section"
            style={{
              marginTop: "1vh",
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",
            }}
          >
            <h4 style={{ textAlign: "left" }}>Questions</h4>
            <Card>
              <Row>
                <Col span={7}>
                  <span>Total questions: {formvalues?.length}</span>
                </Col>
                <Col span={13}></Col>
                <Col span={4}>
                  <Button type="primary" htmlType="button" onClick={showDrawer}>
                    Add Questions
                  </Button>
                </Col>
              </Row>
            </Card>
            <Drawer
              style={{ background: "#e6eaf0" }}
              title="Add Questions"
              placement="right"
              onClose={onClose}
              visible={visible}
              width={700}
            >
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Form
                    form={form}
                    layout="vertical"
                  >
                    <Form.Item label="Filter" name="type">
                      <Select
                        defaultValue="choice"
                        style={{ width: "30vh", marginTop: "8px" }}
                        onChange={handleSelectChange1}
                      >
                        <Option value="choice">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <CiOutlined style={{ marginRight: "8px" }} />
                            Choice
                          </div>
                        </Option>
                        <Option value="comment">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <CommentOutlined style={{ marginRight: "8px" }} />
                            Comment
                          </div>
                        </Option>
                        <Option value="scale">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <FieldNumberOutlined style={{ marginRight: "8px" }} />
                            Scale
                          </div>
                        </Option>
                        <Option value="rating">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <StarOutlined style={{ marginRight: "8px" }} />
                            Rating
                          </div>
                        </Option>
                      </Select>
                    </Form.Item>
                  </Form>
                </div>
                <div
                  style={{
                    maxHeight: "calc(110vh - 250px)",
                    overflowY: "auto",
                  }}
                >
                  <div style={{ marginLeft: "20px", flex: 1 }}>
                    {activeCard1 === "choice" && (
                      <Card
                        id={activeCard1}
                        style={{
                          marginTop: "1vh",
                          width: "95%",
                          border: "2px solid #d9d9d9",
                        }}
                      >
                        <Form
                          name="question"
                          onFinish={onFinish}
                          form={form}
                          layout="vertical"
                        >
                          <Form.Item
                            label="Question"
                            name="question"
                            rules={[
                              {
                                required: true,
                                message: "Please input the name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item name="answer" label="Answer">
                            <Card style={{ marginTop: 8, padding: 16 }}>
                              {choices.map((choice, index) => (
                                <Row
                                  key={index}
                                  gutter={8}
                                  style={{ marginBottom: 8 }}
                                >
                                  <Col flex="auto">
                                    <Input
                                      placeholder="Enter choice"
                                      value={choice}
                                      onChange={(e) => handleInput(index, e)}
                                    />
                                  </Col>
                                  <Col flex="none">
                                    <Button
                                      type="Primary"
                                      onClick={() => deleteChoice(index)}
                                      icon={<DeleteOutlined />}
                                    />
                                  </Col>
                                </Row>
                              ))}
                              <Button onClick={addChoice} type="link">
                                Add Choice
                              </Button>
                            </Card>
                          </Form.Item>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                        </Form>
                      </Card>
                    )}
                    {activeCard1 === "comment" && (
                      <Card
                        id="comment"
                        style={{
                          marginTop: "1vh",
                          width: "95%",
                          border: "2px solid #d9d9d9",
                        }}
                      >
                        <Form
                          name="question"
                          onFinish={onFinish}
                          form={form}
                          layout="vertical"
                        >
                          <Form.Item
                            label="Question"
                            name="question"
                            rules={[
                              {
                                required: true,
                                message: "Please input the name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                         
                          <Form.Item>
                            <Button type="primary" danger htmlType="submit">
                              Save
                            </Button>
                          </Form.Item>
                        </Form>
                      </Card>
                    )}
                    {activeCard1 === "scale" && (
                      <Card
                        id="nps"
                        style={{
                          marginTop: "1vh",
                          width: "95%",
                          border: "2px solid #d9d9d9",
                        }}
                      >
                        <Form
                          name="question"
                          onFinish={onFinish}
                          form={form}
                          layout="vertical"
                        >
                          <Form.Item
                            label="Question"
                            name="question"
                            rules={[
                              {
                                required: true,
                                message: "Please input the name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item name="answer" label="Answer score">
                            <Slider min={0} max={10} defaultValue={10} disabled />
                         
                          </Form.Item>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                         
                        </Form>
                      </Card>
                    )}
                    {activeCard1 === "rating" && (
                      <Card
                        id="star"
                        style={{
                          marginTop: "1vh",
                          width: "95%",
                          border: "2px solid #d9d9d9",
                        }}
                      >
                        <Form
                          name="question"
                          onFinish={onFinish}
                          form={form}
                          layout="vertical"
                        >
                          <Form.Item
                            label="Question"
                            name="question"
                            rules={[
                              {
                                required: true,
                                message: "Please input the name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            name="representation"
                            label="Representation"
                          >
                            <Rate defaultValue={5} disabled />
                          </Form.Item>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                        
                        </Form>
                      </Card>
                    )}
                  </div>
                </div>
              </div>

            </Drawer>
            <div style={styles.content}>
              {formvalues.map((question, index) => (
                <Card
                  key={index}
                  style={{ marginTop: "10px", textAlign: "left" }}
                >
                  <p>{`Question ${index + 1}: ${question.question}`}</p>

                  {question?.type === "choice" && (
                    <ul>
                      {Array.isArray(question?.choices) ? (
                        question.choices.map((choice, idx) => (
                          <li key={idx}>{choice}</li>
                        ))
                      ) : (
                        <li>{question?.answer}</li>
                      )}
                    </ul>
                  )}

                  {question?.type === "comment" &&
                   <p>comments</p>
                   }

                  {question?.type === "scale" && (
                    <Slider min={0} max={10} defaultValue={10} disabled />
                  )}

                  {question?.type === "rating" && (
                    <Rate defaultValue={5} disabled />
                  )}
                </Card>
              ))}
            </div>
          </Card>
          <br />
          <Form onFinish={onFinish1} form={form1}>
            <Card
              id="feedback-section"
              style={{
                marginTop: "1vh",
                border: "1px solid #959696",
                width: "70%",
                margin: "0 auto",
              }}
            >
              <h4 style={{ textAlign: "left" }}>Post feedback message</h4>
              {isEditingFeedback ? (
                <div>
                  <Input.TextArea
                    value={newFeedbackText === "No data available" ? "" : newFeedbackText} // Set empty string in the input if it says "No data available"
                    onChange={(e) => setNewFeedbackText(e.target.value)}
                    rows={4}
                    placeholder="Enter feedback text here"
                    style={{ marginBottom: "10px" }}
                  />
                  <Button
                    type="default"
                    onClick={handleCancelFeedback}
                    style={{ marginRight: "8px" }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" onClick={handleSaveFeedback}>
                    Save
                  </Button>
                </div>
              ) : (
                <div>
                  <p style={{ textAlign: "left" }}>
                    {introText?.feedbackText || "No data available"}
                  </p>
                  <Button type="link" onClick={() => setIsEditingFeedback(true)}>
                    Edit
                  </Button>
                </div>
              )}
            </Card>
            <Form.Item style={{ textAlign: "right", marginTop: "1%" }}>
              <Button onClick={handlePrevious} style={{ marginRight: "10px" }}>
                Previous
              </Button>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </div>
  );
};

export default Question;
