import { Button, Card, Divider, message, notification, Radio, Rate, Slider, Modal, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import background1 from '../../images/background1.webp';
import { url } from '../../url/url';
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';

const Empsurvey = () => {
    const { name } = useParams();
    const [scaleValue, setScaleValue] = useState(0);
    const [survey, setSurvey] = useState([]);
    const [responses, setResponses] = useState({}); // For storing answers for each question by index
    const user = JSON.parse(localStorage.getItem("user"));
    const [isSubmitVisible, setIsSubmitVisible] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isIdentityChecked, setIsIdentityChecked] = useState(false);
    const [isIdentityModalVisible, setIsIdentityModalVisible] = useState(false);
    const [empSurvey, setEmpSurvey] = useState([]);
    const [count, setCount] = useState(0);

    const fetchEmpSurvey = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(`${url}/get/empsurveys/${dbName}`);
            setEmpSurvey(response.data);
            console.log(response.data, "empsurvey");

        } catch {
            console.log("Error");
        }
    };

    // To track if checkbox is checked
    const handleNext = () => {
        if (currentQuestionIndex < survey[0]?.questions?.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const currentQuestion = survey[0]?.questions?.[currentQuestionIndex];

    const handleCancelSubmit = () => {
        setIsSubmitVisible(false); // Set modal visibility to false to close it
    };

    const handleFormSubmit = async () => {
        const empIdentity = survey[0].introduction.empIdentity;

        if (empIdentity === "Maintain anonymity") {
            console.log("anonomous");
            await postSurveyForm(false); // Pass false for anonymity
        } else if (empIdentity === "identity") {
            console.log("identity");
            setIsIdentityModalVisible(true);
        } else if (empIdentity === "Mandate identity disclosure") {
            console.log("disclosure");
            await postSurveyForm(true); // Pass true for mandatory disclosure
        }
    };

    const postSurveyForm = async (identityStatus) => {
        const dbName = localStorage.getItem("dbName");
        console.log(identityStatus, "ide");

        const formValues = {
            userName: user.displayName,
            manager: user.manager,
            hr: user.hr,
            surveyName: survey[0].introduction.name,
            questions: survey[0].questions.map((data, index) => ({
                question: data.question,
                answer: responses[index] || [], // Retrieve each answer from responses based on index
            })),
            isIdentity: identityStatus, // Pass the identity status directly
        };

        try {
            const response = await axios.post(`${url}/post/empsurveys/${dbName}`, formValues);
            notification.success({
                message: 'Feedback posted successfully!',
                description: 'Thank you for your valuable feedback!',
                placement: 'topRight',
            });
            setIsSubmitVisible(true);
            setIsIdentityModalVisible(false);
            setCount(count + 1)
            console.log(response.data, "Surveyform");
        } catch (error) {
            console.error("Error publishing survey:", error);
        }
    };

    // Event handlers for updating responses state based on question type
    const handleScaleChange = (value) => setResponses((prev) => ({ ...prev, [currentQuestionIndex]: [value] }));
    const handleChoiceChange = (e) => setResponses((prev) => ({ ...prev, [currentQuestionIndex]: [e.target.value] }));
    const handleRatingChange = (value) => setResponses((prev) => ({ ...prev, [currentQuestionIndex]: [value] }));
    const handleCommentChange = (e) => setResponses((prev) => ({ ...prev, [currentQuestionIndex]: [e.target.value] }));

    useEffect(() => {
        fetchSurvey();
        fetchEmpSurvey();
    }, [count])

    const fetchSurvey = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(`${url}/get/surveys/${dbName}`);
            const filter = response.data.filter((item) => item.introduction.name === name);
            setSurvey(filter);
        } catch {
            console.log("Error");
        }
    };

    const hasResponded = empSurvey.some(
        (response) =>
            response.surveyName === survey[0]?.introduction.name &&
            response.userName === user.displayName
    );

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: `url(${survey[0]?.introduction?.surveyImage
                    ? survey[0].introduction.surveyImage
                    : background1
                    })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                minHeight: "470px",
            }}
        >
            <Card style={{ marginLeft: "auto", marginRight: "auto", display: "block", width: "90%", height: "70vh" }}>
                {hasResponded ? (
                    <div style={{ marginTop: "12vh" }}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img
                                src={require("../../images/feedback1.avif")}
                                style={{ width: "30vh", height: "30vh" }}
                            />
                        </div>
                        <h5 className='text-center' style={{ color: "green" }}>Your feedback has been submitted</h5 >
                    </div>
                ) : (
                    <>
                        <h4>{survey[0]?.introduction?.name}</h4>
                        <Divider />

                        {currentQuestion && (
                            <div key={currentQuestionIndex}>
                                <p>Q{currentQuestionIndex + 1}. {currentQuestion.question}</p>

                                {currentQuestion.type === "scale" && (
                                    <Slider min={0} max={10} defaultValue={0} onChange={handleScaleChange} />
                                )}

                                {currentQuestion.type === "choice" && (
                                    <Radio.Group onChange={handleChoiceChange}>
                                        {currentQuestion.choices?.map((ans, aIndex) => (
                                            <Radio key={aIndex} value={ans}>{ans}</Radio>
                                        ))}
                                    </Radio.Group>
                                )}

                                {currentQuestion.type === "rating" && (
                                    <Rate defaultValue={0} count={5} onChange={handleRatingChange} />
                                )}
                                {currentQuestion.type === "comment" && (
                                    <TextArea placeholder="Your additional comments" onChange={handleCommentChange} />
                                )}
                            </div>
                        )}

                        <div style={{ textAlign: "center",marginTop:"3vh" }}>
                            <Button
                                type="primary"
                                danger
                                htmlType="button"
                                onClick={handlePrevious}
                                disabled={currentQuestionIndex === 0}
                                style={{ marginRight: 10 }}
                            >
                                Previous
                            </Button>
                            {currentQuestionIndex < survey[0]?.questions?.length - 1 ? (
                                <Button
                                    type="primary"
                                    danger
                                    htmlType="button"
                                    onClick={handleNext}
                                >
                                    Next
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="button" onClick={handleFormSubmit}>
                                    Submit
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </Card>

            <Modal
                visible={isSubmitVisible}
                onCancel={handleCancelSubmit}
                footer={null}
                width={1000}
                bodyStyle={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    minHeight: "400px",
                }}
            >
                <Card style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}>
                    <img
                        src={require("../../images/thumbsup-removebg-preview.png")}
                        style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}
                        alt="Thank You"
                    />
                    <br />
                    <br />
                    <h4 style={{ textAlign: "center" }}>{survey[0]?.feedbackText}</h4>
                </Card>
            </Modal>
            <Modal
                visible={isIdentityModalVisible}
                onCancel={() => setIsIdentityModalVisible(false)}
                onOk={() => postSurveyForm(isIdentityChecked)}
                okText="Submit"
                cancelText="Cancel"
            >
                <p>Do you want to disclose your identity while submitting the feedback?</p>
                <Checkbox onChange={(e) => setIsIdentityChecked(e.target.checked)}>
                    Yes, I want to reveal my identity.
                </Checkbox>
            </Modal>
        </div>
    )
}

export default Empsurvey;
