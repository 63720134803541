import React, { useState } from "react";
import { Upload, message, Spin, Progress, Steps, Row, Col, Card, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./UploadForm.css";

const { Dragger } = Upload;
const { Step } = Steps;

const steps = [
  { title: "Uploading your resume..." },
  { title: "Parsing your resume..." },
  { title: "Identifying core sections..." },
  { title: "Analyzing work experiences..." },
];

const UploadForm = () => {
  const [loading, setLoading] = useState(false);
  const [parsedData, setParsedData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [preparing, setPreparing] = useState(false);
  const navigate = useNavigate();

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        message.error("You can only upload PDF files!");
        return Upload.LIST_IGNORE; // Prevent the upload
      }
      return true; // Allow upload if it's a PDF
    },
    customRequest: ({ file, onSuccess, onError }) => {
      setLoading(true); // Start loading animation

      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Extract base64 string

        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileBase64", base64String); // Append base64 to the formData

        fetch(`https://ems.finaran.com:5000/api/uploadResume`, {
          method: "POST",
          body: formData,
        })
          .then(async (res) => {
            if (res.ok) {
              const data = await res.json();
              onSuccess(file); // Notify Upload component of success

              setCurrentStep(1); // Move to the next step
              setTimeout(() => setCurrentStep(2), 1000);
              setTimeout(() => setCurrentStep(3), 2000);
              const updatedData = {
                ...data,
                pdf: base64String,
              };
              setTimeout(() => {
                setParsedData(updatedData); // Store parsed data from backend
                setLoading(false); // Stop loading
                setCurrentStep(4);

                // Show the "Preparing" message
                setPreparing(true);
                setTimeout(() => {
                  // Navigate to ParseResume and pass parsedData
                  navigate(`/home/ParseResume`, {
                    state: { parsedData: updatedData }, // Pass parsed data
                  });
                }, 3000); // Delay the navigation for 3 seconds
              }, 3000);
            } else {
              throw new Error("Failed to upload file");
            }
          })
          .catch((err) => {
            console.error("Upload error:", err); // Log the error
            onError("Upload failed.");
            message.error("Upload failed. Please try again.");
            setLoading(false); // Stop loading on failure
          });
      };

      reader.onerror = (err) => {
        console.error("File reading error:", err);
        onError("Failed to read file");
      };

      reader.readAsDataURL(file); // Read the file as a data URL (base64)
    },
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Button onClick={goBack}>Back</Button>
      <Row justify="space-between">
        <Col span={11}>
          <div className="upload-form">
            <h3 style={{ color: "#fff" }}>Upload Your Resume</h3>
            <Dragger
              {...props}
              style={{
                backgroundColor: "#00134D",
                padding: "50px",
                borderRadius: "10px",
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text" style={{ color: "#fff" }}>
                Click or drag your resume file to this area to upload
              </p>
              <p className="ant-upload-hint" style={{ color: "#fff" }}>
                Supported format: PDF
              </p>
            </Dragger>

            {loading && (
              <div
                className="loading-overlay"
                style={{ marginTop: "20px", textAlign: "center" }}
              >
                <Spin size="large" tip="Parsing resume... Please wait" />
                {/* <Progress
            percent={(currentStep / steps.length) * 100}
            status="active"
            style={{ marginTop: "20px", color: "#fff" }}
          /> */}
                <Steps
                  current={currentStep}
                  size="small"
                  direction="vertical"
                  style={{
                    marginTop: "20px",
                    width: "300px",
                    margin: "0 auto",
                    color: "#fff",
                  }}
                >
                  {steps.map((item, index) => (
                    <Step
                      key={index}
                      title={
                        <>
                          <span style={{ color: "#fff" }}>{item.title}</span>{" "}
                          {index < currentStep && (
                            <span style={{ color: "#52c41a" }}>
                              ({Math.round(((index + 1) / steps.length) * 100)}%)
                            </span>
                          )}
                        </>
                      }
                      style={{
                        marginTop: "20px",
                        width: "300px",
                        margin: "0 auto",
                        color: "#fff",
                      }}
                    />
                  ))}
                </Steps>
              </div>
            )}

            {/* Show "Preparing..." message before navigating */}
            {preparing && (
              <div
                className="loading-overlay"
                style={{ marginTop: "20px", textAlign: "center", color: "#fff" }}
              >
                <Spin
                  size="large"
                  tip="Preparing your resume review, just a second..."
                  style={{ marginTop: "20px", textAlign: "center", color: "#fff" }}
                />
              </div>
            )}
          </div>
        </Col>
        <Col span={11}>
          <Card style={{ borderColor: '#cabdfc',
                        boxShadow: '0 3px 3px #7459e3',}}>
            <h4>Optimize Your Job Applications</h4>
            <p>
              Utilize our features to extract key information from your resume,
              ensuring a better match with job descriptions. Get insights and
              recommendations to enhance your application.
            </p>

            <h4>Steps to Get Started</h4>
            <Steps
              direction="vertical"
              current={currentStep}
              onChange={(step) => setCurrentStep(step)}
            >
              <Step
                title="Upload your resume"
                description="Upload your resume to our system."
              />
              <Step
                title="Review your information"
                description="Confirm that all your details are accurate."
              />
              <Step
                title="Submit your resume"
                description="Send your resume to our database."
              />
              <Step
                title="Get job matches"
                description="Receive insights on jobs relevant to your profile."
              />
            </Steps>

            <button className="pro-btn">Try Now for Free</button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UploadForm;
