// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-title {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items */
  position: relative; /* For positioning the duration */
}

.tab-duration {
  background-color: #f0f0f0; /* Light grey background */
  border-radius: 4px; /* Rounded corners */
  padding: 4px 8px; /* Some padding */
  margin-top: 4px; /* Spacing between label and duration */
}

.ant-tabs-card .ant-tabs-card-content {
  border-radius: 8px; /* Optional: Round corners for tab content */
}

.ant-tabs-card .ant-tabs-card-header {
  border-bottom: none; /* Remove bottom border from the card header */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Recruitment/TabComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAE,uBAAuB;EAC5C,kBAAkB,EAAE,iCAAiC;AACvD;;AAEA;EACE,yBAAyB,EAAE,0BAA0B;EACrD,kBAAkB,EAAE,oBAAoB;EACxC,gBAAgB,EAAE,iBAAiB;EACnC,eAAe,EAAE,uCAAuC;AAC1D;;AAEA;EACE,kBAAkB,EAAE,4CAA4C;AAClE;;AAEA;EACE,mBAAmB,EAAE,8CAA8C;AACrE","sourcesContent":[".tab-title {\n  display: flex;\n  flex-direction: column;\n  align-items: center; /* Center align items */\n  position: relative; /* For positioning the duration */\n}\n\n.tab-duration {\n  background-color: #f0f0f0; /* Light grey background */\n  border-radius: 4px; /* Rounded corners */\n  padding: 4px 8px; /* Some padding */\n  margin-top: 4px; /* Spacing between label and duration */\n}\n\n.ant-tabs-card .ant-tabs-card-content {\n  border-radius: 8px; /* Optional: Round corners for tab content */\n}\n\n.ant-tabs-card .ant-tabs-card-header {\n  border-bottom: none; /* Remove bottom border from the card header */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
