import React, { useEffect, useRef, useState } from "react";
import {
  DownloadOutlined,
  EyeFilled,
  EyeOutlined,
  PrinterOutlined,
  ShareAltOutlined,
  StarOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Input,
  Menu,
  Modal,
  Progress,
  Rate,
  Row,
  Select,
  Space,
  Tabs,
  Tooltip,
  message,
} from "antd";
import { url } from "../../url/url";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";

const { Item } = Menu;
const { TabPane } = Tabs;
const { Meta } = Card;
const { Option } = Select;

const Traininghome = () => {
  const [selectedMenu, setSelectedMenu] = useState("my-courses");
  const [selectedTab, setSelectedTab] = useState("1");
  const [selectedOption, setSelectedOption] = useState(null);
  const [courses, setCourses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [selfCourses, setSelfCourses] = useState([]);
  const users = JSON.parse(localStorage.getItem("user"));
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [coursesByCategory, setCoursesByCategory] = useState(allCourses);
  const [count, setCount] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [rating, setRating] = useState(0);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [documentCompleted, setDocumentCompleted] = useState([]);
  const [documentSelf, setDocumentSelf] = useState([]);
  const [documentRegistered, setDocumentRegistered] = useState([]);
  const [documentAll, setDocumentAll] = useState([]);
  const [selectedDocument, setDocument] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [modalContent1, setModalContent1] = useState([]);
  const [newWindow, setNewWindow] = useState(null);
  const [completed, setCompleted] = useState([]);
  const [courseName, setCourseName] = useState(null);
  const [locationState, setLocationState] = useState(null);
  const Navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [video, setVideo] = useState(false);
  const videoRef = useRef(null); // Create a ref for the video element

  useEffect(() => {
    console.log("!location");
    if (location?.state) {
      console.log("inside if in location set", location?.state?.key);
      setSelectedTab(location?.state?.key);
      setOpenRating(true);
      setLocationState(location?.state?.id);
      setCourseName(location?.state?.courseName);
    }
  }, [location?.state]);

  const importVideosFromFolder = (folderName) => {
    console.log(folderName, "folder");

    const requireContext = require.context(
      "../../trainingVideos",
      true,
      /\.(mp4|webm|ogg)$/
    );
    return requireContext
      .keys()
      .filter((key) => key.startsWith(`./${folderName}/`))
      .map((file) => ({
        src: requireContext(file),
        name: file.replace(`./${folderName}/`, ""),
      }));
  };
  const importContentFromFolder = (folderName, contentType) => {
    const files = [];
    console.log(folderName, contentType, "type");

    // Static path to content folder without using dynamic string
    const context = require.context(
      `../../trainingVideos`, // Set static path
      true,
      /\.(mp4|webm|ogg|pdf|docx|xlsx|pptx)$/ // Include all extensions
    );

    // Filter files based on contentType and folderName
    context.keys().forEach((key) => {
      if (
        key.startsWith(`./${folderName}/`) && // Ensure file is in the right folder
        ((contentType === "video" && /\.(mp4|webm|ogg)$/.test(key)) ||
          (contentType === "document" && /\.(pdf|docx|xlsx|pptx)$/.test(key)))
      ) {
        files.push({
          src: context(key),
          name: key.replace(`./${folderName}/`, ""),
        });
      }
    });

    return files;
  };

  const handleRatingChange = (value) => {
    setRating(value);
  };

  const handleRatingSubmit = async (course, rating) => {
    const dbName = localStorage.getItem("dbName");
    console.log(rating, location, "rating");
    let courseid;
    if (location?.state?.id) {
      courseid = location?.state?.id;
      console.log("inside if");
    } else {
      courseid = course._id;
    }
    try {
      await axios.put(`${url}/rate-course/${courseid}/${dbName}`, {
        rating,
      });
      message.success("Rating updated successfully");
      setCount(count + 1);
      handleRatingCancel();
      //window.history.replaceState({}, '', location.pathname);
      window.history.replaceState({}, "");
      console.log("location after reset state", location);
    } catch (error) {
      console.error("Error:", error); // Log the error for debugging
      message.error("Failed to update rating");
    }
  };

  const showRating = () => {
    setOpenRating(true);
  };

  const handleRatingCancel = () => {
    setOpenRating(false);
    console.log(location, "location");
    setCourseName(null);
    // Navigate(location.pathname, {
    //   replace: true,
    //   state: {},
    // });
    window.history.replaceState({}, "");
    console.log(location.state, "state in handleRatingCancel");
  };
  useEffect(() => {
    if (selectedCategory) {
      const filtered = allCourses.filter(
        (course) => course.category === selectedCategory
      );
      setCoursesByCategory(filtered);
    } else {
      setCoursesByCategory(allCourses);
    }
  }, [selectedCategory, allCourses]);

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setSelectedCourse(null); // Reset the selected course when category changes
  };
  const categoryImages = {
    Developing: require("../../images/web-programming-languages.webp"),
    "Data Science": require("../../images/data-science.jpg"),
    Design: require("../../images/design.png"),
  };

  const handleDocClick = (docSrc) => {
    setDocument(docSrc);
    setVideo(null);
  };

  const handleVideoClick = (videoSrc) => {
    setVideo({ src: videoSrc, name: videoSrc.split("/").pop() });
    setDocument(null);
  };
  const handleButtonClick = async (courseName, course) => {
    setIsLoading(true); // Start loading
    try {
      const documents = await fetchDocuments(course.courseName);
      console.log(documents, "documents");

      if (
        !documents ||
        (documents.length === 0 &&
          !documents[0].document &&
          !documents[0].video)
      ) {
        message.error("No documents or videos found for this course.");
        return;
      }

      if (!selectedCourses.includes(courseName)) {
        setSelectedCourses([...selectedCourses, courseName]);
      }

      setButtonClicked(true);
      setCurrentCourse(course);
      console.log(course, documents[0].document, "setcurrentcourse");

      let videoContent;
      let documentContent;
      // Load content dynamically
      if (documents[0].video) {
        videoContent = await importVideosFromFolder(course.courseName);
      }
      if (documents[0].document) {
        documentContent = await importContentFromFolder(
          course.courseName,
          "document"
        );
      }
      console.log(videoContent, "import");
      const modalContentCombined = (
        <div style={{ display: "flex", width: "100%", height: "100%" }}>
          {/* Thumbnails Column */}
          <div
            style={{
              flex: "1",
              padding: "10px",
              borderRight: "1px solid #ddd",
              overflowY: "auto",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px",maxHeight:"95vh",overflowY:"scroll" }}
            >
              {/* Document Thumbnails */}
              {documentContent?.map((doc, index) => (
                <div key={index} style={{ textAlign: "center" }}>
                  <Card
                    style={{
                      border: "1px solid #fcc00d",
                      width: "40vh",
                      marginBottom: "10px",

                    }}
                    onClick={() => handleDocClick(doc.src)}
                  >
                    <embed
                      src={doc.src}
                      onClick={() => handleDocClick(doc.src)}
                      style={{
                        height: "8vh",
                        width: "25vh"
                      }}
                    />
                    <p>
                      {doc.name.split("-").pop()}
                    </p>
                  </Card>
                </div>
              ))}

              {/* Video Thumbnails */}
              {videoContent?.map((videoSrc, index) => (
                <div key={index} style={{ textAlign: "center" }}>
                  <Card
                    style={{
                      border:
                        video && video.src === videoSrc.src
                          ? "2px solid #ffcc00"
                          : "1px solid #fcc00d",
                      width: "40vh",
                      marginBottom: "10px",
                    }}
                  >
                    {console.log(video.src, videoSrc.src, "videocheck")}

                    <video
                      style={{ cursor: "pointer", }}
                      src={videoSrc.src}
                      onClick={() => handleVideoClick(videoSrc.src)}
                      type="video/mp4"
                      width={150}
                      controls={false}
                    />
                    <h6 className="text-center" style={{ marginTop: "1vh" }}>
                      {videoSrc.name.split("-").pop()}
                    </h6>
                  </Card>
                </div>
              ))}
            </div>
          </div>

          {/* Content Area */}
          {/* Display selected document or video */}
          {/* {selectedDocument && (
              <div style={{ marginTop: "20px" }}>
                {selectedDocument.endsWith(".pdf") ? (
                  <embed
                    src={selectedDocument}
                    width="500"
                    height="600"
                    type="application/pdf"
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedDocument,
                    }}
                  />
                )}
              </div>
            )}

            {video && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <h6 style={{ marginBottom: "1vh" }}>{video.name}</h6>
                <video
                  key={video.index}
                  src={video.src}
                  type="video/mp4"
                  controls
                  width={450}
                />
              </div>
            )} */}
        </div>
      );

      setModalContent(modalContentCombined);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch documents:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleSubmit = async (course) => {
    const completedEntry = course.completed.find(
      (entry) => entry.name === users.displayName
    );
    const dateObj = new Date(completedEntry.date);

    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    const formData = {
      personName: users.displayName,
      courseName: course.courseName,
      date: completedEntry ? formattedDate : "",
    };
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await fetch(
        `https://ems.finaran.com:5000/api/certs/${dbName}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate certificate");
      }

      // Download the PDF certificate
      downloadCertificate();
    } catch (error) {
      console.error("Error generating certificate:", error);
      // Handle error gracefully, e.g., show a message to the user
    }
  };

  const downloadCertificate = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(
        `https://ems.finaran.com:5000/api/download_certificate/${dbName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download certificate");
      }

      // Convert response to blob
      const blob = await response.blob();
      console.log(response, "res");
      // Create download link and trigger click
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "course_completion_certificate.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading certificate:", error);
      // Handle error gracefully, e.g., show a message to the user
    }
  };
  const handleViewClick = async (course) => {
    console.log("about to fetch documents handleViewClick", course);
    const documents = await fetchDocuments(course.courseName);
    console.log(documents, "documents handleViewClick");

    if (
      (!documents || documents.length === 0 || !documents[0].document) &&
      !documents[0].video
    ) {
      message.error("No documents or videos found for this course.");
      return;
    }

    if (!selectedCourses.includes(courseName)) {
      setSelectedCourses([...selectedCourses, courseName]);
    }

    setButtonClicked(true);
    setCurrentCourse(course);
    console.log(course, "setcurrentcourse");

    // Dynamically set the modal content based on whether it's a document or video
    if (documents[0].document) {
      console.log("inside doc");
      setModalContent(
        <div>
          <embed
            src={documents[0].document}
            width="500"
            height="600"
            type="application/pdf"
          />
        </div>
      );
      setIsModalOpen1(true);
    } else if (documents[0].video) {
      const importedVideos = importVideosFromFolder(course.courseName);
      console.log(importedVideos, course.courseName, "videoinside");

      setModalContent1(importedVideos);
      setIsModalOpen(true);
    }
  };

  const handleModalClose = (course) => {
    console.log(course, selectedTab, "courses in handlemodelclose");

    if (selectedTab !== "3" && window.confirm("Completed this course?")) {
      if (course.assessment && course.assessment.length > 0) {
        const status = "Assessment Inprogress";
        updateSelfCompleted(course, status, "true");
        setIsModalOpen(false);
        Navigate(`/home/assessment/${course.courseName}`, {
          state: {
            openRating: true,
            course: course._id,
            courseName: course.courseName,
            key: selectedTab,
          },
        });
      } else {
        const status = "Completed";
        updateSelfCompleted(course, status, "false");
        setIsModalOpen(false);
      }
    } else {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
      setIsModalOpen(false);
    }
    showRating();
  };

  const handleCourseChange = (value) => {
    setSelectedCourse(value);
  };
  useEffect(() => {
    fetchCourses();
  }, [count]);

  console.log(selfCourses, "coursesinself");
  const fetchDocuments = async (course) => {
    console.log(course, "name");
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(
        `${url}/get/document/${course}/${dbName}`
      );

      const allCourses = response.data;
      console.log(allCourses, "responseindocument");
      setDocumentAll(allCourses);

      return allCourses;
    } catch (err) {
      console.log(err.message);
    }
  };
  const fetchCourses = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/empcourses/${dbName}`);

      const allCourses = response.data;
      console.log(allCourses, "response");
      setAllCourses(allCourses);
      const userCourses = allCourses.filter((course) =>
        course.registered.includes(users.displayName)
      );
      setCourses(userCourses);
      const selfCourses = allCourses.filter((course) =>
        course.selfRegistered.includes(users.displayName)
      );
      setSelfCourses(selfCourses);
      console.log(selfCourses, "coursesinself");
      const selfCompletedCourses = allCourses.filter((course) =>
        course.completed.some((entry) => entry.name === users.displayName)
      );

      console.log(selfCompletedCourses, "filtered courses");
      setCompleted(selfCompletedCourses);
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteSelfCourse = async (course) => {
    const dbName = localStorage.getItem("dbName");
    const userName = users.displayName;

    // Check if the user's name is in the selfRegistered array
    if (course.selfRegistered.includes(userName)) {
      course.selfRegistered = course.selfRegistered.filter(
        (name) => name !== userName
      );

      try {
        const response = await axios.delete(
          `${url}/remove/selfcourse/${dbName}/${course.courseName}/${userName}`
        );
        message.success(
          `${course.courseName} course has been Removed Successfully`
        );
        setSelfCourses(
          selfCourses.filter((data) => data.courseName != course.courseName)
        );
        console.log(response, "response");
      } catch (err) {
        console.log(err.message);
      }
    } else {
      console.log("User is not self-registered for this course");
    }
  };
  const addToSelectedCourses = async (course) => {
    const dbName = localStorage.getItem("dbName");
    if (
      courses.some(
        (existingCourse) => existingCourse.courseName === course.courseName
      )
    ) {
      message.warning("Course is already added to your courses!");
      return;
    }
    console.log(course._id, "Courses");
    try {
      const response = await fetch(
        `${url}/updateSelfRegistered/${dbName}/${course._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayName: users.displayName,
            Status: "Enrolled",
          }),
        }
      );

      if (response.ok) {
        console.log(response, "dtata");
        message.success("Course added successfully!");
        setCount(count + 1);
        // setSelectedMenu("my-courses");
      } else {
        throw new Error("Failed to add course");
      }
    } catch (error) {
      message.error("Error adding course: " + error.message);
    }
  };

  const updateInprogress = async (course) => {
    const dbName = localStorage.getItem("dbName");
    console.log(course._id, "Courses");
    try {
      const response = await fetch(
        `${url}/updateprogress/${dbName}/${course._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayName: users.displayName,
            Status: "Inprogress",
          }),
        }
      );

      if (response.ok) {
        console.log(response, "dtata");
        message.warning("Course Inprogress!");
        setCount(count + 1);
      } else {
        throw new Error("Failed to add course");
      }
    } catch (error) {
      message.error("Error adding course: " + error.message);
    }
  };

  const updateSelfCompleted = async (course, status, isAssesment) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await fetch(
        `${url}/updateselfcompleted/${dbName}/${course.courseName}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayName: users.displayName,
            mark: isAssesment,
            date: new Date().toISOString(),
            Status: status,
          }),
        }
      );

      if (response.ok) {
        console.log(response, "dtata");
        message.success("Course Updated successfully!");
        setCount(count + 1);
      } else {
        throw new Error("Failed to add course");
      }
    } catch (error) {
      message.error("Error adding course: " + error.message);
    }
  };

  const videoFilename = currentCourse?.video[0];

  const handleClick = () => {
    setSelectedCourse("my-courses");
  };

  const handleMenuClick = (e) => {
    setSelectedMenu(e.key);
  };

  const handleTabClick = (key) => {
    setSelectedTab(key);
  };

  const uniqueCategories = [
    ...new Set(allCourses.map((course) => course.category)),
  ];

  const filteredCourses = allCourses.filter((course) => {
    return (
      (!selectedCategory || course.category === selectedCategory) &&
      (!selectedCourse || course.courseName === selectedCourse)
    );
  });

  const Navigation = (course) => {
    console.log(course._id, selectedTab, "courseid");
    Navigate(`/home/assessment/${course.courseName}`, {
      state: {
        openRating: true,
        course: course._id,
        key: selectedTab,
      },
    });
  };

  return (
    <div style={{ zoom: "90%" }}>
      <Menu
        mode="horizontal"
        style={{ background: "#f5f5f0" }}
        onClick={handleMenuClick}
        defaultSelectedKeys={["home"]}
      >
        <Item key="my-courses">My Courses</Item>
        <Item key="all-courses">All Courses</Item>
      </Menu>

      <div id="home">
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2px",
          }}
        >
          {selectedMenu === "my-courses" && (
            <Col span={24}>
              <Card>
                <Row justify="space-between">
                  <h6>{users.displayName}'s Courses</h6>
                  <Divider />
                  <Tabs
                    activeKey={selectedTab}
                    onChange={(key) => handleTabClick(key)}
                  >
                    <TabPane key="1" tab="Courses I have to do">
                      <Row justify="space-between">
                        {courses.map((course) => (
                          <Col span={7}>
                            <Card
                              style={{
                                width: "45vh",
                                height: "55vh",
                                borderRadius: "0%",
                                border: "2px solid #d6d6c2",
                                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                              }}
                              cover={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {console.log(course.content, "content")}
                                  <div
                                    onClick={() => {
                                      let url = course.content || "";
                                      if (url === undefined || url === "") {
                                        console.warn(
                                          "Invalid or undefined URL:",
                                          url
                                        );
                                        alert(
                                          "The content URL is invalid or not available."
                                        );
                                      } else if (typeof url === "string") {
                                        if (
                                          !url.startsWith("http://") &&
                                          !url.startsWith("https://")
                                        ) {
                                          url = "https://" + url;
                                        }
                                        window.open(url, "_blank");
                                      }
                                    }}
                                  >
                                    <img
                                      src={
                                        categoryImages[course.category] ||
                                        require("../../images/Default.jpg")
                                      }
                                      style={{
                                        cursor: "pointer",
                                        height: "20vh",
                                      }}
                                      alt="Course Cover"
                                    />
                                  </div>
                                </div>
                              }
                              title={
                                <h6 className="text-center">
                                  {course.courseName}
                                </h6>
                              }
                            >
                              <h6
                                className="text-center"
                                style={{
                                  fontSize: "13px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  maxWidth: "40vh",
                                }}
                              >
                                {course.description}
                              </h6>
                              {course.assessmentPending?.some((assess) =>
                                assess.includes(users.displayName)
                              ) ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    onClick={() => Navigation(course)}
                                  >
                                    Take Assessment
                                  </Button>
                                  <EyeOutlined
                                    onClick={() => handleViewClick(course)}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    onClick={() =>
                                      handleButtonClick(
                                        course.courseName,
                                        course
                                      )
                                    }
                                  >
                                    Continue to lesson
                                  </Button>
                                </div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {course.rating ? (
                                  <Row>
                                    <Rate
                                      disabled
                                      allowHalf
                                      defaultValue={course.rating}
                                    />
                                    <span style={{ color: "red" }}>
                                      {course?.numberOfRatings}
                                    </span>
                                  </Row>
                                ) : (
                                  <Row>
                                    <Rate disabled allowHalf defaultValue={0} />
                                  </Row>
                                )}
                              </div>
                            </Card>
                            <br />
                            <Modal
                              visible={isModalOpen}
                              onCancel={() => handleModalClose(currentCourse)}
                              width={940}
                              footer={null}
                            >
                              <Col span={24}>
                                <h6>All</h6>
                              </Col>
                              <Row>
                                <Col span={10}>
                                  {modalContent}
                                </Col>
                                <Col span={12}>
                                  <div style={{ textAlign: "center" }}>
                                    {selectedDocument || video ? (
                                      <>
                                        {selectedDocument && (
                                          <div style={{ marginTop: "20px" }}>
                                            {selectedDocument.endsWith(".pdf") ? (
                                              <embed
                                                src={selectedDocument}
                                                width="500"
                                                height="500"
                                                type="application/pdf"
                                              />
                                            ) : (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: selectedDocument,
                                                }}
                                              />
                                            )}
                                          </div>
                                        )}

                                        {video && (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              marginTop: "20px",
                                            }}
                                          >
                                            <h6 style={{ marginBottom: "1vh" }}>{video.name.split("-").pop()}</h6>
                                            <video
                                              key={video.index}
                                              src={video.src}
                                              type="video/mp4"
                                              controls
                                              width={500}
                                            />
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <div style={{ marginTop: "20vh" }}>
                                        <h6>Select a document or video to view</h6>
                                      </div>
                                    )}
                                  </div>
                                </Col>

                              </Row>
                            </Modal>
                            <Modal
                              visible={openRating}
                              onCancel={handleRatingCancel}
                              footer={null}
                            >
                              <h5 className="text-center">
                                Rate your experience for{" "}
                                {location?.state
                                  ? location.state.courseName
                                  : currentCourse && currentCourse.courseName}
                              </h5>
                              <p className="text-center">
                                We highly value your feedback! Kindly take a
                                moment to rate your experience
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Rate
                                  allowHalf
                                  style={{ fontSize: "45px" }}
                                  onChange={handleRatingChange}
                                  value={rating}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "2vh",
                                }}
                              >
                                <Button
                                  type="primary"
                                  danger
                                  onClick={() =>
                                    handleRatingSubmit(currentCourse, rating)
                                  }
                                >
                                  Send
                                </Button>
                              </div>
                            </Modal>
                          </Col>
                        ))}
                      </Row>
                    </TabPane>
                    <TabPane key="2" tab="Courses I've chosen to do">
                      {console.log("selfCourses", selfCourses)}
                      <Row justify="space-between">
                        {selfCourses.map((select) => (
                          <Col span={7}>
                            <Card
                              style={{
                                width: "45vh",
                                height: "55vh",
                                borderRadius: "0%",
                                border: "2px solid #d6d6c2",
                                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                              }}
                              cover={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {console.log(select, "content1")}
                                  <div
                                    onClick={() => {
                                      let url = select.content;
                                      if (
                                        !url.startsWith("http://") &&
                                        !url.startsWith("https://")
                                      ) {
                                        url = "https://" + url;
                                      }
                                      window.open(url, "_blank");
                                    }}
                                  >
                                    <img
                                      src={
                                        categoryImages[select.category] ||
                                        require("../../images/Default.jpg")
                                      }
                                      style={{
                                        cursor: "pointer",
                                        height: "20vh",
                                      }}
                                      alt="Course Cover"
                                    />
                                  </div>
                                </div>
                              }
                              title={
                                <h6 className="text-center">
                                  {select.courseName}
                                </h6>
                              }
                            >
                              <h6
                                className="text-center"
                                style={{
                                  fontSize: "13px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  maxWidth: "40vh",
                                }}
                              >
                                {select.description}
                              </h6>
                              {select.assessmentPending?.some((assess) =>
                                assess.includes(users.displayName)
                              ) ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    onClick={() => Navigation(select)}
                                  >
                                    Take Assessment
                                  </Button>
                                  {/* <Tooltip title="View document"> */}
                                  <EyeFilled
                                    style={{
                                      marginLeft: "2vh",
                                      marginTop: "2vh",
                                    }}
                                    onClick={() => handleViewClick(select)}
                                  />
                                  {/* </Tooltip> */}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    onClick={() =>
                                      handleButtonClick(
                                        select.courseName,
                                        select
                                      )
                                    }
                                  >
                                    Continue to lesson
                                  </Button>
                                </div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {select.rating ? (
                                  <Row>
                                    <Rate
                                      disabled
                                      allowHalf
                                      defaultValue={select.rating}
                                    />
                                    <span style={{ color: "red" }}>
                                      {select?.numberOfRatings}
                                    </span>
                                  </Row>
                                ) : (
                                  <Row>
                                    <Rate disabled allowHalf defaultValue={0} />
                                  </Row>
                                )}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Link
                                  onClick={() => deleteSelfCourse(select)}
                                  style={{ color: "red" }}
                                >
                                  Remove from list
                                </Link>
                              </div>
                            </Card>
                            <br />
                            <Modal
                              visible={openRating}
                              onCancel={handleRatingCancel}
                              footer={null}
                            >
                              <h5 className="text-center">
                                Rate your experience for{" "}
                                {location?.state
                                  ? location.state.courseName
                                  : currentCourse &&
                                  currentCourse.courseName}{" "}
                              </h5>
                              <p className="text-center">
                                We highly value your feedback! Kindly take a
                                moment to rate your experience
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Rate
                                  allowHalf
                                  style={{ fontSize: "45px" }}
                                  onChange={handleRatingChange}
                                  value={rating}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "2vh",
                                }}
                              >
                                <Button
                                  type="primary"
                                  danger
                                  onClick={() =>
                                    handleRatingSubmit(currentCourse, rating)
                                  }
                                >
                                  Send
                                </Button>
                              </div>
                            </Modal>

                            <Modal
                              visible={isModalOpen}
                              onCancel={() => handleModalClose(currentCourse)}
                              width={940}
                              footer={null}
                            >
                              <Col span={24}>
                                <h6>All</h6>
                              </Col>
                              <Row>
                                <Col span={10}>
                                  {modalContent}
                                </Col>
                                <Col span={12}>
                                  <div style={{ textAlign: "center" }}>
                                    {selectedDocument || video ? (
                                      <>
                                        {selectedDocument && (
                                          <div style={{ marginTop: "20px" }}>
                                            {selectedDocument.endsWith(".pdf") ? (
                                              <embed
                                                src={selectedDocument}
                                                width="500"
                                                height="500"
                                                type="application/pdf"
                                              />
                                            ) : (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: selectedDocument,
                                                }}
                                              />
                                            )}
                                          </div>
                                        )}

                                        {video && (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              marginTop: "20px",
                                            }}
                                          >
                                            <h6 style={{ marginBottom: "1vh" }}>{video.name.split("-").pop()}</h6>
                                            <video
                                              key={video.index}
                                              src={video.src}
                                              type="video/mp4"
                                              controls
                                              width={500}
                                            />
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <div style={{ marginTop: "20vh" }}>
                                        <h6>Select a document or video to view</h6>
                                      </div>
                                    )}
                                  </div>
                                </Col>

                              </Row>
                            </Modal>
                          </Col>
                        ))}
                      </Row>
                    </TabPane>
                    <TabPane key="3" tab="Courses I've done">
                      <Row justify="space-between">
                        {completed.map((course) => (
                          <Col span={7}>
                            <Card
                              style={{
                                width: "45vh",
                                borderRadius: "0%",
                                border: "2px solid #d6d6c2",
                                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                              }}
                              cover={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {console.log(course, "content")}
                                  <div
                                    onClick={() => {
                                      let url = course.content || "";
                                      if (url === undefined || url === "") {
                                        console.warn(
                                          "Invalid or undefined URL:",
                                          url
                                        );
                                        alert(
                                          "The content URL is invalid or not available."
                                        );
                                      } else if (typeof url === "string") {
                                        if (
                                          !url.startsWith("http://") &&
                                          !url.startsWith("https://")
                                        ) {
                                          url = "https://" + url;
                                        }
                                        window.open(url, "_blank");
                                      }
                                    }}
                                  >
                                    <img
                                      src={
                                        categoryImages[course.category] ||
                                        require("../../images/Default.jpg")
                                      }
                                      style={{
                                        cursor: "pointer",
                                        height: "20vh",
                                      }}
                                      alt="Course Cover"
                                    />
                                  </div>
                                </div>
                              }
                              title={
                                <h6 className="text-center">
                                  {course.courseName}
                                </h6>
                              }
                            >
                              <h6
                                className="text-center"
                                style={{
                                  fontSize: "13px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  maxWidth: "40vh", // adjust the max width to your liking
                                }}
                              >
                                {course.description}
                              </h6>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  onClick={() => handleViewClick(course)}
                                  type="primary"
                                >
                                  View
                                </Button>
                                <Button
                                  icon={<DownloadOutlined />}
                                  style={{ left: "2vh" }}
                                  type="primary"
                                  danger
                                  title="Download Certificate"
                                  onClick={() => handleSubmit(course)}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {course.rating ? (
                                  <Row>
                                    <Rate
                                      disabled
                                      allowHalf
                                      defaultValue={course.rating}
                                    />
                                    <span style={{ color: "red" }}>
                                      {course?.numberOfRatings}
                                    </span>
                                  </Row>
                                ) : (
                                  <Row>
                                    <Rate disabled allowHalf defaultValue={0} />
                                  </Row>
                                )}
                              </div>
                            </Card>
                            <Modal
                              visible={isModalOpen1}
                              onCancel={() => setIsModalOpen1(false)}
                              width={600}
                              footer={null}
                            >
                              {modalContent}
                            </Modal>

                            <Modal
                              visible={isModalOpen}
                              onCancel={() => handleModalClose(currentCourse)}
                              width={940}
                              footer={null}
                            >
                              <Col span={24}>
                                <h6>All</h6>
                              </Col>
                              <Row>
                                <Col span={10}>
                                  {modalContent}
                                </Col>
                                <Col span={12}>
                                  <div style={{ textAlign: "center" }}>
                                    {selectedDocument || video ? (
                                      <>
                                        {selectedDocument && (
                                          <div style={{ marginTop: "20px" }}>
                                            {selectedDocument.endsWith(".pdf") ? (
                                              <embed
                                                src={selectedDocument}
                                                width="500"
                                                height="500"
                                                type="application/pdf"
                                              />
                                            ) : (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: selectedDocument,
                                                }}
                                              />
                                            )}
                                          </div>
                                        )}

                                        {video && (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              marginTop: "20px",
                                            }}
                                          >
                                            <h6 style={{ marginBottom: "1vh" }}>{video.name.split("-").pop()}</h6>
                                            <video
                                              key={video.index}
                                              src={video.src}
                                              type="video/mp4"
                                              controls
                                              width={500}
                                            />
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <div style={{ marginTop: "20vh" }}>
                                        <h6>Select a document or video to view</h6>
                                      </div>
                                    )}
                                  </div>
                                </Col>

                              </Row>
                            </Modal>

                            <br />
                          </Col>
                        ))}
                      </Row>
                    </TabPane>
                  </Tabs>
                  <Modal
                    visible={isModalOpen1}
                    onCancel={() => setIsModalOpen1(false)}
                    width={600}
                    footer={null}
                  >
                    {modalContent}
                  </Modal>
                </Row>
              </Card>
              <br />
            </Col>
          )}
          {selectedMenu === "all-courses" && (
            <Col span={24}>
              <Card>
                <h1 style={{ fontSize: "3vh", textAlign: "left" }}>
                  Too much choice? Just show courses you're interested in..
                </h1>
                <Row justify="space-between">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Select Category"
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                    style={{ width: "40vh" }}
                  >
                    {uniqueCategories.map((category) => (
                      <Option key={category} value={category}>
                        {category}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Select Courses"
                    onChange={handleCourseChange}
                    value={selectedCourse}
                    style={{ width: "40vh" }}
                    disabled={!selectedCategory}
                  >
                    {coursesByCategory.map((course) => (
                      <Option key={course.index} value={course.courseName}>
                        {course.courseName}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Card>
              <br />
              <Card>
                <h5>*Newly Released Courses*</h5>
              </Card>
              <br />
              <Card>
                <Row gutter={[16, 16]}>
                  <>
                    {/* Other JSX elements */}
                    {filteredCourses.map((allcourse, index) => {
                      let status = "";
                      {
                        console.log(
                          "allcourse.name",
                          allcourse.courseName,
                          courses,
                          selfCourses
                        );
                      }

                      const enrolled = courses.some(
                        (course) => course.courseName === allcourse.courseName
                      );
                      const enrolledSelf = selfCourses.some(
                        (course) => course.courseName === allcourse.courseName
                      );
                      const isCompleted = completed.some(
                        (course) => course.courseName === allcourse.courseName
                      );

                      if (enrolled) {
                        console.log("Enrolled", allcourse.courseName);
                        status = "Enrolled";
                      } else if (enrolledSelf) {
                        console.log("Enrolled self", allcourse.courseName);
                        status = "Enrolled";
                      } else if (isCompleted) {
                        console.log("Completed", allcourse.courseName);
                        status = "Completed";
                      }

                      return (
                        <Col key={index} span={8}>
                          <Card
                            style={{
                              width: "45vh",
                              borderRadius: "0%",
                              border: "2px solid #d6d6c2",
                              boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                            }}
                            cover={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  onClick={() =>
                                    window.open(allcourse.content, "_blank")
                                  }
                                >
                                  <img
                                    src={
                                      categoryImages[allcourse.category] ||
                                      require("../../images/Default.jpg")
                                    }
                                    style={{
                                      cursor: "pointer",
                                      height: "20vh",
                                    }}
                                    alt="Course Cover"
                                  />
                                </div>
                              </div>
                            }
                            title={
                              <h6 className="text-center">
                                {allcourse.courseName}
                              </h6>
                            }
                          >
                            <h6
                              className="text-center"
                              style={{
                                fontSize: "13px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "40vh", // adjust the max width to your liking
                              }}
                            >
                              {allcourse.description}
                            </h6>
                            {status === "Enrolled" ? (
                              <Button
                                style={{ left: "10vh" }}
                                type="primary"
                                disabled
                              >
                                Enrolled
                              </Button>
                            ) : status === "Completed" ? (
                              <Button
                                style={{ left: "10vh" }}
                                type="primary"
                                disabled
                              >
                                Completed
                              </Button>
                            ) : (
                              <Button
                                style={{ left: "5vh" }}
                                type="primary"
                                onClick={() => addToSelectedCourses(allcourse)}
                              >
                                Add to my Course
                              </Button>
                            )}
                          </Card>
                          <br />
                        </Col>
                      );
                    })}
                  </>
                </Row>
              </Card>
              <div />
            </Col>
          )}

          {/* {selectedMenu === 'add-my-learning' && (
                        <Col span={24}>

                            <Card>
                                <Row justify="space-around">
                                    <img src={require("../../images/images (3).jpg")} />
                                    <Col span={12}>
                                        <h5 style={{ fontSize: "15px" }}>First-time Line Management</h5>
                                        <p style={{ fontSize: "10px" }}>About 2 hrs 45 mins</p>
                                        <StarOutlined /><StarOutlined /><StarOutlined /><StarOutlined /><StarOutlined />
                                        <p style={{ fontSize: "12px" }}>In this course you'll find a collection of online modules that will help you make the transition into your first role as a line manager.</p>
                                        <Button type='link' icon={<ShareAltOutlined />} >Share course</Button>
                                    </Col>
                                </Row>
                                <Divider />

                            </Card>
                            <br />
                            <Row justify="space-evenly">
                                <Col span={7}>
                                    <Card cover={<><img src={require("../../images/images (1).jpg")} style={{ height: "15vh" }} /> </>}>
                                        <h5 style={{ fontSize: "13px" }}>Leadership Qualities</h5>
                                        <p style={{ fontSize: "10px" }}>About40mins</p>
                                        <Button type='primary' danger>Continue to Lesson</Button>
                                    </Card>
                                </Col>
                                <Col span={7}>
                                    <Card cover={<><img src={require("../../images/header-background.jpg")} style={{ height: "15vh" }} /> </>}>
                                        <h5 style={{ fontSize: "13px" }}>Developing Team</h5>
                                        <p style={{ fontSize: "10px" }}>About20mins</p>
                                        <Button type='primary' danger>Continue to Lesson</Button>

                                    </Card>
                                </Col>
                                <Col span={7}>
                                    <Card cover={<><img src={require("../../images/GEN-Rogelberg-Remote-Virtual-Zoom-Meeting-Video-Conference-1290x860-1.jpg")} style={{ height: "15vh" }} /> </>}>
                                        <h5 style={{ fontSize: "13px" }}>Leading Meeting</h5>
                                        <p style={{ fontSize: "10px" }}>About30mins</p>
                                        <Button type='primary' danger>Continue to Lesson</Button>

                                    </Card>
                                </Col>
                            </Row>
                        </Col >
                    )} */}
        </Row>
      </div>
    </div>
  );
};

export default Traininghome;
