import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Avatar,
  Input,
  Tooltip,
  Tabs,
  Button,
  Space,
  Card,
  Descriptions,
  Modal,
  Image,
  notification,
  Form,
  Checkbox,
  DatePicker,
  Select,
  message,
  Rate,
  Slider,
  Divider,
  Radio,
  Transfer,
  Spin,
  Menu,
  Dropdown,
  Alert,
} from "antd";
import {
  CalendarOutlined,
  UserOutlined,
  EnvironmentOutlined,
  DollarOutlined,
  CheckCircleOutlined,
  CodeOutlined,
  BarChartOutlined,
  IdcardOutlined,
  ExperimentOutlined,
  FileTextOutlined,
  HomeOutlined,
  GlobalOutlined,
  SolutionOutlined,
  BookOutlined,
  TeamOutlined,
  StarOutlined,
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  DownOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "./TabComponent.css";
import axios from "axios";
import { Collapse, Typography } from "antd";
import { url } from "../../url/url";
import moment from "moment";

const { Panel } = Collapse;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;
const questions = [
  {
    key: 1,
    label: "What is your Current Location?",
    placeholder: "Enter your current location",
    name: "currentLocation",
    prefix: (
      <EnvironmentOutlined style={{ marginBottom: "12px", color: "#96310f" }} />
    ),
    type: "input",
  },
  {
    key: 2,
    label: "Are you open to relocating?",
    name: "relocate",
    type: "radio",
    options: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
  },
  {
    key: 3,
    label: "Preferred Locations (if relocating)?",
    placeholder: "Enter preferred locations",
    name: "preferredLocations",
    prefix: (
      <EnvironmentOutlined style={{ marginBottom: "12px", color: "#96310f" }} />
    ),
    type: "input",
  },
  {
    key: 4,
    label: "What is your current CTC?",
    placeholder: "Enter your current CTC",
    name: "currentCTC",
    prefix: (
      <DollarOutlined style={{ marginBottom: "12px", color: "#96310f" }} />
    ),
    type: "input",
  },
  {
    key: 5,
    label: "What are your salary expectations for this role?",
    placeholder: "Enter your expected CTC",
    name: "expectedCTC",
    prefix: (
      <DollarOutlined style={{ marginBottom: "12px", color: "#96310f" }} />
    ),
    type: "input",
  },
  {
    key: 6,
    label:
      "How many years of experience do you have in your primary skill area?",
    placeholder: "Enter total years of experience",
    name: "yearsExperience",
    prefix: (
      <CalendarOutlined style={{ marginBottom: "12px", color: "#96310f" }} />
    ),
    type: "input",
  },
  {
    key: 7,
    label:
      "On a scale of 1 to 5, how would you rate your proficiency in your primary skill?",
    name: "primarySkillsRating",
    type: "slider",
    min: 1,
    max: 5,
  },
  {
    key: 8,
    label:
      "On a scale of 1 to 5, how would you rate your proficiency in your secondary skill?",
    name: "secondarySkillsRating",
    type: "slider",
    min: 1,
    max: 5,
  },
  {
    key: 9,
    label: "What are your primary skills relevant to this position?",
    placeholder: "Enter your primary skills",
    name: "primarySkills",
    prefix: <CodeOutlined style={{ marginBottom: "12px", color: "#96310f" }} />,
    type: "input",
  },
  {
    key: 10,
    label:
      "What are your secondary skills that complement your primary expertise?",
    placeholder: "Enter your secondary skills",
    name: "secondarySkills",
    prefix: (
      <BarChartOutlined style={{ marginBottom: "12px", color: "#96310f" }} />
    ),
    type: "input",
  },
];

const ApplicantDetail = () => {
  const { jobId: initialJobId } = useParams();
  const [jobId, setJobId] = useState(initialJobId);
  const encodedJobId = encodeURIComponent(jobId);
  const location = useLocation();
  const candidateId = location.state?.candidateId;
  const initialRole = location.state?.role || "";
  const [role, setRole] = useState(initialRole);
  const [loading, setLoading] = useState(false);
  const [Users, setUsers] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [activeTab, setActiveTab] = useState("Pre Screening");
  const dbName = localStorage.getItem("dbName");
  const [candidateAccepted, setCandidateAccepted] = useState(false);
  const [isRemoteChecked, setIsRemoteChecked] = useState(false);
  const [isLocationDisabled, setIsLocationDisabled] = useState(false);
  const [notes, setNotes] = useState(selectedRecord?.notes || "");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isResumeVisible, setIsResumeVisible] = useState(false);
  const [isResumeEdit, setIsResumeEdit] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isFirstInterviewFinished, setIsFirstInterviewFinished] = useState(
    false
  );
  const [count, setCount] = useState([]);
  const [skills, setSkills] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [targetKeys1, setTargetKeys1] = useState([]);
  const [ratings, setRatings] = useState({});
  const [ratings1, setRatings1] = useState({});
  const [user, setUser] = useState({});
  const [answer, setAnswer] = useState([]);
  const [form, setForm] = useState({
    comments: "",
    questionsAndAnswers: {},
    primarySkills: [],
    secondarySkills: [],
  });
  const [jobs, setJob] = useState([]);
  const navigate = useNavigate();
  const [skill, setSkill] = useState([]);
  const [education, setEducation] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [projects, setProjects] = useState([]);
  const [summary, setSummary] = useState([]);
  const [pdf, setPdf] = useState();
  const users = JSON.parse(localStorage.getItem("user"));
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [resumeData, setResumeData] = useState({
    primarySkills: [],
    secondarySkills: [],
  });
  const [isRescheduling, setIsRescheduling] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState();
  const [scheduledDate, setScheduledDate] = useState(null);

  const mapDataToForm = () => {
    if (user) {
      form1.setFieldsValue({
        candidateName: user["candidateName"] || "",
        experience: user["totalExperience"] || "",
        jobTitle: user["currentJobTitle"] || "",
        location: user["location"] || "",
        availability: user["availability"] || "",
        language: Array.isArray(user["languageProficiency"])
          ? user["languageProficiency"].join(", ")
          : "",
        salary: user["salaryExpectation"] || "", // Check if the key name is correct
        Email: user["email"] || "",
        PhoneNumber: user["phoneNumber"] || "", // Make sure key matches the actual user object
      });

      // Ensure the `Key Skills` field is an array, default to an empty array if undefined or null
      setSkill(Array.isArray(user["keySkills"]) ? user["keySkills"] : []);

      // Ensure `Education` is an array, default to an empty array if undefined or null
      setEducation(Array.isArray(user["education"]) ? user["education"] : []);

      // Ensure `Certifications` is an array, default to an empty array if undefined or null
      setCertifications(
        Array.isArray(user["certifications"]) ? user["certifications"] : []
      );

      // Ensure `Projects/Achievements` is an array, default to an empty array if undefined or null
      setProjects(
        Array.isArray(user["projectsAchievements"])
          ? user["projectsAchievements"]
          : []
      );

      // Handle summary and notes, ensuring the default value is an empty array or string
      setSummary(
        Array.isArray(user["notes"]) ? user["notes"] : [user["notes"] || ""]
      );

      // Handle the pdf URL or file
      setPdf(user["pdf"] || ""); // Default to empty if not available
    }
  };

  useEffect(() => {
    mapDataToForm();
  }, [user]);

  const handleAdd = (setter) => {
    setter((prev) => [...prev, ""]);
  };

  const handleRemove = (index, setter) => {
    setter((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (candidateId && jobId) {
      fetchData();
    }
  }, [candidateId, jobId, count]);

  useEffect(() => {
    fetchSkills();
    fetchUsers();
  }, [count]);

  const saveFormDataToSelectedRecord = () => {
    if (selectedRecord && selectedRecord.interviewArray) {
      selectedRecord.interviewArray[activeTab] = { ...form };
    }
  };

  useEffect(() => {
    if (selectedRecord && selectedRecord.interviewArray) {
      const currentInterview =
        selectedRecord.interviewArray.find(
          (interview) => interview.roundName === activeTab
        ) || selectedRecord.interviewArray[0];

      setForm({
        ...currentInterview,
        primarySkills: resumeData.primarySkills || [],
        secondarySkills: resumeData.secondarySkills || [],
      });
      if (isRescheduling) {
        form2.setFieldsValue({
          roundName: currentInterview?.roundName || "",
          interviewerName: currentInterview?.interviewerName || "",
          location: currentInterview?.location || "",
          description: currentInterview?.description || "",
        });
        setIsInitialLoad(false);
        const interviewDate = currentInterview?.interviewDate;
        const formattedDate = interviewDate
          ? moment(interviewDate).format("YYYY-MM-DD HH:mm:ss")
          : "";

        setDate(formattedDate);
      } else {
        form2.resetFields();
      }
    }
  }, [activeTab, selectedRecord, resumeData, isRescheduling, isInitialLoad]);

  if (!selectedRecord) {
    return <p>No record found for ID: {jobId}</p>;
  }

  const handleDateChange = (date) => {
    setScheduledDate(date);
  };

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const fetchSkills = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${url}/get/candidateResumes/${candidateId}/${dbName}`
      );
      const data = response.data[0];
      setUser(data);

      // Format the key skills, primary skills, and secondary skills
      const formattedSkills = data.keySkills.map((skill, index) => ({
        key: index.toString(),
        title: skill,
      }));

      setSkills(formattedSkills);

      // Initialize primary and secondary target keys with ratings
      const primaryTargetKeys = data.primarySkills
        .map((skill, index) => {
          return formattedSkills.find((s) => s.title === skill.skill)?.key;
        })
        .filter((key) => key !== undefined);

      const secondaryTargetKeys = data.secondarySkills
        .map((skill, index) => {
          return formattedSkills.find((s) => s.title === skill.skill)?.key;
        })
        .filter((key) => key !== undefined);

      setTargetKeys(primaryTargetKeys);
      setTargetKeys1(secondaryTargetKeys);

      return data;
    } catch (error) {
      console.error("Failed to fetch updated details:", error);
    }
    setLoading(false);
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${url}/api/interviewDetails/${dbName}/${candidateId}/${encodedJobId}`
      );

      if (!response.ok) {
        if (response.status === 404) {
          console.log("Job application not found.");
        } else {
          console.error(
            "Error fetching interview details:",
            response.statusText
          );
        }
        return;
      }

      const data = await response.json();
      const fetchedResumeData = await fetchSkills();
      const res = await axios.get(
        `${url}/api/getJobs/${dbName}/${candidateId}`
      );
      const allJobs = res.data;

      setJob(allJobs);
      setResumeData({
        primarySkills: fetchedResumeData.primarySkills || [],
        secondarySkills: fetchedResumeData.secondarySkills || [],
        candidateName: fetchedResumeData.candidateName || "",
        email: fetchedResumeData.email || "",
        currentJobTitle: fetchedResumeData.currentJobTitle || "",
        PhoneNumber: fetchedResumeData.phoneNumber || "",
        notes: fetchedResumeData.notes || [],
      });

      const appliedHistory = data.history.find(
        (entry) => entry.changeType === "Created"
      );
      const appliedDate = appliedHistory
        ? appliedHistory.date
        : data.history[0]?.changeType === "Created"
        ? data.history[0].date
        : null;

      const interviewArray = data?.interviews || [];
      setSelectedRecord({
        ...data,
        interviews: interviewArray,
        summary: fetchedResumeData.notes || [],
        candidateName: fetchedResumeData.candidateName || "",
        email: fetchedResumeData.email || "",
        currentJobTitle: fetchedResumeData.currentJobTitle || "",
        PhoneNumber: fetchedResumeData.phoneNumber || "",
        AppliedDate: formatDate(appliedDate),
        interviewArray,
      });

      // Set form based on activeTab mapped with roundName
      const initialInterview =
        interviewArray.find((interview) => interview.roundName === activeTab) ||
        interviewArray[0]; // fallback to the first interview if no match

      setForm({
        ...initialInterview,
        primarySkills: fetchedResumeData.primarySkills || [],
        secondarySkills: fetchedResumeData.secondarySkills || [],
      });
    } catch (error) {
      console.error("Error fetching interview details:", error);
    }
    setLoading(false);
  };

  const handleChange = (index, value, setter) => {
    setter((prev) => prev.map((item, i) => (i === index ? value : item)));
  };

  const handleChange1 = (newTargetKeys) => {
    setTargetKeys1(newTargetKeys);
  };

  const onFinish = async (values) => {
    const updatedData = {
      candidateName: values.candidateName,
      experience: values.experience,
      jobTitle: values.jobTitle,
      location: values.location,
      availability: values.availability,
      language: values.language,
      salaryExpectation: values.salary,
      Email: values.Email,
      PhoneNumber: values.PhoneNumber,
      Education: education, // Updated education array
      Certifications: certifications, // Updated certifications array
      "Projects/Achievements": projects, // Updated projects array
      "Key Skills": skill, // Updated skills array
      pdf: pdf,
      Notes: summary,
      jobId: user["jobId"],
      role: user["role"],
      referralName: user["referralName"],
    };

    // Send updatedData to the server or handle it as needed
    console.log("Updated form data:", updatedData);
    // Make an API call to save the updated data (POST request)
    await axios
      .post(`${url}/api/resume/${dbName}/${users.displayName}`, updatedData)
      .then((response) => {
        console.log("Data saved successfully", response.data);

        notification.success({
          message: "CV Saved Successfully",
          style: {
            top: "25px",
            left: "25px",
          },
        });
      })
      .catch((error) => {
        console.error("Error saving data", error);

        // Check if the error response exists and display the custom message
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          notification.error({
            message: "Error Saving CV",
            description: error.response.data.message, // Show error message from backend
            style: {
              top: "25px",
              left: "25px",
            },
          });
        } else {
          // Display a generic error message if there's no custom error message
          notification.error({
            message: "Error Saving CV",
            description:
              "An error occurred while saving the CV. Please try again.",
            style: {
              top: "25px",
              left: "25px",
            },
          });
        }
      });
    setIsResumeEdit(false);
  };

  const handleViewResume = () => {
    setIsResumeVisible(true);
  };

  const handleEditResume = async () => {
    setIsResumeEdit(true);
  };

  const handleScheduleInterview = (recordType) => {
    if (recordType === "reschedule") {
      setIsRescheduling(true);
      setIsInitialLoad(true);
    } else {
      setIsRescheduling(false);
    }
    setIsModalVisible(true);
  };

  const handleCloseInterviewModal = () => setIsModalVisible(false);

  const handleCheckboxChange = (e) => {
    setIsLocationDisabled(e.target.checked);
  };

  const handleCandidateAcceptedChange = (e) => {
    setCandidateAccepted(e.target.checked);
  };

  const handleFormSubmit = async (values) => {
    try {
      const encodedjobId = encodeURIComponent(jobId);
      const candidateId = user.candidateId;

      if (!jobId || !candidateId) {
        console.error("Job ID or Candidate ID is missing");
        return;
      }

      const formattedDate = scheduledDate
        ? moment(scheduledDate).format("YYYY-MM-DD HH:mm")
        : null;
      const interviewData = {
        roundName: values.roundName || activeTab,
        interviewStatus: "Scheduled",
        interviewerName: values.interviewerName,
        location: values.location,
        interviewDate: scheduledDate
          ? scheduledDate.toISOString()
          : new Date().toISOString(),
        description: values.description,
      };

      const response = await axios.put(
        `${url}/api/jobApplication/${dbName}/${encodedJobId}/${candidateId}`,
        {
          interviewData,
          updatedBy: users.displayName,
          roundName: values.roundName || activeTab,
          comments: values.comments || form.comments,
        }
      );

      const candidateEmail = selectedRecord.email;

      const emailSubject = isRescheduling
        ? `Interview Rescheduling for ${role} - ${values.roundName} Round`
        : `Upcoming Interview for ${role} - ${values.roundName} Round`;

      const emailMessage = `Dear ${selectedRecord.candidateName},

We ${
        isRescheduling
          ? "would like to inform you that your interview has been rescheduled"
          : "are pleased to inform you that your interview has been scheduled"
      } for the position of ${role}.

**Interview Details:**
- **Interview Name:** ${values.interviewerName}
- **Round:** ${values.roundName}
- **Date and Time:** ${formattedDate}
- **Location:** ${values.location}
- **Round Description:** ${values.description}

Best regards,
The Recruitment Team`;

      // Create ICS file content
      const icsContent = `BEGIN:VCALENDAR
  VERSION:2.0
  PRODID:-//YourCompany//YourProduct//EN
  BEGIN:VEVENT
  UID:${new Date().getTime()}@yourcompany.com
  DTSTAMP:${moment()
    .utc()
    .format("YYYYMMDDTHHmmss")}Z
  DTSTART:${moment(values.interviewDate)
    .utc()
    .format("YYYYMMDDTHHmmss")}Z
  DTEND:${moment(values.interviewDate)
    .add(1, "hour")
    .utc()
    .format("YYYYMMDDTHHmmss")}Z
  SUMMARY:${values.interviewName} - ${values.roundName}
  DESCRIPTION:${values.description}
  LOCATION:${values.location}
  END:VEVENT
  END:VCALENDAR`;

      // Send email with ICS file attachment to add directly to the calendar
      await axios.post(`${url}/api/sendInterviewEmail`, {
        email: candidateEmail,
        subject: emailSubject,
        message: emailMessage,
        attachments: [
          {
            filename: "interview.ics",
            content: icsContent,
            type: "text/calendar",
          },
        ],
      });

      form2.resetFields();
      handleCloseInterviewModal();
      setCount(count + 1);
      notification.success({
        message: "Success",
        description: "Interview updated successfully",
        placement: "topRight",
      });
    } catch (error) {
      console.error("Error updating interview:", error);
      notification.error({
        message: "Error",
        description: "Failed to update interview. Please try again.",
        placement: "topRight",
      });
    }
  };

  const handleTabChange = (key) => {
    saveFormDataToSelectedRecord();
    setActiveTab(key);
  };

  // Function to handle changes in question answers and update form state
  const handleQuestionsChange = (question, value) => {
    setForm((prevForm) => {
      const answersArray = Array.isArray(prevForm.questionsAndAnswers)
        ? prevForm.questionsAndAnswers
        : [];

      // Check if the question already exists in answersArray
      const existingAnswerIndex = answersArray.findIndex(
        (qa) => qa.question === question
      );

      // If the question exists, update its answer; otherwise, add it as a new entry
      const updatedAnswers =
        existingAnswerIndex >= 0
          ? answersArray.map((qa, index) =>
              index === existingAnswerIndex ? { ...qa, answer: value } : qa
            )
          : [...answersArray, { question, answer: value }];

      return { ...prevForm, questionsAndAnswers: updatedAnswers };
    });
  };

  const handleOk = (value) => {
    axios
      .post(`${url}/api/shortlist/${dbName}`, {
        candidateId: user.candidateId,
        candidateName: user.candidateName,
        jobId,
        role,
        updatedBy: users.displayName,
        status: value,
        roundName: activeTab,
      })
      .then((response) => {
        // Handle success
        notification.success({
          message: "Success",
          description:
            response.data.message || "Candidate shortlisted successfully.",
        });
        setCount(count + 1);
      })
      .catch((error) => {
        // Handle error
        notification.error({
          message: "Error",
          description:
            error.response?.data?.error ||
            "An error occurred while shortlisting the candidate.",
        });
      });
  };

  const renderInput = (question) => {
    const answersArray = Array.isArray(form?.questionsAndAnswers)
      ? form.questionsAndAnswers
      : [];
    const foundAnswer = answersArray.find(
      (qa) => qa.question === question.label
    );
    const answer = foundAnswer ? foundAnswer.answer : "";

    switch (question.type) {
      case "input":
        return (
          <Form.Item>
            <Input
              value={answer}
              size="small"
              style={{ width: "50vh", height: "6vh" }}
              placeholder={question.placeholder}
              prefix={question.prefix}
              onChange={(e) =>
                handleQuestionsChange(question.label, e.target.value)
              }
            />
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item>
            <Radio.Group
              value={answer}
              onChange={(e) =>
                handleQuestionsChange(question.label, e.target.value)
              }
            >
              {question.options.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      case "slider":
        return (
          <Form.Item>
            <Slider
              value={answer}
              min={question.min}
              max={question.max}
              step={1}
              onChange={(value) => handleQuestionsChange(question.label, value)}
            />
          </Form.Item>
        );
      default:
        return null;
    }
  };
  const handleSelectForNextRound = () => {
    console.log("Selected for Next Round");
    // Logic for selecting for next round
  };

  const handleFinalSelect = () => {
    console.log("Selected");
    // Logic for final selection
  };

  const renderTabContent = (roundName) => {
    // Check if the roundName is for pre-screening
    const alertType =
      {
        Created: "info",
        Applied: "info",
        ["Pre Screened"]: "info",
        Shortlisted: "success",
        Selected: "success",
        Rejected: "error",
      }[selectedRecord.status] || "info";

    const menu = (
      <Menu>
        <Menu.Item
          key="nextRound"
          onClick={() => handleOk("Selected for next round")}
        >
          Select for Next Round
        </Menu.Item>
        <Menu.Item key="selected" onClick={() => handleOk("Selected")}>
          Selected
        </Menu.Item>
      </Menu>
    );

    if (roundName === "Pre Screening") {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "-6vh",
            }}
          >
            <Button
              onClick={() => handleScheduleInterview()}
              disabled={
                selectedRecord?.interviews?.[0]?.interviewStatus !==
                  "Selected for next round" ||
                selectedRecord?.interviews?.length > 1
              }
            >
              {console.log(
                selectedRecord?.interviews?.length,
                " selectedRecord?.interviews?.length"
              )}
              <CalendarOutlined /> Schedule Interview
            </Button>
          </div>
          <br />
          <Card>
            <Row gutter={16}>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        border: "1px solid #ddd",
                      }}
                      src={
                        user?.profileImage
                          ? user?.profileImage
                          : Math.random() > 0.5
                          ? require("../../images/male1.png")
                          : require("../../images/female1.png")
                      }
                      alt="Profile"
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <h5 style={{ margin: 0 }}>
                        {selectedRecord?.candidateName}
                      </h5>
                      <div
                        style={{
                          marginLeft: "8px",
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        {selectedRecord.status !== "Selected" &&
                          selectedRecord.status !== "Rejected" &&
                          selectedRecord.status === "Pre Screened" && (
                            <Tooltip title="Shortlist">
                              <StarOutlined
                                onClick={() => handleOk("Shortlisted")}
                                style={{ color: "#ffa500", cursor: "pointer" }}
                              />
                            </Tooltip>
                          )}
                        {selectedRecord.status !== "Applied" && (
                          <>
                            <Dropdown overlay={menu} trigger={["click"]}>
                              <Tooltip title="Select">
                                <CheckOutlined
                                  style={{ color: "green", cursor: "pointer" }}
                                />
                              </Tooltip>
                            </Dropdown>
                            <Tooltip title="Reject">
                              <CloseOutlined
                                onClick={() => handleOk("Rejected")}
                                style={{ color: "red", cursor: "pointer" }}
                              />
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <Alert
                      message={`Status: ${selectedRecord.status}`}
                      type={alertType}
                      showIcon
                    />
                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <Button onClick={handleViewResume}>View Resume</Button>
                    <Button onClick={handleEditResume}>Edit Resume</Button>
                  </div>
                </div>
                <br />

                <Descriptions
                  bordered
                  column={2}
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <Descriptions.Item
                    label="Email:"
                    style={{ textAlign: "left" }}
                  >
                    {selectedRecord?.email || "Not available"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Current Job Position:"
                    style={{ textAlign: "left" }}
                  >
                    {selectedRecord?.currentJobTitle}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Phone:"
                    style={{ textAlign: "left" }}
                  >
                    {selectedRecord?.PhoneNumber || "Not available"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Applied Date:"
                    style={{ textAlign: "left" }}
                  >
                    {selectedRecord?.AppliedDate || "Not assigned"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Summary:"
                    style={{ textAlign: "left" }}
                  >
                    {selectedRecord?.summary || "Not available"}
                  </Descriptions.Item>
                </Descriptions>

                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginTop: "10px",
                  }}
                >
                  <Tabs defaultActiveKey="1" style={{ flex: 1 }}>
                    <TabPane tab="Note" key="1">
                      <textarea
                        placeholder="Internal notes..."
                        rows={4}
                        style={{
                          width: "80%",
                          padding: "10px",
                          borderRadius: "5px",
                          marginBottom: "10px",
                          boxSizing: "border-box",
                          border: "1px solid #ccc",
                        }}
                        value={form.comments}
                        onChange={handleNotesChange}
                      />
                    </TabPane>

                    <TabPane tab="Questions" key="2">
                      <div
                        style={{
                          maxHeight: "56vh",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <Row gutter={[16, 16]} justify="center">
                          {questions.map((question, index) => (
                            <Col span={12} key={question.key}>
                              <h6
                                style={{ fontSize: "13px", color: "#96310f" }}
                              >
                                {index + 1}. {question.label}
                              </h6>
                              {renderInput(question)}
                              <Divider />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </TabPane>

                    <TabPane tab="Skills" key="3">
                      <Row gutter={[16, 16]} justify="space-between">
                        <Col xs={24} sm={24} lg={11}>
                          <Transfer
                            dataSource={skills}
                            titles={["Key skills", "Primary"]}
                            targetKeys={targetKeys}
                            onChange={(newSkills) =>
                              handleSkillsChange(newSkills, "primarySkills")
                            }
                            render={renderSkillWithRating}
                            listStyle={{ height: 250 }}
                            showSearch={true}
                            filterOption={(inputValue, item) =>
                              item.title
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                          />
                        </Col>

                        <Col xs={24} sm={24} lg={11}>
                          <Transfer
                            dataSource={skills}
                            titles={["Key skills", "Secondary"]}
                            targetKeys={targetKeys1}
                            onChange={(newSkills) =>
                              handleSkillsChange(newSkills, "secondarySkills")
                            }
                            render={renderSkillWithRating1}
                            listStyle={{ height: 250 }}
                            showSearch={true}
                            filterOption={(inputValue, item) =>
                              item.title
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>

                  {users.jobTitle === "hr" && (
                    <Button
                      type="primary"
                      onClick={handleSubmit1}
                      style={{ marginLeft: "10px" }}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      );
    }

    // For other rounds, render the specific round content
    const interviewData = selectedRecord.interviews.find(
      (interview) => interview.roundName === roundName
    );

    const isLastInterview =
      selectedRecord.interviews[selectedRecord.interviews.length - 1]
        ?.roundName === activeTab;
    console.log(selectedRecord, activeTab, isLastInterview, "last");
    if (interviewData) {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "-6vh",
            }}
          >
            {interviewData?.interviewStatus !== "Scheduled" ? (
              <Button
                onClick={() => handleScheduleInterview()}
                disabled={
                  !(
                    interviewData?.interviewStatus === "Selected for next round"
                  ) ||
                  interviewData?.interviewStatus === "Rejected" ||
                  !isLastInterview
                }
              >
                <CalendarOutlined /> Schedule Interview
              </Button>
            ) : (
              <Button
                onClick={() => handleScheduleInterview("reschedule")}
                disabled={interviewData?.interviewStatus === "Rejected"}
              >
                <CalendarOutlined /> Reschedule Interview
              </Button>
            )}
          </div>
          <br />
          <Card>
            <Row gutter={16}>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Left section: Profile Image and Name */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        border: "1px solid #ddd",
                      }}
                      src={
                        user?.profileImage
                          ? user?.profileImage
                          : Math.random() > 0.5
                          ? require("../../images/male1.png")
                          : require("../../images/female1.png")
                      }
                      alt="Profile"
                    />
                    <div style={{ marginLeft: "10px" }}>
                      <h5>{selectedRecord?.candidateName}</h5>
                    </div>
                  </div>

                  <div
                    style={{
                      marginLeft: "8px",
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    {selectedRecord.status !== "Selected" &&
                      selectedRecord.status !== "Rejected" &&
                      selectedRecord.status === "Pre Screened" && (
                        <Tooltip title="Shortlist">
                          <StarOutlined
                            onClick={() => handleOk("Shortlisted")}
                            style={{ color: "#ffa500", cursor: "pointer" }}
                          />
                        </Tooltip>
                      )}
                    {selectedRecord.status !== "Applied" && (
                      <>
                        <Dropdown overlay={menu} trigger={["click"]}>
                          <Tooltip title="Select">
                            <CheckOutlined
                              style={{ color: "green", cursor: "pointer" }}
                            />
                          </Tooltip>
                        </Dropdown>
                        <Tooltip title="Reject">
                          <CloseOutlined
                            onClick={() => handleOk("Rejected")}
                            style={{ color: "red", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                  {/* Right section: Status Alert */}
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <Alert
                    message={`Status: ${selectedRecord.status}`}
                    type={alertType}
                    showIcon
                  />
                </div>

                <br />
                <Descriptions bordered column={2}>
                  <Descriptions.Item label="Round:">
                    {interviewData?.roundName || "Not available"}
                  </Descriptions.Item>

                  <Descriptions.Item label="Interviewer Name:">
                    {interviewData?.interviewerName || "Not available"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Location:">
                    {interviewData?.location || "Not available"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Interview Date:">
                    {interviewData?.interviewDate
                      ? formatDate(interviewData.interviewDate)
                      : "Not available"}
                  </Descriptions.Item>

                  <Descriptions.Item label="Description:">
                    {interviewData?.description || "No comments"}
                  </Descriptions.Item>
                </Descriptions>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginTop: "10px",
                  }}
                >
                  {console.log(form.comments, "comments")}
                  <Tabs defaultActiveKey="1" style={{ flex: 1 }}>
                    <TabPane tab="Feed Back" key="1">
                      <textarea
                        value={form.comments}
                        onChange={handleNotesChange}
                        placeholder="Feed back comments..."
                        rows={4}
                        style={{
                          width: "100%",
                          padding: "10px",
                          borderRadius: "5px",
                          marginBottom: "10px",
                        }}
                      />
                    </TabPane>
                  </Tabs>
                  {users.displayName === interviewData.interviewerName && (
                    <Button
                      type="primary"
                      onClick={handleFormSubmit}
                      style={{ marginLeft: "10px" }}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      );
    }
  };

  const handleNotesChange = (e) => {
    const { value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      comments: value,
    }));
  };

  const handleRateChange1 = (key, value) => {
    setForm((prevForm) => {
      const skillTitle = skills?.find((s) => s.key === key)?.title;
      if (!skillTitle) return prevForm;

      // Update primarySkills rating
      const updatedPrimarySkills = prevForm.primarySkills.map((skill) => {
        if (skill.skill === skillTitle) {
          return { ...skill, rating: value };
        }
        return skill;
      });

      console.log("Updated primarySkills:", updatedPrimarySkills);
      return { ...prevForm, primarySkills: updatedPrimarySkills };
    });
  };

  const handleRateChange2 = (key, value) => {
    setForm((prevForm) => {
      const skillTitle = skills?.find((s) => s.key === key)?.title;
      if (!skillTitle) return prevForm;

      // Update secondarySkills rating
      const updatedSecondarySkills = prevForm.secondarySkills.map((skill) => {
        if (skill.skill === skillTitle) {
          return { ...skill, rating: value };
        }
        return skill;
      });

      console.log("Updated secondarySkills:", updatedSecondarySkills);
      return { ...prevForm, secondarySkills: updatedSecondarySkills };
    });
  };

  const handleSkillsChange = (newSkills, skillType) => {
    const updatedSkills = newSkills
      .map((key) => {
        const skillObject = skills?.find((skill) => skill.key === key);
        const existingSkill = form[skillType]?.find(
          (skill) => skill.skill === skillObject?.title
        );

        return skillObject
          ? {
              skill: skillObject.title,
              rating: existingSkill ? existingSkill.rating : 0, // Use existing rating or initialize to 0
            }
          : null; // Initialize to null if skill not found
      })
      .filter((skill) => skill !== null); // Filter out null entries

    setForm((prevForm) => ({
      ...prevForm,
      [skillType]: updatedSkills, // Update the specific skill type in the form
    }));

    if (skillType === "primarySkills") {
      setTargetKeys(newSkills);
    } else {
      setTargetKeys1(newSkills);
    }
  };

  const handleSubmit1 = () => {
    // Check if all fields are empty
    if (
      !form.comments &&
      Object.keys(form.questionsAndAnswers || {}).length === 0 &&
      Array.isArray(form.primarySkills) &&
      form.primarySkills.length === 0 &&
      Array.isArray(form.secondarySkills) &&
      form.secondarySkills.length === 0
    ) {
      notification.warning({
        message: "No data to submit",
        description: "Please fill in at least one field before submitting.",
        style: { top: "25px", left: "25px" },
      });
      return;
    }

    const formData = {
      jobId,
      comments: form.comments,
      questionsAndAnswers: form.questionsAndAnswers,
      primarySkills: form.primarySkills || [],
      secondarySkills: form.secondarySkills || [],
      updatedBy: users.displayName,
      roundName: activeTab,
    };
    axios
      .put(
        `${url}/api/jobApplication/${dbName}/${encodedJobId}/${user.candidateId}`,
        formData
      )
      .then((response) => {
        console.log("Data submitted successfully:", response.data);
        notification.success({
          message: "Updated Successfully",
          style: {
            top: "25px",
            left: "25px",
          },
        });
        setForm({
          comments: "",
          questionsAndAnswers: {},
          primarySkills: "",
          secondarySkills: "",
        });
        setCount(count + 1);
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        notification.error({
          message: "Submission Error",
          description:
            "There was an error while submitting the data. Please try again.",
          style: {
            top: "25px",
            left: "25px",
          },
        });
      });
  };
  const renderSkillWithRating = (item) => {
    const skillRating =
      form?.primarySkills?.find((skill) => skill.skill === item.title)
        ?.rating || 0;


    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <span style={{ flex: 1 }}>{item.title}</span>
          {targetKeys.includes(item.key) && (
            <Rate
              allowHalf
              defaultValue={skillRating} // Use updated skillRating here
              onChange={(value) => handleRateChange1(item.key, value)}
              style={{ marginLeft: 8, fontSize: "15px" }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderSkillWithRating1 = (item) => {
    const skillRating =
      form?.secondarySkills?.find((skill) => skill.skill === item.title)
        ?.rating || 0;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <span style={{ flex: 1 }}>{item.title}</span>
          {targetKeys1.includes(item.key) && (
            <Rate
              allowHalf
              defaultValue={skillRating} // Use updated skillRating here
              onChange={(value) => handleRateChange2(item.key, value)}
              style={{ marginLeft: 8, fontSize: "15px" }}
            />
          )}
        </div>
      </div>
    );
  };

  const handleMenuClick = ({ key }) => {
    const selectedJob = jobs.find((job) => job.jobId === key);
    if (selectedJob) {
      setJobId(selectedJob.jobId);
      setRole(selectedJob.role);
    }
    setVisible(false);
  };

  const jobMenu = (
    <Menu onClick={handleMenuClick}>
      {jobs.map((job) => (
        <Menu.Item key={job.jobId}>
          {job.role} - {job.jobId}
        </Menu.Item>
      ))}
    </Menu>
  );

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        // Custom loader with your image GIF
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ padding: "20px" }}>
        <Button onClick={goBack} icon={<ArrowLeftOutlined/>}/>
          <div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <h6 style={{ color: "#00134D", textAlign: "center", margin: 0 }}>
                {role} Role
              </h6>
              <Dropdown overlay={jobMenu} trigger={["click"]}>
                <DownOutlined style={{ cursor: "pointer", fontSize: "16px" }} />
              </Dropdown>
            </div>

            {/* Tabs Component */}
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "10px",
              }}
            >
              <Tabs
                defaultActiveKey="1"
                activeKey={activeTab}
                type="card"
                onChange={handleTabChange}
                style={{ flex: 1 }}
              >
                {selectedRecord?.interviews &&
                selectedRecord.interviews.length > 0 ? (
                  selectedRecord.interviews.map((interview, index) => (
                    <TabPane
                      tab={interview.roundName}
                      key={interview.roundName}
                    >
                      {renderTabContent(interview.roundName)}
                    </TabPane>
                  ))
                ) : (
                  <TabPane tab="Pre Screening" key="Pre Screening">
                    {renderTabContent("Pre Screening")}
                  </TabPane>
                )}
              </Tabs>
            </div>
          </div>

          <Modal
            title="Schedule Interview"
            visible={isModalVisible}
            onCancel={handleCloseInterviewModal}
            footer={null}
            width={850}
          >
            {selectedRecord && selectedRecord?.interviewArray && (
              <Form
                form={form2}
                onFinish={handleFormSubmit}
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 16 }}
              >
                <Row
                  gutter={[16, 16]}
                  justify="space-between"
                  style={{ marginTop: "5vh" }}
                >
                  <Col span={11}>
                    <Form.Item
                      label="Job ID"
                      labelCol={{ span: 9 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <Input
                        prefix={
                          <TeamOutlined style={{ marginBottom: "3vh" }} />
                        }
                        value={jobId}
                        size="small"
                        style={{ height: "6vh", fontSize: "14px" }}
                        readOnly
                      />
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      labelCol={{ span: 9 }}
                      wrapperCol={{ span: 16 }}
                      label="Candidate Id"
                      rules={[
                        {
                          required: true,
                          message: "Please enter candidate name!",
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        value={user.candidateId}
                        prefix={
                          <UserOutlined style={{ marginBottom: "3vh" }} />
                        }
                        style={{ height: "6vh", fontSize: "14px" }}
                        readOnly
                      />
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      labelCol={{ span: 9 }}
                      wrapperCol={{ span: 16 }}
                      label="Round Name"
                      name="roundName"
                      rules={[
                        {
                          required: true,
                          message: "Please select round name!",
                        },
                      ]}
                    >
                      <Input
                        suffixIcon={<TeamOutlined />}
                        style={{ width: "100%", fontSize: "14px" }}
                        placeholder="Enter Round Name"
                        readOnly={isRescheduling}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      labelCol={{ span: 9 }}
                      wrapperCol={{ span: 16 }}
                      label="Interviewer Name"
                      name="interviewerName"
                      placeholder="Select Interviewer Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter interviewer name!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        suffixIcon={
                          <UserOutlined style={{ marginBottom: "3vh" }} />
                        }
                        style={{ width: "100%" }}
                      >
                        {Users?.filter(
                          (users) =>
                            users.jobTitle !== "employee" &&
                            users.empStatus !== "inactive"
                        ).map((users, index) => (
                          <Option key={users.id} value={users.displayName}>
                            {users.displayName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      label="Location"
                      name="location"
                      labelCol={{ span: 9 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <Select
                        style={{ width: "100%" }}
                        suffixIcon={<EnvironmentOutlined />}
                        disabled={isLocationDisabled}
                      >
                        <Option value="Remote">Remote</Option>
                        <Option value="Walk-in">Walk-in</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      label="Interview Date"
                      name="interviewDate"
                      labelCol={{ span: 9 }}
                      wrapperCol={{ span: 16 }}
                      rules={[
                        {
                          required: true,
                          message: "Please select interview date!",
                        },
                      ]}
                    >
                      <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={handleDateChange}
                      />
                    </Form.Item>
                    {date && isRescheduling && (
                      <div style={{ textAlign: "center" }}>
                        <p>
                          Scheduled Date:{" "}
                          {moment(date).format("YYYY-MM-DD HH:mm:ss")}
                        </p>
                      </div>
                    )}
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      label="Description"
                      name="description"
                      labelCol={{ span: 9 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <TextArea
                        prefix={<CommentOutlined />}
                        rows={1}
                        placeholder="Enter description"
                      />
                    </Form.Item>
                  </Col>

                  {/* <Col span={12}>
                    <Form.Item
                      label="Acceptance"
                      labelCol={{ span: 9 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <Checkbox onChange={handleCandidateAcceptedChange}>
                        Candidate Accepted
                      </Checkbox>
                    </Form.Item>
                  </Col> */}
                  <Button type="primary" danger htmlType="submit">
                    Submit
                  </Button>
                </Row>
              </Form>
            )}
          </Modal>

          <Modal
            title={
              <div
                style={{
                  backgroundColor: "#7060fc",
                  color: "white",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                AI Generated Resume
              </div>
            }
            visible={isResumeVisible}
            onCancel={() => setIsResumeVisible(false)}
            footer={null}
            width={650}
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {user && (
              <div>
                <Row>
                  <Col span={6}>
                    <img
                      style={{
                        width: "15vh",
                        height: "15vh",
                        borderRadius: "50%",
                        objectFit: "cover",
                        border: "1px solid #ddd",
                      }}
                      src={
                        user?.profileImage
                          ? user?.profileImage
                          : Math.random() > 0.5
                          ? require("../../images/male1.png")
                          : require("../../images/female1.png")
                      }
                    />
                  </Col>
                  <Col span={18} style={{ marginTop: "4px" }}>
                    <h6 style={{ fontWeight: "bold" }}>
                      {user?.candidateName}
                    </h6>
                    <p style={{ marginTop: "-14px", fontSize: "13px" }}>
                      {user?.currentJobTitle}
                    </p>
                    <p style={{ marginTop: "-19px", fontSize: "13px" }}>
                      {" "}
                      {user?.email}
                    </p>
                    <p style={{ marginTop: "-17px", fontSize: "13px" }}>
                      {" "}
                      {user?.phoneNumber}
                    </p>
                    <p style={{ marginTop: "-17px", fontSize: "13px" }}>
                      {" "}
                      {user?.location}
                    </p>
                    <p style={{ marginTop: "-17px", fontSize: "13px" }}>
                      {" "}
                      {user?.totalExperience} years
                    </p>
                  </Col>
                </Row>
                <Divider style={{ marginTop: "-10px" }} />
                <span style={{ fontWeight: "bold" }}>Summary</span>
                <p style={{ color: "#7a7980", fontStyle: "italic" }}>
                  {user?.notes}
                </p>
                <div style={{ marginTop: "10px" }}>
                  <p style={{ fontWeight: "bold" }}>Skills</p>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      marginTop: "5px",
                      flexWrap: "wrap",
                    }}
                  >
                    {user?.keySkills?.map((skill, index) => (
                      <span
                        key={index}
                        style={{
                          backgroundColor: "#f1f1f1",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          border: "1px solid #ddd",
                          fontSize: "12px",
                        }}
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                </div>
                <br />
                {user?.projectsAchievements &&
                  user?.projectsAchievements?.length > 0 && (
                    <div>
                      <strong>Projects & Achievements:</strong>
                      <ul>
                        {user?.projectsAchievements.map((project, index) => (
                          <li key={index}>{project}</li>
                        ))}
                      </ul>
                    </div>
                  )}
              </div>
            )}
          </Modal>

          <Modal
            title={
              <div
                style={{
                  backgroundColor: "#7060fc",
                  color: "white",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                Edit Resume
              </div>
            }
            visible={isResumeEdit}
            onCancel={() => setIsResumeEdit(false)}
            footer={null}
            width={650}
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            <Form
              form={form1}
              layout="vertical"
              onFinish={onFinish}
              style={{ padding: "0 30px" }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    style={{ marginLeft: "11vh" }}
                    placeholder="Profile Image"
                  >
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        border: "1px solid #0c20f5",
                        borderRadius: "5px",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        src={user?.profile_image}
                        preview={false}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        fallback="https://via.placeholder.com/100"
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Candidate Name" name="candidateName">
                    <Input
                      placeholder="Enter your name"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <UserOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Current Job Title" name="jobTitle">
                    <Input
                      placeholder="Enter your current job title"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <IdcardOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Experience" name="experience">
                    <Input
                      placeholder="Enter your experience in years"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <ExperimentOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Skills Field */}
              <Form.Item label="Skills">
                <Collapse
                  style={{
                    fontSize: "15px",
                    borderColor: "#0c20f5",
                    color: "#1113a8",
                  }}
                >
                  <Panel header="View Skills Information" key="1">
                    {skill?.map((skill, index) => (
                      <Space
                        key={index}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Input
                          value={skill}
                          onChange={(e) =>
                            handleChange(index, e.target.value, setSkill)
                          }
                          placeholder="Enter a skill"
                          style={{
                            fontSize: "15px",
                            borderColor: "#0c20f5",
                            color: "#1113a8",
                          }}
                          prefix={
                            <SolutionOutlined
                              style={{ color: "#0c20f5", marginTop: "-10px" }}
                            />
                          }
                        />
                        <Button
                          type="link"
                          onClick={() => handleRemove(index, setSkill)}
                        >
                          Remove
                        </Button>
                      </Space>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => handleAdd(setSkills)}
                      style={{ width: "100%" }}
                    >
                      Add Skill
                    </Button>
                  </Panel>
                </Collapse>
              </Form.Item>

              {/* Education Field */}
              <Form.Item label="Education">
                <Collapse
                  style={{
                    fontSize: "15px",
                    borderColor: "#0c20f5",
                    color: "#1113a8",
                  }}
                >
                  <Panel header="View Education Information" key="2">
                    {education?.length > 0 ? (
                      education?.map((edu, index) => {
                        // If education is a string, split it into parts
                        if (typeof edu === "string") {
                          // Assuming the string format is "Degree, Institution, University"
                          const eduParts = edu
                            .split(",")
                            .map((part) => part.trim());
                          const degree = eduParts[0] || "";
                          const institution = eduParts[1] || "";
                          const university = eduParts[2] || "";

                          return (
                            <Card
                              key={index}
                              style={{
                                marginBottom: 8,
                                padding: 10,
                                fontSize: "15px",
                                borderColor: "#0c20f5",
                                color: "#1113a8",
                              }}
                            >
                              <Form.Item
                                label="Degree"
                                style={{ marginBottom: 4 }}
                              >
                                <Text>{degree}</Text>
                              </Form.Item>
                              {institution && (
                                <Form.Item
                                  label="Institution"
                                  style={{ marginBottom: 4 }}
                                >
                                  <Text>{institution}</Text>
                                </Form.Item>
                              )}
                              {university && (
                                <Form.Item
                                  label="University"
                                  style={{ marginBottom: 4 }}
                                >
                                  <Text>{university}</Text>
                                </Form.Item>
                              )}
                            </Card>
                          );
                        } else {
                          // Case where education is an object with detailed fields
                          return (
                            <Card
                              key={index}
                              style={{
                                marginBottom: 8,
                                padding: 10,
                                fontSize: "15px",
                                borderColor: "#0c20f5",
                                color: "#1113a8",
                              }}
                            >
                              <Form.Item
                                label="Degree"
                                style={{ marginBottom: 4 }}
                              >
                                <Text>
                                  {edu.Degree || edu.Degree || edu["Degree"]}
                                </Text>
                              </Form.Item>
                              {edu.Percentage && (
                                <Form.Item
                                  label="Percentage/CGPA"
                                  style={{ marginBottom: 4 }}
                                >
                                  <Text>
                                    {edu.Percentage || edu["Percentage/CGPA"]}
                                  </Text>
                                </Form.Item>
                              )}
                              {edu.Year && (
                                <Form.Item
                                  label="Year of Passing"
                                  style={{ marginBottom: 4 }}
                                >
                                  <Text>
                                    {edu.Year || edu["Year of Passing"]}
                                  </Text>
                                </Form.Item>
                              )}
                              {edu.Institution && (
                                <Form.Item
                                  label="Institution"
                                  style={{ marginBottom: 4 }}
                                >
                                  <Text>
                                    {edu.Institution ||
                                      edu["Institute/College"]}
                                  </Text>
                                </Form.Item>
                              )}
                              {edu["University/Board"] && (
                                <Form.Item
                                  label="University/Board"
                                  style={{ marginBottom: 4 }}
                                >
                                  <Text>{edu["University/Board"]}</Text>
                                </Form.Item>
                              )}
                              {edu.GPA && (
                                <Form.Item
                                  label="GPA"
                                  style={{ marginBottom: 4 }}
                                >
                                  <Text>{edu.GPA}</Text>
                                </Form.Item>
                              )}
                              {edu.Major && (
                                <Form.Item
                                  label="Major"
                                  style={{ marginBottom: 4 }}
                                >
                                  <Text>{edu.Major}</Text>
                                </Form.Item>
                              )}
                              {edu.Location && (
                                <Form.Item
                                  label="Location"
                                  style={{ marginBottom: 4 }}
                                >
                                  <Text>{edu.Location}</Text>
                                </Form.Item>
                              )}
                            </Card>
                          );
                        }
                      })
                    ) : (
                      <Text>No education information available.</Text>
                    )}
                  </Panel>
                </Collapse>
              </Form.Item>

              {/* Certifications Field */}
              <Form.Item label="Certifications">
                <Collapse
                  style={{
                    fontSize: "15px",
                    borderColor: "#0c20f5",
                    color: "#1113a8",
                  }}
                >
                  <Panel header="View Certification Information" key="3">
                    {certifications?.map((cert, index) => (
                      <Space
                        key={index}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Input
                          value={cert}
                          onChange={(e) =>
                            handleChange(
                              index,
                              e.target.value,
                              setCertifications
                            )
                          }
                          placeholder="Enter certification"
                          style={{
                            fontSize: "15px",
                            borderColor: "#0c20f5",
                            color: "#1113a8",
                          }}
                          prefix={
                            <FileTextOutlined
                              style={{ color: "#0c20f5", marginTop: "-10px" }}
                            />
                          }
                        />
                        <Button
                          type="link"
                          onClick={() => handleRemove(index, setCertifications)}
                        >
                          Remove
                        </Button>
                      </Space>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => handleAdd(setCertifications)}
                      style={{ width: "100%" }}
                    >
                      Add Certification
                    </Button>
                  </Panel>
                </Collapse>
              </Form.Item>

              {/* Projects and Achievements Field */}
              <Form.Item label="Projects and Achievements">
                <Collapse
                  style={{
                    fontSize: "15px",
                    borderColor: "#0c20f5",
                    color: "#1113a8",
                  }}
                >
                  <Panel header="View Projects and Achievements" key="4">
                    {projects?.map((project, index) => (
                      <Space
                        key={index}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Input
                          value={project}
                          onChange={(e) =>
                            handleChange(index, e.target.value, setProjects)
                          }
                          placeholder="Describe project/achievement"
                          style={{
                            fontSize: "15px",
                            borderColor: "#0c20f5",
                            color: "#1113a8",
                          }}
                          prefix={
                            <HomeOutlined
                              style={{ color: "#0c20f5", marginTop: "-10px" }}
                            />
                          }
                        />
                        <Button
                          type="link"
                          onClick={() => handleRemove(index, setProjects)}
                        >
                          Remove
                        </Button>
                      </Space>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => handleAdd(setProjects)}
                      style={{ width: "100%" }}
                    >
                      Add Project/Achievement
                    </Button>
                  </Panel>
                </Collapse>
              </Form.Item>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      style={{
                        width: "100%",
                        borderColor: "#0c20f5",
                        color: "#fff",
                        backgroundColor: "#0c20f5",
                      }}
                      htmlType="submit"
                    >
                      Save Changes
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ApplicantDetail;
