import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Segmented,
  Select,
  Table,
  Drawer,
  Radio,
  Slider,
  Form,
  message,
  DatePicker,
  Input,
  Upload,
  List,
  notification,
  Checkbox,
  Rate,
  Tabs,
} from "antd";
import {
  AppstoreOutlined,
  BarsOutlined,
  DownOutlined,
  EditOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate, useNavigation } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import { url } from "../../url/url";
import background1 from "../../images/background1.webp";
import background3 from "../../images/background3.jpg";
import axios from "axios";
import moment from "moment";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Option } = Select;
const Survey = () => {
  const [setShowMenuItems] = useState(false);
  const [setSelectedType] = useState(null);
  const [survey, setSurvey] = useState([]);
  const [empSurvey, setEmpSurvey] = useState([]);
  const [view, setView] = useState("Folder View");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedItem, setSelectedItem] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [size] = useState("default");
  const [editingIndex, setEditingIndex] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [currentType, setCurrentType] = useState("");
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newEndDate, setNewEndDate] = useState(null);
  const [selectedRespondent, setSelectedRespondent] = useState(null);
  const [count, setCount] = useState(0);
  const users = JSON.parse(localStorage.getItem("user"));
  const [currentOptions, setCurrentOptions] = useState([]);

  const [defaultRespondent, setDefaultRespondent] = useState(null);
  const [editingData, setEditingData] = useState({});


  useEffect(() => {
    if (empSurvey && selectedItem) {
      // Find the first respondent for the current survey (selectedItem.introduction.name)
      const firstRespondent = empSurvey.find(
        (respondent) => respondent.surveyName === selectedItem.introduction.name
      );

      if (firstRespondent) {
        let displayName = firstRespondent.userName;

        // Handle anonymity
        if (!firstRespondent.isIdentity) {
          displayName = `Anonymous1`; // Assuming the first anonymous respondent is indexed as 1
        }

        setDefaultRespondent(displayName); // Set default respondent
      }
    }
  }, [empSurvey, selectedItem]);

  const fetchUsers = async () => {
    const dbName = localStorage.getItem("dbName");
    const response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
    const activeUsers = response.data.filter(
      (user) => user.empStatus !== "inactive"
    );
    return activeUsers;
  };

  useEffect(() => {
    const defaultRespondent = empSurvey.filter(
      (respondent) => selectedItem?.introduction?.name === respondent.surveyName
    )[0]?.userName;

    // Only set the default if no respondent has been selected manually
    if (!selectedRespondent && defaultRespondent) {
      setSelectedRespondent(defaultRespondent);
    }
  }, [empSurvey, selectedItem, selectedRespondent]);

  const handleSelectChange = (value) => {
    setSelectedRespondent(value);
  };

  const filteredSurvey = empSurvey.find(
    (respondent) =>
      respondent.userName === selectedRespondent &&
      respondent.surveyName === selectedItem?.introduction?.name
  );

  const categorizeSurveys = (surveys, surveyName) => {
    let completed = 0;
    let inProgress = 0;
    let notTaken = 0;

    surveys.forEach((survey) => {
      if (survey.surveyName === surveyName) {
        const totalQuestions = survey.questions.length;
        const answeredQuestions = survey.questions.filter(
          (q) => q.answer && q.answer.length > 0
        ).length;

        if (answeredQuestions === 0) {
          notTaken++;
        } else if (answeredQuestions < totalQuestions) {
          inProgress++;
        } else {
          completed++;
        }
      }
    });

    return { completed, inProgress, notTaken };
  };

  const surveyName = selectedItem?.introduction?.name;
  const { completed, inProgress, notTaken } = categorizeSurveys(
    empSurvey,
    surveyName
  );
  const totalRespondents = completed + inProgress + notTaken;

  const data = {
    labels: ["Completed", "In Progress", "Not Taken"],
    datasets: [
      {
        data: [completed, inProgress, notTaken],
        backgroundColor: ["#28a745", "#FFCE56", "#FF6384"],
        hoverBackgroundColor: ["#28a745", "#FFCE56", "#FF6384"],
        borderWidth: 5,
      },
    ],
  };

  const categorizeQuestions = (surveys, surveyName) => {
    let answered = 0;
    let unanswered = 0;

    surveys.forEach((survey) => {
      if (survey.surveyName === surveyName) {
        survey.questions.forEach((question) => {
          if (question.answer && question.answer.length > 0) {
            answered++;
          } else {
            unanswered++;
          }
        });
      }
    });

    return { answered, unanswered };
  };

  // Get the categorized data for the selected survey
  const { answered, unanswered } = categorizeQuestions(empSurvey, surveyName);
  const totalQuestions = answered + unanswered;

  const questionData = {
    labels: ["Answered", "Unanswered"],
    datasets: [
      {
        data: [answered, unanswered],
        backgroundColor: ["#36A2EB", "#FF6384"],
        hoverBackgroundColor: ["#36A2EB", "#FF6384"],
        borderWidth: 5,
      },
    ],
  };

  const questionOptions = {
    maintainAspectRatio: false,
    cutout: "70%",
  };

  const showEndDateModal = () => {
    setIsModalVisible(true);
  };

  // useEffect(() => {
  //   if (selectedItem) {
  //     setCurrentOptions(Array(selectedItem.questions.length).fill(null));
  //   }
  // }, [selectedItem]);



  const handleOptionChange = (value, optionIndex, index) => {
    const updatedOptions = [...editingData[index].options];
    updatedOptions[optionIndex] = value;
    setEditingData({
      ...editingData,
      [index]: {
        ...editingData[index],
        options: updatedOptions
      }
    });
  };

  const handleAddOption = (index) => {
    setEditingData({
      ...editingData,
      [index]: {
        ...editingData[index],
        options: [...editingData[index].options, ""]
      }
    });
  };

  const handleRemoveOption = (optionIndex, index) => {
    const updatedOptions = editingData[index].options.filter((_, i) => i !== optionIndex);
    setEditingData({
      ...editingData,
      [index]: {
        ...editingData[index],
        options: updatedOptions
      }
    });
  };




  const handleCheckboxChange = (e) => {
    setShowContent(e.target.checked);
  };
  const handleRemove = () => {
    setUploadedLogo(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-CA");
  };
  const handleMenuClick = (e) => {
    setSelectedType(e.key);
    setShowMenuItems(true);
  };

  const handleUpload = ({ file, onSuccess }) => {
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedLogo(reader.result);
      onSuccess("ok");
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    fetchSurvey();
  }, [count]);

  useEffect(() => {
    fetchEmpSurvey();
  }, [count])
  const fetchSurvey = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/surveys/${dbName}`);
      setSurvey(response.data);
      console.log(response.data, "countsurvey");
      return response.data;
    } catch {
      console.log("Error");
    }
  };

  const fetchEmpSurvey = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/empsurveys/${dbName}`);
      setEmpSurvey(response.data);
      console.log(response.data, "empsurvey");
      if (response?.data?.length > 0) {
        console.log("before update survey", new Date());

        const surveyData = await fetchSurvey(); // Fetch and store survey data
        await updateSurveyStatus(response.data, surveyData); // Pass both empSurvey and survey
        console.log("After update survey", new Date());
      }
    } catch {
      console.log("Error");
    }
  };
  const updateSurveyStatus = async (empSurvey, surveyList) => {
    const dbName = localStorage.getItem("dbName");
    console.log("Starting updateSurveyStatus for each survey in the list");

    const activeUsers = await fetchUsers();
    console.log("Fetched Active Users:", activeUsers);

    // Helper function to check if all applicable users have responded to a survey
    const haveAllApplicableUsersResponded = (applicableUsers, surveyName) => {
      return applicableUsers.every(user =>
        empSurvey.some(
          response =>
            response.surveyName === surveyName &&
            response.userName.trim().toLowerCase() === user.displayName.trim().toLowerCase()
        )
      );
    };

    // Loop through each survey in the list
    for (const survey of surveyList) {
      const surveyName = survey?.introduction?.name;
      const surveyId = survey?._id;

      if (!surveyName || !surveyId) {
        console.log("Skipping survey due to missing name or ID");
        continue;
      }

      console.log(`Processing survey '${surveyName}' with ID: ${surveyId}`);

      // Filter only responses for this specific survey
      const surveyResponses = empSurvey.filter(response => response.surveyName === surveyName);
      console.log(`Filtered responses for '${surveyName}':`, surveyResponses);

      // Case 1: requiredCheck is false or not specified, with at least one response
      if (
        survey?.introduction?.requiredCheck === false ||
        survey?.introduction?.requiredCheck === undefined ||
        survey?.introduction?.requiredCheck === null
      ) {
        if (surveyResponses.length > 0) {
          console.log(`Updating '${surveyName}' status to Completed (requiredCheck: false)`);

          const updateData = { status: "Completed", surveyName };

          try {
            const response = await axios.put(`${url}/update/status/${dbName}/${surveyId}`, updateData);
            console.log(`Survey '${surveyName}' updated to Completed:`, response.data);
          } catch (error) {
            console.log(`Error updating survey '${surveyName}' to Completed:`, error);
          }
        } else {
          console.log(`No responses for '${surveyName}', not updating status.`);
        }
      }

      // Case 2: requiredCheck is true, check all applicable users
      if (survey?.introduction?.requiredCheck === true) {
        console.log(`RequiredCheck is true for '${surveyName}'`);

        const { applicability } = survey;
        let applicableUsers = [];

        // Determine applicable users based on 'All Employees' or specific list
        if (applicability.includes("All Employees")) {
          applicableUsers = activeUsers;
        } else {
          applicableUsers = activeUsers.filter(user =>
            applicability.includes(user.displayName) ||
            applicability.includes(user.empDesignation)
          );
          console.log(`Applicable users for '${surveyName}':`, applicableUsers);
        }

        // Check if all applicable users have responded to this specific survey
        const allResponded = haveAllApplicableUsersResponded(applicableUsers, surveyName);

        console.log(`Have all applicable users responded to '${surveyName}'?`, allResponded);

        if (allResponded) {
          const updateData = { status: "Completed", surveyName };

          try {
            const response = await axios.put(`${url}/update/status/${dbName}/${surveyId}`, updateData);
            console.log(`Survey '${surveyName}' status updated to Completed for applicable users:`, response.data);
          } catch (error) {
            console.log(`Error updating '${surveyName}' status for applicable users:`, error);
          }
        } else {
          console.log(`Not all applicable users have responded to '${surveyName}', status not updated.`);
        }
      }
    }
  };



  const defaultImages = [background1, background3, background3];

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const handleClick = () => {
    navigate("/home/surveyhome");
  };

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setDrawerVisible(true);
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "70%", // Make it a donut chart
  };

  const handleTableRowClick = (record) => {
    setSelectedItem(record);
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
    setSelectedItem(null);
    setIsEditing(false);
  };

  const handleEditClick1 = (item, index) => {
    setEditingIndex(index);
    setEditingData({
      ...editingData,
      [index]: {
        question: item.question,
        type: item.type,
        options: item.choices || []
      }
    });
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setEditingData({
      ...editingData,
      [index]: {
        ...editingData[index],
        [name]: value
      }
    });
  };

  const handleSelectChange1 = (value, index) => {
    setEditingData({
      ...editingData,
      [index]: {
        ...editingData[index],
        type: value
      }
    });
  };


  const handleEditClick = (item, index) => {
    setIsEditing(true);

    form.setFieldsValue({
      name: selectedItem.introduction.name,

      period: [
        moment(selectedItem.introduction.period[0]),
        moment(selectedItem.introduction.period[1]),
      ],
    });
  };
  const calculateDaysLeft = (period) => {
    if (!period || period.length < 2) {
      return "No period data";
    }

    const startDate = new Date(period[0]);
    const endDate = new Date(period[1]);
    const currentDate = new Date();

    // Normalize to midnight
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    console.log(`Start Date: ${startDate.toDateString()}`);
    console.log(`End Date: ${endDate.toDateString()}`);
    console.log(`Current Date: ${currentDate.toDateString()}`);

    const timeDiff = endDate - currentDate;
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return daysLeft >= 0 ? `${daysLeft} days left` : "Expired";
  };

  console.log(calculateDaysLeft(selectedItem?.introduction?.period));

  const getFilteredSurveys = () => {
    if (selectedFilter === "All") {
      return survey;
    }
    return survey.filter((item) => item.status === selectedFilter);
  };

  const handlePublish = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const imagePath = uploadedLogo || selectedItem.introduction.surveyImage;

      const updatedQuestions = selectedItem.questions.map((question) => ({
        ...question,
      }));

      // Prepare the updated data and set status to "Ongoing"
      const updatedData = {
        introduction: {
          ...selectedItem.introduction,
          name: selectedItem.introduction.name,
          desc: selectedItem.introduction.desc,
          surveyImage: imagePath || selectedItem.introduction.surveyImage,
          period: selectedItem.introduction.period,
        },
        questions: updatedQuestions,
        notification: selectedItem.notification,
        status: "Ongoing",
      };

      const response = await axios.put(
        `${url}/update/surveys/${selectedItem._id}/${dbName}`,
        updatedData
      );

      if (response.status === 200) {
        message.success("Survey published successfully!");
        setCount(count + 1);
        setDrawerVisible(false);
        setIsEditing(false);
      } else {
        message.error("Failed to publish survey");
      }
    } catch (error) {
      console.error("Failed to publish survey", error);
      message.error("Failed to publish survey");
    }
  };
  console.log(currentOptions, "optionssss");

  const handleFormSubmit = async (values) => {
    const dbName = localStorage.getItem("dbName");

    try {
      const imagePath = uploadedLogo || selectedItem.introduction.surveyImage;
      const updatedQuestions = selectedItem.questions.map((question, index) => {
        if (editingData[index]) {
          const { question: updatedQuestion, type: updatedType, options: updatedChoices } = editingData[index];

          return {
            ...question,
            question: updatedQuestion,
            type: updatedType,
            choices: updatedChoices
          };
        }
        return question;
      });

      const updatedData = {
        introduction: {
          ...selectedItem.introduction,
          name: values.name || selectedItem.introduction.name,
          desc: values.desc || selectedItem.introduction.desc,
          surveyImage: imagePath,
          requiredCheck: values.requiredCheck || selectedItem.introduction.requiredCheck,
          empIdentity: values.empIdentity || selectedItem.introduction.empIdentity,
          period: values.period
            ? [values.period[0].format("YYYY-MM-DD"), values.period[1].format("YYYY-MM-DD")]
            : selectedItem.introduction.period,
        },
        questions: updatedQuestions,
        notification: showContent || selectedItem.notification,
      };

      const response = await axios.put(
        `${url}/update/surveys/${selectedItem._id}/${dbName}`,
        updatedData
      );

      if (response.status === 200) {
        setCount(count + 1);
        message.success("Survey details updated successfully!");
        setIsEditing(false);
        setEditingIndex(null);
      } else {
        message.error("Failed to update survey details");
      }
    } catch (error) {
      console.error("Failed to update survey details", error);
      message.error("Failed to update survey details");
    }
  };








  const handleEndDateSubmit = async (values) => {
    const dbName = localStorage.getItem("dbName");

    if (!newEndDate) {
      message.error("Please select a new end date");
      return;
    }
    const imagePath = uploadedLogo || selectedItem.introduction.surveyImage;

    try {
      const updatedData = {
        introduction: {
          ...selectedItem.introduction,
          name: values.name || selectedItem.introduction.name,
          desc: values.desc || selectedItem.introduction.desc,
          surveyImage: imagePath || selectedItem.introduction.surveyImage,
          requiredCheck:
            values.requiredCheck || selectedItem.introduction.requiredCheck,
          empIdentity:
            values.empIdentity || selectedItem.introduction.empIdentity,

          period: [selectedItem.introduction.period[0], newEndDate],
        },
        notification: selectedItem.notification
      };

      const response = await axios.put(
        `${url}/update/surveys/${selectedItem._id}/${dbName}`,
        updatedData
      );

      if (response.status === 200) {
        message.success("End date updated successfully!");
        setIsModalVisible(false);
      } else {
        message.error("Failed to update the end date");
      }
    } catch (error) {
      console.error("Failed to update the end date", error);
      message.error("Failed to update the end date");
    }
  };

  const columns = [
    {
      title: "Survey Name",
      dataIndex: "surveyName",
      key: "surveyName",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              record.introduction?.surveyImage
                ? record.introduction.surveyImage // Use surveyImage if available
                : background3
            }
            style={{
              height: "6vh",
              width: "6vh",
              marginRight: "10px",
              borderRadius: "20%",
            }}
            alt="survey"
          />
          <span>{record.introduction.name}</span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        let color;
        switch (record.status) {
          case "Ongoing":
            color = "#f7984a"; // Orange color
            break;
          case "Completed":
            color = "green"; // Green color
            break;
          case "Draft":
            color = "black"; // Black color
            break;
          default:
            color = "gray"; // Default color for unknown status
            break;
        }
        return <span style={{ color }}>{record.status || "Active"}</span>;
      },
    },
    {
      title: "Response",
      dataIndex: "response",
      key: "response",
      render: (text, record) => (
        <span>{record.status === "Completed" ? "100%" : "0%"}</span>
      ),
    },

    {
      title: "Days Left",
      dataIndex: "period",
      key: "daysLeft",
      render: (text, record) => (
        <span style={{ color: "#f76a8b" }}>
          {calculateDaysLeft(record.introduction.period)}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (text, record) => (
        <Button type="link" onClick={() => handleTableRowClick(record)}>
          View Details
        </Button>
      ),
    },
  ];

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="input">Add Using Existing Template</Menu.Item>
    </Menu>
  );

  const filteredSurveys = getFilteredSurveys(); // Get filtered surveys

  return (
    <div>
      <Row
        gutter={[16, 16]}
        justify="space-between"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <Select
            style={{ width: "40vh", marginTop: "3vh" }}
            placeholder="Select Filter"
            value={selectedFilter}
            onChange={handleFilterChange}
          >
            <Select.Option value="All">All</Select.Option>
            <Select.Option value="Ongoing">Ongoing</Select.Option>
            <Select.Option value="Draft">Draft</Select.Option>
            <Select.Option value="Completed">Completed</Select.Option>
          </Select>
        </Col>
        {users.jobTitle != "employee" && (
          <Col>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="primary" onClick={handleClick}>
                Add Engagement Survey <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        )}
        <Col>
          <Segmented
            options={[
              {
                label: <AppstoreOutlined />,
                value: "Folder View",
              },
              {
                label: <BarsOutlined />,
                value: "List View",
              },
            ]}
            value={view}
            onChange={(value) => setView(value)}
          />
        </Col>
      </Row>

      {view === "Folder View" ? (
        <Row gutter={[16, 16]}>
          {filteredSurveys.length > 0 ? (
            filteredSurveys.map((item, index) => {
              if (users.jobTitle === "employee" && item.status === "Draft") {
                return null;
              }

              return (
                <Col key={index} span={6}>
                  <Card
                    style={{
                      height: "38vh",
                      width: "40vh",
                      borderColor: "#cabdfc",
                      boxShadow: "0 3px 3px #7459e3",
                    }}
                    cover={
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <img
                          src={
                            item?.introduction?.surveyImage
                              ? item.introduction.surveyImage
                              : background3
                          }
                          style={{
                            height: "15vh",
                            width: "30vh",
                            marginTop: "2vh",
                            borderRadius: "10%",
                          }}
                          alt="survey"
                        />
                      </div>
                    }
                    onClick={() => {
                      if (users.jobTitle === "employee") {
                        navigate(`/home/empsurvey/${item.introduction.name}`);
                      } else {
                        handleCardClick(item);
                      }
                    }}
                    hoverable
                  >
                    <span style={{ display: "flex", justifyContent: "center" }}>
                      {item?.introduction?.name?.charAt(0).toUpperCase() +
                        item?.introduction?.name?.slice(1)}
                    </span>

                    <p style={{ color: "#666", textAlign: "center" }}>
                      {item.questions.length} Questions -{" "}
                      {item.status === "Completed" ? "100%" : "0%"}
                    </p>

                    {item.status === "Ongoing" ? (
                      <span
                        style={{
                          color: "#cd5aed",
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{ width: "4vh", height: "4vh" }}
                          src={require("../../images/daysleft.png")}
                        />
                        {calculateDaysLeft(item.introduction.period)}
                      </span>
                    ) : (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <span
                          style={{
                            background: item.status === "Completed" ? null : "#8b8d8f",
                            width: "8vh",
                            color: item.status === "Completed" ? "#28a745" : null,
                            marginRight: item.status === "Completed" ? "4vh" : null,
                            fontWeight: "bold",
                          }}
                        >
                          {item.status}
                        </span>
                      </div>
                    )}
                  </Card>
                </Col>
              );
            })
          ) : (
            <Col span={24} style={{ textAlign: "center", marginTop: "10vh" }}>
              <img
                src={require("../../images/No_data_found-removebg-preview.png")}
                alt="No data"
                style={{ width: "40vh", height: "40vh" }}
              />
            </Col>
          )}
        </Row>
      ) : (
        <Table
          dataSource={filteredSurveys}
          columns={columns}
          rowKey={(record) => record.introduction.name}
          pagination={{ pageSize: 5 }}
          locale={{
            emptyText: (
              <img
                src={require("../../images/No_data_found-removebg-preview.png")}
                alt="No data"
                style={{ width: "20%", height: "auto" }}
              />
            ),
          }} // Display empty image for table
        />
      )}

      <Drawer
        style={{ background: "#e6eaf0" }}
        title={selectedItem?.introduction?.name || "Details"}
        visible={drawerVisible}
        onClose={handleCloseDrawer}
        placement="top"
        height="100vh"
      >
        {selectedItem ? (
          <>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Details" key="1">
                <Card title={<h5>Survey Details</h5>}>
                  {/* View Mode */}
                  {!isEditing ? (
                    <>
                      <span style={{ fontSize: "15px" }}>
                        {selectedItem.introduction.name}
                      </span>
                      <br />
                      <Row justify="space-between">
                        <p>
                          <strong>Questions:</strong>{" "}
                          {selectedItem.questions.length}
                        </p>
                        <p>
                          <strong>Status:</strong> {selectedItem.status}
                        </p>{" "}
                        <p>
                          <strong>Start Date:</strong>{" "}
                          {formatDate(selectedItem.introduction.period[0])}
                        </p>
                        <p>
                          <strong>End Date:</strong>{" "}
                          {formatDate(selectedItem.introduction.period[1])}{" "}
                          {selectedItem.status === "Ongoing" && (
                            <EditOutlined
                              onClick={showEndDateModal}
                              style={{ marginLeft: "10px", cursor: "pointer" }}
                            />
                          )}
                          {isModalVisible && (
                            <>
                              <DatePicker
                                value={newEndDate ? moment(newEndDate) : null}
                                onChange={(date) =>
                                  setNewEndDate(date ? date.toDate() : null)
                                }
                                style={{ width: "100%" }}
                              />
                              <Button
                                icon={<SaveOutlined />}
                                type="primary"
                                onClick={handleEndDateSubmit}
                              />
                            </>
                          )}
                        </p>
                        <p>
                          <strong>Days Left:</strong>{" "}
                          {calculateDaysLeft(selectedItem.introduction.period)}
                        </p>
                        <p>
                          <strong>Repeats:</strong>{" "}
                          {selectedItem.introduction.surveyRepeats}
                        </p>
                      </Row>
                      <br />
                      <Row style={{ background: "#f7e7d7" }}>
                        <p>
                          Employee identity preferences:{" "}
                          {selectedItem.introduction.empIdentity}
                        </p>
                        <p>
                          Email:{" "}
                          {selectedItem.notification ? "Enabled" : "Disabled"}
                        </p>
                      </Row>
                      <br />
                      <h6>Applicable To</h6>

                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          flexWrap: "wrap",
                          marginTop: "2vh",
                        }}
                      >
                        {selectedItem?.applicability.map((item, index) => (
                          <span
                            key={index}
                            style={{
                              backgroundColor: "#f1f1f1",
                              borderRadius: "20px",
                              padding: "5px 10px",
                              border: "1px solid #ddd",
                              fontSize: "12px",
                              background: "#cbc8f7",
                            }}
                          >
                            {item}
                          </span>
                        ))}
                      </div>

                      <h6>Questions</h6>
                      {selectedItem.questions.map((questionItem, index) => (
                        <div key={index} style={{ marginBottom: "2vh" }}>
                          <span>
                            {index + 1}. {questionItem.question}{" "}
                          </span>
                          <div style={{ marginLeft: "2vh" }}>
                            {questionItem.type === "choice" &&
                              questionItem.choices.map((option, i) => (
                                <Row key={i}>
                                  <Radio>{option}</Radio>
                                </Row>
                              ))}
                            {questionItem.type === "scale" && (
                              <Slider min={0} max={10} defaultValue={10} style={{ width: "30vh" }} disabled />
                            )}

                            {questionItem.type === "rating" && (
                              <Rate defaultValue={5} />
                            )}
                          </div>
                        </div>
                      ))}
                      <Row justify="space-around">
                        {selectedItem.status === "Draft" && (
                          <>
                            <Button
                              type="primary"
                              onClick={handleEditClick}
                              style={{ marginTop: "20px" }}
                            >
                              Edit <EditOutlined />
                            </Button>
                            <Form.Item>
                              <Button
                                type="primary"
                                danger
                                onClick={handlePublish}
                              >
                                Publish
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Row>
                    </>
                  ) : (
                    <>
                      {selectedItem.status === "Draft" ? (
                        <Form
                          form={form}
                          layout="vertical"
                          onFinish={handleFormSubmit}
                          initialValues={{
                            name: selectedItem.introduction.name,
                            period: [
                              moment(selectedItem.introduction.period[0]),
                              moment(selectedItem.introduction.period[1]),
                            ],
                            desc: selectedItem.introduction.desc,
                            empIdentity: selectedItem.introduction.empIdentity,
                            surveyImage: selectedItem.introduction.surveyImage,
                            notification: selectedItem.notification,
                          }}
                        >
                          {/* Form items for editing the survey */}
                          <Form.Item
                            name="name"
                            label="Survey Name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter a survey name",
                              },
                            ]}
                          >
                            <Input style={{ fontSize: "14px" }} />
                          </Form.Item>
                          <Form.Item
                            name="empIdentity"
                            label="Employee identity preferences"
                          >
                            <Select>
                              <Option value="Maintain anonymity">
                                Maintain anonymity
                              </Option>
                              <Option value="identity">
                                Allow employees to choose to reveal their
                                identity
                              </Option>
                              <Option value="Mandate identity disclosure">
                                Mandate identity disclosure
                              </Option>
                            </Select>
                          </Form.Item>

                          <Form.Item label="Survey Image" name="surveyImage">
                            <Upload
                              customRequest={({ file, onSuccess, onError }) =>
                                handleUpload({
                                  file,
                                  onSuccess,
                                  onError,
                                  type: "logo",
                                })
                              }
                              maxCount={1}
                              onRemove={() => handleRemove("logo")}
                            >
                              <Button icon={<UploadOutlined />}>
                                Upload Image
                              </Button>
                            </Upload>
                            <br />
                            {selectedItem.introduction.surveyImage && (
                              <img
                                src={selectedItem.introduction.surveyImage}
                                style={{
                                  width: "15vh",
                                  height: "15vh",
                                  marginLeft: "5vh",
                                }}
                              />
                            )}
                          </Form.Item>

                          <Form.Item
                            name="period"
                            label="Period"
                            rules={[
                              {
                                required: true,
                                message: "Please Input a Period",
                              },
                            ]}
                          >
                            <RangePicker size={size} />
                          </Form.Item>
                          <List>
                            {selectedItem.questions.map((item, index) => (
                              <div key={index}>
                                {editingIndex === index || editingData[index] ? (
                                  <List.Item>
                                    <p>Question:</p>
                                    <Input
                                      value={editingData[index]?.question || currentQuestion}
                                      onChange={(e) => handleInputChange(e, index)}
                                      placeholder="Edit Question"
                                      name="question"
                                      style={{ width: "40vh" }}
                                    />
                                    <p>Type:</p>
                                    <Select
                                      value={editingData[index]?.type || currentType}
                                      onChange={(value) => handleSelectChange1(value, index)}
                                      placeholder="Select Type"
                                      style={{ width: "40vh", height: "8vh", marginTop: "-16px" }}
                                    >
                                      <Option value="choice">choice</Option>
                                      <Option value="comment">comment</Option>
                                      <Option value="scale">scale</Option>
                                      <Option value="rating">rating</Option>
                                    </Select>

                                    {/* Render options editing only if type is "choice" */}
                                    {editingData[index]?.type === "choice" && (
                                      <div style={{ marginTop: "1em" }}>
                                        <div style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                                          <p>Choices:</p>
                                          {editingData[index]?.options.map((option, optionIndex) => (
                                            <div key={optionIndex} style={{ display: "flex", marginBottom: "0.5em" }}>
                                              <Input
                                                value={option}
                                                onChange={(e) => handleOptionChange(e.target.value, optionIndex, index)}
                                                placeholder={`Option ${optionIndex + 1}`}
                                                style={{ marginRight: "0.5em" }}
                                              />
                                              <Button
                                                type="dashed"
                                                onClick={() => handleRemoveOption(optionIndex, index)}
                                                style={{ color: "#e86661" }}
                                                size="small"
                                              >
                                                Remove
                                              </Button>
                                            </div>
                                          ))}
                                        </div>
                                        <Button style={{ color: "#706d6d" }} onClick={() => handleAddOption(index)} type="dashed">
                                          Add Option
                                        </Button>
                                      </div>
                                    )}

                                  
                                  </List.Item>
                                ) : (
                                  <List.Item>
                                    {index + 1}. {item.question}
                                    <EditOutlined
                                      style={{ marginLeft: "5vh" }}
                                      onClick={() => handleEditClick1(item, index)}
                                    />
                                    <p>Type: {item.type}</p>
                                    {item.type === "choice" && (
                                      <ul>
                                        {item.choices?.map((option, optIndex) => (
                                          <li key={optIndex}>{option}</li>
                                        ))}
                                      </ul>
                                    )}
                                  </List.Item>
                                )}
                              </div>
                            ))}
                          </List>



                          <Form.Item
                            name="notification"
                            valuePropName="checked"
                            style={{ textAlign: "left" }}
                          >
                            <Checkbox onChange={handleCheckboxChange}>
                              Enable email notification
                            </Checkbox>
                          </Form.Item>

                          <Form.Item>
                            <Button type="primary" htmlType="submit" onClick={handleFormSubmit}>
                              Save Changes
                            </Button>
                          </Form.Item>
                        </Form>
                      ) : (
                        <p>Survey is ongoing and cannot be edited.</p>
                      )}
                    </>
                  )}
                </Card>
              </TabPane>
              {selectedItem.status != "Draft" && (
                <TabPane tab="Reports" key="2">
                  <Card style={{ padding: "20px" }}>
                    <Row justify="space-between">
                      <Col span={11}>
                        <h4>Overall statistics</h4>
                        <Row gutter={[16, 16]} align="middle">
                          <Col span={8}>
                            {totalRespondents && data ? (
                              <div
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  margin: "0 auto",
                                }}
                              >
                                <Pie data={data} options={options} />
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginTop: "-95px",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {totalRespondents}
                                  <div style={{ fontSize: "12px" }}>
                                    Total response
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  fontSize: "15px",
                                }}
                              >
                                No data available
                              </div>
                            )}
                          </Col>
                          <Col span={16}>
                            {totalRespondents &&
                              (completed || inProgress || notTaken) ? (
                              <Row>
                                <Col span={8}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        backgroundColor: "#28a745",
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        marginRight: "8px",
                                      }}
                                    ></span>
                                    <strong>{completed}</strong>{" "}
                                    <span style={{ marginLeft: "1vh" }}>
                                      Completed
                                    </span>
                                  </div>
                                </Col>
                                <Col span={8}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        backgroundColor: "#FFCE56",
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        marginRight: "8px",
                                      }}
                                    ></span>
                                    <strong>{inProgress}</strong>{" "}
                                    <span style={{ marginLeft: "1vh" }}>
                                      In-Progress
                                    </span>
                                  </div>
                                </Col>
                                <Col span={8}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        backgroundColor: "#FF6384",
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        marginRight: "8px",
                                      }}
                                    ></span>
                                    <strong>{notTaken}</strong>{" "}
                                    <span style={{ marginLeft: "1vh" }}>
                                      Not Taken
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  fontSize: "15px",
                                }}
                              >
                                No statistics available
                              </div>
                            )}
                            {totalRespondents && completed ? (
                              <Row style={{ marginTop: "20px" }}>
                                <Col span={8}>
                                  <strong>
                                    {(completed / totalRespondents) * 100}%
                                  </strong>
                                  <div>Response rate</div>
                                </Col>
                              </Row>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={11}>
                        <h4>Question Statistics</h4>
                        <Row gutter={[16, 16]} align="middle">
                          <Col span={8}>
                            {totalQuestions && questionData ? (
                              <div
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  margin: "0 auto",
                                }}
                              >
                                <Pie
                                  data={questionData}
                                  options={{ ...questionOptions, cutout: 0 }}
                                />
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginTop: "-95px",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {totalQuestions}
                                  <div style={{ fontSize: "12px" }}>
                                    Total Questions
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  fontSize: "15px",
                                }}
                              >
                                No data available
                              </div>
                            )}
                          </Col>
                          <Col span={16}>
                            {totalQuestions && (answered || unanswered) ? (
                              <>
                                <Row>
                                  <Col span={12}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          backgroundColor: "#36A2EB",
                                          width: "10px",
                                          height: "10px",
                                          borderRadius: "50%",
                                          marginRight: "8px",
                                        }}
                                      ></span>
                                      <strong>{answered}</strong>{" "}
                                      <span style={{ marginLeft: "1vh" }}>
                                        Answered
                                      </span>
                                    </div>
                                  </Col>
                                  <Col span={12}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          backgroundColor: "#FF6384",
                                          width: "10px",
                                          height: "10px",
                                          borderRadius: "50%",
                                          marginRight: "8px",
                                        }}
                                      ></span>
                                      <strong>{unanswered}</strong>{" "}
                                      <span style={{ marginLeft: "1vh" }}>
                                        Unanswered
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  fontSize: "15px",
                                }}
                              >
                                No statistics available
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                  <br />
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Respondent details" key="1">
                      <>
                        <Card>
                          <Select
                            style={{ width: "30vh" }}
                            placeholder="Select respondent"
                            onChange={handleSelectChange}
                            value={defaultRespondent}
                          >
                            {(() => {
                              const anonymousCount = {};

                              return empSurvey
                                .filter(
                                  (respondent) =>
                                    selectedItem?.introduction?.name ===
                                    respondent.surveyName
                                )
                                .map((filteredRespondent, index) => {
                                  let displayName = filteredRespondent.userName;

                                  // If respondent is anonymous, assign an "Anonymous" label with an index
                                  if (!filteredRespondent.isIdentity) {
                                    if (
                                      !anonymousCount[
                                      filteredRespondent.surveyName
                                      ]
                                    ) {
                                      anonymousCount[
                                        filteredRespondent.surveyName
                                      ] = 1; // Start the count for this survey
                                    } else {
                                      anonymousCount[
                                        filteredRespondent.surveyName
                                      ]++; // Increment for each anonymous respondent
                                    }
                                    displayName = `Anonymous${anonymousCount[
                                      filteredRespondent.surveyName
                                    ]
                                      }`;
                                  }

                                  return (
                                    <Select.Option
                                      key={filteredRespondent.userName}
                                      value={filteredRespondent.userName}
                                    >
                                      {displayName}
                                    </Select.Option>
                                  );
                                });
                            })()}
                          </Select>
                          {filteredSurvey ? (
                            <>
                              {(() => {
                                // Initialize a counter for anonymous users
                                const anonymousCount = {};

                                // Determine the display name based on anonymity and count
                                let displayName = filteredSurvey.userName;
                                if (!filteredSurvey.isIdentity) {
                                  if (
                                    !anonymousCount[filteredSurvey.surveyName]
                                  ) {
                                    anonymousCount[
                                      filteredSurvey.surveyName
                                    ] = 1;
                                  } else {
                                    anonymousCount[filteredSurvey.surveyName]++;
                                  }
                                  displayName = `Anonymous${anonymousCount[filteredSurvey.surveyName]
                                    }`;
                                }


                              })()}
                            </>
                          ) : (
                            <p>No respondent details available.</p>
                          )}
                        </Card>
                        <br />
                        <Card>
                          {filteredSurvey?.questions &&
                            filteredSurvey?.questions?.length > 0 ? (
                            filteredSurvey?.questions.map((response, index) => (
                              <div key={index} style={{ marginBottom: "10px" }}>
                                <strong>
                                  Q{index + 1}: {response.question}
                                </strong>
                                <p>ans: {response.answer[0]}</p>
                              </div>
                            ))
                          ) : (
                            <p>No responses available for this survey.</p>
                          )}
                        </Card>
                      </>
                    </TabPane>
                  </Tabs>
                </TabPane>
              )}
            </Tabs>
          </>
        ) : (
          <p>No details available</p>
        )}
      </Drawer>
    </div>
  );
};

export default Survey;
