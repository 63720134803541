import { Button, Card, Col, Divider, Form, Input, message, Rate, Row, Select, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { url } from '../../url/url';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;

const Appraisal = () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const [activeTab, setActiveTab] = useState("1");
    const [questions, setQuestions] = useState([]);
    const [editingQuestionId, setEditingQuestionId] = useState(null);
    const [answers, setAnswers] = useState({});
    const [savedAnswers, setSavedAnswers] = useState({});
    const [empAnswers, setEmpAnswers] = useState([]);
    const [goals, setGoals] = useState([]);
    const [empGoals, setEmpGoals] = useState([]);
    const currentDate = new Date().toISOString();
    const [selectedQuarter, setSelectedQuarter] = useState("Q1");
    const [selectedYear, setSelectedYear] = useState("2024");
    const [review, setReview] = useState('');
    const [selectedRating, setSelectedRating] = useState(null);
    const [comments, setComments] = useState('');
    const [rating, setRating] = useState("")
    const [completed, setCompleted] = useState([]);
    const [empComments, setEmpComments] = useState("");

    const [feedback, setFeedback] = useState({
        comments: '',
        ratings: {
            stressResilience: 0,
            timeManagement: 0,
            teamwork: 0,
            autonomy: 0
        }
    });

    const [expandedCourses, setExpandedCourses] = useState({});

    const handleToggleSkills = (courseId) => {
        setExpandedCourses((prevExpandedCourses) => ({
            ...prevExpandedCourses,
            [courseId]: !prevExpandedCourses[courseId], // Toggle the state for the specific course
        }));
    };


    // Handle comments input change
    const handleCommentsChange = (e) => {
        setFeedback({ ...feedback, comments: e.target.value });
    };

    // Handle ratings change
    const handleRatingChange = (category, value) => {
        setFeedback({
            ...feedback,
            ratings: { ...feedback.ratings, [category]: value }
        });
    };

    // Submit feedback

    const filterRatingsByDate = () => {
        if (!rating || !rating.date) {
            return null;
        }

        const ratingDate = new Date(rating.date);
        const ratingYear = ratingDate.getFullYear();
        const ratingMonth = ratingDate.getMonth();

        if (ratingYear.toString() !== selectedYear) {
            return null;
        }

        // Filter by selected quarter
        if (selectedQuarter === "Q1" && ratingMonth >= 0 && ratingMonth <= 2) return rating;  // Jan, Feb, Mar
        if (selectedQuarter === "Q2" && ratingMonth >= 3 && ratingMonth <= 5) return rating;  // Apr, May, Jun
        if (selectedQuarter === "Q3" && ratingMonth >= 6 && ratingMonth <= 8) return rating;  // Jul, Aug, Sep
        if (selectedQuarter === "Q4" && ratingMonth >= 9 && ratingMonth <= 11) return rating; // Oct, Nov, Dec

        // Optionally, for half-year filters:
        if (selectedQuarter === "H1" && ratingMonth >= 0 && ratingMonth <= 5) return rating;  // Jan to Jun
        if (selectedQuarter === "H2" && ratingMonth >= 6 && ratingMonth <= 11) return rating; // Jul to Dec

        return null;
    };


    const handleSelect = (index) => {
        setSelectedRating(index);
    };

    const handleCommentChange = (e) => {
        setComments(e.target.value);
    };

    const handleRatingPost = async () => {
        const dbName = localStorage.getItem("dbName")
        if (selectedRating === null || comments.trim() === '') {
            message.error('Please select a rating and provide comments.');
            return;
        }

        const payload = {
            userName: users.displayName,
            rating: ratings[selectedRating].text,
            comments: comments,
            date: currentDate
        };
        console.log(payload, "payss");

        try {
            await axios.post(`${url}/post/ratings/${dbName}`, payload);  // Adjust the API endpoint based on your backend
            message.success('Rating and comments submitted successfully');
            setSelectedRating(null);  // Reset after submission
            setComments('');
        } catch (error) {
            console.error(error);
            message.error('Failed to submit rating and comments');
        }
    };

    const getEmojiForRating = (rating) => {
        switch (rating) {
            case "Outstanding":
                return "\u{1F929}";
            case "Exceeds Expectations":
                return "\u{1F604}";
            case "Meets Expectations":
                return "\u{1F60A}";
            case "Needs Improvement":
                return "\u{1F610}";
            case "Unacceptable":
                return "\u{1F615}";
            default:
                return "\u{1F60A}"; // default emoji if no match found
        }
    };

    const ratings = [
        { emoji: "\u{1F929}", text: "Outstanding" },
        { emoji: "\u{1F604}", text: "Exceeds Expectations" },
        { emoji: "\u{1F60A}", text: "Meets Expectations" },
        { emoji: "\u{1F610}", text: "Needs Improvement" },
        { emoji: "\u{1F615}", text: "Unacceptable" },

    ];
    

    const handleUpdateReview = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.put(`${url}/update/manager-review/${users.displayName}/${dbName}`, {
                review,
                date: currentDate,
                status: "closed"
            });
            message.success("Review posted successfully");
        } catch (error) {
            console.error(error.response.data.error);
        }
    };

    const handleUpdateManagerFeedback = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.put(`${url}/update/feedback/${users.displayName}/${dbName}`, {
                date: currentDate,
                status: "closed",
                rating: {
                    stressResilience: feedback.ratings.stressResilience,
                    timeManagement: feedback.ratings.timeManagement,
                    teamwork: feedback.ratings.teamwork,
                    autonomy: feedback.ratings.autonomy
                },
                comments: feedback.comments
            });

            message.success("Manager feedback updated successfully");
        } catch (error) {
            console.error(error.response?.data?.error || error.message);
            message.error("Failed to update manager feedback");
        }
    };


    const handleAddGoal = () => {
        setGoals((prevGoals) => [...prevGoals, { goal: '' }]);
    };

    const handleGoalChange = (index, value) => {
        const updatedGoals = [...goals];
        updatedGoals[index].goal = value;
        setGoals(updatedGoals);
    };

    useEffect(() => {
        fetchQuestions();
        fetchGoals();
        fetchAnswers();
        fetchRatings();
    }, []);


    useEffect(() => {
        fetchCourses();
    }, [selectedQuarter, selectedYear]);

    const fetchQuestions = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(`${url}/get/appraisalquestion/${dbName}`);
            setQuestions(response.data);

        } catch {
            console.log("Error fetching questions");
        }
    };

    const fetchRatings = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(`${url}/get/ratings/${users.displayName}/${dbName}`)
            setRating(response.data);
            console.log(response.data, "ratings");

        } catch {
            console.log("Error fetching questions");
        }
    };

    const fetchAnswers = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(`${url}/get/appraisalanswers/${dbName}`);
            setEmpAnswers(response.data);
            console.log(response.data, "answers");

        } catch {
            console.log("Error fetching questions");
        }
    };

    const filterAnswersByYearAndQuarter = () => {
        return empAnswers.filter((item) => {
            const answerDate = new Date(item.date);  // Parse the date from the answer data
            const answerYear = answerDate.getFullYear();
            const answerMonth = answerDate.getMonth() + 1;  // getMonth() returns 0-indexed months

            // Check if the answer matches the selected year
            const isYearMatch = answerYear.toString() === selectedYear;

            // Determine which quarter the answer falls into
            let isQuarterMatch = false;
            switch (selectedQuarter) {
                case 'Q1':
                    isQuarterMatch = answerMonth >= 1 && answerMonth <= 3;
                    break;
                case 'Q2':
                    isQuarterMatch = answerMonth >= 4 && answerMonth <= 6;
                    break;
                case 'Q3':
                    isQuarterMatch = answerMonth >= 7 && answerMonth <= 9;
                    break;
                case 'Q4':
                    isQuarterMatch = answerMonth >= 10 && answerMonth <= 12;
                    break;
                case 'H1':
                    isQuarterMatch = answerMonth >= 1 && answerMonth <= 6;
                    break;
                case 'H2':
                    isQuarterMatch = answerMonth >= 7 && answerMonth <= 12;
                    break;
                default:
                    isQuarterMatch = true;
            }
            return isYearMatch && isQuarterMatch;
        });
    };


    const fetchGoals = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(`${url}/get/goals/${users.displayName}/${dbName}`);
            setEmpGoals(response.data);
            console.log(response.data, "response");

        } catch {
            console.log("Error fetching questions");
        }
    };
    const handleInputChange = (e, questionId) => {
        setAnswers(prev => ({
            ...prev,
            [questionId]: e.target.value
        }));
    };

    const handleSaveAnswer = (questionId) => {
        setSavedAnswers(prev => ({
            ...prev,
            [questionId]: answers[questionId] || ""
        }));
        setEditingQuestionId(null);
    };
    const handleSubmit = async () => {
        const dbName = localStorage.getItem("dbName");

        const allQuestions = questions
            .map((item) => item.questions)
            .flat();

        // Log to ensure we're getting all the questions
        console.log(allQuestions, "allQuestions");

        // Filter answered questions
        const answeredQuestions = allQuestions
            .filter((item) => {
                const answer = savedAnswers[item._id];
                console.log(item._id, "Checking each question _id and corresponding answer");
                return answer;  // Filter if an answer exists for this question's _id
            })
            .map((item) => ({
                question: item.question,
                answer: savedAnswers[item._id] || "",  // Map the question and the corresponding answer
            }));

        console.log(answeredQuestions, "answeredQuestions");

        // Check if there are any answered questions
        if (answeredQuestions?.length === 0) {
            message.error("No questions answered. Please answer at least one question before submitting.");
            return;
        }

        const payload = {
            userName: users.displayName,
            empComments: empComments,
            questions: answeredQuestions,
            date: currentDate
        };

        console.log(payload, "pay");

        try {
            await axios.post(`${url}/post/appraisalanswers/${dbName}`, payload);  // Ensure dbName is provided
            message.success("Submission successful");
        } catch (error) {
            console.log("Error submitting answers", error);
        }
    };




    const handleGoalSubmit = async () => {
        const dbName = localStorage.getItem("dbName");
        const payload = {
            userName: users.displayName,
            date: currentDate,  // Ensure that currentDate is in a valid format like YYYY-MM-DD
            goals: goals.map((goal, index) => ({
                goalNo: `Goal ${index + 1}`,
                goal: goal.goal
            })),
        };

        console.log(payload, "Payload");

        try {
            const response = await axios.post(`${url}/post/goals/${dbName}`, payload);  // Call the API
            if (response.data.status === 'closed') {
                message.success("Goals submitted successfully and status is closed for this quarter");
            } else {
                message.success("Goals submitted successfully");
            }
        } catch (error) {
            console.log("Error submitting goals", error);
        }
    };

    const filterGoalsByDate = () => {
        const filteredGoals = empGoals?.goals?.filter(goal => {
            const goalDate = new Date(empGoals.date);  // Parse the date
            const goalYear = goalDate.getFullYear();
            const goalMonth = goalDate.getMonth();  // Get month (0 = Jan, 11 = Dec)
            if (goalYear.toString() !== selectedYear) return false;
            if (selectedQuarter === "Q1" && goalMonth >= 0 && goalMonth <= 2) return true;
            if (selectedQuarter === "Q2" && goalMonth >= 3 && goalMonth <= 5) return true;
            if (selectedQuarter === "Q3" && goalMonth >= 6 && goalMonth <= 8) return true;
            if (selectedQuarter === "Q4" && goalMonth >= 9 && goalMonth <= 11) return true;
            if (selectedQuarter === "H1" && goalMonth >= 0 && goalMonth <= 5) return true;
            if (selectedQuarter === "H2" && goalMonth >= 6 && goalMonth <= 11) return true;
            return false;
        });

        return filteredGoals;
    };


    // Helper function to get the date range for the selected quarter and year
    const getQuarterDates = (quarter, year) => {
        switch (quarter) {
            case "Q1": return { start: `${year}-01-01T00:00:00.000Z`, end: `${year}-03-31T23:59:59.999Z` };
            case "Q2": return { start: `${year}-04-01T00:00:00.000Z`, end: `${year}-06-30T23:59:59.999Z` };
            case "Q3": return { start: `${year}-07-01T00:00:00.000Z`, end: `${year}-09-30T23:59:59.999Z` };
            case "Q4": return { start: `${year}-10-01T00:00:00.000Z`, end: `${year}-12-31T23:59:59.999Z` };
            case "H1": return { start: `${year}-01-01T00:00:00.000Z`, end: `${year}-06-30T23:59:59.999Z` };
            case "H2": return { start: `${year}-07-01T00:00:00.000Z`, end: `${year}-12-31T23:59:59.999Z` };
            default: return null;
        }
    };

    // Helper function to filter courses by quarter and year
    const filterCoursesByQuarterAndYear = (courses, quarter, year, userDisplayName) => {
        const { start, end } = getQuarterDates(quarter, year);
        console.log("Start Date:", start, "End Date:", end); // Debugging the dates

        return courses.filter(course =>
            course.completed.some(entry => {
                const completedDate = new Date(entry.date).toISOString(); // Convert to comparable format
                console.log("Completed Date:", completedDate); // Debugging date comparison
                return (
                    entry.name === userDisplayName && // Ensure exact name match
                    completedDate >= start && completedDate <= end // Filter by date range
                );
            })
        );
    };


    const fetchCourses = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(`${url}/get/empcourses/${dbName}`);
            const allCourses = response.data;

            // Filter courses by selected quarter and year
            const filteredCourses = filterCoursesByQuarterAndYear(allCourses, selectedQuarter, selectedYear, users.displayName);
            setCompleted(filteredCourses);

        } catch (err) {
            console.log(err.message);
        }
    };



    const filterReviewsByDate = () => {
        const filteredReviews = empGoals?.managerReview?.filter(review => {
            const reviewDate = new Date(review.date);
            const reviewYear = reviewDate.getFullYear();
            const reviewMonth = reviewDate.getMonth();

            if (reviewYear.toString() !== selectedYear) return false;

            if (selectedQuarter === "Q1" && reviewMonth >= 0 && reviewMonth <= 2) return true;
            if (selectedQuarter === "Q2" && reviewMonth >= 3 && reviewMonth <= 5) return true;
            if (selectedQuarter === "Q3" && reviewMonth >= 6 && reviewMonth <= 8) return true;
            if (selectedQuarter === "Q4" && reviewMonth >= 9 && reviewMonth <= 11) return true;

            if (selectedQuarter === "H1" && reviewMonth >= 0 && reviewMonth <= 5) return true;
            if (selectedQuarter === "H2" && reviewMonth >= 6 && reviewMonth <= 11) return true;

            return false;
        });

        return filteredReviews;
    };




    return (
        <div>
            <Card style={{ height: "30vh" }}>
                <Row justify="space-between">
                    <img src={users.profileImage} style={{ width: "7vh", height: "7vh" }} alt="Profile" />
                    <span style={{ fontSize: "15px", marginRight: "60vh" }}>{users.displayName}</span>
                    <Form.Item label="Select Quarter">
                        <Select style={{ width: "30vh" }} defaultValue="Q1" onChange={(value) => setSelectedQuarter(value)} >
                            <Select.Option value="Q1">Q1</Select.Option>
                            <Select.Option value="Q2">Q2</Select.Option>
                            <Select.Option value="Q3">Q3</Select.Option>
                            <Select.Option value="Q4">Q4</Select.Option>
                            <Select.Option value="H1">H1</Select.Option>
                            <Select.Option value="H2">H2</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Select Year">
                        <Select style={{ width: "30vh" }} defaultValue="2024" onChange={(value) => setSelectedYear(value)}>
                            <Select.Option value="2023">2023</Select.Option>
                            <Select.Option value="2024">2024</Select.Option>
                            <Select.Option value="2025">2025</Select.Option>
                        </Select>
                    </Form.Item>
                </Row>
                <Row style={{ fontSize: "12px" }}>
                    <Col span={6}>
                        <p style={{ fontWeight: "bold" }}>Manager</p>
                    </Col>
                    <Col span={6}>
                        <p>{users.manager}</p>
                    </Col>
                    <Col span={6}>
                        <p style={{ fontWeight: "bold" }}>Department</p>
                    </Col>
                    <Col span={6}>
                        <p>{users.department}</p>
                    </Col>
                </Row>

                <Tabs defaultActiveKey="1" type="card" onChange={setActiveTab}>
                    <TabPane tab="Goals" key="1" />
                    <TabPane tab="Feedback" key="2" />
                    <TabPane tab="Skills" key="3" />
                    <TabPane tab="Ratings" key="4" />
                </Tabs>
            </Card>
            {activeTab === "1" &&
                <Row justify="space-between" gutter={16}>
                    <Col span={12}>
                        <Card style={{ background: "#dce2f7", borderColor: "#086bff", height: "70vh", overflowY: "auto" }}>
                            <h6 style={{ fontSize: "15px", fontWeight: "bold" }} className='text-center'>Employee's Goals</h6>
                            <Row justify="space-between">
                                {!filterGoalsByDate() || filterGoalsByDate()?.length === 0 ? (
                                    null
                                ) : (
                                    <p style={{ fontWeight: "bold" }}>My goals</p>

                                )}
                                {/* {filterGoalsByDate()?.some((item) => item.status !== "closed") && ( */}
                                <Button type="primary" onClick={handleAddGoal}>Add Goals</Button>
                                {/* )} */}
                            </Row>
                            {/* {!filterGoalsByDate() || filterGoalsByDate().length === 0 && */}
                            {goals.map((goal, index) => (
                                <div key={index}>
                                    <Row justify="space-between" style={{ marginTop: '10px' }}>
                                        <h6 style={{ fontSize: '14px' }}>Goal {index + 1}</h6>
                                        <Input
                                            placeholder={`Enter Goal ${index + 1}`}
                                            value={goal.goal}
                                            style={{ fontSize: "14px" }}
                                            onChange={(e) => handleGoalChange(index, e.target.value)} // Handle input change
                                        />
                                    </Row>
                                </div>
                            ))}
                            {filterGoalsByDate()?.map((goal, index) => (
                                <div key={index}>
                                    <h6 style={{ fontSize: '14px', fontWeight: "bold" }}>{goal.goalNo}</h6>
                                    <p style={{ color: "#727375" }}>{goal.goal}</p>
                                </div>
                            ))}
                            {goals.some(goal => goal.goal.trim()) && (
                                <div style={{ textAlign: "center" }}>
                                    <Button type='primary' onClick={handleGoalSubmit}>Submit</Button>
                                </div>
                            )}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card style={{ background: "#dce2f7", borderColor: "#086bff", height: "70vh", overflowY: "auto" }}>
                            <h6 className='text-center' style={{ fontSize: "15px", fontWeight: "bold" }}>Manager's Review</h6>
                            <br />

                            {filterReviewsByDate()?.some((item) => item.status === "closed") ? (
                                filterReviewsByDate()?.map((review, index) => (
                                    <div key={index} style={{ marginTop: '10px' }}>
                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "-16vh" }}>
                                            <img src={require("../../images/review.png")} style={{ width: "45vh", height: "45vh" }} />
                                        </div>
                                        <h6 style={{ fontSize: '14px', fontWeight: "bold", marginTop: "-8vh", textAlign: "center" }}>Review</h6>
                                        <p style={{ color: "#727375", marginTop: "-1vh", textAlign: "center" }}>{review.review}</p>
                                    </div>
                                ))
                            ) : (
                                <>
                                    <Input.TextArea
                                        rows={4}
                                        placeholder='Input your Reviews'
                                        value={review}
                                        onChange={(e) => setReview(e.target.value)}
                                    />
                                    <div style={{ textAlign: "center", marginTop: "2vh" }}>
                                        <Button type='primary' onClick={handleUpdateReview}>Submit your Review</Button>
                                    </div>
                                </>
                            )}

                        </Card>
                    </Col>
                </Row>
            }
            {activeTab === "2" &&
                <Row justify="space-between" gutter={16}>
                    <Col span={12}>
                        <Card style={{ background: "#dce2f7", borderColor: "#086bff", height: "70vh", overflowY: "auto" }}>
                            <Row justify="space-between">
                                <h6 style={{ fontSize: "15px" }}>Employee's Feedback</h6>
                            </Row>
                            <Form.Item label="Employee Comments">
                                <Input.TextArea
                                    placeholder='Input your Comments'
                                    value={empComments}
                                    onChange={(e) => setEmpComments(e.target.value)}  // Update state on change
                                />
                            </Form.Item>

                            {filterAnswersByYearAndQuarter()?.length > 0 ? (
                                filterAnswersByYearAndQuarter().map((item, index) => (
                                    <div key={item._id || index}>
                                        {item.questions.map((qItem, qIndex) => (
                                            <div key={qItem._id || qIndex}>
                                                <p style={{ fontWeight: "bold" }}>Question {qIndex + 1}</p>
                                                <Row justify="space-between">
                                                    <h6 style={{ fontSize: "14px" }}>{qItem.question}</h6>
                                                    {!qItem.answer ? ( // Display icons only if there's no answer
                                                        editingQuestionId === qItem._id ? (
                                                            <SaveOutlined
                                                                onClick={() => handleSaveAnswer(qItem._id)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        ) : (
                                                            <EditOutlined
                                                                onClick={() => setEditingQuestionId(qItem._id)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        )
                                                    ) : null}
                                                </Row>
                                                {editingQuestionId === qItem._id ? (
                                                    <Input
                                                        placeholder="Enter your answer"
                                                        value={answers[qItem._id] || ""}
                                                        onChange={(e) => handleInputChange(e, qItem._id)}
                                                    />
                                                ) : (
                                                    <p style={{ color: "#636569", fontStyle: "italic", fontSize: "12px" }}>
                                                        {qItem.answer ? (
                                                            <span><strong>Answer:</strong> {qItem.answer}</span>
                                                        ) : (
                                                            qItem.description
                                                        )}
                                                    </p>
                                                )}
                                                {!qItem.answer && (
                                                    <div style={{ textAlign: "center" }}>
                                                        <Button type='primary' onClick={handleSubmit}>Submit</Button>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                questions.map((item, index) => (
                                    <div key={item._id || index}>
                                        {item.questions.map((qItem, qIndex) => (
                                            <div key={qItem._id || qIndex}>
                                                <p style={{ fontWeight: "bold" }}>Question {qIndex + 1}</p>
                                                <Row justify="space-between">
                                                    <h6 style={{ fontSize: "14px" }}>{qItem.question}</h6>
                                                    {!qItem.answer ? (
                                                        editingQuestionId === qItem._id ? (
                                                            <SaveOutlined
                                                                onClick={() => handleSaveAnswer(qItem._id)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        ) : (
                                                            <EditOutlined
                                                                onClick={() => setEditingQuestionId(qItem._id)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        )
                                                    ) : null}
                                                </Row>
                                                {editingQuestionId === qItem._id ? (
                                                    <Input
                                                        placeholder="Enter your answer"
                                                        value={answers[qItem._id] || ""}
                                                        onChange={(e) => handleInputChange(e, qItem._id)}
                                                    />
                                                ) : (
                                                    <p style={{ color: "#636569", fontStyle: "italic", fontSize: "12px" }}>
                                                        {qItem.description}
                                                    </p>
                                                )}

                                            </div>
                                        ))}
                                    </div>
                                ))
                            )}
                            {/* {!questions.some(item =>
                                item.questions.some(qItem => savedAnswers[qItem._id])
                            ) && ( */}
                            <div style={{ textAlign: "center", marginTop: "20px" }}>
                                <Button type="primary" onClick={handleSubmit}>Submit</Button>
                            </div>
                            {/* )} */}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card style={{ background: "#f7dced", borderColor: "#f502d0", height: "70vh", overflowY: "auto" }}>
                            <h6 style={{ fontSize: "15px" }}>Manager's Feedback</h6>
                            <p style={{ fontWeight: "bold" }}>Feedback</p>
                            <Input.TextArea
                                placeholder='Input your Comments'
                                value={feedback.comments}
                                onChange={handleCommentsChange}
                            />

                            <Row justify="space-between" style={{ marginTop: "1vh", color: "#636569", fontSize: "12px" }}>
                                <p>Stress Resilience</p>
                                <Rate
                                    value={feedback.ratings.stressResilience}
                                    onChange={(value) => handleRatingChange("stressResilience", value)}
                                />
                            </Row>

                            <Row justify="space-between" style={{ color: "#636569", fontSize: "12px" }}>
                                <p>Time Management</p>
                                <Rate
                                    value={feedback.ratings.timeManagement}
                                    onChange={(value) => handleRatingChange("timeManagement", value)}
                                />
                            </Row>

                            <Row justify="space-between" style={{ color: "#636569", fontSize: "12px" }}>
                                <p>Teamwork</p>
                                <Rate
                                    value={feedback.ratings.teamwork}
                                    onChange={(value) => handleRatingChange("teamwork", value)}
                                />
                            </Row>

                            <Row justify="space-between" style={{ color: "#636569", fontSize: "12px" }}>
                                <p>Autonomy</p>
                                <Rate
                                    value={feedback.ratings.autonomy}
                                    onChange={(value) => handleRatingChange("autonomy", value)}
                                />
                            </Row>

                            <div style={{ textAlign: "center" }}>
                                <Button type='primary' onClick={handleUpdateManagerFeedback}>Submit</Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
            {console.log(completed, "completedddd")}

            {activeTab === "3" && (
                <Row gutter={16}>
                    <Col span={24}>
                        <Card style={{ background: "#dce2f7", borderColor: "#086bff", height: "70vh", overflowY: "auto" }}>
                            <Row justify="space-between">
                                {completed?.map((course, index) => {
                                    // Determine whether to show all skills or just the first 5
                                    const showAllSkills = expandedCourses[course.courseId] || false; // Default to false
                                    const displayedSkills = showAllSkills ? course?.skills : course?.skills?.slice(0, 3); // Show all or first 5

                                    return (
                                        <Col span={7} key={course.courseId}>
                                            <Card
                                                style={{
                                                    width: "57vh",
                                                    maxHeight: "45vh",
                                                    height: "45vh",
                                                    overflowY: "auto",
                                                    borderRadius: "0%",
                                                    borderColor: '#cabdfc',
                                                    boxShadow: '0 3px 3px #7459e3',
                                                }}
                                            >
                                                <h6
                                                    className="text-center"
                                                    style={{
                                                        color: "#1409b0",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        maxWidth: "45vh",
                                                        marginTop: "-12px",
                                                    }}
                                                >
                                                    {course.courseName}
                                                </h6>
                                                <Divider style={{ marginTop: "-5px" }} />
                                                {course.completed?.map((entry) => (
                                                    entry.name === users.displayName && (
                                                        <div key={entry._id.$oid} style={{ fontSize: "14px", textAlign: "center", color: "#0a9118", fontWeight: "bold", marginTop: "-20px" }}>
                                                            Marks scored: {entry.mark} / {course.minimumMarks}
                                                        </div>
                                                    )
                                                ))}

                                                <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', marginTop: "2vh" }}>
                                                    {displayedSkills?.map((skill, skillIndex) => (
                                                        <span
                                                            key={skillIndex}
                                                            style={{
                                                                backgroundColor: '#f1f1f1',
                                                                borderRadius: '20px',
                                                                padding: '5px 10px',
                                                                border: '1px solid #ddd',
                                                                fontSize: '12px',
                                                                background: "#cbc8f7",
                                                                width: "50vh",
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            {skill}
                                                        </span>
                                                    ))}
                                                </div>

                                                {/* Conditionally show the "more" or "less" link */}
                                                {course.skills?.length > 5 && (
                                                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                                                        <a onClick={() => handleToggleSkills(course.courseId)} style={{ cursor: "pointer", color: "#086bff", fontSize: "12px" }}>
                                                            {showAllSkills ? "Show less" : "Show more"}
                                                        </a>
                                                    </div>
                                                )}

                                            </Card>
                                            <br />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Card>
                    </Col>
                </Row>
            )}

            {activeTab === "4" && (
                <Row gutter={16} justify="center">
                    <Col span={24}>
                        <Card style={{ borderColor: "#086bff" }}>
                            <h6 className="text-center" style={{ fontSize: "15px" }}>Rating</h6>
                            <br />
                            {console.log(filterRatingsByDate(), "filterrate")}

                            <Row gutter={16} justify="center" align="middle">
                                {filterRatingsByDate() === null ? (
                                    ratings?.map((rating, index) => (
                                        <>
                                            <Col
                                                key={index}
                                                xs={12}
                                                sm={8}
                                                md={6}
                                                lg={4}
                                                style={{ display: "flex", justifyContent: "center" }}
                                            >
                                                <Card
                                                    style={{
                                                        background: selectedRating === index ? "#f5dec9" : "#dce2f7",
                                                        borderColor: selectedRating === index ? "#fa0a4a" : "#fc084d",
                                                        color: "#000",
                                                        cursor: "pointer",
                                                        height: "16vh",
                                                        width: "22vh",
                                                        textAlign: "center",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                    onClick={() => handleSelect(index)}
                                                >
                                                    <div style={{ fontSize: "24px", marginTop: "-2vh" }}>{rating.emoji}</div>
                                                    <div style={{ marginTop: "1px", fontSize: "14px" }}>{rating.text}</div>
                                                </Card>
                                            </Col>

                                        </>
                                    ))
                                ) : (
                                    <>
                                        <Col
                                            xs={12}
                                            sm={8}
                                            md={6}
                                            lg={4}
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            <Card
                                                style={{
                                                    background: "#f5dec9",
                                                    borderColor: "#fc084d",
                                                    color: "#000",
                                                    cursor: "pointer",
                                                    height: "16vh",
                                                    width: "22vh",
                                                    textAlign: "center",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ fontSize: "24px", marginTop: "-2vh" }}>
                                                    {getEmojiForRating(filterRatingsByDate()?.rating)}
                                                </div>
                                                <div style={{ marginTop: "1px", fontSize: "14px" }}>{filterRatingsByDate().rating}</div>
                                            </Card>
                                        </Col>
                                        <p style={{ fontSize: "14px", color: "blue" }}>{filterRatingsByDate().comments}</p>
                                    </>
                                )}
                            </Row>
                            {filterRatingsByDate() === null &&
                                <Row style={{ marginTop: "1vh" }}>
                                    <Col span={8}></Col>
                                    <Col span={8}>
                                        <Input.TextArea
                                            placeholder="Input your Comments"
                                            rows={4}
                                            style={{ width: "100vh" }}
                                            value={comments}
                                            onChange={handleCommentChange}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Button
                                            type="primary"
                                            danger
                                            style={{ marginTop: "6vh", marginLeft: "3vh" }}
                                            onClick={handleRatingPost}
                                        >
                                            Post
                                        </Button>
                                    </Col>
                                </Row>
                            }
                            <br />


                        </Card>
                    </Col>
                </Row>
            )}



        </div>
    )
}

export default Appraisal;
