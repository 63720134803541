/*My Work->Employee details Table*/
import React, { useState, useEffect } from "react";
import {
  Table,
  message,
  Form,
  Modal,
  Select,
  Popconfirm,
  notification,
  Image,
  Avatar,
  Space,
  Spin,
} from "antd";
import { url } from "../../url/url";
import axios from "axios";
import { Button, Input } from "antd";
import "./employee.css";
import {
  PlusOutlined,
  SearchOutlined,
  EditFilled,
  EyeFilled,
  DeleteFilled,
  QuestionCircleOutlined,
  UploadOutlined,
  DisconnectOutlined,
  PieChartOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { decryptRole } from "../../encrypt";
const { Search } = Input;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const Emp = (props) => {
  const [Users, setUsers] = useState([]);
  const [List, setList] = useState([]);
  const [imgvisible, setImgvisible] = useState(false);
  const [newImage, setNewImage] = useState("");
  const [edit, setEdit] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [emptype, setEmptype] = useState([]);
  const [getUser, setGetUser] = useState([]);
  // const user = props.data;
  const user = JSON.parse(localStorage.getItem("user"));
  const [newProfileImage, setNewProfileImage] = useState(null);
  const [newPanImage, setNewPanImage] = useState(null);
  const [newAadharImage, setNewAadharImage] = useState(null);
  const [panCopy, setPanCopy] = useState(null);
  const [aadharUpload, setAadharUpload] = useState(null);
  const [projects, setProjects] = useState([]);
  const formRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [companylaptop, setCompanylaptop] = useState(null);
  const status = localStorage.getItem("status");
  const encryptedRoleFromStorage = localStorage.getItem("role");
  const jobTitle = decryptRole(encryptedRoleFromStorage);

  // const [user] = useState(props.data)
  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      ...record,
      profileImage: record.profileImage,
      panCopy: record.panCopy,
      aadharUpload: record.aadharUpload,
    });
  };

  const fetchData = async () => {
    const dbName = localStorage.getItem("dbName");

    try {
      const response = await fetch(`${url}/api/projectsdetails/${dbName}`);
      const data = await response.json();
      setProjects(data);

      // Create filters for the "Employee Id" column
      const empCodeFilters = data.map((project) => ({
        text: project.TotalEmp.join(", "),
        value: project.TotalEmp.join(", "),
      }));

      // Update the filters for the "Employee Id" column
      columns.filters = empCodeFilters;

      console.log(data, "projectname");
      // ... Rest of the code remains the same
    } catch (error) {
      console.error(error);
    }
  };

  const shouldUpdate = (prevProps, nextProps) => {
    if (
      prevProps.dataSource === nextProps.dataSource &&
      prevProps.count === nextProps.count
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    //fetchUsers();
    fetchList();
    fetchData();
    fetchUserData();
  }, [count]);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const displayName = `${user.displayName}`;
      const dbName = localStorage.getItem("dbName");
      if (jobTitle === "admin") {
        const response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
        setUsers(response.data);
      } else if (jobTitle === "manager") {
        const response = await axios.get(
          `${url}/api/get/emplist/${displayName}/manager/${dbName}`
        );
        setUsers(response.data);
      } else if (jobTitle === "hr") {
        const response = await axios.get(
          `${url}/api/get/emplist/${displayName}/hr/${dbName}`
        );
        setUsers(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  // Api call
  const fetchList = async () => {
    const dbName = localStorage.getItem("dbName");
    const response = await axios.get(`${url}/api/get/manager/${dbName}`);
    console.log("manager list fetched fetchlist");
    const activeUsers = response.data.filter(
      (User) => User.empStatus !== "inactive"
    );
    setList(activeUsers);
  };

  const handleAzureUpdate = async (values) => {
    let userPrincipalName;

    try {
      const domain = localStorage.getItem("domain");
      const res = await axios.get(`${url}/api/getmasterorg/${domain}`);
      const clientId = res.data.org.clientId;
      const tenantId = res.data.org.tenantId;
      const clientSecret = res.data.org.clientSecret;
      console.log(clientId, "clientId update");

      const response = await fetch(
        `${url}/api/getUser/${values.displayName}/${clientId}/${tenantId}/${clientSecret}`
      );
      if (response.ok) {
        const userData = await response.json();
        // Now you can work with the userData object
        console.log("User Data:", userData);

        userPrincipalName = userData.value[0].userPrincipalName;
        console.log("userPrincipalName in azure update", userPrincipalName);
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    //const userPrincipalName = response.userPrincipalName;

    console.log("value in update", values);
    try {
      const domain = localStorage.getItem("domain"); // Make sure you have the user's email stored in 'userData' from a previous step
      const res = await axios.get(`${url}/api/getmasterorg/${domain}`);
      const clientId = res.data.org.clientId;
      const tenantId = res.data.org.tenantId;
      const clientSecret = res.data.org.clientSecret;
      console.log(clientId, "clientId update");
      console.log(tenantId, "tenantId update");
      console.log(clientSecret, "clientSecret update");

      const response = await axios.post(
        `${url}/api/updateUserInfo/${userPrincipalName}/${clientId}/${tenantId}/${clientSecret}`,
        {
          values,
        }
      );
      console.log(response);
      setUsers(
        Users.map((data) =>
          data.displayName === editingRecord.displayName
            ? { ...data, ...response.data.data }
            : data
        )
      );
      setEditingRecord(null);
      console.log("Record updated successfully!");
    } catch (error) {
      console.error(error);
      // message.error("Failed to update the record.");
    }
  };
  const handleCompanyLaptopChange = (value) => {
    console.log("Company Laptop Value (onChange):", value);
    // You can also set the value in the form if needed
    setCompanylaptop(value);
  };

  const handleDBUpdate = async (values, type) => {
    if (type === true) {
      values.empStatus = "inactive";
    }

    const userType = localStorage.getItem("userType");
    if (userType === "true") {
      handleAzureUpdate(values);
    }

    try {
      const dbName = localStorage.getItem("dbName");
      if (image) {
        values.profileImage = image;
      } else if (aadharUpload) {
        values.aadharUpload = aadharUpload;
      } else if (panCopy) {
        values.panCopy = panCopy;
      }

      if (values.empStatus === "inactive") {
        values.terminationDate = format(new Date(), "dd-MM-yyyy");
      }

      // Split the manager name and email only if the format is correct
      if (
        values.manager &&
        values.manager.includes("(") &&
        values.manager.includes(")")
      ) {
        const selectedUser = values.manager.split("(");
        const manager_displayname = selectedUser[0].trim();
        const manager_email = selectedUser[1].replace(")", "").trim();

        values.manager = manager_displayname;
        values.managerEmail = manager_email;
      }

      console.log("values", values.email);
      const mail = values.email;
      const response = await axios.put(
        `${url}/api/updateemployee/${
          editingRecord ? editingRecord.email : mail
        }/${dbName}`,
        values
      );

      setUsers(
        Users.map((record) =>
          record.email === values.email
            ? { ...record, ...response.data }
            : record
        )
      );
      console.log("after response");
      setEditingRecord(null);
      notification.success({ message: "Record updated in DB successfully!" });

      if (values.empStatus === "inactive") {
        const DeleteBal = await axios.delete(
          `${url}/deleteRecord/${
            editingRecord ? editingRecord.empCode : values.empCode
          }/${dbName}`,
          {
            data: {
              displayName: values.displayName,
            },
          }
        );
        values.userType = "inactive";
      }

      console.log(values.userType, "usertype inactive master update");
      const res = await axios.put(
        `${url}/api/masteruser/update/${
          editingRecord ? editingRecord.email : values.email
        }`,
        values
      );
      setCount(count + 1);
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error("Failed to update DB record.");
    }
  };

  const handleAzureDelete = async (record) => {
    let userPrincipalName;

    try {
      const domain = localStorage.getItem("domain"); // Make sure you have the user's email stored in 'userData' from a previous step
      const res = await axios.get(`${url}/api/getmasterorg/${domain}`);
      const clientId = res.data.org.clientId;
      const tenantId = res.data.org.tenantId;
      const clientSecret = res.data.org.clientSecret;

      const response = await fetch(
        `${url}/api/getUser/${record.displayName}/${clientId}/${tenantId}/${clientSecret}`
      );
      if (response.ok) {
        const userData = await response.json();
        // Now you can work with the userData object
        console.log("User Data:", userData);
        userPrincipalName = userData.value[0].userPrincipalName;
        console.log(userPrincipalName, "userPrincipalName");
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    //const userPrincipalName = response.userPrincipalName;
    console.log("dislay name in azuredbdel is", userPrincipalName);
    const dbName = localStorage.getItem("dbName");

    // console.log(data);
    try {
      const domain = localStorage.getItem("domain"); // Make sure you have the user's email stored in 'userData' from a previous step
      const res = await axios.get(`${url}/api/getmasterorg/${domain}`);
      const clientId = res.data.org.clientId;
      const tenantId = res.data.org.tenantId;
      const clientSecret = res.data.org.clientSecret;
      console.log(userPrincipalName, "userPrincipalName");

      const response = await axios.delete(
        `${url}/api/deleteUser/${userPrincipalName}/${clientId}/${tenantId}/${clientSecret}`
      );
      if (response.ok)
        setUsers(
          Users.filter((data) => data.userPrincipalName !== userPrincipalName)
        );
      notification.success({ message: "Employee deleted successfully!" });
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  // modal
  const handleDelete = async (record) => {
    const resp = await handleMasterDelete(record);

    if (resp === 200) {
      const respdel = await handleDBDelete(record);
      const userType = localStorage.getItem("userType");
      if (userType === "true" && respdel === 200) {
        console.log("inside handleazuredel");
        handleAzureDelete(record);
      }
    } else if (resp === 300) {
      console.log("Associated in project");
    } else {
      //console.error(error);
      message.error("Failed to delete the record.");
    }
  };
  const handleDBDelete = async (record) => {
    const dbName = localStorage.getItem("dbName");

    try {
      const response = await axios.delete(
        `${url}/api/deleteemployee/${record.email}/${dbName}`
      );
      const DeleteBal = await axios.delete(
        `${url}/deleteRecord/${record.empCode}/${dbName}`,
        {
          data: {
            displayName: record.displayName,
          },
        }
      );
      setUsers(Users.filter((data) => data.email !== record.email));
      setEditingRecord(null);
      resetFormFields();
      notification.success({ message: "Record deleted successfully!" });
      if (response.status === 200) {
        return response.status;
        // notification.success({message:"Record deleted successfully from master user table!");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to delete the record.");
    }
  };
  const handleMasterDelete = async (record) => {
    const userType = localStorage.getItem("userType");
    const domain = localStorage.getItem("domain");
    const dbName = localStorage.getItem("dbName");

    let officeEmail = "";
    console.log("in master delete");
    const response = await fetch(
      `${url}/api/projects/${record.displayName}/${dbName}`
    );
    const data = await response.json();
    console.log(data, "datain projects");
    if (data.length === 0) {
      const userType = localStorage.getItem("userType");
      console.log(userType, "userType");

      try {
        const officeEmail = record.email; // Use the email from values
        console.log(officeEmail, "officeemil");

        // if (userType === "true") {
        //   // Check if userType is "true" (Azure)
        //   const firstName = record.firstName.toLowerCase();
        //   const lastName = record.lastName.toLowerCase();
        //   officeEmail = `${firstName}.${lastName.charAt(0)}@${domain}`;
        //   console.log(officeEmail, "officeemil");
        // } else {
        //   // Native user
        //   officeEmail = record.email; // Use the email from values
        //   console.log(officeEmail, "officeemil");
        // }
        const massterresp = await axios.delete(
          `${url}/api/deletemasteremp/${officeEmail}`
        );
        console.log(
          "Record deleted successfully from master user table!",
          massterresp.status
        );

        if (massterresp.status === 200) {
          return massterresp.status;
          // notification.success({message:"Record deleted successfully from master user table!");
        }
      } catch (error) {
        console.error(error);
        return 500;
        message.error("Failed to delete the record.");
      }
    } else {
      notification.open({
        message: "Alert Notification ",
        description: "This Employee is Assigned in Project",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
      return 300;
    }
  };

  // const UploadImage = (props) => {
  //   return (
  //     <Popconfirm
  //       placement="top"
  //       icon={null}
  //       onConfirm={() => Edit(props.id)}
  //       onCancel={() => confirm(props.url)}
  //       okText="Edit"
  //       cancelText="view"
  //       cancelButtonProps={{
  //         style: { color: "white", backgroundColor: "blue" },
  //       }}
  //       okButtonProps={{ style: { color: "white", backgroundColor: "red" } }}
  //     >
  //       <img
  //         style={{ height: "40px", width: "40px", borderRadius: "30px" }}
  //         src={props.url}
  //       />
  //     </Popconfirm>
  //   );
  // };

  // const confirm = (url) => {
  //   setImgvisible(true);
  //   setNewImage(url);
  // };
  // const Edit = (userid) => {
  //   setImgvisible(true);
  //   setImageId(userid);
  //   setEdit(true);
  // };

  function close() {
    setImgvisible(false);
    setNewImage("");
    setEdit(false);
    setImage("");
    setImageId("");
  }

  const handleProfileImageUpload = () => {
    const fileInput = document.getElementById("profileImageInput");

    // Add an event listener to the file input to capture the file selection
    fileInput.addEventListener("change", () => {
      const selectedFile = fileInput.files[0];

      if (selectedFile) {
        // Check if the selected file type is an image (you can customize the supported types)
        if (selectedFile.type.startsWith("image/")) {
          // Proceed with the upload
          // You can implement the upload logic here
        } else {
          // Display an Ant Design error message for unsupported file types
          message.error("Invalid file type. Please upload an image file.");
          // You can also clear the input field or take other actions as needed
          fileInput.value = "";
        }
      }
    });

    // Trigger the file input click event
    fileInput.click();
  };

  const handleAadharImageUpload = () => {
    const fileInput = document.getElementById("aadharUploadInput");

    // Add an event listener to the file input to capture the file selection
    fileInput.addEventListener("change", () => {
      const selectedFile = fileInput.files[0];

      if (selectedFile) {
        // Check if the selected file type is an image (you can customize the supported types)
        if (selectedFile.type.startsWith("image/")) {
          // Proceed with the upload
          // You can implement the upload logic here
        } else {
          // Display an Ant Design error message for unsupported file types
          message.error("Invalid file type. Please upload an image file.");
          // You can also clear the input field or take other actions as needed
          fileInput.value = "";
        }
      }
    });

    // Trigger the file input click event
    fileInput.click();
  };

  const handlePanImageUpload = () => {
    const fileInput = document.getElementById("panCopyInput");

    // Add an event listener to the file input to capture the file selection
    fileInput.addEventListener("change", () => {
      const selectedFile = fileInput.files[0];

      if (selectedFile) {
        // Check if the selected file type is an image (you can customize the supported types)
        if (selectedFile.type.startsWith("image/")) {
          // Proceed with the upload
          // You can implement the upload logic here
        } else {
          // Display an Ant Design error message for unsupported file types
          message.error("Invalid file type. Please upload an image file.");
          // You can also clear the input field or take other actions as needed
          fileInput.value = "";
        }
      }
    });

    // Trigger the file input click event
    fileInput.click();
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // const filteredData = Users.filter((item) => {
  //   if (item.displayName && item.empStatus !== "inactive") {
  //     return item.displayName.toLowerCase().includes(searchQuery.toLowerCase());
  //   }
  //   return false;
  // });

  // Updated search filter
  const filteredData = Users.filter((item) => {
    if (item.empStatus !== "inactive") {
      const searchTerm = searchQuery.toLowerCase();

      // Check for direct string matching in yearsofexp
      const yearExpRegex = new RegExp(searchTerm.replace(/\s+/g, ""), "i"); // Case-insensitive and ignores spaces

      // Check if any of the relevant fields contain the search query
      return (
        (item.displayName &&
          item.displayName.toLowerCase().includes(searchTerm)) ||
        (item.manager && item.manager.toLowerCase().includes(searchTerm)) ||
        // (item.manager && item.manager.toLowerCase().includes(searchTerm)) ||
        // (item.lastName && item.lastName.toLowerCase().includes(searchTerm)) ||

        (item.firstName && item.firstName.toLowerCase().includes(searchTerm)) ||
        (item.lastName && item.lastName.toLowerCase().includes(searchTerm)) ||
        (item.email && item.email.toLowerCase().includes(searchTerm)) ||
        (item.jobTitle && item.jobTitle.toLowerCase().includes(searchTerm)) ||
        (item.department &&
          item.department.toLowerCase().includes(searchTerm)) ||
        (item.empCode && item.empCode.toLowerCase().includes(searchTerm)) ||
        (item.organization &&
          item.organization.toLowerCase().includes(searchTerm)) ||
        (item.yearsofexp && yearExpRegex.test(item.yearsofexp))
      );
    }
    return false;
  });

  //  // Filter data based on the search query across the whole table
  //  const filteredData = Users.filter((item) =>
  //   Object.values(item).some((val) =>
  //     String(val).toLowerCase().includes(searchQuery.toLowerCase())
  //   )
  // );

  const handleImageChange1 = (e) => {
    console.log("inside handleImageChange1");
    const file = e.target.files[0];
    setNewProfileImage(file);

    if (file) {
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add allowed image file types here

      // Check if the selected file type is in the allowed list
      if (allowedFileTypes.includes(file.type)) {
        const formData = new FormData();
        formData.append("profileImage", file);

        const reader = new FileReader();
        reader.onloadend = () => {
          console.log(reader.result, "image");

          // Set the profileImage field in the form to the new image URL
          form.setFieldsValue({
            ...form.getFieldsValue(),
            profileImage: reader.result, // Set to the new profile image URL
          });
        };
        reader.readAsDataURL(file);
      } else {
        message.error("Invalid file type. Please select a valid image file.");
      }
    } else {
      // Clear the profileImage field if no file is selected
      setImage(null);
      formRef.current.setFieldsValue({ image: null, profileImage: null });
    }
  };

  const handlePanImageChange = (e) => {
    console.log("inside handlePanImageChange");
    const file = e.target.files[0];
    setNewPanImage(file);
    if (file) {
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add allowed image file types here

      // Check if the selected file type is in the allowed list
      if (allowedFileTypes.includes(file.type)) {
        const formData = new FormData();
        formData.append("panCopy", file);

        const reader = new FileReader();
        reader.onloadend = () => {
          console.log(reader.result, "pan image");

          // Set the panCopy field in the form to the new image URL
          form.setFieldsValue({
            ...form.getFieldsValue(),
            panCopy: reader.result, // Set to the new pan image URL
          });
        };
        reader.readAsDataURL(file);
      } else {
        message.error("Invalid file type. Please select a valid image file.");
      }
    } else {
      // Clear the panCopy field if no file is selected
      setPanCopy(null);
      formRef.current.setFieldsValue({ panCopy: null });
    }
  };

  const handleAadharImageChange = (e) => {
    console.log("inside handleAadharImageChange");
    const file = e.target.files[0];
    setNewAadharImage(file);
    if (file) {
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add allowed image file types here

      // Check if the selected file type is in the allowed list
      if (allowedFileTypes.includes(file.type)) {
        const formData = new FormData();
        formData.append("aadharUpload", file);

        const reader = new FileReader();
        reader.onloadend = () => {
          console.log(reader.result, "aadhar image");

          // Set the aadharupload field in the form to the new image URL
          form.setFieldsValue({
            ...form.getFieldsValue(),
            aadharUpload: reader.result, // Set to the new aadhar image URL
          });
        };
        reader.readAsDataURL(file);
      } else {
        message.error("Invalid file type. Please select a valid image file.");
      }
    } else {
      // Clear the aadharupload field if no file is selected
      setAadharUpload(null);
      formRef.current.setFieldsValue({ aadharUpload: null });
    }
  };
  const handleDeleteImage = () => {
    // Clear the newProfileImage state and reset the profileImage field in the form
    setNewProfileImage("");
    form.setFieldsValue({
      ...form.getFieldsValue(),
      profileImage: null,
    });
  };
  const handleDeleteAadhaar = () => {
    // Clear the newProfileImage state and reset the profileImage field in the form
    setNewAadharImage("");
    form.setFieldsValue({
      ...form.getFieldsValue(),
      aadharUpload: null,
    });
  };
  const handleDeletePan = () => {
    // Clear the newProfileImage state and reset the profileImage field in the form
    setNewPanImage("");
    form.setFieldsValue({
      ...form.getFieldsValue(),
      panCopy: null,
    });
  };
  const uniqueManager = Array.from(new Set(Users.map((user) => user.manager)));
  const uniquehrName = Array.from(new Set(Users.map((user) => user.hrName)));
  const accessToken = localStorage.getItem("accessToken");
  const isTokenValid = accessToken && accessToken.length > 15;
  let columns = [];

  columns.push(
    {
      title: "Emp Id",
      dataIndex: "empCode",
      onFilter: (value, record) => record.empCode === value,
    },
    {
      title: "Profile",
      dataIndex: "profileImage",
      key: "profileImage",
      width: 80,
      render: (profileImage, record) => {
        if (profileImage) {
          // If a profile image is available, display it.
          return (
            <div style={{ alignItems: "center" }}>
              {" "}
              <Avatar size="large" src={profileImage} />{" "}
            </div>
          );
        } else {
          // If there's no profile image, display initials of the first and last names.
          const nameInitials = `${record.firstName
            .charAt(0)
            .toUpperCase()}${record.lastName.charAt(0).toUpperCase()}`;
          return (
            <Avatar
              size="large"
              style={{
                backgroundColor: "#fde3cf",
                color: "#f56a00",
              }}
            >
              {nameInitials}
            </Avatar>
          );
        }
      },
    },

    {
      title: "Name",
      dataIndex: "displayName",
      className: "ant-table-cell",
      width: 200,
      render: (text) => <div title={text}>{text}</div>,
    },

    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   className: "ant-table-cell",
    // },
    {
      title: "Manager",
      dataIndex: "manager",
      className: "ant-table-cell",
      width: 200,
      filters: uniqueManager.map((manager) => ({
        text: manager,
        value: manager,
      })),
      onFilter: (value, record) => record.manager === value,
    },
    {
      title: "HR",
      dataIndex: "hrName",
      className: "ant-table-cell",
      width: 200,
      filters: uniquehrName.map((hrName) => ({
        text: hrName,
        value: hrName,
      })),
      onFilter: (value, record) => record.hrName === value,
    }
  );

  columns.push(
    {
      title: "Project Name",
      className: "ant-table-cell",
      width: 230,
      render: (_, record) => {
        // Filter the projects where the employee's displayName is in TotalEmp
        const matchingProjects = projects.filter((project) =>
          project.TotalEmp.includes(record.displayName)
        );

        // Extract project names from matching projects
        const projectNames = matchingProjects.map(
          (project) => project.ProjectName
        );

        // Join project names into a single string
        return (
          <div title={projectNames.join(", ") || "Not Assigned"}>
            {projectNames.join(", ") || "Not Assigned"}
          </div>
        );
      },
    },
    {
      title: "Years of Completion",
      key: "completionOfYear",
      align: "center",
      width: 200,
      render: (text, record) => {
        const joinDate = new Date(record.joinDate);
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - joinDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffYears = Math.floor(diffDays / 365);
        const remainingDays = diffDays % 365;
        const diffMonths = Math.floor(remainingDays / 30);
        const remainingDaysInMonth = remainingDays % 30;
        const completionString = `${diffYears}Yrs ${diffMonths}Mths ${remainingDaysInMonth}Days`;
        return <div title={completionString}>{completionString}</div>;
      },
    },
    {
      title: "Action",
      className: "ant-table-cell",
      align: "center",
      width: 260,
      render: (_, record) => (
        <>
          <Link to={`/home/orgchart/${record.email}`}>
            <Button
              onClick={(e) => e.stopPropagation()}
              size="small"
              icon={<PieChartOutlined />}
              title="Org Chart"
            />
          </Link>
          {isTokenValid && (
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(record);
              }}
              icon={<EditFilled />}
              title="Edit"
            />
          )}
          {(jobTitle === "hr" || jobTitle === "admin") && (
            <>
              {isTokenValid && (
                <Popconfirm
                  title="Are you sure to delete this record?"
                  onConfirm={(e) => {
                    e.stopPropagation();
                    handleDelete(record);
                  }}
                  onCancel={(e) => {
                    e.stopPropagation();
                  }}
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    size="small"
                    icon={<DeleteFilled />}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    title="Delete"
                  />
                </Popconfirm>
              )}
              <Popconfirm
                title="Are you sure to Inactive this user?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  handleDBUpdate(record, true);
                }}
                onCancel={(e) => {
                  e.stopPropagation();
                }}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  icon={<UserDeleteOutlined />}
                  title="Inactive Employee"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Popconfirm>
            </>
          )}
        </>
      ),
    }
  );
  const resetFormFields = () => {
    form.setFieldsValue({
      displayName: null,
      email: null,
      personalemail: null,
      manager: null,
      hrName: null,
      jobTitle: null,
      phoneNumber: null,
      alternateMobile: null,
      emergencyContact: null,
      address: null,
      gender: null,
      dob: null,
      pan: null,
      aadharNumber: null,
      empType: null,
      department: null,
      yearsofexp: null,
      accountNumber: null,
      accountType: null,
      bankName: null,
      ifscCode: null,
      empDesignation: null,
      joinDate: null,
      casualLeave: null,
      annualLeave: null,
      bloodGroup: null,
      empDependents: null,
      companyLaptop: null,
      laptopSerial: null,
      laptopWarranty: null,
      accessaries: null,
      profileImage: null, // Set profileImage to null
      panCopy: null, // Set panCopy to null
      aadharUpload: null, // Set aadharUpload to null
    });
  };
  
  const onCancel = () => {
    // Reset the newProfileImage state to null
    setNewProfileImage(null);
    setNewPanImage(null);
    setNewAadharImage(null);
    setEditingRecord(null);
    resetFormFields();
  };
  // image

  const handleImageUpload = async () => {
    console.log(image);
    try {
      const response = await axios.post(
        `${url}/upload-profile-image/${ImageId}`,
        {
          imageString: image,
        }
      );
      setCount(count + 1);
      if (response.status === 200) {
        notification.success({ message: "Record updated successfully!" });
        close();
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to update the record.");
    }
  };
  const validateFirstName = (rule, value, callback) => {
    const pattern = /^(?=.*[A-Za-z])[A-Za-z ]*$/;
    const number = /^[0-9]+$/;
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/; // Regular expression to match email format
    const empcodePattern = /^[A-Za-z0-9._%+-]+$/;
    const addressPattern = /^(?=.*[A-Za-z0-9,./])[ A-Za-z0-9,./]+$/;

    if (rule.field === "firstName" && !pattern.test(value)) {
      callback("Please enter only alphabetic characters for First Name");
    } else if (rule.field === "lastName" && !pattern.test(value)) {
      callback("Please enter only alphabetic characters for Last Name");
    } else if (rule.field === "email" && !emailPattern.test(value)) {
      callback("Please enter a valid email address");
    } else if (rule.field === "empCode" && !empcodePattern.test(value)) {
      callback("Please enter a valid value");
    } else if (rule.field === "empDesignation" && !pattern.test(value)) {
      callback("Please enter only alphabetic characters for Position");
      // } else if (rule.field === "department" && !pattern.test(value)) {
      //   callback("Please enter only alphabetic characters for Department");
    } else if (rule.field === "accountNumber" && !number.test(value)) {
      callback("Please enter a valid value");
    } else if (rule.field === "bankName" && !pattern.test(value)) {
      callback("Please enter only alphabetic characters for Bank Name");
    } else if (rule.field === "ifscCode" && !empcodePattern.test(value)) {
      callback("Please enter a valid value");
    } else if (rule.field === "address" && !addressPattern.test(value)) {
      callback("Please enter only alphabetic characters for Address");
    } else if (rule.field === "casualleave" && !number.test(value)) {
      callback("Please enter a valid value");
    } else if (rule.field === "annualleave" && !number.test(value)) {
      callback("Please enter a valid value");
    } else {
      callback();
    }
  };

  const validateYearFormat = (rule, value, callback) => {
    // Check if the value is a valid date with a 4-digit year
    const regex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    if (!regex.test(value)) {
      callback("Please enter a valid date in YYYY-MM-DD format.");
    } else {
      callback();
    }
  };
  const [image, setImage] = useState("");
  const [ImageId, setImageId] = useState("");
  return (
    <div
      style={{
        transform: "scale(0.9)",
        transformOrigin: "0 0",
        width: "110%",
      }}
    >
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <h4 className="text-center">Employee Details </h4>

          <div
            className="tag"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px",
            }}
          >
            <Modal
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              footer={
                image ? (
                  <>
                    <Button type="primary" danger onClick={handleImageUpload}>
                      Delete
                    </Button>
                    <Button type="primary" onClick={handleImageUpload}>
                      Upload
                    </Button>
                    <Button onClick={close}>Close</Button>
                  </>
                ) : (
                  <Button onClick={close}>Close</Button>
                )
              }
              visible={imgvisible}
              closable={false}
            >
              {edit ? (
                <>
                  {image ? (
                    <div className="row">
                      <div className="right col-6">
                        <img src={image} alt="Uploaded Image" width="200" />
                      </div>
                    </div>
                  ) : (
                    <Input
                      type="file"
                      name="image"
                      onChange={handleImageUpload}
                    />
                  )}
                </>
              ) : (
                <img width={350} src={newImage} />
              )}
            </Modal>
            <Link to="/home/empdetails">
              <Button
                className="buttons"
                type="primary"
                icon={<PlusOutlined />}
                style={{ color: "black" }}
              >
                New Employee
              </Button>
            </Link>
            <div>
              {jobTitle === "hr" && (
                <Link to="/home/empfileupload">
                  <Button
                    className="buttons"
                    type="primary"
                    icon={<UploadOutlined />}
                    style={{ color: "black" }}
                  >
                    Employee Upload
                  </Button>
                </Link>
              )}
            </div>
            <div>
              <Input
                type="text"
                size="large"
                style={{ height: "40px", fontSize: "15px" }}
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </div>
          </div>
          <div style={{ overflowX: "auto" }}>
            <Table
              className="custom-table col-lg-12 col-md-12 col-sm-12"
              columns={columns}
              dataSource={filteredData}
              onRow={(record) => ({
                onClick: () => {
                  window.location.href = `/home/userdetails/${record.email}`;
                },
              })}
              shouldComponentUpdate={shouldUpdate}
              scroll={{ x: true }}
              rowClassName="custom-row"
              sticky
            />
          </div>

          {editingRecord && (
            <Modal
              title="Edit Employee"
              width={550}
              visible={editingRecord !== null}
              onCancel={() => onCancel()}
              onOk={() => form.submit()}
              bodyStyle={{ maxHeight: 450, overflowY: "auto", margin: "20px" }}
              footer={[
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Button
                      type="primary"
                      onClick={() => form.submit()}
                      disabled={status === "disable"}
                    >
                      Ok
                    </Button>
                    <Button onClick={() => onCancel()}>Cancel</Button>
                  </div>
                </div>,
              ]}
            >
              <Form
                {...layout}
                form={form}
                initialValues={editingRecord}
                onFinish={handleDBUpdate}
                className="custom-form"
              >
                <Form.Item
                  label="Name"
                  name="displayName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="Mail"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Mail!",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="PersonalEmail"
                  name="personalemail"
                  rules={[
                    {
                      type: "email",
                      message: "",
                      required: false,
                    },
                    {
                      validator: validateFirstName,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Manager"
                  name="manager"
                  rules={[
                    {
                      required: true,
                      message: "Please input Manager Name!",
                    },
                  ]}
                >
                  <Select size="large">
                    {List.map((user, index) => (
                      <Select.Option
                        key={user.index}
                        value={`${user.displayName}(${user.email})`}
                      >
                        {user.displayName}({user.email})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="HR"
                  name="hrName"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input HR Name!",
                  //   },
                  // ]}
                >
                  <Select size="large">
                    {Users.filter(
                      (users) =>
                        users.jobTitle === "hr" &&
                        users.empStatus !== "inactive"
                    ).map((users, index) => (
                      <Option key={users.id} value={users.displayName}>
                        {users.displayName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Role"
                  name="jobTitle"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Job-Title!",
                    },
                  ]}
                >
                  <Select placeholder="Select your position">
                    {jobTitle === "superAdmin" && (
                      <Option value="admin">Admin</Option>
                    )}

                    {jobTitle === "admin" && (
                      <>
                        <Option value="employee">Employee</Option>
                        <Option value="manager">Manager</Option>
                        <Option value="hr">HR</Option>
                      </>
                    )}

                    {jobTitle === "hr" && (
                      <>
                        <Option value="employee">Employee</Option>
                        <Option value="manager">Manager</Option>
                        <Option value="hr">HR</Option>
                      </>
                    )}

                    {jobTitle === "manager" && (
                      <>
                        <Option value="employee">Employee</Option>
                        <Option value="manager">Manager</Option>
                      </>
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Mobile Phone"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Mobile number!",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Mobile number should be a 10-digit number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Alternate Mobile"
                  name="alternateMobile"
                  rules={[
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Mobile number should be a 10-digit number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="EmergencyContact"
                  name="emergencyContact"
                  rules={[
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Mobile number should be a 10-digit number.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Address"
                  name="address"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Address!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Gender!",
                    },
                  ]}
                >
                  <Select size="large">
                    {["male", "female"].map((user, index) => (
                      <option key={index} value={user}>
                        {user}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="DateofBirth"
                  name="dob"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    {
                      validator: validateYearFormat, // Use the custom validation function
                    },
                  ]}
                >
                  <Input style={{ height: "40px" }} type="date" size="large" />
                </Form.Item>
                <Form.Item
                  label="PAN"
                  name="pan"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Pan number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Aadhar"
                  name="aadharNumber"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your Aadhar Number!",
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Emp Type"
                  name="empType"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Employee Type!",
                    },
                  ]}
                >
                  <Select>
                    {["Permanent", "Contract"].map((user, index) => (
                      <option key={index} value={user}>
                        {user}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Department"
                  name="department"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Department!",
                    },
                    { validator: validateFirstName },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Years of exp"
                  name="yearsofexp"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Gender!",
                    },
                  ]}
                >
                  <Select>
                    {[...Array(31)].map((user, index) => (
                      <option key={index} value={user}>
                        {user}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="AccountNo"
                  name="accountNumber"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                    // { validator: validateFirstName },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Account Type"
                  name="accountType"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your Account Type!",
                  //   },
                  // ]}
                >
                  <Select>
                    {["Savings", "Current"].map((user, index) => (
                      <option key={index} value={user}>
                        {user}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Bank Name"
                  name="bankName"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                    // { validator: validateFirstName },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="IFSCcode"
                  name="ifscCode"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                    // { validator: validateFirstName },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Position"
                  name="empDesignation"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Designation!",
                    },
                    { validator: validateFirstName },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="DateofJoin"
                  name="joinDate"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    {
                      validator: validateYearFormat, // Use the custom validation function
                    },
                  ]}
                >
                  <Input style={{ height: "40px" }} type="date" size="large" />
                </Form.Item>
                <Form.Item
                  label="Casual Leave"
                  name="casualLeave"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Casual Leave value",
                    },
                    { validator: validateFirstName },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="annualLeave"
                  label="Annual Leave"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Annual Leave value",
                    },
                    { validator: validateFirstName },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="bloodGroup"
                  label="Blood Group"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="empDependents"
                  label="Employee Dependent"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="companyLaptop"
                  label="Company Laptop"
                  rules={[{ required: false, message: "" }]}
                >
                  <Select
                    placeholder="Select Company Laptop status"
                    onChange={(value) => handleCompanyLaptopChange(value)}
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Form.Item>
                {console.log(
                  editingRecord.companyLaptop,
                  companylaptop,
                  "companylaptop"
                )}
                {(companylaptop === "yes" ||
                  editingRecord.companyLaptop === "yes") && (
                  <>
                    <Form.Item
                      name="laptopSerial"
                      label="Laptop S.No"
                      rules={[{ required: false, message: "" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="laptopWarranty"
                      label="Laptop Warranty"
                      rules={[{ required: false, message: "" }]}
                    >
                      <Input type="date" />
                    </Form.Item>
                  </>
                )}
                {companylaptop === "no" &&
                  form.setFieldsValue({
                    laptopSerial: "",
                    laptopWarranty: "",
                  })}
                <Form.Item
                  name="accessaries"
                  label="Accessories"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label="Profile Image" name="profileImage">
                  <Button onClick={handleProfileImageUpload}>
                    Upload Profile Image
                  </Button>
                  <input
                    type="file"
                    id="profileImageInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange1}
                  />
                  {newProfileImage ? (
                    <div>
                      <img
                        src={URL.createObjectURL(newProfileImage)}
                        alt="Profile"
                        style={{ maxWidth: "100px" }}
                      />
                      <Button
                        danger
                        size="small"
                        icon={<DeleteFilled />}
                        onClick={handleDeleteImage}
                      ></Button>
                    </div>
                  ) : form.getFieldValue("profileImage") ? (
                    <div>
                      <img
                        src={form.getFieldValue("profileImage")} // Display the existing image
                        alt="Profile"
                        style={{ maxWidth: "100px" }}
                      />
                      <Button
                        danger
                        size="small"
                        icon={<DeleteFilled />}
                        onClick={handleDeleteImage}
                      ></Button>
                    </div>
                  ) : null}
                </Form.Item>
                <Form.Item label="Aadhar Upload" name="aadharUpload">
                  <Button onClick={handleAadharImageUpload}>
                    Upload Aadhar Upload
                  </Button>
                  <input
                    type="file"
                    id="aadharUploadInput"
                    style={{ display: "none" }}
                    onChange={handleAadharImageChange}
                  />
                  {newAadharImage ? (
                    <div>
                      <img
                        src={URL.createObjectURL(newAadharImage)}
                        alt="Profile"
                        style={{ maxWidth: "100px" }}
                      />
                      <Button
                        danger
                        size="small"
                        icon={<DeleteFilled />}
                        onClick={handleDeleteAadhaar}
                      ></Button>
                    </div>
                  ) : form.getFieldValue("aadharUpload") ? (
                    <div>
                      <img
                        src={form.getFieldValue("aadharUpload")} // Display the existing image
                        alt="aadharUpload"
                        style={{ maxWidth: "100px" }}
                      />
                      <Button
                        danger
                        size="small"
                        icon={<DeleteFilled />}
                        onClick={handleDeleteAadhaar}
                      ></Button>
                    </div>
                  ) : null}
                </Form.Item>
                <Form.Item label="PAN Upload" name="panCopy">
                  <Button onClick={handlePanImageUpload}>
                    Upload PAN Copy
                  </Button>
                  <input
                    type="file"
                    id="panCopyInput"
                    style={{ display: "none" }}
                    onChange={handlePanImageChange}
                  />
                  {newPanImage ? (
                    <div>
                      <img
                        src={URL.createObjectURL(newPanImage)}
                        alt="Profile"
                        style={{ maxWidth: "100px" }}
                      />
                      <Button
                        danger
                        size="small"
                        icon={<DeleteFilled />}
                        onClick={handleDeletePan}
                      ></Button>
                    </div>
                  ) : form.getFieldValue("panCopy") ? (
                    <div>
                      <img
                        src={form.getFieldValue("panCopy")} // Display the existing image
                        alt="panCopy"
                        style={{ maxWidth: "100px" }}
                      />
                      <Button
                        danger
                        size="small"
                        icon={<DeleteFilled />}
                        onClick={handleDeletePan}
                      ></Button>
                    </div>
                  ) : null}
                </Form.Item>
              </Form>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default Emp;
