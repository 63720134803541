import React from 'react';
import { Card, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, TeamOutlined, UploadOutlined, FileDoneOutlined, FileTextOutlined, CalendarOutlined, GiftOutlined, AlertOutlined, BarChartOutlined, ContactsOutlined, StopOutlined, IdcardOutlined } from '@ant-design/icons';
import './hrhome.css';


const EmployeeManagementTile = () => {
    const tileData = [
        // { title: 'Onboard New Joinee', link: '/home/empdetails', icon: <UserOutlined /> },
        { title: 'Manage Employees', link: '/home/employee', icon: <TeamOutlined /> },
        // { title: 'Employees Upload', link: '/emplist', icon: <UploadOutlined /> },
        { title: 'Performance Upload', link: '/home/ReviewTable', icon: <FileDoneOutlined /> },
        { title: 'Letters', link: '/home/letter', icon: <FileTextOutlined /> },
        { title: 'Work Anniversaries', link: '/home/workanni', icon: <CalendarOutlined /> },
        { title: 'Birthdays', link: '/home/birthday', icon: <GiftOutlined /> },
        { title: 'Low Leave Balance', link: '/home/lowleave', icon: <AlertOutlined /> },
        { title: 'Asset Report', link: '/home/asset', icon: <BarChartOutlined /> },
        { title: 'Emergency Contacts', link: '/home/contacts', icon: <ContactsOutlined /> },
        { title: 'Background Verification', link: '/home/emp', icon: <ContactsOutlined /> },
        // { title: 'Exit Management', link: '/home/exit', icon: <UserOutlined /> },
        { title: 'Offer Letter', link: '/home/offerletter', icon: <FileDoneOutlined /> },
        { title: 'Inactive Employees', link: '/home/inactive', icon: <StopOutlined /> },
        { title: 'Posted Jobs', link: '/home/post', icon: <IdcardOutlined/> },
        { title: 'Training', link: '/home/training', icon: <FileDoneOutlined /> },

 
    ];
    const colorData = localStorage.getItem("color");
    console.log(colorData,"colordata");
    
    return (
        <div className="site-card-wrapper" style={{zoom:"90%"}}>
            <Row gutter={[16, 16]}>
                {tileData.map((tile, index) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={index}>
                        <Card
                            className="tile-card"
                            title={tile.icon}
                            headStyle={{
                                background: colorData,
                                color: 'white',
                                textAlign: 'center',
                                display: 'flex',
                            }}
                            style={{
                                background: 'white',
             
                            }}
                        >
                            <Link className="tile-link" to={tile.link} style={{ color: colorData }}>
                                {tile.title}
                            </Link>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};



export default EmployeeManagementTile;
