import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Space,
  message,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  UserOutlined,
  IdcardOutlined,
  ExperimentOutlined,
  FileTextOutlined,
  HomeOutlined,
  CalendarOutlined,
  GlobalOutlined,
  DollarOutlined,
  SolutionOutlined,
  BookOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Collapse, Typography } from "antd";
import { url } from "../../url/url";
import axios from "axios";

const { Panel } = Collapse;
const { Title, Text } = Typography;
const Parseresume = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const parsedData = location.state?.parsedData;
  console.log(parsedData, "paredData");
  const dbName = localStorage.getItem("dbName");
  const [skills, setSkills] = useState([]);
  const [education, setEducation] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [projects, setProjects] = useState([]);
  const [summary, setSummary] = useState([]);
  const [pdf, setPdf] = useState();
  const navigate = useNavigate();
  const users = JSON.parse(localStorage.getItem("user"));

  const mapDataToForm = () => {
    if (parsedData) {
      form.setFieldsValue({
        candidateName: parsedData["Candidate Name"],
        experience: parsedData["Total Experience (Years)"],
        jobTitle: parsedData["Current Job Title"],
        location: parsedData["Location"],
        availability: parsedData["Availability"],
        language: parsedData["Language Proficiency"]?.join(", "),
        salary: parsedData["Salary Expectation"],
        Email: parsedData["Email"],
        PhoneNumber: parsedData["PhoneNumber"],
      });

      setSkills(
        Array.isArray(parsedData["Key Skills"]) ? parsedData["Key Skills"] : []
      );
      setEducation(
        Array.isArray(parsedData["Education"]) ? parsedData["Education"] : []
      );
      setCertifications(
        Array.isArray(parsedData["Certifications"])
          ? parsedData["Certifications"]
          : []
      );
      setProjects(
        Array.isArray(parsedData["Projects/Achievements"])
          ? parsedData["Projects/Achievements"]
          : []
      );
      setSummary(
        Array.isArray(parsedData["Notes"])
          ? parsedData["Notes"]
          : [parsedData["Notes"] || ""]
      );
      setPdf(parsedData["pdf"]);
    }
  };

  useEffect(() => {
    mapDataToForm();
  }, [parsedData]);

  const onFinish = async (values) => {
    const updatedData = {
      candidateName: values.candidateName,
      experience: values.experience,
      jobTitle: values.jobTitle,
      location: values.location,
      availability: values.availability,
      language: values.language,
      salary: values.salary,
      Email: values.Email,
      PhoneNumber: values.PhoneNumber,
      Education: education, // Updated education array
      Certifications: certifications, // Updated certifications array
      "Projects/Achievements": projects, // Updated projects array
      "Key Skills": skills, // Updated skills array
      pdf: pdf,
      Notes: summary,
      jobId: parsedData["jobId"],
      role: parsedData["role"],
      referralName: parsedData["referralName"],
      salaryExpectation: parsedData["salary"],
    };

    // Send updatedData to the server or handle it as needed
    console.log("Updated form data:", updatedData);
    // Make an API call to save the updated data (POST request)
    await axios
      .post(`${url}/api/resume/${dbName}/${users.displayName}`, updatedData)
      .then((response) => {
        console.log("Data saved successfully", response.data);

        notification.success({
          message: "CV Saved Successfully",
          style: {
            top: "25px",
            left: "25px",
          },
        });

        setTimeout(() => {
          const userConfirmation = window.confirm(
            "Your CV has been successfully submitted. Would you like to navigate to the homepage or stay on this page?"
          );

          // Handle user response
          if (userConfirmation) {
            // Redirect to homepage if user selects 'Yes'
            navigate(-1);
          }
        }, 500); // Delay of 500ms to allow notification to display
      })
      .catch((error) => {
        console.error("Error saving data", error);

        // Check if the error response exists and display the custom message
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          notification.error({
            message: "Error Saving CV",
            description: error.response.data.message, // Show error message from backend
            style: {
              top: "25px",
              left: "25px",
            },
          });
        } else {
          // Display a generic error message if there's no custom error message
          notification.error({
            message: "Error Saving CV",
            description:
              "An error occurred while saving the CV. Please try again.",
            style: {
              top: "25px",
              left: "25px",
            },
          });
        }
      });
  };

  const handleAdd = (setter) => {
    setter((prev) => [...prev, ""]);
  };

  const handleRemove = (index, setter) => {
    setter((prev) => prev.filter((_, i) => i !== index));
  };

  const handleChange = (index, value, setter) => {
    setter((prev) => {
      const newArr = [...prev];
      newArr[index] = value;
      return newArr;
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Button onClick={goBack} icon={<ArrowLeftOutlined />} />
      <Row justify="space-between">
        <Col span={10}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "2vh",
            }}
          >
            <Card
              style={{
                borderColor: "#cabdfc",
                boxShadow: "0 3px 3px #7459e3",
                maxHeight: "92vh",
                overflowY: "scroll",
              }}
              className="custom-scrollbar"
            >
              <h5
                className="text-center"
                style={{ color: "#190af2", fontWeight: "bold" }}
              >
                Professional Overview
              </h5>
              <p
                className="text-center"
                style={{ color: "#8a898c", fontStyle: "italic" }}
              >
                This is a professional overview generated by AI based on the
                uploaded resume. You can evaluate the summary, including key
                skills and experiences, and easily save the details to the
                database.
              </p>
              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={require("../../images/resume2.webp")}
                  style={{ width: "30vh", height: "30vh", objectFit: "cover" }}
                  alt="Resume Overview"
                />
              </div>
              <div style={{ color: "#0099ff", padding: "1vh" }}>
                <h6
                  style={{
                    textAlign: "left",
                    color: "#190af2",
                    fontWeight: "bold",
                  }}
                >
                  Summary:
                </h6>
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                    marginTop: "10px",
                  }}
                >
                  {/* Assuming `summary` is an array of bullet points */}
                  {summary?.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
              </div>
            </Card>
          </div>
        </Col>

        <Col span={13}>
          <Card
            style={{
              borderColor: "#cabdfc",
              boxShadow: "0 3px 3px #7459e3",
              maxHeight: "92vh",
              overflowY: "scroll",
            }}
            className="custom-scrollbar"
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              style={{ padding: "0 30px" }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Candidate Name" name="candidateName">
                    <Input
                      placeholder="Enter your name"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <UserOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Email" name="Email">
                    <Input
                      placeholder="Enter your Email"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <GlobalOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Current Job Title" name="jobTitle">
                    <Input
                      placeholder="Enter your current job title"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <IdcardOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Experience" name="experience">
                    <Input
                      placeholder="Enter your experience in years"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <ExperimentOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Skills Field */}
              <Form.Item label="Skills">
                <Collapse
                  style={{
                    fontSize: "15px",
                    borderColor: "#0c20f5",
                    color: "#1113a8",
                  }}
                >
                  <Panel header="View Skills Information" key="1">
                    {skills?.map((skill, index) => (
                      <Space
                        key={index}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Input
                          value={skill}
                          onChange={(e) =>
                            handleChange(index, e.target.value, setSkills)
                          }
                          placeholder="Enter a skill"
                          style={{
                            fontSize: "15px",
                            borderColor: "#0c20f5",
                            color: "#1113a8",
                          }}
                          prefix={
                            <SolutionOutlined
                              style={{ color: "#0c20f5", marginTop: "-10px" }}
                            />
                          }
                        />
                        <Button
                          type="link"
                          onClick={() => handleRemove(index, setSkills)}
                        >
                          Remove
                        </Button>
                      </Space>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => handleAdd(setSkills)}
                      style={{ width: "100%" }}
                    >
                      Add Skill
                    </Button>
                  </Panel>
                </Collapse>
              </Form.Item>
              {/* Education Field */}
              <Form.Item label="Education">
                <Collapse
                  style={{
                    fontSize: "15px",
                    borderColor: "#0c20f5",
                    color: "#1113a8",
                  }}
                >
                  <Panel header="View Education Information" key="2">
                    {education?.length > 0 ? (
                      education?.map((edu, index) => (
                        <Card
                          key={index}
                          style={{
                            marginBottom: 8,
                            padding: 10,
                            fontSize: "15px",
                            borderColor: "#0c20f5",
                            color: "#1113a8",
                          }}
                        >
                          <Text strong>Degree:</Text> {edu.Degree}
                          <br />
                          <Text strong>Percentage/CGPA:</Text> {edu.Percentage}
                          <br />
                          <Text strong>Year of Passing:</Text>{" "}
                          {edu["Year of Passing"]}
                          <br />
                          <Text strong>University:</Text> {edu.University}
                        </Card>
                      ))
                    ) : (
                      <Text>No education information available.</Text>
                    )}
                  </Panel>
                </Collapse>
              </Form.Item>

              {/* Certifications Field */}
              <Form.Item label="Certifications">
                <Collapse
                  style={{
                    fontSize: "15px",
                    borderColor: "#0c20f5",
                    color: "#1113a8",
                  }}
                >
                  <Panel header="View Certification Information" key="3">
                    {certifications?.map((cert, index) => (
                      <Space
                        key={index}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Input
                          value={cert}
                          onChange={(e) =>
                            handleChange(
                              index,
                              e.target.value,
                              setCertifications
                            )
                          }
                          placeholder="Enter certification"
                          style={{
                            fontSize: "15px",
                            borderColor: "#0c20f5",
                            color: "#1113a8",
                          }}
                          prefix={
                            <FileTextOutlined
                              style={{ color: "#0c20f5", marginTop: "-10px" }}
                            />
                          }
                        />
                        <Button
                          type="link"
                          onClick={() => handleRemove(index, setCertifications)}
                        >
                          Remove
                        </Button>
                      </Space>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => handleAdd(setCertifications)}
                      style={{ width: "100%" }}
                    >
                      Add Certification
                    </Button>
                  </Panel>
                </Collapse>
              </Form.Item>

              {/* Projects and Achievements Field */}
              <Form.Item label="Projects and Achievements">
                <Collapse
                  style={{
                    fontSize: "15px",
                    borderColor: "#0c20f5",
                    color: "#1113a8",
                  }}
                >
                  <Panel header="View Projects and Achievements" key="4">
                    {projects?.map((project, index) => (
                      <Space
                        key={index}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Input
                          value={project}
                          onChange={(e) =>
                            handleChange(index, e.target.value, setProjects)
                          }
                          placeholder="Describe project/achievement"
                          style={{
                            fontSize: "15px",
                            borderColor: "#0c20f5",
                            color: "#1113a8",
                          }}
                          prefix={
                            <HomeOutlined
                              style={{ color: "#0c20f5", marginTop: "-10px" }}
                            />
                          }
                        />
                        <Button
                          type="link"
                          onClick={() => handleRemove(index, setProjects)}
                        >
                          Remove
                        </Button>
                      </Space>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => handleAdd(setProjects)}
                      style={{ width: "100%" }}
                    >
                      Add Project/Achievement
                    </Button>
                  </Panel>
                </Collapse>
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Email" name="Email">
                    <Input
                      placeholder="Enter your Email"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <GlobalOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Phone Number" name="PhoneNumber">
                    <Input
                      placeholder="Enter your Phone Number"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <CalendarOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Location" name="location">
                    <Input
                      placeholder="Enter your location"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <GlobalOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Availability" name="availability">
                    <Input
                      placeholder="Enter your availability"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <CalendarOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Language Proficiency" name="language">
                    <Input
                      placeholder="Enter your language proficiency"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <GlobalOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Salary Expectation" name="salary">
                    <Input
                      placeholder="Enter your salary expectation"
                      style={{
                        fontSize: "15px",
                        borderColor: "#0c20f5",
                        color: "#1113a8",
                      }}
                      prefix={
                        <DollarOutlined
                          style={{ color: "#0c20f5", marginTop: "-10px" }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Parseresume;
