import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Select,
  Modal,
  Avatar,
  Form,
  Checkbox,
  Menu,
  Dropdown,
  message,
  Radio,
  Layout,
  Tooltip,
  Divider,
  Typography,
  List,
  AutoComplete,
} from "antd";
import {
  TrophyOutlined,
  BarChartOutlined,
  FileTextOutlined,
  UserOutlined,
  DownOutlined,
  DeleteOutlined,
  GiftOutlined,
  AppstoreOutlined,
  EllipsisOutlined,
  LikeOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import moment from "moment";
import profilePic from "../../images/avatarpro1.png";
import profilePic1 from "../../images/avatar2.png";
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const emojis = [
  "\u{1F60A}",
  "\u{1F389}",
  "\u{1F929}",
  "\u{1F680}",
  "\u{2764}",
  "\u{1F44D}",
];
// Helper function to generate a random color
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Function to generate a random light color
function getRandomLightColor() {
  const r = Math.floor(Math.random() * 128) + 127; // Ensure a light color
  const g = Math.floor(Math.random() * 128) + 127; // Ensure a light color
  const b = Math.floor(Math.random() * 128) + 127; // Ensure a light color
  return `rgb(${r},${g},${b})`;
}

// const getInitials = (firstName, lastName) => {
//   if (!firstName && !lastName) return '';

//   // Extract the first letter of the first and last names
//   const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
//   const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';

//   return `${firstInitial}${lastInitial}`;
// };

const getInitials = (name) => {
  if (!name) return "";
  const nameParts = name.split(" ");
  const initials = nameParts.map((part) => part.charAt(0).toUpperCase());
  return initials.join("");
};

// Function to fetch employee data
async function fetchEmployees() {
  const response = await fetch("/employees");
  if (!response.ok) {
    throw new Error("Failed to fetch employees");
  }
  const employees = await response.json();
  return employees;
}

const TileLayout = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPollModalVisible, setIsPollModalVisible] = useState(false);
  const [options, setOptions] = useState([""]);
  const [form] = Form.useForm();
  const [kudos, setKudos] = useState([]);
  const [polls, setPolls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("kudos");
  const [pollsData, setPollsData] = useState(polls);
  const [modalContent, setModalContent] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [kudosData, setKudosData] = useState([]);
  const [celebrate, setCelebrate] = React.useState(false);

  const [remainingTime, setRemainingTime] = useState("");
  const [isClosed, setIsClosed] = useState(false);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hoveredOption, setHoveredOption] = useState(null);

  const [isCelebrating, setIsCelebrating] = useState(true);

  const [selectedReaction, setSelectedReaction] = useState(null);
  const [comments, setComments] = useState([]);
  const [showReactions, setShowReactions] = useState(false);
  const [newComment, setNewComment] = useState("");
  const commentInputRef = useRef(null); // To focus on the comment input when "Comment" is clicked

  // const [selectedEmployee, setSelectedEmployee] = React.useState('');
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [showReactionMenu, setShowReactionMenu] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [comment, setComment] = useState("");
  const [showReactionModal, setShowReactionModal] = useState(false);
  const [reactions, setReactions] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [commentText, setCommentText] = useState("");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date"; // Handle invalid date
    }
    return date.toLocaleString(); // Adjust formatting as needed
  };

  const handleReactionClick = (reactionId) => {
    setSelectedReaction(reactionId);
    setShowReactions(false); // Hide reactions after selecting one
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleCommentKeyPress = (e) => {
    if (e.key === "Enter" && comment.trim()) {
      const newComment = {
        employeeName: user.displayName,
        comment,
        profilePic: user.profileImage,
      };
      setComments([...comments, newComment]);
      setComment("");
      setShowCommentBox(false);
    }
  };

  const triggerCelebration = () => {
    setCelebrate(true);
    setTimeout(() => setCelebrate(false), 5000); // Celebration lasts for 5 seconds
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showPollModal = () => {
    setIsPollModalVisible(true);
  };

  const handlePollCancel = () => {
    setIsPollModalVisible(false);
  };

  // const addPollOption = () => {
  //   setOptions([...options, '']);
  // };

  const handleChange = (values) => {
    setSelectedValues(values);
  };

  // Fetch employees on mount
  useEffect(() => {
    async function loadEmployees() {
      try {
        const employeeData = await fetchEmployees();
        setEmployees(employeeData);
      } catch (error) {
        console.error("Error loading employees:", error);
      }
    }
    loadEmployees();
  }, []);

  // Handle comment submission
  const handleComSubmit = async () => {
    if (comment.trim() === "") return; // Avoid submitting empty comments
    const newComment = { employeeName: selectedEmployee, text: comment };
    setComments([...comments, newComment]);
    setComment("");
    setShowCommentBox(false);
    handleSubmit(); // Automatically submit when comment is posted
  };

  // Handle reaction selection
  const handleReactionSelect = (emoji) => {
    const reaction = { employeeName: selectedEmployee, emoji };
    setReactions([...reactions, reaction]);
    setShowReactionMenu(false);
    handleSubmit(); // Automatically submit when reaction is selected
  };

  // const handleReactionSelect = async (emoji) => {
  //   try {
  //     const response = await axios.put(`http://localhost:27017/kudos/reactions`, {
  //       emoji,
  //       userName: user.displayName,
  //       // employeeName: values.employeeName // Assuming 'values.name' contains employeeName or some unique identifier
  //     });
  //     console.log('Reaction added:', response.data);
  //     // Optionally, update the UI or state to reflect the new reaction
  //   } catch (error) {
  //     console.error('Error adding reaction:', error);
  //     message.error('Failed to add reaction. Please try again.');
  //   }
  // };

  // const handleComSubmit = async (e) => {
  //   if (e.key === 'Enter') {
  //     try {
  //       const response = await axios.put(`http://localhost:27017/kudos/comments`, {
  //         text: comment,
  //         userName: user.displayName,

  //         // employeeName: values.name // Assuming 'values.name' contains employeeName or a unique identifier
  //       });
  //       console.log('Comment added:', response.data);
  //       // Clear the comment input box and refresh the comments
  //       setComment('');
  //     } catch (error) {
  //       console.error('Error adding comment:', error);
  //       message.error('Failed to add comment. Please try again.');
  //     }
  //   }
  // };

  // Fetch reactions and open modal
  const openReactionModal = async () => {
    const response = await fetch(`/reactions/${selectedEmployee}`);
    const data = await response.json();
    setReactions(data.reactions);
    setShowReactionModal(true);
  };

  // Handle Enter key press for comment submission
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent newline character
      handleComSubmit();
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true); // Start loading
    try {
      values.userName = user.displayName;
      values.empCode = user.empCode;
      console.log("Submitting data:", values);
      await axios.post(`http://localhost:27017/kudos`, {
        userName: values.userName,
        empCode: values.empCode,
        employeeName: values.name,
        postingIn: values.postingIn,
        recognizeValue: values.value,
        message: values.message,
        reactions: values.reactions || [],
        comments: values.comments || [],
      });
      form.resetFields(); // Reset form fields on successful submission
      message.success("Form submitted successfully!"); // Show success message
      handleCancel(); // Close modal after submission
    } catch (error) {
      console.error("Error giving kudos:", error);
      message.error("Failed to submit form. Please try again."); // Show error message
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const fetchKudos = async () => {
    try {
      const response = await axios.get(`http://localhost:27017/getkudos`);
      setKudos(response.data); // Update the kudos state with fresh data
    } catch (error) {
      console.error("Error fetching kudos:", error);
    }
  };

  // const handleOptionChange = (index, event) => {
  //   const newOptions = [...options];
  //   newOptions[index] = event.target.value;
  //   setOptions(newOptions);
  // };

  // const deletePollOption = (index) => {
  //   const newOptions = options.filter((_, i) => i !== index);
  //   setOptions(newOptions);
  // };

  const handleOptionChange = (index, e) => {
    const newOptions = [...options];
    newOptions[index] = e.target.value;
    setOptions(newOptions);
  };

  const addPollOption = () => {
    if (options.length < 7) {
      setOptions([...options, ""]);
    }
  };

  const deletePollOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  useEffect(() => {
    const fetchKudos = async () => {
      try {
        const response = await axios.get(`http://localhost:27017/getkudos`);
        setKudos(response.data);
        console.log("Data", response.data);
      } catch (error) {
        console.error("Error fetching kudos:", error);
      }
    };

    fetchKudos();
  }, []);

  const handleVote = async (pollId, optionId) => {
    try {
      setSelectedOption(optionId);
      await axios.post(`http://localhost:27017/vote`, { pollId, optionId });
      message.success("Vote cast successfully!");

      // Refetch polls to update the votes in the UI
      fetchPolls();
    } catch (error) {
      console.error("Error casting vote:", error);
      message.error("Failed to cast vote. Please try again.");
    }
  };

  const handlePollSubmit = async (values) => {
    try {
      values.userName = user.displayName;
      values.empCode = user.empCode;
      //  const Options = options.map(option => ({ text: option, votes: 0 }));
      await axios.post(`http://localhost:27017/poll`, {
        userName: values.userName,
        empCode: values.empCode,
        postingIn: values.postingIn,
        question: values.question,
        options: options,
        anonymous: values.anonymous || false,
        duration: values.duration,
        reactions: values.reactions || [],
        comments: values.comments || [],
      });
      form.resetFields(); // Reset form fields on successful submission });
      message.success("Poll created successfully!");
      form.resetFields();
      handlePollCancel();
    } catch (error) {
      console.error("Error creating poll:", error);
      message.error("Failed to create poll. Please try again.");
    }
  };

  const fetchPolls = async () => {
    try {
      const response = await axios.get(`http://localhost:27017/getpolls`);
      setPolls(response.data);
      console.log("Response", response.data);
    } catch (error) {
      console.error("Error fetching polls:", error);
    }
  };

  useEffect(() => {
    // Fetch polls data when the component mounts
    fetchPolls();
  }, []);

  const handleMenuClick = (e) => {
    setSelectedMenuItem(e.key);
  };

  const tiles = [
    {
      icon: <TrophyOutlined />,
      title: "Give Kudos",
      color: "#FFC0CB",
      onClick: showModal,
    },
    {
      icon: <BarChartOutlined />,
      title: "Create Polls",
      color: "#b2f772",
      onClick: showPollModal,
    },
    { icon: <FileTextOutlined />, title: "Create Posts", color: "#a6edb9" },
  ];

  const calculateRemainingTime = (poll) => {
    const endDate = new Date(
      new Date(poll.createdAt).getTime() + poll.duration * 24 * 60 * 60 * 1000
    );
    const now = new Date();

    if (now > endDate) {
      return "Closed";
    } else {
      const timeDiff = endDate - now;
      const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hoursLeft = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );

      if (daysLeft > 0) {
        return `${daysLeft} day${daysLeft > 1 ? "s" : ""} left`;
      } else if (hoursLeft > 0) {
        return `${hoursLeft} hour${hoursLeft > 1 ? "s" : ""} left`;
      } else {
        return "Less than an hour left";
      }
    }
  };

  const handleViewVotes = (poll) => {
    setSelectedPoll(poll);
  };

  const handleCloseModal = () => {
    setSelectedPoll(null);
  };

  // Handle adding reaction
  const handleAddReaction = async (kudosId, emoji) => {
    try {
      const reactionData = {
        userName: user.displayName,
        employeeName: user.employeeName,
        emoji,
      };
      await axios.post(
        `http://localhost:27017/kudos/${kudosId}/reaction`,
        reactionData
      );
      message.success("Reaction added!");
      fetchKudos(); // Refetch kudos after adding a reaction
    } catch (error) {
      console.error("Error adding reaction:", error);
      message.error("Failed to add reaction");
    }
  };

  // Handle adding comment
  const handleAddComment = async (kudosId) => {
    if (!commentText) return;
    try {
      const commentData = {
        userName: user.displayName,
        employeeName: user.employeeName,
        text: commentText,
      };
      await axios.post(
        `http://localhost:27017/kudos/${kudosId}/comment`,
        commentData
      );
      setCommentText(""); // Clear input
      message.success("Comment added!");
      fetchKudos(); // Refetch kudos after adding a comment
    } catch (error) {
      console.error("Error adding comment:", error);
      message.error("Failed to add comment");
    }
  };

  // Handle adding reaction to a poll
  const handleAddPollReaction = async (pollId, emoji) => {
    try {
      const reactionData = {
        userName: user.displayName, // Adjust according to your user data structure
        employeeName: user.employeeName, // Adjust according to your user data structure
        emoji,
      };
      await axios.post(
        `http://localhost:27017/poll/${pollId}/reaction`,
        reactionData
      ); // Update URL for poll reaction
      message.success("Reaction added!");
      fetchPolls(); // Refetch polls after adding a reaction, if you have a function like this
    } catch (error) {
      console.error("Error adding reaction:", error);
      message.error("Failed to add reaction");
    }
  };

  // Handle adding comment to a poll
  const handleAddPollComment = async (pollId) => {
    if (!commentText) return;
    try {
      const commentData = {
        userName: user.displayName, // Adjust according to your user data structure
        employeeName: user.employeeName, // Adjust according to your user data structure
        text: commentText,
      };
      await axios.post(
        `http://localhost:27017/poll/${pollId}/comment`,
        commentData
      ); // Update URL for poll comment
      setCommentText(""); // Clear input field after adding a comment
      message.success("Comment added!");
      fetchPolls(); // Refetch polls after adding a comment, if you have a function like this
    } catch (error) {
      console.error("Error adding comment:", error);
      message.error("Failed to add comment");
    }
  };

  return (
    <>
      <div
        style={{
          width: "80%",
          padding: "20px 0",
          margin: "0 auto",
          background: "#e6e8e6",
        }}
      >
        <Row
          gutter={[16, 16]}
          justify="center"
          style={{ marginTop: "2%", padding: "0 16px" }}
        >
          {tiles.map((tile, index) => (
            <Col
              key={index}
              xs={24}
              sm={12}
              md={8}
              lg={6}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                hoverable
                onClick={tile.onClick}
                style={{
                  border: `2px solid ${tile.color}`,
                  borderRadius: "8px",
                  textAlign: "center",
                  backgroundColor: tile.color,
                  padding: "10px",
                  width: "150px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Added shadow for elevation
                  transform: "translateY(-25px)", // Moves the card 10px forward/up
                  transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition effect
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    backgroundColor: tile.color,
                    padding: "10px",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                >
                  {tile.icon}
                </div>
                <h4
                  style={{ marginTop: "12px", color: "#333", fontSize: "14px" }}
                >
                  {tile.title}
                </h4>
              </Card>
            </Col>
          ))}
        </Row>

        <Modal
          title="Give Kudos"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
            >
              Give
            </Button>,
          ]}
        >
          <div
            style={{
              maxHeight: "calc(90vh - 250px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={16}>
                <h5>You are posting in:</h5>
              </Col>
              <Col span={8}>
                <Select defaultValue="Everyone" style={{ width: "100%" }}>
                  <Option value="groups">All Groups</Option>
                  <Option value="appreciations">Appreciations</Option>
                  <Option value="buy">Buy/Sell/Rent</Option>
                  <Option value="company">Company News</Option>
                  <Option value="events">Events</Option>
                  <Option value="everyone">Everyone</Option>
                </Select>
              </Col>
            </Row>
            <Form layout="vertical" onFinish={handleSubmit} form={form}>
              <Row gutter={16}>
                <Col span={20}>
                  <Form.Item
                    label="Search Employee"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input the name of employee!",
                      },
                    ]}
                  >
                    <Input
                      prefix={
                        <Avatar>
                          <UserOutlined />
                        </Avatar>
                      }
                      placeholder="Search by Emp No. / Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={20}>
                  <Form.Item label="Recognize Values" name="value">
                    <Select
                      mode="multiple"
                      value={selectedValues}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      placeholder="Select"
                      dropdownStyle={{ maxHeight: "200px", overflowY: "auto" }} // Remove extra scroll
                    >
                      <Option value="approachable">
                        <Checkbox
                          checked={selectedValues.includes("approachable")}
                        >
                          Approachable
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You work well with others.
                        </p>
                      </Option>
                      <Option value="articulate">
                        <Checkbox
                          checked={selectedValues.includes("articulate")}
                        >
                          Articulate
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You can express yourself well in front of groups.
                        </p>
                      </Option>
                      <Option value="autonomous">
                        <Checkbox
                          checked={selectedValues.includes("autonomous")}
                        >
                          Autonomous
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You are a self-starter with lots of initiative and
                          agency.
                        </p>
                      </Option>
                      <Option value="collaborator">
                        <Checkbox
                          checked={selectedValues.includes("collaborator")}
                        >
                          Collaborator
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You are a teamwork champion and culture builder.
                        </p>
                      </Option>
                      <Option value="competitive">
                        <Checkbox
                          checked={selectedValues.includes("competitive")}
                        >
                          Competitive
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You thrive under pressure.
                        </p>
                      </Option>
                      <Option value="creative">
                        <Checkbox checked={selectedValues.includes("creative")}>
                          Creative
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You are the endless source of original ideas.
                        </p>
                      </Option>
                      <Option value="Devoted">
                        <Checkbox checked={selectedValues.includes("Devoted")}>
                          Devoted
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          {" "}
                          You are committed to the company's success.
                        </p>
                      </Option>
                      <Option value="Efficient">
                        <Checkbox
                          checked={selectedValues.includes("Efficient")}
                        >
                          Efficient
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          {" "}
                          You have a very quick turnaround time.
                        </p>
                      </Option>
                      <Option value="Enthuslatic">
                        <Checkbox
                          checked={selectedValues.includes("Enthuslatic")}
                        >
                          Enthuslatic
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You put all in every project
                        </p>
                      </Option>
                      <Option value="Independent">
                        <Checkbox
                          checked={selectedValues.includes("Independent")}
                        >
                          Independent
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You need a little direction
                        </p>
                      </Option>
                      <Option value="Innovator">
                        <Checkbox
                          checked={selectedValues.includes("Innovator")}
                        >
                          Innovator
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You are the visionary boundary-pusher
                        </p>
                      </Option>
                      <Option value="Leader">
                        <Checkbox checked={selectedValues.includes("Leader")}>
                          Leader
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You set an example for an exemplary role model and
                          empowerer.
                        </p>
                      </Option>
                      <Option value="Learner">
                        <Checkbox checked={selectedValues.includes("Learner")}>
                          Learner
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You can learn new things and put that learning to good
                          use.
                        </p>
                      </Option>
                      <Option value="Motivator">
                        <Checkbox
                          checked={selectedValues.includes("Motivator")}
                        >
                          Motivator
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You are the true inspiration and change driver.
                        </p>
                      </Option>
                      <Option value="Open-minded">
                        <Checkbox
                          checked={selectedValues.includes("Open-minded")}
                        >
                          Open-minded
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You take constructive criticism well.
                        </p>
                      </Option>
                      <Option value="Opinionated">
                        <Checkbox
                          checked={selectedValues.includes("Opinionated")}
                        >
                          Opinionated
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You are comfortable voicing opinions.
                        </p>
                      </Option>
                      <Option value="Planning">
                        <Checkbox checked={selectedValues.includes("Planning")}>
                          Planning
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You can come up with a good plan for a project or
                          initiative.
                        </p>
                      </Option>
                      <Option value="Problem Solver">
                        <Checkbox
                          checked={selectedValues.includes("Problem Solver")}
                        >
                          Problem Solver
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You can solve problems in the most elegant and
                          effective manner.
                        </p>
                      </Option>
                      <Option value="Resourceful">
                        <Checkbox
                          checked={selectedValues.includes("Resourceful")}
                        >
                          Resourceful
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You use every tool at hand.
                        </p>
                      </Option>
                      <Option value="Strategist">
                        <Checkbox
                          checked={selectedValues.includes("Strategist")}
                        >
                          Strategist
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You have the planning mastery with clear vision.
                        </p>
                      </Option>
                      <Option value="Team Player">
                        <Checkbox
                          checked={selectedValues.includes("Team Player")}
                        >
                          Team Player
                        </Checkbox>
                        <p style={{ marginLeft: "24px", marginBottom: 0 }}>
                          You foster unity and team bining.
                        </p>
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={20}>
                  <Form.Item
                    label="Your message"
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "Please type your message here!",
                      },
                    ]}
                  >
                    <TextArea rows={4} placeholder="Type message here..." />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={20}>
                  <Form.Item label="Add Reaction" name="reactions">
                    <Row>
                      {emojis.map((emoji, index) => (
                        <Col key={index} span={6} xs={24} sm={12} md={8} lg={6}>
                          <Button
                            key={index}
                            onClick={() =>
                              form.setFieldsValue({ reactions: emoji })
                            }
                          >
                            {emoji}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
        <Modal
          title="Create a Poll"
          visible={isPollModalVisible}
          onCancel={handlePollCancel}
          footer={[
            <Button key="cancel" onClick={handlePollCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={() => form.submit()}>
              Create
            </Button>,
          ]}
        >
          <div
            style={{
              maxHeight: "calc(90vh - 250px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={16}>
                <h5>You are posting in:</h5>
              </Col>
              <Col span={8}>
                <Select defaultValue="Everyone" style={{ width: "100%" }}>
                  <Option value="groups">All Groups</Option>
                  <Option value="appreciations">Appreciations</Option>
                  <Option value="buy">Buy/Sell/Rent</Option>
                  <Option value="company">Company News</Option>
                  <Option value="events">Events</Option>
                  <Option value="everyone">Everyone</Option>
                </Select>
              </Col>
            </Row>
            <Form layout="vertical" onFinish={handlePollSubmit} form={form}>
              <Row gutter={16}>
                <Col span={20}>
                  <Form.Item
                    label="Question"
                    name="question"
                    rules={[
                      {
                        required: true,
                        message: "Please input your question!",
                      },
                    ]}
                  >
                    <Input placeholder="Ask Question" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={20}>
                  <Form.Item label="Options (Max. 7 allowed)" required>
                    {options.map((option, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px",
                        }}
                      >
                        <Input
                          placeholder={`Option ${index + 1}`}
                          value={option}
                          onChange={(e) => handleOptionChange(index, e)}
                          style={{ marginRight: "8px" }}
                        />
                        <DeleteOutlined
                          onClick={() => deletePollOption(index)}
                          style={{ color: "red", cursor: "pointer" }}
                        />
                      </div>
                    ))}
                    {options.length < 7 && (
                      <Button type="link" onClick={addPollOption}>
                        Add Poll Option
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={20}>
                  <Form.Item name="anonymous" valuePropName="checked">
                    <Checkbox>Make Voters identity anonymous.</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={20}>
                  <Form.Item
                    label="Poll Duration (Day(s) open)"
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message: "Please input the poll duration!",
                      },
                    ]}
                  >
                    <Input placeholder="Poll Duration" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>

        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            width={250}
            style={{
              background: "#fff",
              marginTop: "50px",
              background: "#e6e8e6",
            }}
          >
            <div style={{ padding: "16px" }}>
              {/* Filter Title */}
              <Title level={4} style={{ margin: 0 }}>
                Filters
              </Title>

              <Divider
                style={{
                  margin: "12px 0",
                  height: "1px",
                  backgroundColor: "#808782",
                }}
              />

              {/* Activities Title */}
              <Title level={5} style={{ margin: "0 0 12px 0" }}>
                Activities
              </Title>

              {/* Menu */}
              <Menu
                mode="inline"
                selectedKeys={[selectedMenuItem]}
                onClick={handleMenuClick}
                style={{ height: "100%", borderRight: 0 }}
              >
                <Menu.Item
                  key="all"
                  icon={<AppstoreOutlined />}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Radio
                    checked={selectedMenuItem === "all"}
                    style={{ marginRight: 8 }}
                  />
                  All Activities
                </Menu.Item>
                <Menu.Item
                  key="kudos"
                  icon={<GiftOutlined />}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Radio
                    checked={selectedMenuItem === "kudos"}
                    style={{ marginRight: 8 }}
                  />
                  Kudos
                </Menu.Item>
                <Menu.Item
                  key="polls"
                  icon={<BarChartOutlined />}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Radio
                    checked={selectedMenuItem === "polls"}
                    style={{ marginRight: 8 }}
                  />
                  Polls
                </Menu.Item>
              </Menu>
            </div>
          </Sider>

          {selectedMenuItem === "polls" && (
            <div>
              <Row gutter={[8, 8]} justify="center">
                {/* {polls && polls.length > 0 ? (
                  polls.map((poll, index) => ( */}
                {Array.isArray(polls) ? (
                  polls.map((poll, index) => (
                    <Col key={index} span={20} xs={24} sm={20} md={16} lg={14}>
                      <Card
                        bordered={true}
                        style={{
                          width: "100%",
                          marginTop: "50px",
                          background: "#e6e8e6",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              textAlign: "left",
                              padding: 0,
                              margin: 0,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              size={80}
                              src={poll.profileImage}
                              style={{ marginRight: "10px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div style={{ fontWeight: "bold" }}>
                                {poll.userName}
                              </div>
                              <div
                                style={{
                                  marginTop: "4px",
                                  fontSize: "14px",
                                  color: "#888",
                                }}
                              >
                                {poll.empCode}
                              </div>
                            </div>
                          </p>
                          <div
                            style={{ textAlign: "right", marginBottom: "20px" }}
                          >
                            <p>
                              {new Date(poll.createdAt).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "3%",
                            justifyContent: "center",
                          }}
                        >
                          <Card
                            key={index}
                            title={
                              <span style={{ whiteSpace: "normal" }}>
                                {poll.question}
                              </span>
                            }
                            bordered={true}
                            style={{
                              width: "350px",
                              marginTop: "3%",
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            <p style={{ fontSize: "12px", color: "#888" }}>
                              <UserOutlined /> Anonymous vote
                            </p>
                            {poll.options.map((option, idx) => (
                              <div
                                key={idx}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "10px",
                                  cursor: "pointer",
                                  textAlign: "center",
                                  fontSize: "16px",

                                  background:
                                    selectedOption === option._id
                                      ? "#f0f0f0" // Light green when selected
                                      : hoveredOption === option._id
                                      ? "#87CEEB" // Light blue on hover
                                      : "#f0f0f0", // Default background
                                  borderRadius: "5px",
                                  transition: "background-color 0.3s ease", // Smooth transition for hover effect
                                }}
                                onClick={() => {
                                  setSelectedOption(option._id); // Set selected option
                                  handleVote(poll._id, option._id); // Call vote handler
                                }}
                                onMouseEnter={() =>
                                  setHoveredOption(option._id)
                                } // Set hovered option on mouse enter
                                onMouseLeave={() => setHoveredOption(null)} // Remove hover on mouse leave
                              >
                                {option.text}

                                {/* <span style={{ marginLeft: '10px' }}>{option.votes} votes</span> */}
                              </div>
                            ))}
                          </Card>
                        </div>
                        <Row>
                          <Col span={16}>
                            <div
                              style={{
                                textAlign: "left",
                                marginTop: "10px",
                                color: "#555",
                                fontWeight: "bold",
                              }}
                            >
                              Total Votes:{" "}
                              {poll.options.reduce(
                                (total, option) => total + option.votes,
                                0
                              )}
                            </div>

                            <div
                              style={{
                                textAlign: "left",
                                marginTop: "10px",
                                color: "#555",
                                fontWeight: "bold",
                              }}
                            >
                              Duration: {calculateRemainingTime(poll)}
                            </div>
                          </Col>

                          <Col span={8} style={{ textAlign: "right" }}>
                            <div style={{ marginTop: "30px" }}>
                              <span
                                onClick={() => handleViewVotes(poll)}
                                style={{
                                  color: "blue", // Link color
                                  cursor: "pointer", // Pointer cursor to indicate clickability
                                }}
                              >
                                View Votes
                              </span>
                            </div>
                          </Col>
                        </Row>

                        {/* <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto', border: '1px solid #ddd', borderRadius: '8px' }}> */}
                        <Divider
                          style={{
                            margin: "12px 0",
                            height: "1px",
                            backgroundColor: "#bec2bf",
                          }}
                        />

                        {/* Reactions Section */}
                        <div style={{ marginTop: "20px" }}>
                          <div style={{ fontWeight: "bold" }}>Reactions:</div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              flexWrap: "wrap",
                            }}
                          >
                            {poll.reactions && poll.reactions.length > 0 ? (
                              poll.reactions.map((reaction, reactionIndex) => (
                                <div
                                  key={reactionIndex}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <span>{reaction.emoji}</span> {/* Emoji */}
                                  <span
                                    style={{ fontSize: "12px", color: "#888" }}
                                  >
                                    {reaction.employeeName}
                                  </span>{" "}
                                  {/* Employee name */}
                                </div>
                              ))
                            ) : (
                              <p>No Reactions</p>
                            )}
                          </div>
                          {/* React to this post */}
                          <div style={{ marginTop: "10px" }}>
                            {emojis.map((emoji, emojiIndex) => (
                              <span
                                key={emojiIndex}
                                style={{ cursor: "pointer", margin: "0 5px" }}
                                onClick={() =>
                                  handleAddPollReaction(poll._id, emoji)
                                }
                              >
                                {emoji}
                              </span>
                            ))}
                          </div>
                        </div>

                        {/* Comments Section */}
                        <div style={{ marginTop: "20px" }}>
                          <div style={{ fontWeight: "bold" }}>Comments:</div>
                          <div>
                            {poll.comments && poll.comments.length > 0 ? (
                              poll.comments.map((comment, commentIndex) => (
                                <div
                                  key={commentIndex}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <div style={{ fontWeight: "bold" }}>
                                    {comment.userName}
                                  </div>
                                  <div>{comment.text}</div>
                                  <div
                                    style={{ fontSize: "12px", color: "#888" }}
                                  >
                                    {new Date(
                                      comment.createdAt
                                    ).toLocaleString()}
                                  </div>
                                </div>

                                // <div key={commentIndex} style={{ marginBottom: '10px' }}>
                                //   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left'  }}>
                                //     <span style={{ fontWeight: 'bold', marginRight: '10px' }}>{comment.userName}:</span>
                                //     {comment.text}
                                //   </div>
                                //   <div style={{ fontSize: '12px', color: '#888', textAlign: 'left' }}>
                                //     {new Date(comment.createdAt).toLocaleString()}
                                //   </div>
                                // </div>
                              ))
                            ) : (
                              <p>No Comments</p>
                            )}
                          </div>
                          {/* Add a comment */}
                          <div style={{ marginTop: "10px" }}>
                            <input
                              type="text"
                              placeholder="Add a comment"
                              value={commentText}
                              onChange={(e) => setCommentText(e.target.value)}
                            />
                            <Button
                              type="primary"
                              onClick={() => handleAddPollComment(poll._id)}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                        {/* </div> */}
                      </Card>
                    </Col>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                      width: "100vw",
                      overflow: "hidden",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        margin: 0,
                        fontSize: "24px",
                      }}
                    >
                      No Polls available.
                    </p>
                  </div>
                )}
              </Row>
            </div>
          )}

          {selectedPoll && (
            <Modal
              title={`Votes for ${selectedPoll.question}`}
              visible={!!selectedPoll}
              onCancel={handleCloseModal}
              footer={null}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {selectedPoll.options.map((option, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      marginBottom: "8px",
                      borderRadius: "8px",
                      backgroundColor: "#f0f0f0",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                  >
                    <div style={{ flex: 1, textAlign: "center" }}>
                      {option.text}
                    </div>
                    <span style={{ marginLeft: "10px" }}>
                      {option.votes} votes
                    </span>
                  </div>
                ))}
              </div>
            </Modal>
          )}

          {selectedMenuItem === "kudos" && (
            <div>
              {/* <Card title="Kudos" style={{ marginTop: "2%", backgroundColor: "#e4f5f7" }}> */}
              <Row gutter={[8, 8]} justify="center">
                {/* {kudos && kudos.length > 0 ? (
                  kudos.map((kudo, index) => ( */}
                {Array.isArray(kudos) ? (
                  kudos.map((kudo, index) => (
                    <Col key={index} span={16} xs={24} sm={20} md={16} lg={14}>
                      <Card
                        bordered={true}
                        style={{
                          width: "100%",
                          marginTop: "50px",
                          background: "#e6e8e6",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              textAlign: "left",
                              padding: 0,
                              margin: 0,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              size={80}
                              src={kudo.profileImage}
                              style={{ marginRight: "10px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div style={{ fontWeight: "bold" }}>
                                {kudo.userName}
                              </div>

                              {/* <div style={{ fontWeight: 'bold' }}>Will Smith</div> */}
                              <div
                                style={{
                                  marginTop: "4px",
                                  fontSize: "14px",
                                  color: "#888",
                                }}
                              >
                                {kudo.empCode}
                              </div>
                              {/* <div style={{ marginTop: '4px', fontSize: '14px', color: '#888' }}>#E1234</div> */}
                            </div>
                          </p>
                          <div
                            style={{ textAlign: "right", marginBottom: "20px" }}
                          >
                            <p>
                              {new Date(kudo.createdAt).toLocaleDateString()}
                            </p>
                          </div>
                        </div>

                        <p
                          style={{
                            textAlign: "center",
                            padding: 0,
                            margin: 0,
                            marginTop: "20px",
                          }}
                        >
                          <Avatar
                            size={80}
                            src={profilePic1}
                            style={{ marginRight: "10px" }}
                          />
                          {/* <Avatar
                            size={100}
                            src={kudo.profilePic || undefined}
                            style={{ backgroundColor: '#87d068' }}
                          >
                            {!kudo.profilePic && `${getInitials(kudo.firstName, kudo.lastName)}`} */}
                          {/* </Avatar>  */}
                          <p style={{ margin: 0, fontSize: "16px" }}>
                            {kudo.employeeName || "Unknown Employee"}
                          </p>
                        </p>

                        <div style={{ marginTop: "50px" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              flexWrap: "wrap",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            {kudo.recognizeValue &&
                            kudo.recognizeValue.length > 0 ? (
                              kudo.recognizeValue.map((value, index) => (
                                <div
                                  key={index}
                                  style={{
                                    padding: "10px 20px", // Increase padding for a more pill-like shape
                                    backgroundColor: getRandomColor(),
                                    borderRadius: "50px", // Round shape
                                    color: "#fff",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    display: "inline-block",
                                    border: `2px solid ${getRandomLightColor()}`, // Colored outline
                                    boxShadow: `0 2px 4px rgba(0,0,0,0.1)`, // Optional shadow for better visibility
                                    minWidth: "60px", // Set a minimum width for uniformity
                                  }}
                                >
                                  {value}
                                </div>
                              ))
                            ) : (
                              <p>No Recognize Values</p>
                            )}
                          </div>
                        </div>
                        {/* <div style={{ padding: '10px 0' }}> */}
                        <div
                          style={{
                            fontSize: "16px",
                            marginTop: "50px",
                            textAlign: "center",
                          }}
                        >
                          {kudo.message}
                        </div>

                        {/* <div style={{ marginTop: "20px", fontWeight: 'bold' }}>
                          Reactions: {kudo.reactions ? kudo.reactions : 'No Reactions'}
                        </div> */}

                        <Divider
                          style={{
                            margin: "12px 0",
                            height: "1px",
                            backgroundColor: "#bec2bf",
                          }}
                        />

                        {/* Reactions Section */}
                        <div style={{ marginTop: "20px" }}>
                          <div style={{ fontWeight: "bold" }}>Reactions:</div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              flexWrap: "wrap",
                            }}
                          >
                            {kudo.reactions && kudo.reactions.length > 0 ? (
                              kudo.reactions.map((reaction, reactionIndex) => (
                                <div
                                  key={reactionIndex}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <span>{reaction.emoji}</span>
                                  <span
                                    style={{ fontSize: "12px", color: "#888" }}
                                  >
                                    {reaction.employeeName}
                                  </span>
                                </div>
                              ))
                            ) : (
                              <p>No Reactions</p>
                            )}
                          </div>
                          {/* React to this post */}
                          <div style={{ marginTop: "10px" }}>
                            {emojis.map((emoji, emojiIndex) => (
                              <span
                                key={emojiIndex}
                                style={{ cursor: "pointer", margin: "0 5px" }}
                                onClick={() =>
                                  handleAddReaction(kudo._id, emoji)
                                }
                              >
                                {emoji}
                              </span>
                            ))}
                          </div>
                        </div>

                        {/* Comments Section */}
                        <div style={{ marginTop: "20px" }}>
                          <div style={{ fontWeight: "bold" }}>Comments:</div>
                          <div>
                            {kudo.comments && kudo.comments.length > 0 ? (
                              kudo.comments.map((comment, commentIndex) => (
                                <div
                                  key={commentIndex}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <div style={{ fontWeight: "bold" }}>
                                    {comment.userName}
                                  </div>
                                  <div>{comment.text}</div>
                                  <div
                                    style={{ fontSize: "12px", color: "#888" }}
                                  >
                                    {new Date(
                                      comment.createdAt
                                    ).toLocaleString()}
                                  </div>
                                </div>

                                // <div key={commentIndex} style={{ marginBottom: '10px' }}>
                                //   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left'  }}>
                                //     <span style={{ fontWeight: 'bold', marginRight: '10px' }}>{comment.userName}:</span>
                                //     {comment.text}
                                //   </div>
                                //   <div style={{ fontSize: '12px', color: '#888', textAlign: 'left' }}>
                                //     {new Date(comment.createdAt).toLocaleString()}
                                //   </div>
                                // </div>
                              ))
                            ) : (
                              <p>No Comments</p>
                            )}
                          </div>
                          {/* Add a comment */}
                          <div style={{ marginTop: "10px" }}>
                            <input
                              type="text"
                              placeholder="Add a comment"
                              value={commentText}
                              onChange={(e) => setCommentText(e.target.value)}
                            />
                            <Button
                              type="primary"
                              onClick={() => handleAddComment(kudo._id)}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p>No Kudos available.</p>
                )}
              </Row>
              {/* </Card> */}
            </div>
          )}
        </Layout>
      </div>
    </>
  );
};

export default TileLayout;
