import {
  BuildOutlined,
  DownOutlined,
  PlusOutlined,
  UserOutlined,
  PlusSquareFilled,
  FolderOpenOutlined,
  StarFilled,
  PaperClipOutlined,
  UsergroupAddOutlined,
  RightOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Button,
  Avatar,
  Modal,
  Card,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  message,
  Datfeatureker,
  Select,
  Tabs,
  Checkbox,
  Tooltip,
  notification,
  Popconfirm,
} from "antd";
import React, { useState, useEffect } from "react";
import { TabPane } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../../url/url";
import axios from "axios";
import AddActivity from "./AddActivity";
import Activityboard from "./Activityboard";
import BarChart from "./charts";
import Issueboard from "./Issueboard";
import Barchart from "./charts";
import SprintGanttChart from "./reports";
const { Option } = Select;
const Board = (props) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [selectedState, setSelectedState] = useState("todo");
  const [selectedState1, setSelectedState1] = useState("To Do");
  const [selectedState2, setSelectedState2] = useState("To Do");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [moveSprint, setMoveSprint] = useState("");
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSprint, setSelectedSprint] = useState(null);
  const [selectedSprintId, setSelectedSprintId] = useState({
    sprintId: "",
    sprintName: "",
  });
  const [sprint, setSprint] = useState(null);
  const [existingSprints, setExistingSprints] = useState([]);
  const [showNewItemButton, setShowNewItemButton] = useState(false);
  const [createSprintModalVisible, setCreateSprintModalVisible] = useState(
    false
  );
  const [modalSubmitted, setModalSubmitted] = useState(false);
  const [feature, setfeature] = useState([]);
  const [editingfeature, setEditingfeature] = useState(null);
  const [count, setCount] = useState(0);
  const [featureactivity, setfeatureactivity] = useState(null);
  const [selectedfeature, setSelectedfeature] = useState(null);
  const location = useLocation();
  const [startDate, setStartDate] = useState(null);
  const [currentSprintOrder, setCurrentSprintOrder] = useState(0);
  const [nextSprints, setNextSprints] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (moveSprint.sprintId) {
      const currentProject = moveSprint.sprintId.split("-")[0];
      const currentOrder = parseInt(moveSprint.sprintId.split("-")[1].slice(1));
      setCurrentSprintOrder(currentOrder);

      const filteredSprints = existingSprints
        .filter((sprint) => {
          const sprintOrder = parseInt(sprint.sprintId.split("-")[1].slice(1));
          const sprintProject = sprint.sprintId.split("-")[0];
          return sprintProject === currentProject && sprintOrder > currentOrder;
        })
        .filter((sprint) => sprint.sprintId !== moveSprint.sprintId);

      setNextSprints(filteredSprints);
    } else {
      setNextSprints([]);
    }
  }, [moveSprint, existingSprints]);

  useEffect(() => {
    if (location.state) {
      console.log(location.state, "state");
      if (location.state.selectedProject) {
        setSelectedProject(location.state.selectedProject);
        console.log(location.state, "sprint");
      }
      if (location.state.selectedSprint) {
        setSelectedSprint(location.state.selectedSprint);
        setSprint(location.state.selectedSprint);
        fetchFeature(location.state.selectedSprint.sprintId);
      }
    } else {
      if (sprint) {
        fetchFeature(sprint.sprintId);
      }
    }
  }, [location.state, count]);

  const Addlink = () => {
    setOpen(true);
  };

  const handlefeatureClick = (featureItem) => {
    setModalVisible(true);
    if (featureItem && featureItem.featureName) {
      setEditingfeature(featureItem);
      console.log(featureItem, "feature");
      form1.setFieldsValue({
        featureName: featureItem.featureName,
        assignee: featureItem.assignee,
        description: featureItem.description,
        priority: featureItem.priority,
        featurestartDate: featureItem.featurestartDate
          ? moment(featureItem.featurestartDate, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            )
          : undefined,
        featureendDate: featureItem.featureendDate
          ? moment(featureItem.featureendDate, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            )
          : undefined,
        // ... set other values as needed
      });
    } else {
      setEditingfeature(null);
      form1.setFieldsValue({
        featureName: "",
        assignee: [],
        description: "",
        priority: "",
        featurestartDate: "",
        featureendDate: "",
      });
    }
  };

  const Addlink1 = () => {
    setOpen1(true);
  };

  const handleactivityClick = (featureItem) => {
    setModalVisible2(true);
    setfeatureactivity(featureItem);
    console.log(featureItem, "featureItem");
  };
  const handleMoveSprint = (featureItem) => {
    setOpen1(true);
    setMoveSprint(featureItem);
  };
  const handleIssueClick = () => {
    setModalVisible1(true);
  };
  const handlefeature = async () => {
    console.log("formdata");
    try {
      const formData = await form1.validateFields();
      console.log("formdata", formData);
      const dbName = localStorage.getItem("dbName");
      const response = await axios.post(`${url}/feature/form-data/${dbName}`, {
        ...formData,
        sprintId: sprint.sprintId,
        sprintName: sprint.sprintName,
        project: selectedProject.ProjectName,
        editingfeature: editingfeature,
      });
      form1.resetFields();
      setModalVisible(false);
      setEditingfeature(null);
      setModalSubmitted(true);
      setCount(count + 1);
      notification.success({ message: "Feature Created Successfully" });
    } catch (error) {
      console.error("Error posting form data:", error);
      // if (error.errorFields) {
      //   error.errorFields.forEach(({ name, errors }) => {
      //     form1.setFields([
      //       {
      //         name,
      //         errors,
      //       },
      //     ]);
      //   });
      // }
    }
  };

  const handleDeletefeature = async (featureItem) => {
    const dbName = localStorage.getItem("dbName");
    axios
      .delete(`${url}/api/features/${featureItem.featureId}/${dbName}`)
      .then((response) => {
        setCount(count + 1);
      })
      .catch((error) => {
        console.error("Error deleting feature:", error);
      });
  };

  const handleCancel = () => {
    setEditingfeature(null);
    setModalVisible(false);
    form1.resetFields();
  };
  useEffect(() => {
    fetchData();
  }, []);
  // useEffect(() => {
  //   if (!location.state && sprint ) {
  //     fetchFeature(sprint.sprintId);
  //     console.log("useffect",selectedSprint);
  //   }
  // }, [count]);
  useEffect(() => {
    if(selectedProject){
    console.log(selectedProject, "projectselect");
    fetchSprintsByProjectName(selectedProject);
    }
  }, [selectedProject]);
  const handleSelect = (selectedValues) => {
    setSelectedUsers(selectedValues);
  };

  const menu = (
    <Menu style={{ width: "170px", height: "180px" }}>
      {/* Add menu items for the dropdown */}
      <img
        src={require("../../images/star-button.png")}
        alt=""
        style={{ width: "5vh" }}
      />
      <Menu.Item
        onClick={handlefeatureClick}
        style={{ bottom: "5vh", left: "5vh", fontSize: "15px" }}
        key="1"
      >
        feature
      </Menu.Item>
      <img
        src={require("../../images/warning.png")}
        alt=""
        style={{ width: "5vh" }}
      />
      <Menu.Item
        onClick={handleIssueClick}
        style={{ bottom: "5vh", left: "5vh", fontSize: "15px" }}
        key="2"
      >
        Issue
      </Menu.Item>
      <img
        src={require("../../images/tasks.png")}
        alt=""
        style={{ width: "5vh" }}
      />
      <Menu.Item
        onClick={handleactivityClick}
        style={{ bottom: "5vh", left: "5vh", fontSize: "15px" }}
        key="3"
      >
        activity
      </Menu.Item>
    </Menu>
  );

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputChange1 = (e) => {
    setInputValue1(e.target.value);
  };
  const handleInputChange2 = (e) => {
    setInputValue2(e.target.value);
  };
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      setModalVisible(false);
    }
  };

  const handleEnterPress1 = (e) => {
    if (e.key === "Enter") {
      setModalVisible1(false);
    }
  };
  const handleEnterPress2 = (e) => {
    if (e.key === "Enter") {
      setModalVisible2(false);
    }
  };
  const handleSelectChange = async (value, featureItem) => {
    if (featureItem && featureItem.featureName) {
      const previousState = featureItem.status;
      try {
        setIsUpdating(true);
        setSelectedState(value);

        const dbName = localStorage.getItem("dbName");
        await axios.post(
          `${url}/feature/update-status/${featureItem.featureId}/${dbName}`,
          {
            status: value,
          }
        );

        console.log("API call successful");
        setCount(count + 1);
      } catch (error) {
        console.error("Error updating status:", error);
        message.error("Some of the Activities are not done");

        setSelectedState((prevSelectedState) => previousState);
        setIsUpdating(true);

        setCount(count + 1);
        console.log(previousState, "Reverted to previous state");
      } finally {
        setIsUpdating(false);
      }
    } else {
      setSelectedState(value);
    }
  };

  const handleselect = (value) => {
    const sprint = nextSprints.find((sprint) => sprint.sprintId === value);
    setSelectedSprintId(sprint);
  };

  const handleCancel1 = () => {
    setOpen1(false);
    setSelectedSprintId("");
  };

  const handleOk = () => {
    // Retrieve the database name and feature ID from localStorage
    const dbName = localStorage.getItem("dbName");
    const featureId = moveSprint.featureId;

    const sprintValue = selectedSprintId.sprintId.split("-").pop();
    console.log(moveSprint.featureId, "featureid");

    const currentFeatureId = moveSprint.featureId.split("-S")[0];
    const newFeatureId = `${currentFeatureId}-${sprintValue}`;

    const newFeatureData = {
      ...moveSprint,
      sprintId: selectedSprintId.sprintId,
      sprintName: selectedSprintId.sprintName,
      featureId: newFeatureId,
    };

    console.log(newFeatureData.featureId);

    // Send a POST request to create the new feature and move activities
    axios
      .post(`${url}/feature/move/${dbName}/${featureId}`, {
        newFeatureData,
      })
      .then((response) => {
        setCount(count + 1);
        notification.success({
          message: "Sprint ID updated and activities moved successfully",
        });
        setOpen1(false);
        setSelectedSprintId("");
      })
      .catch((error) => {
        // Handle error
        console.error("Error moving activities:", error);
      });
  };

  const handleSelectChange1 = (value) => {
    setSelectedState1(value);
  };
  const handleSelectChange2 = (value) => {
    setSelectedState2(value);
  };
  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const displayName = `${user.firstName} ${user.lastName}`;
      const response = await axios.get(
        `${url}/getproject/${user.displayName}/${dbName}`
      );
      setTableData(response.data);
      setData(response.data);
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
    }
  };
  const handlefeatureSelect = (value) => {
    setSelectedfeature(value);
  };
  const fetchSprintsByProjectName = async (selectedproject) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(
        `${url}/sprints/${selectedproject.ProjectName}/sprint/${dbName}`
      );
      const sprints = await response.json();
      const sprintDetails = sprints.map((sprint) => ({
        sprintId: sprint.sprintId,
        sprintName: sprint.sprintName,
        startDate: sprint.startDate,
        endDate: sprint.endDate,
      }));
      setExistingSprints(sprintDetails);
      return sprintDetails;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("An error occurred while fetching sprint details.");
    }
  };

  const fetchFeature = async (selectedsprint) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(
        `${url}/feature/${selectedsprint}/sprintid/${dbName}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Feature data");
      }
      const data = await response.json();
      setfeature(data);
    } catch (error) {
      console.error("Error:", error);
      throw new Error("An error occurred while fetching sprint details.");
    }
  };

  const handleSelectProject = async (projectId) => {
    const selectedProject = data.find((project) => project._id === projectId);
    setSelectedProject(selectedProject);
    console.log(selectedProject, "project");
    localStorage.setItem("Project", selectedProject);
    setSelectedSprint(null);
    setfeature(null);
    navigate({
      pathname: window.location.pathname,
      search: window.location.search,
      hash: window.location.hash,
      state: null,
    });

    await fetchSprintsByProjectName(selectedProject);
  };

  const handleCreateSprint = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      // Check if the sprint name already exists
      const sprintNameExists = existingSprints.some(
        (sprint) =>
          sprint.sprintName.toLowerCase() ===
          form.getFieldValue("sprintName").toLowerCase()
      );

      if (sprintNameExists) {
        message.error("Sprint name already exists");
        return;
      }
      await form.validateFields();
      const response = await axios.post(
        `${url}/create-sprint/${dbName}`,
        form.getFieldsValue()
      );
      setCreateSprintModalVisible(false);
      notification.success({ message: "Sprint Created Successfully" });
      form.resetFields();
      setExistingSprints([
        ...existingSprints,
        {
          sprintId: response.data.sprintId,
          sprintName: response.data.sprintName,
          startDate: response.data.startDate,
          endDate: response.data.endDate,
        },
      ]);
      //setCount(count + 1);
    } catch (error) {
      console.error("Error creating sprint:", error);
      message.error("Failed to create sprint. Please try again later.");
    }
  };
  const handleactivity = async (featureId, featureName, assignee) => {
    console.log("feature id and name", featureId, featureName, assignee);
    const featureItem = feature.find(
      (item) => item.featureName === featureName
    );
    // const selectedProject = data.find(
    //   (project) => project._id === featureItem.projectId
    // );

    navigate(`/home/activityboard?featureId=${featureId}`, {
      state: {
        selectedProject,
        selectedSprint,
        featureName,
        featureId,
        assignee,
      },
    });
  };

  const menu3 = (
    <Menu>
      {data.map((project) => (
        <Menu.Item
          key={project._id}
          icon={<BuildOutlined />}
          onClick={() => handleSelectProject(project._id)}
        >
          {project.ProjectName}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleFormSubmit = async (values) => {
    try {
      const dbName = localStorage.getItem("dbName");
      values.featureName = featureactivity.featureName;
      values.featureId = featureactivity.featureId;
      values.reporter = user.displayName;
      values.status = "todo";
      const response = await fetch(
        `${url}/post/activity/${dbName}/${selectedProject.Projectcode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }
      notification.success({ message: "Activity submitted successfully" });
      // Close the modal after successful form submission
      setModalVisible2(false);
      form.resetFields();
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show error message to the user, etc.
    }
  };
  const handleMenuItemClick = () => {
    const queryParams = new URLSearchParams();
    queryParams.append("projectName", selectedProject.ProjectName);
    navigate(`/home/sprintdirectory?${queryParams.toString()}`);
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();

    // Add leading zero if month or day is less than 10
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  };
  const menu5 = (
    <Menu>
      {data.map((project) => (
        <Menu.Item key={project._id} icon={<BuildOutlined />}>
          {project.ProjectName}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleCreateNewSprint = () => {
    setCreateSprintModalVisible(true);
  };

  const menu4 = (
    <Menu>
      {existingSprints?.map((sprint) => (
        <Menu.Item
          key={sprint.sprintId}
          onClick={() => handleSprintSelection(sprint)}
        >
          {sprint.sprintName} ({sprint.startDate} to {sprint.endDate})
        </Menu.Item>
      ))}
      {user.jobTitle !== "employee" && (
        <>
          <Menu.Item key="createSprint" onClick={handleCreateNewSprint}>
            Create New Sprint
          </Menu.Item>
          <Menu.Item key="Sprint Directory" onClick={handleMenuItemClick}>
            Sprint Directory
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const EmployeeInitials = ({ employee, index, colors }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const toggleTooltip = () => {
      setShowTooltip(!showTooltip);
    };

    return (
      <div
        key={index}
        style={{
          position: "relative",
          display: "inline-block",
          margin: "0 5px",
        }}
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
      >
        <div
          style={{
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            background: colors[index % colors.length],
            textAlign: "center",
            lineHeight: "30px",
            cursor: "pointer",
          }}
        >
          {employee.initials}
        </div>
        {showTooltip && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: "50%",
              transform: "translateX(-50%)",
              padding: "5px",
              background: "#fff",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          >
            {employee.fullName}
          </div>
        )}
      </div>
    );
  };

  const handleSprintSelection = async (sprint) => {
    setSelectedSprint(sprint);
    console.log(sprint, "sprint");
    setSprint(sprint);
    await fetchFeature(sprint.sprintId);
  };

  const renderEmployeeInitials = () => {
    if (selectedProject) {
      const initials = selectedProject.TotalEmp.map((employee) => {
        const [firstName, lastName] = employee?.split(" ") || [];
        return {
          initials: `${firstName ? firstName[0].toUpperCase() : ""}${
            lastName ? lastName[0].toUpperCase() : ""
          }`,
          fullName: employee,
        };
      });

      const colors = [
        "#ff726f",
        "#ffa649",
        "#ffd54f",
        "#64b5f6",
        "#81c784",
        "#ba68c8",
      ];

      return (
        <div>
          {initials.map((employee, index) => (
            <EmployeeInitials
              key={index}
              employee={employee}
              index={index}
              colors={colors}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  const handleactivityBoard = (projectId) => {
    const selectedProject = data.find((project) => project._id === projectId);
    if (sprint) {
      navigate("/home/activityboard", {
        state: { selectedProject, selectedSprint },
      });
    } else {
      navigate("/home/activityboard", { state: { selectedProject } });
    }
  };

  const menu1 = (
    <Menu>
      <Menu.Item icon={<PaperClipOutlined />} key="1">
        Existing item
      </Menu.Item>
      <Menu.Item icon={<FolderOpenOutlined />} key="2">
        New item
      </Menu.Item>
    </Menu>
  );

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };
  return (
    <div>
      {/* <h5 style={{ textAlign: "center" }}>Project Tracking</h5> */}
      <Row gutter={[16, 16]}>
        <Col span={4}>
          <Dropdown overlay={menu3} placement="bottomLeft">
            <Button type="link" icon={<BuildOutlined />}>
              {selectedProject && selectedProject.ProjectName
                ? selectedProject.ProjectName
                : selectedProject || data.length === 0
                ? "No Project Data"
                : "Select Project"}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
        <Col span={8} style={{ marginLeft: "10vh" }}>
          {selectedProject && (
            <Dropdown overlay={menu4} placement="bottomLeft">
              <Button type="link" icon={<CalendarOutlined />}>
                {selectedSprint ? (
                  <>
                    {selectedSprint.sprintName} ({selectedSprint.startDate} to{" "}
                    {selectedSprint.endDate})
                  </>
                ) : (
                  "Select Sprint"
                )}
                <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </Col>
        <Col span={8} style={{ marginLeft: "auto" }}>
          {renderEmployeeInitials()}
        </Col>
      </Row>
      <Modal
        title="Create Sprint"
        visible={createSprintModalVisible}
        onCancel={() => {
          setCreateSprintModalVisible(false);
          form.resetFields();
        }}
        onOk={handleCreateSprint}
      >
        {/* Sprint Form Fields */}
        <Form form={form} layout="vertical">
          <Row>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 20 }}
                name="projectName"
                label="Project Name"
                initialValue={selectedProject?.ProjectName}
              >
                <Input
                  className="disabled-input"
                  disabled
                  value={selectedProject?.ProjectName}
                  style={{
                    height: "6vh",
                    fontSize: "15px",
                    backgroundColor: "#fff",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 20 }}
                name="projectId"
                label="Project Id"
                initialValue={selectedProject?.Projectcode}
              >
                <Input
                  className="disabled-input"
                  disabled
                  value={selectedProject?.Projectcode}
                  style={{
                    height: "6vh",
                    fontSize: "15px",
                    backgroundColor: "#fff",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 24 }}
            name="sprintName"
            label="Sprint Name"
            rules={[
              {
                required: true,
                message: "Please enter sprint name",
              },
            ]}
          >
            <Input
              style={{
                height: "6vh",
                fontSize: "15px",
              }}
              placeholder="Enter Sprint name"
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 20 }}
                name="startDate"
                label="Start Date"
                rules={[
                  {
                    required: true,
                    message: "Please select start date",
                  },
                ]}
              >
                <Input type="Date" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 20 }}
                name="endDate"
                label="End Date"
                rules={[
                  {
                    required: true,
                    message: "Please select end date",
                  },
                ]}
              >
                <Input type="Date" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="status" label="Status" initialValue="todo" hidden>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Tabs>
        <TabPane
          tab="Feature Board"
          key="Feature Board"
          style={{ zoom: "80%" }}
        >
          {!selectedSprint || !selectedProject ? (
            <>
              <h6
                className="text-center"
                style={{ marginTop: "6vh", color: "#e69660" }}
              >
                Please select a Project and Sprint to View the Feature Board
              </h6>
            </>
          ) : (
            <>
              {/* <Col span={8}>
                {selectedProject && (
                  <Button
                    type="text"
                    shape="rectangle"
                    onClick={() => handleactivityBoard(selectedProject._id)}
                    icon={<BuildOutlined />}
                  >
                    View activityboard
                  </Button>
                )}
              </Col> */}
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <Card
                    style={{
                      background: "#dce7fa",
                      height: "150vh",
                      overflowY: "scroll",
                    }}
                  >
                    <Row>
                      <img
                        src={require("../../images/to-do.png")}
                        style={{ width: "6vh", height: "6vh" }}
                      />
                      <h5
                        style={{
                          color: "#4287f5",
                          fontWeight: "bold",
                          marginLeft: "2vh",
                        }}
                      >
                        To Do
                      </h5>
                      {user.jobTitle !== "employee" && (
                        <Button
                          icon={<PlusOutlined />}
                          disabled={!selectedProject || !selectedSprint}
                          onClick={handlefeatureClick}
                          type="primary"
                          style={{
                            left: "22vh",
                            background: "#f28a83",
                          }}
                        >
                          Feature
                        </Button>
                      )}
                    </Row>
                    <Divider style={{ marginTop: "10px" }} />

                    {/* Feature */}
                    <Modal
                      visible={modalVisible}
                      onCancel={handleCancel}
                      width={700}
                      footer={[
                        <Button
                          key="submit"
                          type="primary"
                          onClick={handlefeature}
                          disabled={user.jobTitle === "employee"}
                        >
                          {editingfeature ? "Update" : "Create"}
                        </Button>,
                      ]}
                    >
                      <Form
                        form={form1}
                        layout="vertical"
                        onFinish={handlefeature}
                      >
                        <Form.Item>
                          <img
                            src={require("../../images/star-button.png")}
                            alt=""
                            style={{ width: "4vh" }}
                          />
                          <span
                            style={{ marginBottom: "4vh", marginLeft: "1vh" }}
                          >
                            {editingfeature ? "EDIT feature" : "NEW feature"}{" "}
                          </span>
                        </Form.Item>
                        <Form.Item
                          name="featureName"
                          label="Feature Name"
                          initialValue={
                            editingfeature ? editingfeature.featureName : ""
                          }
                        >
                          <Input
                            placeholder="Enter Feature Name..."
                            style={{ height: "30px", fontSize: "15px" }}
                          />
                        </Form.Item>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <Form.Item
                              label="Assigned To"
                              name="assignee"
                              initialValue={
                                editingfeature ? editingfeature.assignee : []
                              }
                            >
                              <Select
                                mode="multiple"
                                allowClear
                                name="assignee"
                                placeholder="Select Assignee"
                                onChange={handleSelect}
                              >
                                {selectedProject &&
                                  selectedProject.TotalEmp.map(
                                    (employee, index) => (
                                      <Select.Option
                                        key={index}
                                        value={employee}
                                      >
                                        {/* Here, you can customize how you want to display the employee name and avatar */}
                                        {employee}
                                      </Select.Option>
                                    )
                                  )}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="priority"
                              label="Priority"
                              initialValue={
                                editingfeature
                                  ? editingfeature.priority
                                  : undefined
                              }
                            >
                              <Select placeholder="Select Priority">
                                <Select.Option value="high">High</Select.Option>
                                <Select.Option value="medium">
                                  Medium
                                </Select.Option>
                                <Select.Option value="low">Low</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <Form.Item
                              name="featurestartDate"
                              label="Start Date"
                              initialValue={
                                editingfeature
                                  ? moment(
                                      editingfeature.featurestartDate,
                                      "DD-MM-YYYY"
                                    ).format("YYYY-MM-DD")
                                  : undefined
                              }
                            >
                              <Input
                                type="date"
                                min={getCurrentDate()}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="featureendDate"
                              label="Target Date"
                              initialValue={
                                editingfeature
                                  ? moment(
                                      editingfeature.featureendDate,
                                      "DD-MM-YYYY"
                                    ).format("YYYY-MM-DD")
                                  : undefined
                              }
                            >
                              <Input
                                type="date"
                                min={startDate}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          name="description"
                          label="Description"
                          initialValue={
                            editingfeature ? editingfeature.description : ""
                          }
                        >
                          <Input.TextArea
                            rows={2}
                            placeholder="Click to add Description"
                          />
                        </Form.Item>
                      </Form>
                    </Modal>
                    {/* Issue */}
                    <Modal
                      visible={modalVisible1}
                      onCancel={() => setModalVisible1(false)}
                      width={1200}
                      footer={[
                        <Button
                          key="submit"
                          type="primary"
                          onClick={() => setModalVisible1(false)}
                        >
                          Save
                        </Button>,
                      ]}
                    >
                      <img
                        src={require("../../images/warning.png")}
                        alt=""
                        style={{ width: "4vh" }}
                      />
                      <span style={{ marginBottom: "4vh", marginLeft: "1vh" }}>
                        NEW ISSUE
                      </span>
                      <Input
                        placeholder="Enter Issue Name..."
                        value={inputValue1}
                        onChange={handleInputChange1}
                        onPressEnter={handleEnterPress1}
                        size="large"
                        style={{ marginRight: "8vw", marginTop: "2vh" }}
                      />
                      <Avatar size={40} icon={<UserOutlined />} /> Unassigned
                      <Row
                        style={{
                          border: "1px solid #ccc",
                          padding: "4px",
                          backgroundColor: "#f5f5f5",
                          marginTop: "3vh",
                        }}
                      >
                        <Col span={9}>
                          <label style={{ fontSize: "17px", display: "block" }}>
                            State:
                          </label>
                          <Checkbox style={{ marginLeft: "5px" }}>
                            to do
                          </Checkbox>
                        </Col>
                        <Col span={8}>
                          <label>Area:</label>
                          <span>Finaran</span>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <h5>Description</h5>
                          <Divider style={{ width: "10vh" }} />
                          <Input.TextArea
                            style={{ width: "70vh" }}
                            rows={3}
                            placeholder="Click to add Description"
                          />
                          <label>Discussion</label>
                          <Divider style={{ width: "10vh" }} />
                          <Input.TextArea
                            style={{ width: "70vh" }}
                            rows={3}
                            placeholder="Add a Comment"
                          />
                        </Col>
                        <Col span={8}>
                          <h5 style={{ marginLeft: "10vh" }}>Planning</h5>
                          <Divider style={{ width: "10vh" }} />
                          <div style={{ marginLeft: "10vh" }}>
                            <label>Priority</label>
                            <Select style={{ width: "30vh" }}>
                              <Select.Option value="high">High</Select.Option>
                              <Select.Option value="medium">
                                Medium
                              </Select.Option>
                              <Select.Option value="low">Low</Select.Option>
                            </Select>
                            <label>Efforts</label>
                            <Input style={{ width: "30vh", height: "35px" }} />
                          </div>
                        </Col>
                        <Col span={8}>
                          {/* <h5>Development</h5>
                        <Divider style={{ width: "10vh" }} />
                        <Button
                          type="link"
                          icon={<PlusOutlined style={{ color: "green" }} />}
                          onClick={Addlink}
                        >
                          Add links
                        </Button> */}
                          <h5 style={{ marginTop: "5vh" }}></h5>
                          <Divider style={{ width: "10vh" }} />
                          <Dropdown overlay={menu1} placement="bottomLeft">
                            <Button
                              type="link"
                              icon={<PlusOutlined style={{ color: "green" }} />}
                            >
                              Add links
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Col>
                      </Row>
                    </Modal>
                    {/* Add Links */}
                    <Modal
                      visible={open}
                      onCancel={() => setOpen(false)}
                      width={500}
                      title="ADD LINKS"
                    >
                      <br />

                      <h6>You are adding links from</h6>
                      <img
                        src={require("../../images/map.png")}
                        alt=""
                        style={{ width: "5vh", height: "5vh" }}
                      />
                      <label style={{ marginTop: "3vh" }}>Link Type</label>
                      <Select style={{ width: "65vh" }}>
                        <Select.Option value="Build">Build</Select.Option>
                        <Select.Option value="Found in Build">
                          Found in Build
                        </Select.Option>
                        <Select.Option value="Integrated in Build">
                          Integrated in Build
                        </Select.Option>
                      </Select>
                      <label style={{ marginTop: "3vh" }}>Repository</label>
                      <Select style={{ width: "65vh" }}>
                        <Select.Option value="high">Finaran</Select.Option>
                      </Select>
                      <label style={{ marginTop: "3vh" }}>Branch</label>
                      <Select style={{ width: "65vh" }}>
                        <Select.Option value="Mine">Mine</Select.Option>
                        <Select.Option value="All Branches">
                          All Branches
                        </Select.Option>
                      </Select>
                      <label style={{ marginTop: "3vh" }}>Comments</label>
                      <Input />
                    </Modal>
                    {/* activity */}
                    <Modal
                      // visible={open1}
                      onCancel={() => setOpen1(false)}
                      width={500}
                      title="ADD LINKS"
                    >
                      <br />

                      <h6>You are adding links from</h6>
                      <img
                        src={require("../../images/map.png")}
                        alt=""
                        style={{ width: "5vh", height: "5vh" }}
                      />
                      <label style={{ marginTop: "3vh" }}>Link Type</label>
                      <Select style={{ width: "65vh" }}>
                        <Select.Option value="Build">Build</Select.Option>
                        <Select.Option value="Found in Build">
                          Found in Build
                        </Select.Option>
                        <Select.Option value="Integrated in Build">
                          Integrated in Build
                        </Select.Option>
                      </Select>
                      <label style={{ marginTop: "3vh" }}>Repository</label>
                      <Select style={{ width: "65vh" }}>
                        <Select.Option value="high">Finaran</Select.Option>
                      </Select>
                      <label style={{ marginTop: "3vh" }}>Branch</label>
                      <Select style={{ width: "65vh" }}>
                        <Select.Option value="Mine">Mine</Select.Option>
                        <Select.Option value="All Branches">
                          All Branches
                        </Select.Option>
                      </Select>
                      <label style={{ marginTop: "3vh" }}>Comments</label>
                      <Input />
                    </Modal>
                    <Modal
                      title="Move to Next Sprint"
                      visible={open1}
                      onOk={handleOk}
                      onCancel={handleCancel1}
                    >
                      <p>Select Sprint:</p>
                      <Select
                        style={{ width: "100%" }}
                        onChange={handleselect}
                        value={selectedSprintId.sprintId}
                      >
                        {nextSprints?.map((sprint) => (
                          <Option key={sprint.sprintId} value={sprint.sprintId}>
                            {sprint.sprintName} ({sprint.startDate} to{" "}
                            {sprint.endDate})
                          </Option>
                        ))}
                      </Select>
                    </Modal>

                    {feature &&
                      feature.length > 0 &&
                      feature
                        .filter((featureItem) => {
                          if (user.jobTitle === "employee") {
                            const isAssignee = featureItem.assignee.includes(
                              user.displayName
                            );
                            return featureItem.status === "todo" && isAssignee;
                          } else {
                            return featureItem.status === "todo";
                          }
                        })
                        .map((featureItem, index) => (
                          <Card
                            key={featureItem._id}
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#f5f5f0",
                              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                              borderLeft: "4px solid #d6d6c2",
                              height: "33vh",
                              marginTop:
                                featureItem._id !== feature.length - 1
                                  ? "1rem"
                                  : 0,
                            }}
                          >
                            <div>
                              <img
                                src={require("../../images/star-button.png")}
                                alt=""
                                style={{
                                  width: "4vh",
                                  marginRight: "0.5rem",
                                }}
                              />
                              <Link
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                }}
                                onClick={() => handlefeatureClick(featureItem)}
                              >
                                {featureItem.featureName}
                              </Link>
                            </div>
                            <div
                              style={{
                                marginTop: "1rem",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {/* <span style={{ fontWeight: "bold" }}>State: </span> */}
                              <Form.Item label="State">
                                <Select
                                  style={{ marginTop: "2vh" }}
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  value={
                                    isUpdating
                                      ? selectedState
                                      : featureItem.status
                                  }
                                  onChange={(value) =>
                                    handleSelectChange(value, featureItem)
                                  }
                                  disabled={
                                    isUpdating || user.jobTitle === "employee"
                                  }
                                >
                                  <Option value="todo">To Do</Option>
                                  <Option value="doing">Doing</Option>
                                  <Option value="done">Done</Option>
                                </Select>
                              </Form.Item>
                              {featureItem.priority === "high" ? (
                                <img
                                  src={require("../../images/high.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    marginTop: "1vh",
                                  }}
                                />
                              ) : featureItem.priority === "low" ? (
                                <img
                                  src={require("../../images/low.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    marginTop: "1vh",
                                  }}
                                />
                              ) : featureItem.priority === "medium" ? (
                                <img
                                  src={require("../../images/medium.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    marginTop: "1vh",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  gap: "0",
                                  marginTop: "10px",
                                }}
                              >
                                {featureItem.assignee.map((assignees) => {
                                  const [
                                    firstName,
                                    secondName,
                                  ] = assignees.split(" ");
                                  const initials = `${firstName
                                    .charAt(0)
                                    .toUpperCase()}${secondName
                                    .charAt(0)
                                    .toUpperCase()}`;
                                  return (
                                    <Avatar
                                      key={assignees}
                                      style={{
                                        color: "black",
                                        background: "orange",
                                      }}
                                    >
                                      {initials}
                                    </Avatar>
                                  );
                                })}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                type="text"
                                style={{ bottom: "5vh" }}
                                icon={
                                  <img
                                    src={require("../../images/button.png")}
                                    style={{ width: "5vh", height: "5vh" }}
                                  />
                                }
                                onClick={() => handleactivityClick(featureItem)}
                              >
                                {" "}
                                Add Activity
                              </Button>

                              <Button
                                type="text"
                                style={{ bottom: "5vh" }}
                                icon={
                                  <img
                                    src={require("../../images/vision.png")}
                                    style={{ width: "5vh", height: "5vh" }}
                                  />
                                }
                                onClick={() =>
                                  handleactivity(
                                    featureItem.featureId,
                                    featureItem.featureName,
                                    featureItem.assignee
                                  )
                                }
                              >
                                View Activity
                              </Button>
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                              }}
                            >
                              <Dropdown
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                overlay={
                                  <Menu style={{ width: "200px" }}>
                                    <Menu.Item
                                      key="Move"
                                      onClick={() =>
                                        handleMoveSprint(featureItem)
                                      }
                                    >
                                      Move to Next Sprint
                                    </Menu.Item>
                                    <Menu.Item key="delete">
                                      <Popconfirm
                                        title="Are you sure to delete this feature?"
                                        onConfirm={() =>
                                          handleDeletefeature(featureItem)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <a href="#">Delete</a>
                                      </Popconfirm>
                                    </Menu.Item>
                                    {/* Add more menu items as needed */}
                                  </Menu>
                                }
                                placement="bottomRight"
                              >
                                <EllipsisOutlined
                                  style={{ fontSize: 20, cursor: "pointer" }}
                                />
                              </Dropdown>
                              {console.log(featureactivity, "featureitem")}
                              {modalVisible2 && (
                                <AddActivity
                                  featureactivity={featureactivity}
                                  editingfeature={editingfeature}
                                  selectedProject={selectedProject}
                                  handleSelect={handleSelect}
                                  modalVisible2={modalVisible2}
                                  setModalVisible2={setModalVisible2}
                                  handleFormSubmit={handleFormSubmit}
                                />
                              )}
                            </div>
                          </Card>
                        ))}

                    {/* {inputValue1 && selectedState1 === "To Do" && (
                    <Card
                      style={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        width: "100%",
                        height: "18vh",
                        borderRadius: "5px",
                        backgroundColor: "#f5f5f0",
                        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <img
                        src={require("../../images/warning.png")}
                        alt=""
                        style={{ width: "4vh" }}
                      />
                      <Link
                        style={{ marginLeft: "1vh" }}
                        onClick={handleIssueClick}
                      >
                        {inputValue1}
                      </Link>
                      <br />
                      <Form.Item
                        value={selectedState1}
                        label="State"
                        style={{ marginTop: "5vh" }}
                      >
                        <Select
                          defaultValue={selectedState1}
                          style={{ width: 120 }}
                          onChange={handleSelectChange1}
                        >
                          <Option value="To Do">To Do</Option>
                          <Option value="Doing">Doing</Option>
                          <Option value="Done">Done</Option>
                        </Select>
                      </Form.Item>
                    </Card>
                  )}
                  {inputValue2 && selectedState2 === "To Do" && (
                    <Card
                      style={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        width: "100%",
                        height: "18vh",
                        borderRadius: "5px",
                        backgroundColor: "#f5f5f0",
                        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <img
                        src={require("../../images/tasks.png")}
                        alt=""
                        style={{ width: "4vh" }}
                      />
                      <Link
                        style={{ marginLeft: "1vh" }}
                        onClick={handleactivityClick}
                      >
                        {inputValue2}
                      </Link>
                      <br />
                      <Form.Item
                        value={selectedState2}
                        label="State"
                        style={{ marginTop: "5vh" }}
                      >
                        <Select
                          defaultValue={selectedState2}
                          style={{ width: 120 }}
                          onChange={handleSelectChange2}
                        >
                          <Option value="To Do">To Do</Option>
                          <Option value="Doing">Doing</Option>
                          <Option value="Done">Done</Option>
                        </Select>
                      </Form.Item>
                    </Card>
                  )} */}
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  {/* <Divider
                        style={{
                          borderColor: "#a3a19b",
                          borderWidth: "2px",
                          margin: "0",
                        }}
                      /> */}
                  <Card
                    style={{
                      background: "#dce7fa",
                      height: "150vh",
                      overflowY: "scroll",
                    }}
                  >
                    <Row>
                      <img
                        src={require("../../images/loading-bar.png")}
                        style={{ width: "6vh", height: "6vh" }}
                      />
                      <h5
                        style={{
                          color: "#4287f5",
                          fontWeight: "bold",
                          marginLeft: "2vh",
                        }}
                      >
                        Doing
                      </h5>
                    </Row>
                    <Divider />
                    {feature &&
                      feature.length > 0 &&
                      feature
                        .filter((featureItem) => {
                          if (user.jobTitle === "employee") {
                            const isAssignee = featureItem.assignee.includes(
                              user.displayName
                            );
                            return featureItem.status === "doing" && isAssignee;
                          } else {
                            return featureItem.status === "doing";
                          }
                        })
                        .map((featureItem) => (
                          <Card
                            key={featureItem._id}
                            style={{
                              borderRadius: "5px",
                              height: "33vh",
                              backgroundColor: "#f5f5f0",
                              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                              borderLeft: "4px solid #d6d6c2",
                              marginTop:
                                featureItem._id !== feature.length - 1
                                  ? "1rem"
                                  : 0,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={require("../../images/star-button.png")}
                                alt=""
                                style={{
                                  width: "4vh",
                                  marginRight: "0.5rem",
                                }}
                              />
                              <Link
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                }}
                                onClick={() => handlefeatureClick(featureItem)}
                              >
                                {featureItem.featureName}
                              </Link>
                            </div>
                            <div
                              style={{
                                marginTop: "1rem",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Form.Item label="State">
                                <Select
                                  style={{ marginTop: "2vh" }}
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  defaultValue={featureItem.status}
                                  onChange={(value) =>
                                    handleSelectChange(value, featureItem)
                                  }
                                  disabled={user.jobTitle === "employee"}
                                >
                                  <Option value="todo">To Do</Option>
                                  <Option value="doing">Doing</Option>
                                  <Option value="done">Done</Option>
                                </Select>
                              </Form.Item>

                              {featureItem.priority === "high" ? (
                                <img
                                  src={require("../../images/high.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    marginTop: "1vh",
                                  }}
                                />
                              ) : featureItem.priority === "low" ? (
                                <img
                                  src={require("../../images/low.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    marginTop: "1vh",
                                  }}
                                />
                              ) : featureItem.priority === "medium" ? (
                                <img
                                  src={require("../../images/medium.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    marginTop: "1vh",
                                  }}
                                />
                              ) : (
                                ""
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  gap: "0",
                                  marginTop: "10px",
                                }}
                              >
                                {featureItem.assignee.map((assignees) => {
                                  const [
                                    firstName,
                                    secondName,
                                  ] = assignees.split(" ");
                                  const initials = `${firstName
                                    .charAt(0)
                                    .toUpperCase()}${secondName
                                    .charAt(0)
                                    .toUpperCase()}`;
                                  return (
                                    <Avatar
                                      key={assignees}
                                      style={{
                                        color: "black",
                                        background: "orange",
                                      }}
                                    >
                                      {initials}
                                    </Avatar>
                                  );
                                })}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                type="text"
                                style={{ bottom: "5vh" }}
                                icon={
                                  <img
                                    src={require("../../images/button.png")}
                                    style={{ width: "5vh", height: "5vh" }}
                                  />
                                }
                                onClick={() => handleactivityClick(featureItem)}
                              >
                                {" "}
                                Add Activity
                              </Button>

                              <Button
                                type="text"
                                style={{ bottom: "5vh" }}
                                icon={
                                  <img
                                    src={require("../../images/vision.png")}
                                    style={{ width: "5vh", height: "5vh" }}
                                  />
                                }
                                onClick={() =>
                                  handleactivity(
                                    featureItem.featureId,
                                    featureItem.featureName,
                                    featureItem.assignee
                                  )
                                }
                              >
                                View Activity
                              </Button>
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                              }}
                            >
                              <Dropdown
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                overlay={
                                  <Menu style={{ width: "200px" }}>
                                    <Menu.Item
                                      key="Move"
                                      onClick={() =>
                                        handleMoveSprint(featureItem)
                                      }
                                    >
                                      Move to Next Sprint
                                    </Menu.Item>
                                    <Menu.Item key="delete">
                                      <Popconfirm
                                        title="Are you sure to delete this feature?"
                                        onConfirm={() =>
                                          handleDeletefeature(featureItem)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <a href="#">Delete</a>
                                      </Popconfirm>
                                    </Menu.Item>
                                    {/* Add more menu items as needed */}
                                  </Menu>
                                }
                                placement="bottomRight"
                              >
                                <EllipsisOutlined
                                  style={{ fontSize: 20, cursor: "pointer" }}
                                />
                              </Dropdown>
                              {console.log(featureactivity, "featureitem")}
                              {modalVisible2 && (
                                <AddActivity
                                  featureactivity={featureactivity}
                                  editingfeature={editingfeature}
                                  selectedProject={selectedProject}
                                  handleSelect={handleSelect}
                                  modalVisible2={modalVisible2}
                                  setModalVisible2={setModalVisible2}
                                  handleFormSubmit={handleFormSubmit}
                                />
                              )}
                            </div>
                          </Card>
                        ))}

                    {/* {selectedState === "Doing" && inputValue && (
                    <Card
                      style={{
                        marginTop: "5vh",
                        width: "40vh",
                        height: "25vh",
                        background: "#91f582",
                        borderColor: "green",
                      }}
                    >
                      <img
                        src={require("../../images/king.png")}
                        alt=""
                        style={{ width: "4vh" }}
                      />
                      <Link
                        style={{ marginLeft: "1vh" }}
                        onClick={handlefeatureClick}
                      >
                        {inputValue}
                      </Link>
                      <br />
                      <Form.Item
                        value={selectedState}
                        label="State"
                        style={{ marginTop: "5vh" }}
                      >
                        <Select
                          defaultValue={selectedState}
                          style={{ width: 120 }}
                          onChange={handleSelectChange}
                        >
                          <Option value="To Do">To Do</Option>
                          <Option value="Doing">Doing</Option>
                          <Option value="Done">Done</Option>
                        </Select>
                      </Form.Item>
                    </Card>
                  )}
                  {selectedState2 === "Doing" && inputValue2 && (
                    <Card
                      style={{
                        marginTop: "5vh",
                        width: "40vh",
                        height: "25vh",
                        background: "#91f582",
                        borderColor: "green",
                      }}
                    >
                      <img
                        src={require("../../images/tasks.png")}
                        alt=""
                        style={{ width: "4vh" }}
                      />
                      <Link
                        style={{ marginLeft: "1vh" }}
                        onClick={handleactivityClick}
                      >
                        {inputValue2}
                      </Link>
                      <br />
                      <Form.Item
                        value={selectedState2}
                        label="State"
                        style={{ marginTop: "5vh" }}
                      >
                        <Select
                          defaultValue={selectedState2}
                          style={{ width: 120 }}
                          onChange={handleSelectChange2}
                        >
                          <Option value="To Do">To Do</Option>
                          <Option value="Doing">Doing</Option>
                          <Option value="Done">Done</Option>
                        </Select>
                      </Form.Item>
                    </Card>
                  )} */}
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <Card
                    style={{
                      background: "#dce7fa",
                      height: "150vh",
                      overflowY: "scroll",
                    }}
                  >
                    <Row>
                      <img
                        src={require("../../images/done.png")}
                        style={{ width: "6vh", height: "6vh" }}
                      />
                      <h5
                        style={{
                          color: "#4287f5",
                          fontWeight: "bold",
                          marginLeft: "2vh",
                        }}
                      >
                        Done
                      </h5>
                    </Row>
                    <Divider />
                    {/* {selectedState1 === "Done" && inputValue1 && (
                    <Card
                      style={{
                        marginTop: "5vh",
                        width: "40vh",
                        height: "25vh",
                        background: "#91f582",
                        borderColor: "green",
                      }}
                    >
                      <img
                        src={require("../../images/warning.png")}
                        alt=""
                        style={{ width: "4vh" }}
                      />
                      <Link
                        style={{ marginLeft: "1vh" }}
                        onClick={handleIssueClick}
                      >
                        {inputValue1}
                      </Link>
                      <br />
                      <Form.Item
                        value={selectedState1}
                        label="State"
                        style={{ marginTop: "5vh" }}
                      >
                        <Select
                          defaultValue={selectedState1}
                          style={{ width: 120 }}
                          onChange={handleSelectChange1}
                        >
                          <Option value="To Do">To Do</Option>
                          <Option value="Doing">Doing</Option>
                          <Option value="Done">Done</Option>
                        </Select>
                      </Form.Item>
                    </Card>
                  )} */}
                    {feature &&
                      feature.length > 0 &&
                      feature
                        .filter((featureItem) => {
                          if (user.jobTitle === "employee") {
                            const isAssignee = featureItem.assignee.includes(
                              user.displayName
                            );
                            return featureItem.status === "done" && isAssignee;
                          } else {
                            return featureItem.status === "done";
                          }
                        })
                        .map((featureItem) => (
                          <Card
                            key={featureItem._id}
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#f5f5f0",
                              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                              borderLeft: "4px solid #d6d6c2",
                              height: "33vh",
                              marginTop:
                                featureItem._id !== feature.length - 1
                                  ? "1rem"
                                  : 0,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={require("../../images/star-button.png")}
                                alt=""
                                style={{
                                  width: "4vh",
                                  marginRight: "0.5rem",
                                }}
                              />
                              <Link
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                }}
                                onClick={() => handlefeatureClick(featureItem)}
                              >
                                {featureItem.featureName}
                              </Link>
                            </div>
                            <div
                              style={{
                                marginTop: "1rem",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Form.Item label="State">
                                <Select
                                  style={{ marginTop: "2vh" }}
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  defaultValue={featureItem.status}
                                  onChange={(value) =>
                                    handleSelectChange(value, featureItem)
                                  }
                                  disabled={user.jobTitle === "employee"}
                                >
                                  <Option value="todo">To Do</Option>
                                  <Option value="doing">Doing</Option>
                                  <Option value="done">Done</Option>
                                </Select>
                              </Form.Item>
                              {featureItem.priority === "high" ? (
                                <img
                                  src={require("../../images/high.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    marginTop: "1vh",
                                  }}
                                />
                              ) : featureItem.priority === "low" ? (
                                <img
                                  src={require("../../images/low.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    marginTop: "1vh",
                                  }}
                                />
                              ) : featureItem.priority === "medium" ? (
                                <img
                                  src={require("../../images/medium.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    marginTop: "1vh",
                                  }}
                                />
                              ) : (
                                ""
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  gap: "0",
                                  marginTop: "10px",
                                }}
                              >
                                {featureItem.assignee.map((assignees) => {
                                  const [
                                    firstName,
                                    secondName,
                                  ] = assignees.split(" ");
                                  const initials = `${firstName
                                    .charAt(0)
                                    .toUpperCase()}${secondName
                                    .charAt(0)
                                    .toUpperCase()}`;
                                  return (
                                    <Avatar
                                      key={assignees}
                                      style={{
                                        color: "black",
                                        background: "orange",
                                      }}
                                    >
                                      {initials}
                                    </Avatar>
                                  );
                                })}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                type="text"
                                style={{ bottom: "5vh" }}
                                icon={
                                  <img
                                    src={require("../../images/button.png")}
                                    style={{ width: "5vh", height: "5vh" }}
                                  />
                                }
                                onClick={() => handleactivityClick(featureItem)}
                                disabled
                              >
                                {" "}
                                Add Activity
                              </Button>

                              <Button
                                type="text"
                                style={{ bottom: "5vh" }}
                                icon={
                                  <img
                                    src={require("../../images/vision.png")}
                                    style={{ width: "5vh", height: "5vh" }}
                                  />
                                }
                                onClick={() =>
                                  handleactivity(
                                    featureItem.featureId,
                                    featureItem.featureName,
                                    featureItem.assignee
                                  )
                                }
                              >
                                View Activity
                              </Button>
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                              }}
                            >
                              <Dropdown
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                overlay={
                                  <Menu style={{ width: "200px" }}>
                                    <Menu.Item
                                      key="Move"
                                      onClick={() =>
                                        handleMoveSprint(featureItem)
                                      }
                                    >
                                      Move to Next Sprint
                                    </Menu.Item>
                                    <Menu.Item key="delete">
                                      <Popconfirm
                                        title="Are you sure to delete this feature?"
                                        onConfirm={() =>
                                          handleDeletefeature(featureItem)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <a href="#">Delete</a>
                                      </Popconfirm>
                                    </Menu.Item>
                                    {/* Add more menu items as needed */}
                                  </Menu>
                                }
                                placement="bottomRight"
                              >
                                <EllipsisOutlined
                                  style={{ fontSize: 20, cursor: "pointer" }}
                                />
                              </Dropdown>
                              {console.log(featureactivity, "featureitem")}
                              {modalVisible2 && (
                                <AddActivity
                                  featureactivity={featureactivity}
                                  editingfeature={editingfeature}
                                  selectedProject={selectedProject}
                                  handleSelect={handleSelect}
                                  modalVisible2={modalVisible2}
                                  setModalVisible2={setModalVisible2}
                                  handleFormSubmit={handleFormSubmit}
                                />
                              )}
                            </div>
                          </Card>
                        ))}

                    {/* {selectedState2 === "Done" && inputValue2 && (
                    <Card
                      style={{
                        marginTop: "5vh",
                        width: "40vh",
                        height: "25vh",
                        background: "#91f582",
                        borderColor: "green",
                      }}
                    >
                      <img
                        src={require("../../images/tasks.png")}
                        alt=""
                        style={{ width: "4vh" }}
                      />
                      <Link
                        style={{ marginLeft: "1vh" }}
                        onClick={handleactivityClick}
                      >
                        {inputValue2}
                      </Link>
                      <br />
                      <Form.Item
                        value={selectedState2}
                        label="State"
                        style={{ marginTop: "5vh" }}
                      >
                        <Select
                          defaultValue={selectedState2}
                          style={{ width: 120 }}
                          onChange={handleSelectChange2}
                        >
                          <Option value="To Do">To Do</Option>
                          <Option value="Doing">Doing</Option>
                          <Option value="Done">Done</Option>
                        </Select>
                      </Form.Item>
                    </Card>
                  )} */}
                  </Card>{" "}
                </Col>
              </Row>
            </>
          )}
        </TabPane>
        <TabPane tab="Project Activity Board" key="Activity Board">
          <Activityboard
            propselectedProject={selectedProject}
            propselectedSprint={selectedSprint}
          />
        </TabPane>
        <TabPane tab="Backlogs" key="Backlogs">
          <Issueboard propselectedProject={selectedProject} />
        </TabPane>
{/* 
        <TabPane tab="Reports" key="Reports" style={{ zoom: "80%" }}>
          {/* <h5>Analytics</h5>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Card>
                  <span>Cumulative Flow Diagram</span>
                  <p style={{ color: "GrayText", marginTop: "5vh" }}>
                    Average Work in Progress
                  </p>
                  <p style={{ fontSize: "40px" }}>0</p>
                  <Link style={{ marginLeft: "20vh", bottom: "20vh" }}>
                    View Full Report
                    <RightOutlined />
                  </Link>
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <span>Velocity</span>
                  <p style={{ marginTop: "10vh" }}>There was an Error</p>
                  <p style={{ color: "GrayText" }}>
                    {" "}
                    Iteration dates must be set to use this report. Click to set
                    iteration dates
                  </p>
                </Card>
              </Col>
            </Row>  
          {selectedProject ? (
            <>
              <BarChart selectedProject={selectedProject} />
            </>
          ) : (
            <p
              className="text-center"
              style={{ marginTop: "6vh", color: "#e69660" }}
            >
              Please select a Project and Sprint to View the Report.
            </p>
          )}
        </TabPane>
        <TabPane tab="report">
          {selectedProject ? (
            <>
              <SprintGanttChart selectedProject={selectedProject} />
            </>
          ) : (
            <p
              className="text-center"
              style={{ marginTop: "6vh", color: "#e69660" }}
            >
              Please select a Project and Sprint to View the Report.
            </p>
          )}
        </TabPane>
         */}
      </Tabs>
    </div>
  );
};

export default Board;
