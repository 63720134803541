// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-form {
  background-color: #00134D; /* Background color */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff; /* Text color */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Dark overlay background */
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ant-spin-dot-item {
  background-color: #1890ff !important; /* Customize spinner color */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Resume Database/UploadForm.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,qBAAqB;EAChD,aAAa;EACb,mBAAmB;EACnB,uCAAuC;EACvC,kBAAkB;EAClB,WAAW,EAAE,eAAe;AAC9B;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B,EAAE,4BAA4B;EAC5D,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,oCAAoC,EAAE,4BAA4B;AACpE","sourcesContent":[".upload-form {\n  background-color: #00134D; /* Background color */\n  padding: 40px;\n  border-radius: 10px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n  text-align: center;\n  color: #fff; /* Text color */\n}\n\n.loading-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.8); /* Dark overlay background */\n  z-index: 999;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.ant-spin-dot-item {\n  background-color: #1890ff !important; /* Customize spinner color */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
