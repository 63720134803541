import React, { useEffect, useState } from "react";
import {
  Layout,
  Input,
  Select,
  Button,
  Row,
  Col,
  Card,
  message,
  Modal,
  Tooltip,
  Divider,
  Tabs,
  Rate,
  notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  DownloadOutlined,
  EyeOutlined,
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  SearchOutlined,
  StarOutlined,
  EditOutlined,
  FilePdfOutlined,
  RobotOutlined,
  StarFilled,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { url } from "../../url/url";
import Application from "../Recruitment/Application";

const { Option } = Select;
const { Content } = Layout;
const { TabPane } = Tabs;

const Resumemanagement = () => {
  const [workExperience, setWorkExperience] = useState("");
  const [skills, setSkills] = useState("");
  const [otherSkills, setOtherSkills] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [resume, setResume] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [modalResumeUrl, setModalResumeUrl] = useState("");
  const [hoveredUser, setHoveredUser] = useState(null); // To store the user data for the modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isResumeModalVisible, setIsResumeModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isAllJob, setIsAllJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState({
    jobId: "",
    role: "",
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const dbName = localStorage.getItem("dbName");
  const users = JSON.parse(localStorage.getItem("user"));
  const [jobs, setJob] = useState([]);
  const [isJob, setIsJob] = useState(false);
  const [shortlistedStatus, setShortlistedStatus] = useState({});
  const [count, setCount] = useState(0);
  const [sortCriteria, setSortCriteria] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleShortlist = async (user) => {
    setSelectedUser(user);
    await fetchJob(user.candidateId);
    setIsAllJob(false);
    setIsModalVisible1(true);
  };

  const handleCandidateClick = (user) => {
    const candidateStatus = shortlistedStatus[user.candidateId]?.status;
    console.log(candidateStatus, "candidateStatus");
    if (candidateStatus === undefined) {
      // Open the modal when status is undefined
      setIsAllJob(false);
      setIsJob(true);
      setIsModalVisible1(true);
      setSelectedUser(user); // Store the currently selected user for later use
    } else {
      const jobId = shortlistedStatus[user.candidateId]?.jobId;
      const encodedJobId = encodeURIComponent(jobId);

      console.log(jobId, "jobid");
      navigate(`/home/ApplicantDetail/${encodedJobId}`, {
        state: {
          candidateId: user.candidateId,
          role: shortlistedStatus[user.candidateId]?.role,
        },
      });
    }
  };

  const handleOk = async () => {
    // Submit the selected jobId with candidate info
    await submitShortlist(selectedUser.candidateId, selectedUser.candidateName);
    setSelectedJob({
      jobId: "",
      role: "",
    });
    setIsModalVisible1(false);
    setCount((prevCount) => prevCount + 1);
  };

  const submitShortlist = async (candidateId, candidateName) => {
    const jobId = selectedJob.jobId;
    const role = selectedJob.role;
    let status;
    if (
      shortlistedStatus?.[selectedUser?.candidateId]?.status === undefined ||
      isAllJob
    ) {
      status = "Applied";
    } else {
      status = "Shortlisted";
    }
    axios
      .post(`${url}/api/shortlist/${dbName}`, {
        candidateId,
        candidateName,
        jobId,
        role,
        updatedBy: users.displayName,
        status,
      })
      .then((response) => {
        // Handle success
        notification.success({
          message: "Success",
          description:
            response.data.message || "Candidate shortlisted successfully.",
        });
      })
      .catch((error) => {
        // Handle error
        notification.error({
          message: "Error",
          description:
            error.response?.data?.error ||
            "An error occurred while shortlisting the candidate.",
        });
      });
  };

  // Function to handle card hover
  const handleCardHover = (user) => {
    setHoveredUser(user); // Set the user details
    setIsModalVisible(true); // Show the modal
  };

  // Function to close modal
  const handleModalClose = () => {
    setIsModalVisible(false);
    setIsResumeModalVisible(false);
    setHoveredUser(null);
  };

  useEffect(() => {
    fetchResume();
  }, [count]);

  useEffect(() => {
    fetchJob();
  }, [count, isJob]);

  useEffect(() => {
    if (searchResults.length > 0) {
      checkShortlistedStatus();
    }
  }, [searchResults, dbName]);

  const fetchResume = async () => {
    try {
      const response = await axios.get(`${url}/get/resumes/${dbName}`);
      const data = response.data;
      data.forEach((user) => {
        const totalSkills = [...user.primarySkills, ...user.secondarySkills];
        const totalRatings = totalSkills.reduce(
          (sum, skill) => sum + skill.rating,
          0
        );
        user.averageRating = totalSkills.length
          ? totalRatings / totalSkills.length
          : 0;
      });
      setResume(data);
      setSearchResults(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const fetchJob = async (candidateId) => {
    try {
      let res;
      if (candidateId) {
        res = await axios.get(`${url}/api/getJobs/${dbName}/${candidateId}`);
        const filteredJobList = res.data.filter(
          (job) => job.status !== "Selected" && job.status !== "Rejected"
        );
        setJob(filteredJobList);
      } else {
        res = await axios.get(`${url}/get/jobs/${dbName}`);
        const appliedRes = await axios.get(
          `${url}/api/getJobs/${dbName}/${selectedUser.candidateId}`
        );
        const appliedJobIds = appliedRes.data.map((job) => job.jobId);
        const filteredJobList = res.data
          .filter((job) => job.status !== "Closed")
          .filter((job) => !appliedJobIds.includes(job.jobId));
        setJob(filteredJobList);
        setIsJob(false);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const fetchShortlistedStatus = async (candidateId, dbName) => {
    try {
      const response = await axios.get(
        `${url}/api/shortlist/${dbName}/${candidateId}`
      );
      return {
        isShortlisted: response.data.isShortlisted,
        role: response.data.role,
        jobId: response.data.jobId,
        status: response.data.status,
      };
    } catch (error) {
      console.error("Error fetching shortlisted status:", error);
      return { isShortlisted: false, role: null, jobId: null };
    }
  };

  const checkShortlistedStatus = async () => {
    const statusPromises = searchResults.map(async (user) => {
      const {
        isShortlisted,
        role,
        jobId,
        status,
      } = await fetchShortlistedStatus(user.candidateId, dbName);
      return {
        candidateId: user.candidateId,
        isShortlisted,
        role,
        jobId,
        status,
      };
    });

    const statuses = await Promise.all(statusPromises);
    const statusMap = statuses.reduce(
      (acc, { candidateId, isShortlisted, role, jobId, status }) => {
        acc[candidateId] = { isShortlisted, role, jobId, status };
        return acc;
      },
      {}
    );
    setShortlistedStatus(statusMap);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    filterResults(workExperience, skills, otherSkills, event.target.value);
  };

  const handleExperienceChange = (e) => {
    setWorkExperience(e.target.value);
    filterResults(e.target.value, skills, otherSkills, searchTerm);
  };

  const handleSkillsChange = (e) => {
    setSkills(e.target.value);
    filterResults(workExperience, e.target.value, otherSkills, searchTerm);
  };

  const handleOtherChange = (e) => {
    setOtherSkills(e.target.value);
    filterResults(workExperience, skills, e.target.value, searchTerm);
  };

  const calculateJobFitScore = (resume, searchTerm) => {
    const searchKeywords = searchTerm.toLowerCase().split(" ");
    const titleWords = resume.jobTitle?.toLowerCase().split(" ") || [];
    const summaryWords = resume.summary?.toLowerCase().split(" ") || [];
    const skillsWords =
      resume.skills
        ?.map((skill) => skill.toLowerCase())
        .join(" ")
        .split(" ") || [];
    const locationWords = resume.location?.toLowerCase().split(" ") || [];

    let matchCount = 0;
    searchKeywords.forEach((keyword) => {
      if (
        titleWords.includes(keyword) ||
        summaryWords.includes(keyword) ||
        skillsWords.includes(keyword) ||
        locationWords.includes(keyword)
      ) {
        matchCount += 1;
      }
    });

    const score = (matchCount / searchKeywords.length) * 100;
    return Math.round(score);
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    filterResults(workExperience, skills, otherSkills, searchValue);
  };

  const filterResults = (experience, skills, otherSkills, term) => {
    let filteredResults = resume;

    if (experience) {
      // Convert the input experience to a float
      const experienceYears = parseFloat(experience); // e.g., "2.5" => 2.5

      // Ensure that experienceYears is a valid number
      if (!isNaN(experienceYears)) {
        // Filter the results
        filteredResults = filteredResults.filter((user) => {
          // Ensure totalExperience is a string and extract numeric value
          const userExperienceString = user.totalExperience || "0"; // Default to "0" if undefined

          // Extract the numeric part and convert to float
          const userExperience =
            parseFloat(userExperienceString.replace(/[^0-9.]/g, "")) || 0; // Remove non-numeric characters except dot and convert to float

          // Check if userExperience is greater than or equal to experienceYears
          return userExperience >= experienceYears;
        });
      }
    }

    if (skills) {
      filteredResults = filteredResults.filter((user) => {
        // Check primarySkills or fallback to keySkills if primarySkills is not defined
        const primaryMatch = user.primarySkills?.some((skill) =>
          skill?.skill.toLowerCase().includes(skills.toLowerCase())
        );

        // Check keySkills if primarySkills match wasn't found
        const keySkillsMatch = user.keySkills?.some((skill) =>
          skill.toLowerCase().includes(skills.toLowerCase())
        );

        // Return true if either primarySkills or keySkills match
        return primaryMatch || keySkillsMatch;
      });
    }

    if (otherSkills) {
      filteredResults = filteredResults.filter((user) => {
        // Check secondarySkills or fallback to keySkills if secondarySkills is not defined
        const secondaryMatch = user.secondarySkills?.some((skill) =>
          skill?.skill.toLowerCase().includes(otherSkills.toLowerCase())
        );

        // Check keySkills if secondarySkills match wasn't found
        const keySkillsMatch = user.keySkills?.some((skill) =>
          skill.toLowerCase().includes(otherSkills.toLowerCase())
        );

        // Return true if either secondarySkills or keySkills match
        return secondaryMatch || keySkillsMatch;
      });
    }

    if (term) {
      filteredResults = filteredResults.filter((user) => {
        const notesMatch =
          user.notes?.some((note) =>
            note.toLowerCase().includes(term.toLowerCase())
          ) || false;

        console.log(
          `Checking resume for: ${user.candidateName}, Notes: ${user.notes}, Match: ${notesMatch}`
        );
        return notesMatch;
      });
    }

    // Sort the filtered results based on the rating of the matched skills
    filteredResults.sort((a, b) => {
      const aRating =
        a.primarySkills?.find((skill) =>
          skill.skill.toLowerCase().includes(skills.toLowerCase())
        )?.rating || 0; // Get rating or default to 0

      const bRating =
        b.primarySkills?.find((skill) =>
          skill.skill.toLowerCase().includes(skills.toLowerCase())
        )?.rating || 0; // Get rating or default to 0

      return bRating - aRating; // Sort in descending order
    });

    setSearchResults(filteredResults);
    setNoResultsFound(filteredResults.length === 0);
  };

  const handleViewResume = async (resumeId) => {
    try {
      const response = await axios.get(
        `${url}/get/resumes/${dbName}?includePdf=true`
      );
      const selectedResume = response.data.find(
        (resume) => resume._id === resumeId
      );
      if (selectedResume && selectedResume.pdf) {
        const pdfData = `data:application/pdf;base64,${selectedResume.pdf}`;
        setModalResumeUrl(pdfData);
        setIsResumeModalVisible(true);
      } else {
        console.error("PDF not found for this resume");
      }
    } catch (error) {
      console.error("Failed to fetch PDF:", error);
    }
  };

  const handleResetFilters = () => {
    setSearchTerm("");
    setWorkExperience("");
    setSkills("");
    setOtherSkills("");
    setSearchResults(resume);
    setNoResultsFound(false);
  };

  const handleSortByTimestamp = () => {
    const newOrder =
      sortCriteria === "timestamp" && sortOrder === "asc" ? "desc" : "asc";
    setSortCriteria("timestamp");
    setSortOrder(newOrder);
    sortResults("timestamp", newOrder);
  };

  const handleSortByRating = () => {
    const newOrder =
      sortCriteria === "rating" && sortOrder === "asc" ? "desc" : "asc";
    setSortCriteria("rating");
    setSortOrder(newOrder);
    sortResults("rating", newOrder);
  };

  const sortResults = (criteria, order) => {
    let sortedResults = [...searchResults]; // Clone the current search results

    if (criteria === "timestamp") {
      sortedResults.sort((a, b) => {
        const aDate = new Date(a.history[0]?.date); // Convert history date to Date object
        const bDate = new Date(b.history[0]?.date);
        return order === "asc" ? aDate - bDate : bDate - aDate; // Sort by date
      });
    } else if (criteria === "rating") {
      sortedResults.sort((a, b) => {
        const aRating = a.averageRating || 0; // Fallback to 0 if no rating
        const bRating = b.averageRating || 0; // Fallback to 0 if no rating
        return order === "asc" ? aRating - bRating : bRating - aRating; // Sort by average rating
      });
    }

    setSearchResults(sortedResults); // Update the displayed results
  };

  const handleCancel = () => {
    setSelectedJob({
      jobId: "",
      role: "",
    });
    setIsModalVisible(false);
    setIsModalVisible1(false);
  };

  const handleDownloadResume = () => {
    if (modalResumeUrl) {
      const anchor = document.createElement("a");
      anchor.href = modalResumeUrl;
      anchor.download = "resume.pdf";
      anchor.click();
    } else {
      message.error("No resume available for download.");
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Button onClick={goBack} icon={<ArrowLeftOutlined />} />
      <Tabs>
        {users.jobTitle === "hr" ? (
          <>
            <TabPane tab="Resume Database" key="1">
              <Row
                gutter={[16, 16]}
                style={{
                  marginBottom: "10px",
                  marginTop: "20px",
                  alignItems: "center",
                }} // Align items in the center
              >
                <Col xs={24} sm={6} md={6}>
                  {/* Adjusted widths for better alignment */}
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                      fontSize: "14px",
                    }}
                  >
                    <SearchOutlined
                      style={{ marginRight: "5px", color: "#8c8c8c" }}
                    />
                    Job Title
                  </label>
                  <Input
                    style={{ fontSize: "12px", width: "100%", height: "8vh" }}
                    placeholder="Job title"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </Col>
                <Col xs={24} sm={6} md={6}>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                      fontSize: "14px",
                    }}
                  >
                    <StarOutlined
                      style={{ marginRight: "5px", color: "#8c8c8c" }}
                    />
                    Skills
                  </label>
                  <Input
                    style={{ fontSize: "12px", width: "100%", height: "8vh" }}
                    placeholder="Search by skill"
                    value={skills}
                    onChange={handleSkillsChange}
                  />
                </Col>
                <Col xs={24} sm={6} md={6}>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                      fontSize: "14px",
                    }}
                  >
                    <StarOutlined
                      style={{ marginRight: "5px", color: "#8c8c8c" }}
                    />
                    Others
                  </label>
                  <Input
                    style={{ fontSize: "12px", width: "100%", height: "8vh" }}
                    placeholder="Other skills"
                    value={otherSkills}
                    onChange={handleOtherChange}
                  />
                </Col>
                <Col xs={24} sm={6} md={6}>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                      fontSize: "14px",
                    }}
                  >
                    <EnvironmentOutlined
                      style={{ marginRight: "5px", color: "#8c8c8c" }}
                    />
                    Years of Experience
                  </label>
                  <Input
                    style={{ fontSize: "12px", width: "100%", height: "8vh" }}
                    placeholder="Years of Experience"
                    value={workExperience}
                    onChange={handleExperienceChange}
                  />
                </Col>
              </Row>

              <div style={{ textAlign: "right", marginTop: "-25px" }}>
                <Button
                  type="link"
                  onClick={handleSortByTimestamp}
                  style={{ marginLeft: "8px" }}
                >
                  {sortCriteria === "timestamp" && sortOrder === "asc"
                    ? "↑"
                    : "↓"}{" "}
                  Timestamp
                </Button>
                <Button
                  type="link"
                  onClick={handleSortByRating}
                  style={{ marginLeft: "8px" }}
                >
                  {sortCriteria === "rating" && sortOrder === "asc" ? "↑" : "↓"}{" "}
                  Rating
                </Button>
                <Button type="link" onClick={handleResetFilters}>
                  Reset filters
                </Button>
              </div>

              <Divider />

              {/* Cards Section */}

              <Row gutter={[16, 16]} justify="space-between">
                {searchResults.length > 0 ? (
                  searchResults.map((user) => (
                    <Col xs={24} sm={12} md={8} lg={8} key={user._id}>
                      <Card
                        style={{
                          maxWidth: "100%",
                          height: "30vh",
                          borderColor: "#cabdfc",
                          boxShadow: "0 3px 3px #7459e3",
                          position: "relative",
                          overflow: "hidden",
                          transition:
                            "transform 0.2s ease, box-shadow 0.2s ease",
                        }}
                        hoverable
                        onClick={() => handleCandidateClick(user)}
                        className="candidate-card"
                      >
                        <Tooltip title="View Resume">
                          <FilePdfOutlined
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              fontSize: "18px",
                              color: "#7459e3",
                              cursor: "pointer",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleViewResume(user._id);
                            }}
                          />
                        </Tooltip>

                        <Tooltip title="AI Generated Resume">
                          <RobotOutlined
                            style={{
                              position: "absolute",
                              top: "40px",
                              right: "10px",
                              fontSize: "18px",
                              color: "#7459e3",
                              cursor: "pointer",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleCardHover(user);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Apply to Job">
                          <EditOutlined
                            style={{
                              position: "absolute",
                              top: "70px",
                              right: "10px",
                              fontSize: "18px",
                              color: "#7459e3",
                              cursor: "pointer",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setIsJob(true);
                              setIsAllJob(true);
                              setIsModalVisible1(true);
                              setSelectedUser(user);
                            }}
                          />
                        </Tooltip>

                        {shortlistedStatus[user.candidateId]?.isShortlisted ? (
                          <Tooltip
                            title={
                              shortlistedStatus[user.candidateId]?.role ||
                              "No role assigned"
                            }
                          >
                            <StarFilled
                              style={{
                                position: "absolute",
                                top: "100px",
                                right: "10px",
                                fontSize: "18px",
                                color: "#7459e3",
                                cursor: "pointer",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            />
                          </Tooltip>
                        ) : (
                          shortlistedStatus?.[user.candidateId]?.status !==
                            undefined && (
                            <Tooltip title="Shortlist Candidate">
                              <StarOutlined
                                style={{
                                  position: "absolute",
                                  top: "100px",
                                  right: "10px",
                                  fontSize: "18px",
                                  color: "#7459e3",
                                  cursor: "pointer",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleShortlist(user);
                                }}
                              />
                            </Tooltip>
                          )
                        )}

                        <Row>
                          <Col span={9}>
                            <img
                              style={{
                                width: "15vh",
                                height: "15vh",
                                borderRadius: "50%",
                                objectFit: "cover",
                                border: "1px solid #ddd",
                              }}
                              src={
                                user.profileImage
                                  ? user.profileImage
                                  : Math.random() > 0.5
                                  ? require("../../images/male1.png")
                                  : require("../../images/female1.png")
                              }
                              alt="Profile"
                            />
                          </Col>
                          <Col span={13}>
                            <span>{user.candidateName}</span>
                            <p
                              style={{
                                fontSize: "12px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "150px",
                              }}
                            >
                              {user.currentJobTitle}
                            </p>

                            <div
                              style={{
                                marginTop: "-10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip title={user.email}>
                                <MailOutlined style={{ marginLeft: "4px" }} />
                              </Tooltip>
                              <Tooltip title={user.phoneNumber}>
                                <PhoneOutlined style={{ marginLeft: "15px" }} />
                              </Tooltip>
                              <p
                                style={{
                                  fontSize: "8px",
                                  color: "#7459e3",
                                  marginLeft: "3px",
                                  marginBottom: 0,
                                }}
                              >
                                {shortlistedStatus[user.candidateId]?.status ===
                                undefined
                                  ? "Created"
                                  : shortlistedStatus[user.candidateId]?.status}
                              </p>
                            </div>

                            <div style={{ marginTop: "5px" }}>
                              <Rate
                                allowHalf
                                disabled
                                defaultValue={user.averageRating || 0}
                                style={{ fontSize: "12px", marginLeft: "4px" }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))
                ) : noResultsFound ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: "18px",
                      width: "100%",
                    }}
                  >
                    No results found
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: "18px",
                      width: "100%",
                    }}
                  >
                    No data available
                  </div>
                )}

                {searchResults.length % 3 !== 0 && (
                  <Col xs={24} sm={12} md={8} lg={8}></Col>
                )}
              </Row>

              <Modal
                title="View Resume"
                visible={isResumeModalVisible}
                onCancel={handleModalClose}
                footer={[
                  <Button
                    key="download"
                    onClick={handleDownloadResume}
                    icon={<DownloadOutlined />}
                  >
                    Download
                  </Button>,
                  <Button key="cancel" onClick={handleModalClose}>
                    Close
                  </Button>,
                ]}
                width={700}
              >
                <iframe
                  src={modalResumeUrl}
                  style={{ width: "100%", height: "60vh" }}
                  title="Resume"
                />
              </Modal>

              <Modal
                title={
                  <div
                    style={{
                      backgroundColor: "#7060fc",
                      color: "white",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    AI Generated Resume
                  </div>
                }
                visible={isModalVisible}
                onCancel={handleModalClose}
                footer={null}
                width={650}
                bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
              >
                {hoveredUser && (
                  <div>
                    <Row>
                      <Col span={6}>
                        <img
                          style={{
                            width: "15vh",
                            height: "15vh",
                            borderRadius: "50%",
                            objectFit: "cover",
                            border: "1px solid #ddd",
                          }}
                          src={
                            hoveredUser.profileImage
                              ? hoveredUser.profileImage
                              : Math.random() > 0.5
                              ? require("../../images/male1.png")
                              : require("../../images/female1.png")
                          }
                        />
                      </Col>
                      <Col span={18} style={{ marginTop: "4px" }}>
                        <h6 style={{ fontWeight: "bold" }}>
                          {hoveredUser?.candidateName}
                        </h6>
                        <p style={{ marginTop: "-14px", fontSize: "13px" }}>
                          {hoveredUser.currentJobTitle}
                        </p>
                        <p style={{ marginTop: "-19px", fontSize: "13px" }}>
                          {" "}
                          {hoveredUser.email}
                        </p>
                        <p style={{ marginTop: "-17px", fontSize: "13px" }}>
                          {" "}
                          {hoveredUser.phoneNumber}
                        </p>
                        <p style={{ marginTop: "-17px", fontSize: "13px" }}>
                          {" "}
                          {hoveredUser.location}
                        </p>
                        <p style={{ marginTop: "-17px", fontSize: "13px" }}>
                          {" "}
                          {hoveredUser.totalExperience} years
                        </p>
                      </Col>
                    </Row>
                    <Divider style={{ marginTop: "-10px" }} />
                    <span style={{ fontWeight: "bold" }}>Summary</span>
                    <p style={{ color: "#7a7980", fontStyle: "italic" }}>
                      {hoveredUser.notes}
                    </p>
                    <div style={{ marginTop: "10px" }}>
                      <p style={{ fontWeight: "bold" }}>Skills</p>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          marginTop: "5px",
                          flexWrap: "wrap",
                        }}
                      >
                        {hoveredUser.keySkills.map((skill, index) => (
                          <span
                            key={index}
                            style={{
                              backgroundColor: "#f1f1f1",
                              borderRadius: "20px",
                              padding: "5px 10px",
                              border: "1px solid #ddd",
                              fontSize: "12px",
                            }}
                          >
                            {skill}
                          </span>
                        ))}
                      </div>
                    </div>
                    <br />
                    {hoveredUser.projectsAchievements &&
                      hoveredUser.projectsAchievements.length > 0 && (
                        <div>
                          <strong>Projects & Achievements:</strong>
                          <ul>
                            {hoveredUser.projectsAchievements.map(
                              (project, index) => (
                                <li key={index}>{project}</li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                  </div>
                )}
              </Modal>
              <Modal
                title={
                  shortlistedStatus?.[selectedUser?.candidateId]?.status ===
                    undefined || isAllJob
                    ? ""
                    : "Shortlist Candidate"
                }
                visible={isModalVisible1}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Job Role"
                  value={selectedJob.jobId || undefined}
                  onChange={(value) => {
                    const selectedJob = jobs.find((job) => job.jobId === value);
                    setSelectedJob({
                      jobId: selectedJob.jobId,
                      role: selectedJob.role,
                    });
                  }}
                >
                  {jobs.map((job) => (
                    <Option key={job._id} value={job.jobId}>
                      {job.role} - {job.jobId}
                    </Option>
                  ))}
                </Select>
              </Modal>
            </TabPane>

            <TabPane tab="Job Position Applicants" key="2">
              <Application />
            </TabPane>
          </>
        ) : (
          <TabPane tab="Interview Queue" key="3">
            <Application />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default Resumemanagement;
