import {
  UsergroupAddOutlined,
  AppstoreAddOutlined,
  HourglassOutlined,
  BankOutlined,
  AccountBookOutlined,
  ContactsOutlined,
  CarryOutOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
  SolutionOutlined,
  FieldTimeOutlined,
  WarningOutlined,
  FileDoneOutlined,
  ShopOutlined,
  FileProtectOutlined,
  EnvironmentOutlined,
  DatabaseOutlined,
  MenuOutlined,
  ArrowLeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { Layout, Menu, Tooltip, Spin, Row } from "antd";
import { Link } from "react-router-dom";
import "./sider.css";
import React, { useState, useEffect, useContext } from "react";
import { url } from "../../url/url";
import { ColorContext } from "../../pages/admin/ColorProvider";
import { decryptPlanType, decryptRole } from "../../encrypt";
const { Sider } = Layout;
const { SubMenu } = Menu;
export const Siders = ({ collapse, setCollapse }) => {
  const [collapsed, setCollapsed] = useState(false); // Initialize the state
  const [openSubMenuKeys, setOpenSubMenuKeys] = useState([]);
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState([]);
  const [Image1, setImage1] = useState();
  // const [collapse, setCollapse] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState(null);
  const encryptedPlanTypeFromStorage = localStorage.getItem("uniqueId");
  const planType = decryptPlanType(encryptedPlanTypeFromStorage);
  console.log(planType, "Plan");
  const handleHeaderClick = (header) => {
    setSelectedHeader(header);
  };

  const filterMenuItems = (header) => {
    if (menuData[header]) {
      let isHeaderDisplayed = false;
      return menuData[header].map((menu, index) => {
        if (role === "manager" && menu.access[planType].manager === "Allow") {
          const headerElement = !isHeaderDisplayed ? (
            <u style={{ color: getTextColor(backgroundColor) }}>
              {" "}
              <h4
                onClick={() => setSelectedHeader(null)}
                style={{
                  color: getTextColor(backgroundColor),
                  fontSize: "14px",
                  marginTop: "3vh",
                  cursor: "pointer",
                }}
                key={`header-${index}`}
              >
                <ArrowLeftOutlined /> {menu.header}
              </h4>
            </u>
          ) : null;
          isHeaderDisplayed = true; // Update the flag after rendering the header

          return (
            <>
              {headerElement}
              <Menu.Item
                key={index}
                style={{ height: "12vh", marginTop: "3vh" }}
              >
                <Link
                  to={menu.componentPath}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {iconMappings[menu.icon] || <EnvironmentOutlined />}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: getTextColor(backgroundColor),
                      }}
                    >
                      {menu.menuItem}
                    </span>
                  </div>
                </Link>
              </Menu.Item>
            </>
          );
        }
        if (role === "employee" && menu.access[planType].employee === "Allow") {
          const headerElement = !isHeaderDisplayed ? (
            <u style={{ color: getTextColor(backgroundColor) }}>
              <h4
                onClick={() => setSelectedHeader(null)}
                style={{
                  color: getTextColor(backgroundColor),
                  fontSize: "14px",
                  marginTop: "3vh",
                  cursor: "pointer",
                }}
                key={`header-${index}`}
              >
                <ArrowLeftOutlined /> {menu.header}
              </h4>
            </u>
          ) : null;

          isHeaderDisplayed = true;

          return (
            <>
              {headerElement}
              <Menu.Item
                key={index}
                style={{ height: "12vh", marginTop: "3vh" }}
              >
                <Link
                  to={menu.componentPath}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {iconMappings[menu.icon] || <EnvironmentOutlined />}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: getTextColor(backgroundColor),
                      }}
                    >
                      {menu.menuItem}
                    </span>
                  </div>
                </Link>
              </Menu.Item>
            </>
          );
        }

        if (role === "hr" && menu.access[planType].hr == "Allow") {
          console.log("inside hr");

          const headerElement = !isHeaderDisplayed ? (
            <u style={{ color: getTextColor(backgroundColor) }}>
              {" "}
              <h4
                onClick={() => setSelectedHeader(null)}
                style={{
                  color: getTextColor(backgroundColor),
                  fontSize: "14px",
                  marginTop: "3vh",
                  cursor: "pointer",
                }}
                key={`header-${index}`}
              >
                <ArrowLeftOutlined /> {menu.header}
              </h4>
            </u>
          ) : null;

          isHeaderDisplayed = true; // Update the flag after rendering the header

          return (
            <>
              {headerElement}
              <Menu.Item
                key={index}
                style={{ height: "12vh", marginTop: "3vh" }}
              >
                <Link
                  to={menu.componentPath}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {iconMappings[menu.icon] || <EnvironmentOutlined />}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: getTextColor(backgroundColor),
                      }}
                    >
                      {menu.menuItem}
                    </span>
                  </div>
                </Link>
              </Menu.Item>
            </>
          );
        }
        if (role === "admin" && menu.access[planType].admin === "Allow") {
          const headerElement = !isHeaderDisplayed ? (
            <u style={{ color: getTextColor(backgroundColor) }}>
              {" "}
              <h4
                onClick={() => setSelectedHeader(null)}
                style={{
                  color: getTextColor(backgroundColor),
                  fontSize: "14px",
                  marginTop: "3vh",
                  cursor: "pointer",
                }}
                key={`header-${index}`}
              >
                <ArrowLeftOutlined /> {menu.header}
              </h4>
            </u>
          ) : null;

          isHeaderDisplayed = true; // Update the flag after rendering the header

          return (
            <>
              {headerElement}
              <Menu.Item
                key={index}
                style={{ height: "12vh", marginTop: "3vh" }}
              >
                <Link
                  to={menu.componentPath}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {iconMappings[menu.icon] || <EnvironmentOutlined />}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: getTextColor(backgroundColor),
                      }}
                    >
                      {menu.menuItem}
                    </span>
                  </div>
                </Link>
              </Menu.Item>
            </>
          );
        }
        if (
          role === "superadmin" &&
          menu.access[planType].superadmin === "Allow"
        ) {
          const headerElement = !isHeaderDisplayed ? (
            <u style={{ color: getTextColor(backgroundColor) }}>
              {" "}
              <h4
                onClick={() => setSelectedHeader(null)}
                style={{
                  color: getTextColor(backgroundColor),
                  fontSize: "14px",
                  marginTop: "3vh",
                  cursor: "pointer",
                }}
                key={`header-${index}`}
              >
                <ArrowLeftOutlined /> {menu.header}
              </h4>
            </u>
          ) : null;

          isHeaderDisplayed = true; // Update the flag after rendering the header

          return (
            <>
              {headerElement}
              <Menu.Item
                key={index}
                style={{ height: "12vh", marginTop: "3vh" }}
              >
                <Link
                  to={menu.componentPath}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {iconMappings[menu.icon] || <EnvironmentOutlined />}
                    <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                      {menu.menuItem}
                    </span>
                  </div>
                </Link>
              </Menu.Item>
            </>
          );
        } else {
          return null;
        }
      });
    }
  };

  // useEffect(()=>{
  //   fetchData();
  // });
  // const fetchData = async () => {
  //   try {
  //     const dbName = localStorage.getItem("dbName");
  //     const response = await axios.get(`${url}/admin/logo/${dbName}`);
  //     setImage1(response.data.logo);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const fetchData = async () => {
    try {
      // Replace 'dbName' with the actual database name
      const dbName = localStorage.getItem("dbName");

      // Make a GET request to the backend API
      const response = await axios.get(`${url}/get/combined/${dbName}`);

      // Update the state with the fetched data
      setImage1(response.data);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);
  // Function to toggle the sidebar's collapse state
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const handleSubMenuClick = (header, submenu) => {
    if (openSubMenuKeys.includes(submenu)) {
      console.log(submenu, "submenu");
      // If the clicked submenu is already open, close it
      setOpenSubMenuKeys([]);
    } else {
      console.log(submenu, "submenu");
      // Close all other open submenus and open the new one
      setOpenSubMenuKeys([submenu]);
    }
  };
  const encryptedRoleFromStorage = localStorage.getItem("role");
  const role = decryptRole(encryptedRoleFromStorage);
  const locationCheck = localStorage.getItem("switchState");
  const toShow = role === "manager" || role === "hr";
  const isEmployee = role === "employee" || role === "manager" || role === "hr";
  //New Menu group 18-10-2023 updated
  const isSuperAdmin = role === "superAdmin";
  const isAdmin = role === "admin";
  const isManager = role === "manager" || role === "hr";
  const isEmployees =
    role === "employee" || role === "manager" || role === "hr";
  const [menuData, setMenuData] = useState([]);
  const { color } = useContext(ColorContext);
  const colorData = localStorage.getItem("color");
  console.log(colorData, "colordata");
  const [error, setError] = useState(null);
  // const [openKeys, setOpenKeys] = useState([]);

  const handleClick = () => {
    setCollapse(!collapse);
    setOpenSubMenuKeys([]);
  };

  const backgroundColor = colorData ? colorData : "#00134D";

  const hexToHSL = (hex) => {
    let r = parseInt(hex.slice(1, 3), 16) / 255;
    let g = parseInt(hex.slice(3, 5), 16) / 255;
    let b = parseInt(hex.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return { h, s, l }; // return HSL values
  };

  // Function to determine the text color based on the background color
  const getTextColor = (bgColor) => {
    const { l } = hexToHSL(bgColor);
    return l > 0.5 ? "black" : "white";
  };

  const iconMappings1 = {
    iconName1: (
      <img
        src={require("../../images/study.png")}
        style={{ width: "5vh", height: "5vh" }}
      />
    ),
    iconName2: (
      <img
        src={require("../../images/Reports.png")}
        style={{ width: "5vh", height: "5vh" }}
      />
    ),
    iconName3: (
      <img
        src={require("../../images/approved (1).png")}
        style={{ width: "5vh", height: "5vh" }}
      />
    ),
    iconName4: (
      <img
        src={require("../../images/help-desk.png")}
        style={{ width: "5vh", height: "5vh" }}
      />
    ),
    iconName5: (
      <img
        src={require("../../images/quote-request.png")}
        style={{ width: "5vh", height: "5vh" }}
      />
    ),
  };

  // const filterMenuItems = (header) => {
  //   if (menuData[header]) {
  //     return menuData[header].map((menu, index) => (
  //       <Menu.Item key={index} style={{ height: "10vh" }}>
  //         <Link to={menu.componentPath} style={{ textDecoration: "none" }}>
  //           <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  //             {iconMappings[menu.icon] || <EnvironmentOutlined />}
  //             <span style={{ fontWeight: "bold", fontSize: "12px" }}>{menu.menuItem}</span>
  //           </div>
  //         </Link>
  //       </Menu.Item>
  //     ));
  //   } else {
  //     return null;
  //   }
  // };

  const mapTitleToIcon = (title) => {
    switch (title) {
      case "My Work":
        return "iconName1";
      case "Reports":
        return "iconName2";
      case "Approvals":
        return "iconName3";
      case "Support":
        return "iconName4";
      case "My Requests":
        return "iconName5";
      default:
        return null; // No matching icon
    }
  };

  const iconMappings = {
    UsergroupAddOutlined: (
      <UsergroupAddOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    AppstoreAddOutlined: (
      <AppstoreAddOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    HourglassOutlined: (
      <HourglassOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    BankOutlined: (
      <BankOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    AccountBookOutlined: (
      <AccountBookOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    ContactsOutlined: (
      <ContactsOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    CarryOutOutlined: (
      <CarryOutOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    ReconciliationOutlined: (
      <ReconciliationOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    ScheduleOutlined: (
      <ScheduleOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    SolutionOutlined: (
      <SolutionOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    FieldTimeOutlined: (
      <FieldTimeOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    WarningOutlined: (
      <WarningOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    FileDoneOutlined: (
      <FileDoneOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    ShopOutlined: (
      <ShopOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    FileProtectOutlined: (
      <FileProtectOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    HomeOutlined: (
      <HomeOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    EnvironmentOutlined: (
      <EnvironmentOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
    DatabaseOutlined: (
      <DatabaseOutlined
        style={{
          color: getTextColor(backgroundColor),
          fontSize: collapse ? "20px" : "",
        }}
      />
    ),
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${url}/get/menusider/${planType}`)
      .then((response) => {
        const data = response.data;
        const mapping = data.map((item) => item.access);
        console.log(data, "Access");
        setMenus(mapping);
        console.log(response.data, "datasss");
        // Map the data and include the "icon" field
        const mappedMenuData = data.map((menu) => ({
          key: menu.key,
          componentPath: menu.componentPath,
          header: menu.header,
          menuItem: menu.menuItem,
          admin: menu.admin,
          superAdmin: menu.superAdmin,
          manager: menu.manager,
          hr: menu.hr,
          employee: menu.employee,
          icon: menu.icon,
          access: menu.access, // Include the "icon" field
        }));

        // Group the menu items by their header
        const groupedMenuData = {};
        mappedMenuData.forEach((menu) => {
          if (!groupedMenuData[menu.header]) {
            groupedMenuData[menu.header] = [];
          }
          groupedMenuData[menu.header].push(menu);
        });

        // Fetch icons dynamically and map them to components
        const menuDataWithIcons = Object.keys(groupedMenuData).reduce(
          (acc, header) => {
            acc[header] = groupedMenuData[header].map((menu) => ({
              ...menu,
              icon: [menu.icon], // Map the icon identifier to the component
            }));
            return acc;
          },
          {}
        );

        setMenuData(menuDataWithIcons);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching menu items:", error);
      });
  }, []);

  // const handleMenuClick = ({ key, keyPath }) => {
  //   // If the clicked key is already open, close it; otherwise, open it
  //   setOpenKeys([key]);
  // };

  // const handleOpenChange = (newOpenKeys) => {
  //   setOpenKeys(newOpenKeys);
  // };

  return (
    <div style={{ zoom: "80%" }}>
      {loading ? (
        <div className="loaderhead">
          <Spin size="large" />
        </div>
      ) : (
        <div className="main-container">
          <Sider
            className="first-sider"
            width={collapse ? 150 : 220}
            theme="dark"
            collapse={collapse}
            onCollapse={toggleSidebar}
            style={{
              overflow: "auto",
              height: "126vh",
              width: "auto",
              left: 0,
              // top: 90,
              bottom: 0,
              backgroundColor: backgroundColor,
              position: "fixed",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to="/home">
                <img
                  src={
                    Image1 && Image1.length > 0 && Image1[0].Logo
                      ? Image1[0]?.Logo
                      : require("../../images/logo.png")
                  }
                  alt="finaran"
                  className="img-fluid"
                  style={{
                    height: "60px",
                    width: "160px",
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1vh",
              }}
            >
              {collapse ? (
                <Link
                  onClick={handleClick}
                  style={{ color: getTextColor(backgroundColor) }}
                >
                  <RightCircleOutlined
                    style={{ fontSize: "25px", marginTop: "2vh" }}
                  />
                </Link>
              ) : (
                <Link
                  onClick={handleClick}
                  style={{ color: getTextColor(backgroundColor) }}
                >
                  <LeftCircleOutlined
                    style={{ fontSize: "25px", marginTop: "2vh" }}
                  />
                </Link>
              )}
            </div>
            {collapse ? (
              <Menu
                className="sidebar--side"
                style={{
                  background: backgroundColor,
                }}
                openKeys={openSubMenuKeys}
              >
                {/* <Menu.Item>
                  <ArrowLeftOutlined style={{  color: getTextColor(backgroundColor), }} onClick={() => setSelectedHeader(null)} />
                </Menu.Item> */}

                {!selectedHeader && (
                  <Menu.Item style={{ marginTop: "5vh", height: "10vh" }}>
                    <Link to="/home" style={{ textDecoration: "none" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={require("../../images/menu.png")}
                          style={{ margin: 0, width: "5vh", height: "5vh" }}
                        />
                        <text
                          className="text-center"
                          style={{
                            fontWeight: "bold",
                            color: getTextColor(backgroundColor),
                            fontSize: "12px",
                          }}
                        >
                          Home
                        </text>
                      </div>
                    </Link>
                  </Menu.Item>
                )}
                <>
                  {selectedHeader ? (
                    <Menu style={{ background: backgroundColor }} mode="inline">
                      {filterMenuItems(selectedHeader)}
                    </Menu>
                  ) : (
                    <Menu mode="inline" style={{ background: backgroundColor }}>
                      {role === "manager" &&
                        Object.keys(menuData).map(
                          (header) =>
                            menuData[header].some(
                              (menu) =>
                                menu.access[planType].manager === "Allow"
                            ) && (
                              <Menu.Item
                                key={header}
                                style={{ marginTop: "5vh", height: "10vh" }}
                                onClick={() => handleHeaderClick(header)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {mapTitleToIcon(header) ? (
                                    iconMappings1[mapTitleToIcon(header)]
                                  ) : (
                                    <ReconciliationOutlined
                                      style={{
                                        color: getTextColor(backgroundColor),
                                      }}
                                    />
                                  )}
                                  <text
                                    style={{
                                      fontWeight: "bold",
                                      color: getTextColor(backgroundColor),
                                      fontSize: "12px",
                                    }}
                                  >
                                    {header}
                                  </text>
                                </div>
                              </Menu.Item>
                            )
                        )}
                      {role === "employee" &&
                        Object.keys(menuData).map(
                          (header) =>
                            menuData[header].some(
                              (menu) =>
                                menu.access[planType].employee === "Allow"
                            ) && (
                              <Menu.Item
                                key={header}
                                style={{ marginTop: "5vh", height: "10vh" }}
                                onClick={() => handleHeaderClick(header)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {mapTitleToIcon(header) ? (
                                    iconMappings1[mapTitleToIcon(header)]
                                  ) : (
                                    <ReconciliationOutlined
                                      style={{
                                        color: getTextColor(backgroundColor),
                                      }}
                                    />
                                  )}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: getTextColor(backgroundColor),
                                      fontSize: "12px",
                                    }}
                                  >
                                    {header}
                                  </span>
                                </div>
                              </Menu.Item>
                            )
                        )}
                      {role === "admin" &&
                        Object.keys(menuData).map(
                          (header) =>
                            menuData[header].some(
                              (menu) => menu.access[planType].admin === "Allow"
                            ) && (
                              <Menu.Item
                                key={header}
                                style={{ marginTop: "5vh", height: "10vh" }}
                                onClick={() => handleHeaderClick(header)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {mapTitleToIcon(header) ? (
                                    iconMappings1[mapTitleToIcon(header)]
                                  ) : (
                                    <ReconciliationOutlined />
                                  )}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: getTextColor(backgroundColor),
                                      fontSize: "12px",
                                    }}
                                  >
                                    {header}
                                  </span>
                                </div>
                              </Menu.Item>
                            )
                        )}
                      {role === "hr" &&
                        Object.keys(menuData).map(
                          (header) =>
                            menuData[header].some(
                              (menu) => menu.access[planType].hr === "Allow"
                            ) && (
                              <Menu.Item
                                key={header}
                                style={{ marginTop: "5vh", height: "13vh" }}
                                onClick={() => handleHeaderClick(header)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {mapTitleToIcon(header) ? (
                                    iconMappings1[mapTitleToIcon(header)]
                                  ) : (
                                    <ReconciliationOutlined
                                      style={{
                                        color: getTextColor(backgroundColor),
                                      }}
                                    />
                                  )}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: getTextColor(backgroundColor),
                                      fontSize: "12px",
                                    }}
                                  >
                                    {header}
                                  </span>
                                </div>
                              </Menu.Item>
                            )
                        )}
                      {role === "superadmin" &&
                        Object.keys(menuData).map(
                          (header) =>
                            menuData[header].some(
                              (menu) =>
                                menu.access[planType].superadmin === "Allow"
                            ) && (
                              <Menu.Item
                                key={header}
                                style={{ marginTop: "5vh", height: "10vh" }}
                                onClick={() => handleHeaderClick(header)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {mapTitleToIcon(header) ? (
                                    iconMappings1[mapTitleToIcon(header)]
                                  ) : (
                                    <ReconciliationOutlined
                                      style={{
                                        color: getTextColor(backgroundColor),
                                      }}
                                    />
                                  )}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: getTextColor(backgroundColor),
                                      fontSize: "12px",
                                    }}
                                  >
                                    {header}
                                  </span>
                                </div>
                              </Menu.Item>
                            )
                        )}
                    </Menu>
                  )}
                </>
              </Menu>
            ) : (
              <Menu
                mode="inline"
                className="sidebar--side"
                style={{
                  marginTop: "18px",
                  background: backgroundColor,
                  color: getTextColor(backgroundColor),
                }}
                openKeys={openSubMenuKeys}
              >
                <Menu.Item
                  icon={
                    <HomeOutlined
                      style={{ color: getTextColor(backgroundColor) }}
                    />
                  }
                >
                  <Link to="/home" style={{ textDecoration: "none" }}>
                    <span
                      style={{
                        fontweight: "500",
                        color: getTextColor(backgroundColor),
                      }}
                    >
                      Home
                    </span>
                  </Link>
                </Menu.Item>

                <>
                  {role === "manager" &&
                    Object.keys(menuData).map(
                      (header) =>
                        // Check if there are manager-allowed items under this header
                        menuData[header].some(
                          (menu) => menu.access[planType].manager === "Allow"
                        ) && (
                          <SubMenu
                            key={`sub${header}`}
                            title={
                              <span
                                style={{
                                  color: getTextColor(backgroundColor),
                                  fontWeight: 500,
                                }}
                              >
                                {header}
                              </span>
                            }
                            icon={
                              <Tooltip title={header}>
                                {
                                  <ReconciliationOutlined
                                    style={{
                                      color: getTextColor(backgroundColor),
                                    }}
                                  />
                                }
                              </Tooltip>
                            }
                            onTitleClick={() =>
                              handleSubMenuClick(header, `sub${header}`)
                            }
                          >
                            {menuData[header].map(
                              (menu) =>
                                menu.access[planType].manager === "Allow" && (
                                  <Menu.Item
                                    key={menu.key}
                                    icon={
                                      iconMappings[menu.icon] || (
                                        <EnvironmentOutlined />
                                      )
                                    }
                                    style={{
                                      color: getTextColor(backgroundColor),
                                    }}
                                  >
                                    <Link
                                      to={menu.componentPath}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: 500,
                                          color: getTextColor(backgroundColor),
                                        }}
                                      >
                                        {menu.menuItem}
                                        {/* You might need to change how you render icons */}
                                        {/* <Icon type={menu.icon} /> */}
                                      </span>
                                    </Link>
                                  </Menu.Item>
                                )
                            )}
                          </SubMenu>
                        )
                    )}
                  {role === "hr" &&
                    Object.keys(menuData).map(
                      (header) =>
                        // Check if there are manager-allowed items under this header
                        menuData[header].some(
                          (menu) => menu.access[planType].hr === "Allow"
                        ) && (
                          <SubMenu
                            key={`sub${header}`}
                            title={
                              <span
                                style={{
                                  color: getTextColor(backgroundColor),
                                  fontWeight: 500,
                                }}
                              >
                                {header}
                              </span>
                            }
                            icon={
                              <Tooltip title={header}>
                                {
                                  <ReconciliationOutlined
                                    style={{
                                      color: getTextColor(backgroundColor),
                                    }}
                                  />
                                }
                              </Tooltip>
                            }
                            onTitleClick={() =>
                              handleSubMenuClick(header, `sub${header}`)
                            }
                            popupClassName="submenu-popup"
                          >
                            {menuData[header].map(
                              (menu) =>
                                menu.access[planType].hr === "Allow" && (
                                  <Menu.Item
                                    key={menu.key}
                                    icon={
                                      iconMappings[menu.icon] || (
                                        <EnvironmentOutlined />
                                      )
                                    }
                                  >
                                    <Link
                                      to={menu.componentPath}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: 500,
                                          color: getTextColor(backgroundColor),
                                        }}
                                      >
                                        {menu.menuItem}
                                      </span>
                                    </Link>
                                  </Menu.Item>
                                )
                            )}
                          </SubMenu>
                        )
                    )}
                  {role === "employee" &&
                    Object.keys(menuData).map(
                      (header) =>
                        // Check if there are manager-allowed items under this header
                        menuData[header].some(
                          (menu) => menu.access[planType].employee === "Allow"
                        ) && (
                          <SubMenu
                            key={`sub${header}`}
                            title={
                              <span
                                style={{
                                  color: getTextColor(backgroundColor),
                                  fontWeight: 500,
                                }}
                              >
                                {header}
                              </span>
                            }
                            icon={
                              <Tooltip title={header}>
                                {
                                  <ReconciliationOutlined
                                    style={{
                                      color: getTextColor(backgroundColor),
                                    }}
                                  />
                                }
                              </Tooltip>
                            }
                            onTitleClick={() =>
                              handleSubMenuClick(header, `sub${header}`)
                            }
                          >
                            {menuData[header].map(
                              (menu) =>
                                menu.access[planType].employee === "Allow" && (
                                  <Menu.Item
                                    key={menu.key}
                                    icon={
                                      iconMappings[menu.icon] || (
                                        <EnvironmentOutlined />
                                      )
                                    }
                                  >
                                    <Link
                                      to={menu.componentPath}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: 500,
                                          color: getTextColor(backgroundColor),
                                        }}
                                      >
                                        {menu.menuItem}
                                        {/* You might need to change how you render icons */}
                                        {/* <Icon type={menu.icon} /> */}
                                      </span>
                                    </Link>
                                  </Menu.Item>
                                )
                            )}
                          </SubMenu>
                        )
                    )}
                  {role === "admin" &&
                    Object.keys(menuData).map(
                      (header) =>
                        // Check if there are manager-allowed items under this header
                        menuData[header].some(
                          (menu) => menu.access[planType].admin === "Allow"
                        ) && (
                          <SubMenu
                            key={`sub${header}`}
                            title={
                              <span
                                style={{
                                  color: getTextColor(backgroundColor),
                                  fontWeight: 500,
                                }}
                              >
                                {header}
                              </span>
                            }
                            icon={
                              <Tooltip title={header}>
                                {
                                  <ReconciliationOutlined
                                    style={{
                                      color: getTextColor(backgroundColor),
                                    }}
                                  />
                                }
                              </Tooltip>
                            }
                            onTitleClick={() =>
                              handleSubMenuClick(header, `sub${header}`)
                            }
                          >
                            {menuData[header].map(
                              (menu) =>
                                menu.access[planType].admin === "Allow" && (
                                  <Menu.Item
                                    key={menu.key}
                                    icon={
                                      iconMappings[menu.icon] || (
                                        <EnvironmentOutlined />
                                      )
                                    }
                                  >
                                    <Link
                                      to={menu.componentPath}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: 500,
                                          color: getTextColor(backgroundColor),
                                        }}
                                      >
                                        {menu.menuItem}
                                        {/* You might need to change how you render icons */}
                                        {/* <Icon type={menu.icon} /> */}
                                      </span>
                                    </Link>
                                  </Menu.Item>
                                )
                            )}
                          </SubMenu>
                        )
                    )}
                </>
              </Menu>
            )}
          </Sider>
        </div>
      )}
    </div>
  );
};
