import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, Checkbox, Radio, message, Row, Col, Layout, Card, Popconfirm, Modal, Menu } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import axios from 'axios';
import './selfdata.css';
const { Sider, Content } = Layout;
const EducationalForm = ({ onSave, initialValues, onNext }) => {
    const [form] = Form.useForm();
    const [showJsonData, setShowJsonData] = useState(false);
    const [jsonData, setJsonData] = useState(null);


    const toggleJsonData = () => {
        setShowJsonData(!showJsonData);
    };

    const onJsonDataClose = () => {
        setShowJsonData(false);
    };

    const onJsonDataButtonClick = () => {
        const formData = form.getFieldsValue();
        setJsonData(JSON.stringify(formData, null, 2));
        toggleJsonData();
    };

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);


    const onFinish = (values) => {
        console.log('Received values:', values);
        onSave(values);
        message.success('Saved your Details, You can move to the next step');
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div>
            <Layout style={{ minHeight: '60vh' }}>
                <Sider trigger={null} collapsible style={{ backgroundColor: "white", width: "100%", minWidth: "100%" }}>
                    <Menu mode="inline" defaultSelectedKeys={['1']} style={{ borderRight: 0, marginTop: "80%" }}
                        onClick={({ key }) => {
                            if (key === '1') scrollToSection('pg-details');
                            if (key === '2') scrollToSection('ug-details');
                            if (key === '3') scrollToSection('hsc-details');
                        }}>
                        <Menu.Item key="1">PG</Menu.Item>
                        <Menu.Item key="2">UG</Menu.Item>
                        <Menu.Item key="3">HSC</Menu.Item>
                    </Menu>
                </Sider>
                <Content style={{ padding: 24, margin: 0 }}>
                    <div style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', overflowX: 'hidden' }}>
                        <Form form={form} name="educationalRecords" onFinish={onFinish} onFinishFailed={onFinishFailed} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                            <Row gutter={16}>
                                <Col span={16}>
                                    <Card id="pg-details" title="PG Record" style={{ marginBottom: 16, width: "130%", marginLeft: "8%" }}>
                                        <Form.List name="pgRecords">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, value, name, fieldKey, ...restField }) => (
                                                        <div key={key}>
                                                            <Form.Item {...restField} label="College Name" name={[name, 'collegeName']} fieldKey={[fieldKey, 'collegeName']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter College Name' }]}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Address & Contact No" name={[name, 'collegeAddress']} fieldKey={[fieldKey, 'collegeAddress']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter College Address and Contact Telephone' }]}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="University Name" name={[name, 'universityName']} fieldKey={[fieldKey, 'universityName']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter University Name, Address, and Contact Telephone' }]}>
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item label="City/State/Country" name={[name, 'universityCity']} fieldKey={[fieldKey, 'universityCity']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter University City/State/Country' }]}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="From To (month / year)" name={[name, 'eduDateRange']} fieldKey={[fieldKey, 'eduDateRange']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter From  To (month / year)' }]}>
                                                                <DatePicker.RangePicker style={{ width: "60%" }} />
                                                            </Form.Item>

                                                            <Form.Item {...restField} label="Registration/Roll No." name={[name, 'registrationRollNo']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'registrationRollNo']}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Program" name={[name, 'program']} fieldKey={[fieldKey, 'program']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item">
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Type of degree" name={[name, 'degreeType']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'degreeType']}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Subject Major" name={[name, 'subjectMajor']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'subjectMajor']}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Full Time/Part Time" name={[name, 'fullTimePartTime']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'fullTimePartTime']}>
                                                                <Select>
                                                                    <Select.Option value="Full Time">Full Time</Select.Option>
                                                                    <Select.Option value="Part Time">Part Time</Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Graduation date (month / year)" name={[name, 'graduationDate']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'graduationDate']}>
                                                                <DatePicker.MonthPicker style={{ width: "60%" }} />
                                                            </Form.Item>

                                                            <Form.Item {...restField} label="Educated in Overseas?" name={[name, 'educatedOverseas']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'educatedOverseas']}>
                                                                <Radio.Group onChange={(e) => form.setFieldsValue({ [`educationalRecords[${name}].educatedOverseas`]: e.target.value === 'Yes' })}>
                                                                    <Radio value="Yes">Yes</Radio>
                                                                    <Radio value="No">No</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {form.getFieldValue(['pgRecords', name, 'educatedOverseas']) === 'Yes' && (
                                                                <>
                                                                    <Form.Item {...restField} label="Unique identification no. at Overseas (SSN/TIN)" name={[name, 'overseasUniqueId']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'overseasUniqueId']}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    <Form.Item {...restField} label="Given Name at Overseas" name={[name, 'givenNameOverseas']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'givenNameOverseas']}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                </>
                                                            )}

                                                            <Form.Item wrapperCol={{ span: 24 }}>
                                                                <Popconfirm title="Are you sure you want to remove this educational record?"
                                                                    onConfirm={() => { console.log('Form values:', form.getFieldsValue()); remove(name); }}
                                                                    okText="Yes" cancelText="No">
                                                                    <Button type="danger" icon={<MinusOutlined />}> Remove Educational Record </Button>
                                                                </Popconfirm>
                                                            </Form.Item>
                                                        </div>
                                                    ))}
                                                    <Form.Item wrapperCol={{ span: 24 }}>
                                                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>Add Educational Record</Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Card>
                                    <Card id="ug-details" title="UG Record" style={{ marginBottom: 16, width: "130%", marginLeft: "8%" }}>
                                        <Form.List name="ugRecords">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, value, name, fieldKey, ...restField }) => (
                                                        <div key={key}>
                                                            <Form.Item {...restField} label="College Name" name={[name, 'collegeName']} fieldKey={[fieldKey, 'collegeName']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter College Name' }]}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Address & Contact No" name={[name, 'collegeAddress']} fieldKey={[fieldKey, 'collegeAddress']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter College Address and Contact Telephone' }]}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="University Name" name={[name, 'universityName']} fieldKey={[fieldKey, 'universityName']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter University Name, Address, and Contact Telephone' }]}>
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item label="City/State/Country" name={[name, 'universityCity']} fieldKey={[fieldKey, 'universityCity']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter University City/State/Country' }]}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="From To (month / year)" name={[name, 'eduDateRange']} fieldKey={[fieldKey, 'eduDateRange']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter From  To (month / year)' }]}>
                                                                <DatePicker.RangePicker style={{ width: "60%" }} />
                                                            </Form.Item>

                                                            <Form.Item {...restField} label="Registration/Roll No." name={[name, 'registrationRollNo']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'registrationRollNo']}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Program" name={[name, 'program']} fieldKey={[fieldKey, 'program']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item">
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Type of degree" name={[name, 'degreeType']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'degreeType']}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Subject Major" name={[name, 'subjectMajor']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'subjectMajor']}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Full Time/Part Time" name={[name, 'fullTimePartTime']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'fullTimePartTime']}>
                                                                <Select>
                                                                    <Select.Option value="Full Time">Full Time</Select.Option>
                                                                    <Select.Option value="Part Time">Part Time</Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Graduation date (month / year)" name={[name, 'graduationDate']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'graduationDate']}>
                                                                <DatePicker.MonthPicker style={{ width: "60%" }} />
                                                            </Form.Item>

                                                            <Form.Item {...restField} label="Educated in Overseas?" name={[name, 'educatedOverseas']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'educatedOverseas']}>
                                                                <Radio.Group onChange={(e) => form.setFieldsValue({ [`educationalRecords[${name}].educatedOverseas`]: e.target.value === 'Yes' })}>
                                                                    <Radio value="Yes">Yes</Radio>
                                                                    <Radio value="No">No</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {form.getFieldValue(['ugRecords', name, 'educatedOverseas']) === 'Yes' && (
                                                                <>
                                                                    <Form.Item {...restField} label="Unique identification no. at Overseas (SSN/TIN)" name={[name, 'overseasUniqueId']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'overseasUniqueId']}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    <Form.Item {...restField} label="Given Name at Overseas" name={[name, 'givenNameOverseas']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'givenNameOverseas']}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                </>
                                                            )}

                                                            <Form.Item wrapperCol={{ span: 24 }}>
                                                                <Popconfirm title="Are you sure you want to remove this educational record?"
                                                                    onConfirm={() => { console.log('Form values:', form.getFieldsValue()); remove(name); }}
                                                                    okText="Yes" cancelText="No">
                                                                    <Button type="danger" icon={<MinusOutlined />}> Remove Educational Record </Button>
                                                                </Popconfirm>
                                                            </Form.Item>
                                                        </div>
                                                    ))}
                                                    <Form.Item wrapperCol={{ span: 24 }}>
                                                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>Add Educational Record</Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Card>
                                    <Card id="hsc-details" title="HSC Record" style={{ marginBottom: 16, width: "130%", marginLeft: "8%" }}>
                                        <Form.List name="hscRecords">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, value, name, fieldKey, ...restField }) => (
                                                        <div key={key}>
                                                            <Form.Item {...restField} label="School Name" name={[name, 'schoolName']} fieldKey={[fieldKey, 'collegeName']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter College Name' }]}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Address & Contact No" name={[name, 'schoolAddress']} fieldKey={[fieldKey, 'collegeAddress']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter College Address and Contact Telephone' }]}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item label="City/State/Country" name={[name, 'schoolCity']} fieldKey={[fieldKey, 'universityCity']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter University City/State/Country' }]}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="From To (month / year)" name={[name, 'eduDateRange']} fieldKey={[fieldKey, 'eduDateRange']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" rules={[{ required: true, message: 'Please enter From  To (month / year)' }]}>
                                                                <DatePicker.RangePicker style={{ width: "60%" }} />
                                                            </Form.Item>

                                                            <Form.Item {...restField} label="Registration/Roll No." name={[name, 'registrationRollNo']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'registrationRollNo']}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Board of Method" name={[name, 'degreeType']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'degreeType']}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Subject Major" name={[name, 'subjectMajor']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'subjectMajor']}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item {...restField} label="Full Time/Part Time" name={[name, 'fullTimePartTime']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'fullTimePartTime']}>
                                                                <Select>
                                                                    <Select.Option value="Full Time">Full Time</Select.Option>
                                                                    <Select.Option value="Part Time">Part Time</Select.Option>
                                                                </Select>
                                                            </Form.Item>

                                                            <Form.Item {...restField} label="Educated in Overseas?" name={[name, 'educatedOverseas']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'educatedOverseas']}>
                                                                <Radio.Group onChange={(e) => form.setFieldsValue({ [`educationalRecords[${name}].educatedOverseas`]: e.target.value === 'Yes' })}>
                                                                    <Radio value="Yes">Yes</Radio>
                                                                    <Radio value="No">No</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {form.getFieldValue(['hscRecords', name, 'educatedOverseas']) === 'Yes' && (
                                                                <>
                                                                    <Form.Item {...restField} label="Unique identification no. at Overseas (SSN/TIN)" name={[name, 'overseasUniqueId']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'overseasUniqueId']}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    <Form.Item {...restField} label="Given Name at Overseas" name={[name, 'givenNameOverseas']} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }} className="custom-form-item" fieldKey={[fieldKey, 'givenNameOverseas']}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                </>
                                                            )}

                                                            <Form.Item wrapperCol={{ span: 24 }}>
                                                                <Popconfirm title="Are you sure you want to remove this educational record?"
                                                                    onConfirm={() => { console.log('Form values:', form.getFieldsValue()); remove(name); }}
                                                                    okText="Yes" cancelText="No">
                                                                    <Button type="danger" icon={<MinusOutlined />}> Remove Educational Record </Button>
                                                                </Popconfirm>
                                                            </Form.Item>
                                                        </div>
                                                    ))}
                                                    <Form.Item wrapperCol={{ span: 24 }}>
                                                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>Add Educational Record</Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Card>
                                </Col>
                            </Row>

                            {/* Save button */}
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button type="primary" htmlType="submit">Save All</Button>
                            </Form.Item>

                            <Modal title="JSON Data" open={showJsonData} onCancel={onJsonDataClose}
                                footer={[<Button key="close" onClick={onJsonDataClose}>Close</Button>]}>
                                <pre>{jsonData}</pre>
                            </Modal>
                        </Form>
                    </div>
                </Content>
            </Layout>
        </div >
    );
};

export default EducationalForm;