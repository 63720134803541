import { React, useState, useEffect } from "react";
import "./Resume Home.css";
import { Link } from "react-router-dom";
import { Steps } from "antd";
const { Step } = Steps;

function Resumehome() {
  const [greeting, setGreeting] = useState("Good morning");
  const [currentStep, setCurrentStep] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const getGreeting = () => {
      const currentHour = new Date().getHours();

      if (currentHour < 12) {
        setGreeting("Good morning");
      } else if (currentHour >= 12 && currentHour < 18) {
        setGreeting("Good afternoon");
      } else {
        setGreeting("Good evening");
      }
    };

    getGreeting();
  }, []);

  return (
    <div
      className="Resume"
      style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}
    >
      <main className="main-content">
        <div className="left-section">
          <h2>
            {greeting}, {user.displayName}.
          </h2>
          <p>Upload your resume to tailor it to a job description.</p>

          {currentStep === 0 ?  (
            <>
              <h4>Quick Links</h4>
              <div className="quick-links">
                <div className="quick-link">
                  <h5>Upload Resume</h5>
                  <Link to="/home/ResumeUpload">
                    <div className="link-icon">
                      <img
                        src={require("../../images/upload-file.png")}
                        alt="upload-file"
                        className="quick-link-image"
                      />
                    </div>
                  </Link>
                  <p>Upload your resume to the database for analysis.</p>
                </div>
                <div className="quick-link">
                  <h5>Job-Specific Resume</h5>
                  <Link to="/home/resumeSearch">
                    <div className="link-icon">
                      <img
                        src={require("../../images/cv-objective.png")}
                        alt="cv-objective"
                        className="quick-link-image"
                      />
                    </div>
                  </Link>

                  <p>
                    Analyze your resume and match it against job descriptions.
                    Receive a score and recommendations for suitable roles.
                  </p>
                </div>
              </div>
            </>
          ) : currentStep === 1 ? (
            <div className="step-screenshot">
              <img
                src={require("../../images/upload-resume.png")}
                alt="upload-resume"
                className="step-screenshot-image"
                style={{top:"2vh",width: "70vh", height: "70vh", objectFit: "contain" }}
              />
            </div>
          ) : currentStep === 2 ? (
            <div className="step-screenshot">
              <img
                src={require("../../images/upload-resume.png")}
                alt="review-information"
                className="step-screenshot-image"
                
                style={{top:"2vh",width: "70vh", height: "70vh", objectFit: "contain" }}
                />
            </div>
          ) : currentStep === 3 ? (
            <div className="step-screenshot">
              <img
                src={require("../../images/upload-resume.png")}
                alt="submit-resume"
                className="step-screenshot-image"
                style={{ top:"2vh",width: "70vh", height: "70vh", objectFit: "contain" }}
              />
            </div>
          ) : currentStep === 4 ? (
            <div className="step-screenshot">
              <img
                src={require("../../images/upload-resume.png")}
                alt="get-job-matches"
                className="step-screenshot-image"
                style={{ top:"2vh",width: "70vh", height: "70vh", objectFit: "contain" }}
              />
            </div>
          ) : null}
        </div>

        <div className="right-section">
          <h4>Optimize Your Job Applications</h4>
          <p>
            Utilize our features to extract key information from your resume,
            ensuring a better match with job descriptions. Get insights and
            recommendations to enhance your application.
          </p>

          <h4>Steps to Get Started</h4>
          <Steps
            direction="vertical"
            current={currentStep}
            onChange={(step) => setCurrentStep(step)}
          >
            <Step
              title="Upload your resume"
              description="Upload your resume to our system."
            />
            <Step
              title="Review your information"
              description="Confirm that all your details are accurate."
            />
            <Step
              title="Submit your resume"
              description="Send your resume to our database."
            />
            <Step
              title="Get job matches"
              description="Receive insights on jobs relevant to your profile."
            />
          </Steps>

          <button className="pro-btn">Try Now for Free</button>
        </div>
      </main>
    </div>
  );
}

export default Resumehome;
