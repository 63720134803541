import { Avatar, Button, Card, Col, Divider, Row, Tabs } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../../url/url";
import moment from "moment";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const { TabPane } = Tabs;

const Recruitmentdash = () => {
  const users = JSON.parse(localStorage.getItem("user"));
  const [jobs, setJobs] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const dbName = localStorage.getItem("dbName");
  const [visibleRange, setVisibleRange] = useState(0); // Start with the first two applicants
  const navigate = useNavigate();
  const todayDate = moment().format("YYYY-MM-DD");

  // Filter jobs to include only those whose latest history date matches today's date
  const filteredJobs = jobs.filter((job) => {
    const jobDate = job.history?.[0]?.date
      ? moment(job.history[0].date).format("YYYY-MM-DD")
      : null;
    return jobDate === todayDate;
  });

  console.log(filteredJobs, "filjob");

  const handleNext = () => {
    setVisibleRange((prev) => (prev + 4) % filteredJobs.length);
  };

  const handlePrevious = () => {
    setVisibleRange(
      (prev) => (prev - 4 + filteredJobs.length) % filteredJobs.length
    );
  };

  useEffect(() => {
    fetchJobApplicationDetails();
    fetchJobList();
  }, []);

  const fetchJobList = async () => {
    try {
      const response = await axios.get(`${url}/get/jobs/${dbName}`);
      const filteredJobList = response.data.filter(
        (job) => job.status !== "Closed"
      );
      setJobList(filteredJobList);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const fetchJobApplicationDetails = async () => {
    const response = await axios.get(
      `${url}/get/jobApplicationdetails/${dbName}`
    );
    setJobs(response.data);
    console.log("response", response.data);
  };
  const applicantCountMap = jobs.reduce((acc, job) => {
    const role = job.role;
    acc[role] = (acc[role] || 0) + 1;
    return acc;
  }, {});

  const today = moment();
  const startOfWeek = today.clone().startOf("week");
  const endOfWeek = today.clone().endOf("week");
  const startOfNextWeek = startOfWeek.clone().add(1, "week");
  const endOfNextWeek = endOfWeek.clone().add(1, "week");
  const startOfMonth = today.clone().startOf("month");
  const endOfMonth = today.clone().endOf("month");

  const jobsThisWeek = jobs.filter((job) => {
    const jobDate = moment(job.history[0]?.date);
    return jobDate.isBetween(startOfWeek, endOfWeek, "day", "[]");
  });

  const jobsNextWeek = jobs.filter((job) => {
    const jobDate = moment(job.history[0]?.date);
    return jobDate.isBetween(startOfNextWeek, endOfNextWeek, "day", "[]");
  });

  const jobsThisMonth = jobs.filter((job) => {
    const jobDate = moment(job.history[0]?.date);
    return jobDate.isBetween(startOfMonth, endOfMonth, "day", "[]");
  });

  const handleReviewClick = (item) => {
    const encodedJobId = encodeURIComponent(item.jobId); 
    navigate(`/home/ApplicantDetail/${encodedJobId}`, {
      state: { candidateId: item.candidateId, role: item.role },
    });
  };
  
  const renderJobs = (jobsToRender) =>
    jobsToRender.map((item) => (
      <Row key={item._id} style={{ marginBottom: "10px" }}>
        <Col span={20}>
          <Row>
            <Col sm={7} xs={12} lg={5}>
              <img
                style={{ width: "12vh", height: "12vh", marginTop: "-10px" }}
                src={require("../../images/malegenric.jpg")}
                alt="Candidate"
              />
            </Col>
            <Col xs={12} sm={14} lg={16}>
              <span>{item.candidateName}</span>
              <span
                style={{
                  color: "#7e7d82",
                  marginLeft: "2vh",
                  fontWeight: "bold",
                }}
              >
                {item.jobId}
              </span>
              <p style={{ marginLeft: "-5px", color: "#6566c9" }}>
                {item.candidateId}
              </p>
            </Col>
          </Row>
          <h6 style={{ color: "#271b63", fontSize: "14px" }}>
            Applied for <b>{item.role}</b>
          </h6>
        </Col>
        <Col span={2}>
          <Button
            type="primary"
            style={{ top: "4vh", background: "#322387" }}
            onClick={() =>  handleReviewClick(item)}
          >
            Review
          </Button>
        </Col>
      </Row>
    ));



  return (
    <div>
      <Row justify="space-between">
        <Col span={12}>
          <Card
            style={{
              background: "#160f5c",
              borderColor: "#cabdfc",
              boxShadow: "0 3px 3px #7459e3",
            }}
          >
            <Row>
              <Col span={15}>
                <div style={{ color: "white" }}>
                  <span>Hello {users.displayName}</span>
                  <p style={{ marginLeft: "-6px" }}>
                    You have {filteredJobs?.length} new{" "}
                    {filteredJobs?.length === 1 ? "applicant" : "applicants"}{" "}
                    today
                  </p>

                  <h6 style={{ marginLeft: "-6px", marginTop: "-12px" }}>
                    Review it
                  </h6>
                </div>
              </Col>
              <Col span={2}>
                <img
                  src={require("../../images/recruitment.png")}
                  style={{ width: "30vh", height: "15vh", objectFit: "cover" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={11}>
          <Card
            style={{
              borderColor: "#cabdfc",
              boxShadow: "0 3px 3px #7459e3",
              height: "65vh",
            }}
          >
            <Row justify="space-between">
              <h6
                className="text-center"
                style={{
                  marginTop: "-15px",
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "#271b63",
                }}
              >
                New Applicants
              </h6>
              <span
                style={{
                  marginTop: "-15px",
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "#271b63",
                }}
              >
                Today
              </span>
            </Row>

            {/* Show only two applicants based on visibleRange */}
            {filteredJobs.length === 0 ? (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <img
                  src={require("../../images/no applicants.jpg")}
                  alt="No applicants available"
                  style={{ width: "30vh", height: "30vh", objectFit: "cover" }}
                />
                <p style={{ color: "#6b6b70", fontSize: "14px" }}>
                  No applicants available for today
                </p>
              </div>
            ) : (
              filteredJobs
                .slice(visibleRange, visibleRange + 4)
                .map((item, index) => {
                  const initials = `${
                    item.candidateName[0]
                  }${item.candidateName.slice(-1)}`.toUpperCase();
                  const lightColors = [
                    "#001f3f",
                    "#2f4f4f",
                    "#333333",
                    "#007acc",
                    "#556b2f",
                    "#696969",
                    "#dc143c",
                  ];
                  const backgroundColor =
                    lightColors[(visibleRange + index) % lightColors.length];

                  return (
                    <div
                      key={item.candidateId}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        backgroundColor: "#f1f1f1",
                        borderRadius: "40px",
                        padding: "5px 10px",
                        border: "1px solid #ddd",
                        background: "#e9eff2",
                        width: "80vh",
                      }}
                    >
                      <Avatar
                        size={50}
                        style={{
                          backgroundColor,
                          color: "#fff",
                          marginRight: "10px",
                        }}
                      >
                        {initials}
                      </Avatar>
                      <div>
                        <span style={{ fontWeight: "bold", color: "#2f2b75" }}>
                          {item.candidateName}
                        </span>
                        <p
                          style={{
                            margin: 0,
                            marginLeft: "-5px",
                            fontSize: "13px",
                            color: "#6b6b70",
                          }}
                        >
                          Applied for {item.role}
                        </p>
                      </div>
                    </div>
                  );
                })
            )}
            {filteredJobs.length > 4 && (
              <Row justify="space-between">
                <Button
                  type="link"
                  onClick={handlePrevious}
                  disabled={visibleRange === 0}
                  style={{ color: "#271b63", fontWeight: "bold" }}
                >
                  &larr; Previous
                </Button>
                <Button
                  type="link"
                  onClick={handleNext}
                  disabled={visibleRange + 4 >= filteredJobs.length}
                  style={{ color: "#271b63", fontWeight: "bold" }}
                >
                  Next &rarr;
                </Button>
              </Row>
            )}
          </Card>
        </Col>
        <Col span={12} style={{ marginTop: "-220px" }}>
          <Card
            style={{
              borderColor: "#cabdfc",
              boxShadow: "0 3px 3px #7459e3",
              height: "38vh",
            }}
          >
            <h6
              style={{
                color: "#271b63",
                marginTop: "-15px",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              Quick links
            </h6>
            <Row style={{ gap: "2vh", marginTop: "3px" }}>
              <Col span={11}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Card
                    style={{
                      borderColor: "#cabdfc",
                      boxShadow: "0 3px 3px #7459e3",
                      width: "12vh",
                      height: "11vh",
                      borderRadius: "20vh",
                    }}
                  >
                    <Link to="/home/ResumeUpload">
                      <img
                        src={require("../../images/uploadcv.png")}
                        alt="upload-file"
                        className="quick-link-image"
                        style={{
                          width: "9vh",
                          height: "9vh",
                          objectFit: "cover",
                          marginTop: "-20px",
                          marginLeft: "-15px",
                        }}
                      />
                    </Link>
                  </Card>
                </div>
                <h6
                  className="text-center"
                  style={{
                    fontSize: "14px",
                    marginTop: "5px",
                    fontWeight: "bold",
                    color: "#2c20ab",
                  }}
                >
                  Upload Resume
                </h6>

                <p
                  className="text-center"
                  style={{ color: "#6b6b70", marginTop: "-10px" }}
                >
                  Upload your resume to the database for analysis.
                </p>
              </Col>
              <Col span={11}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Card
                    style={{
                      borderColor: "#cabdfc",
                      boxShadow: "0 3px 3px #7459e3",
                      width: "12vh",
                      height: "11vh",
                      borderRadius: "20vh",
                    }}
                  >
                    <Link to="/home/resumeSearch">
                      <img
                        src={require("../../images/search.png")}
                        alt="cv-objective"
                        className="quick-link-image"
                        style={{
                          width: "9vh",
                          height: "9vh",
                          objectFit: "cover",
                          marginTop: "-20px",
                          marginLeft: "-15px",
                        }}
                      />
                    </Link>
                  </Card>
                </div>
                <h6
                  className="text-center"
                  style={{
                    fontSize: "14px",
                    marginTop: "5px",
                    fontWeight: "bold",
                    color: "#2c20ab",
                  }}
                >
                  Job-Specific Resume
                </h6>
                <p
                  className="text-center"
                  style={{ color: "#6b6b70", marginTop: "-10px" }}
                >
                  Analyze your resume and get job match scores.
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <br />
      <Row justify="space-between">
        <Col span={12}>
          <Card
            style={{
              borderColor: "#cabdfc",
              boxShadow: "0 3px 3px #7459e3",
              height: "55vh",
              maxHeight: "55vh",
              overflowY: "scroll",
            }}
          >
            <h6
              className="text-center"
              style={{
                marginTop: "-15px",
                fontWeight: "bold",
                fontSize: "15px",
                color: "#271b63",
              }}
            >
              Interview Queue
            </h6>
            <Tabs defaultActiveKey="1" onChange={setActiveTabKey} type="card">
              <TabPane tab="This week" key="1">
                {activeTabKey === "1" && renderJobs(jobsThisWeek)}
              </TabPane>
              <TabPane tab="Next week" key="2">
                {activeTabKey === "2" && renderJobs(jobsNextWeek)}
              </TabPane>
              <TabPane tab="This month" key="3">
                {activeTabKey === "3" && renderJobs(jobsThisMonth)}
              </TabPane>
            </Tabs>
          </Card>
        </Col>
        <Col span={11}>
          <Card
            style={{
              borderColor: "#cabdfc",
              boxShadow: "0 3px 3px #7459e3",
              maxHeight: "55vh",
              overflowY: "scroll",
            }}
          >
            <h6
              className="text-center"
              style={{
                marginTop: "-15px",
                fontWeight: "bold",
                fontSize: "15px",
                color: "#271b63",
              }}
            >
              Jobs List
            </h6>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #1f1c75",
                paddingBottom: "10px",
                marginBottom: "10px",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "#271b63",
                }}
              >
                Job Title
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "#271b63",
                }}
              >
                Applications
              </span>
            </div>
            {jobList.map((item, index) => {
              const applicantCount = applicantCountMap[item.role] || 0; // Get the count of applicants for the role
              return (
                <Row
                  key={index}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Col>
                    <span style={{ color: "#4c4ea6" }}>{item.role}</span>
                  </Col>
                  <Col style={{ marginRight: "5vh" }}>
                    <span style={{ color: "#4c4ea6" }}>{applicantCount}</span>
                    <img
                      src={require("../../images/growth.png")}
                      style={{ width: "3vh", height: "3vh" }}
                    />
                  </Col>
                </Row>
              );
            })}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Recruitmentdash;
