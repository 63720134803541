import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Layout, Radio, Divider, Select, Row, Col, Checkbox, Modal, message } from 'antd';  // Import Modal
import axios from 'axios';
import { url } from "../../url/url";
const { Option } = Select;

const Applicability = ({ onPublish, allValues,setAllValues,onPrevious }) => {
  const [showContent, setShowContent] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const employee = JSON.parse(localStorage.getItem("user"));
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();
  const [designation, setDesignation] = useState([]);
  const [status, setStatus] = useState('');
  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };


  const handlePrevious = () => {
    const updatedAllValues = { ...allValues };
    setAllValues(updatedAllValues);
    onPrevious(); // Call the previous step
  };

  const handleCheckboxChange = (e) => {
    setShowContent(e.target.checked);
    setAllValues((prev) => ({ ...prev, emailNotification: e.target.checked })); // Save checkbox state
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setAllValues((prev) => ({ ...prev, selectedOption: e.target.value })); // Save selected option to state
  };

  const handleSubmit = (values) => {
    let updatedValues = { ...values, status };

    if (selectedOption === 'All employees') {
      updatedValues.applicability = ['All Employees'];
    } else if (selectedValue === 'Designation' || selectedValue === 'Users') {
      updatedValues.applicability = values.applicability || [];
    }

    // Save applicability to allValues
    setAllValues((prev) => ({
      ...prev,
      applicability: updatedValues.applicability,
    }));

    console.log(updatedValues, "onPublish");

    onPublish(updatedValues);
  };
  const [selectedOption, setSelectedOption] = useState('Enable Comments');


  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const dbName = localStorage.getItem("dbName");
    const response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
    const activeUsers = response.data.filter((user) => user.empStatus !== "inactive");

    // Extract unique designations and user names
    const uniqueDesignations = [...new Set(activeUsers.map((item) => item.empDesignation))];
    const userNames = activeUsers.map((item) => item.displayName);

    setDesignation(uniqueDesignations);
    setUsers(userNames);
  };



  const handlePublishConfirm = () => {
    Modal.confirm({
      title: 'Do you want to publish this survey?',
      content: 'If you click Yes, the survey will be published and the status will be set as "Ongoing". If you click No, the status will be saved as "Draft".',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        setStatus('Ongoing');  // Set status as "Ongoing"
        form.submit();         // Trigger form submission manually
        message.success('Survey published successfully');
      },
      onCancel: () => {
        setStatus('Draft');    // Set status as "Draft"
        form.submit();         // Trigger form submission manually
        message.info('Survey saved as draft');
      },
    });
  };
  return (
    <div>
      <Layout style={{ minHeight: '90vh' }}>
        <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <Form name="basic" onFinish={handleSubmit} form={form} layout="vertical">
            {/* Applicability Card */}
            <Card style={{
              marginTop: "1vh",
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",
            }}>
              <h5 style={{ textAlign: "left" }}>Applicability</h5>
              <p style={{ textAlign: "left" }}>Select the target set of employees</p>
              <Divider />
              <Form.Item label="Select applicability" name="applicable" rules={[{ required: true, message: "Select applicability" },]}>
                <Radio.Group value={selectedOption} onChange={handleOptionChange}>
                  <Radio value="All employees">All Employees</Radio>
                  <Radio value="Specific employees">Specific Employees</Radio>
                </Radio.Group>
              </Form.Item>

              {selectedOption === 'All employees' && (
                <Card style={{ backgroundColor: '#bfbfbf', height: '8vh' }}>
                  <p style={{ textAlign: 'left', marginTop: '-2vh' }}>Survey is applicable to all employees of the organization</p>
                </Card>
              )}

              {selectedOption === 'Specific employees' && (
                <>
                  <Form.Item name="specificEmployees" label="Select specific employees" rules={[{ required: true, message: "Select specific employees" },]}>
                    <Select onChange={handleSelectChange}>
                      <Option value="Designation">Designation</Option>
                      <Option value="Users">Users</Option>
                    </Select>
                  </Form.Item>
                </>
              )}

              {selectedValue === 'Designation' && (
                <Form.Item name="applicability" label="Select Designation" rules={[{ required: true, message: "Select Designation" },]}>
                  <Select placeholder="Select a designation">
                    {designation.map((designation) => (
                      <Option key={designation} value={designation}>
                        {designation}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              {selectedValue === 'Users' && (
                <Form.Item name="applicability" label="Select Users" rules={[{ required: true, message: "Select Users" },]}>
                  <Select mode="multiple" placeholder="Select users">
                    {users.map((user) => (
                      <Option key={user} value={user}>
                        {user}
                      </Option> 
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Card>

            {/* Notification Card */}
            <br />
            <Card style={{
              marginTop: "1vh",
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",
            }}>
              <p style={{ textAlign: "left", fontSize: "20px" }}>Notification</p>
              <p style={{ textAlign: "left" }}>Notify applicable employees when a new survey has been added</p>
              <Divider />
              <Form.Item name="notification" valuePropName="checked" style={{ textAlign: "left" }}>
                <Checkbox onChange={handleCheckboxChange}>Enable email notification</Checkbox>
              </Form.Item>
              {showContent && (
                <Card title="Email message" style={{ textAlign: "left" }}>
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>You are invited to participate in {employee.displayName} survey</p>
                  <Divider />
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>Hello {employee.displayName},</p>
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>We have a new survey, your participation in the survey is of great importance to us.</p>
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>Please take a moment to complete the survey.</p>
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>Survey Details</p>
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>Survey Name: {employee.displayName}</p>
                  <p style={{ textAlign: "left", padding: 0, fontWeight: "lighter" }}>Survey Period: {allValues?.setup?.period[0]?.$d ? new Date(allValues.setup.period[0].$d).toLocaleDateString() : 'N/A'} - {allValues?.setup?.period[1]?.$d ? new Date(allValues.setup.period[1].$d).toLocaleDateString() : 'N/A'}</p>
                </Card>
              )}
            </Card>

            <br />
            <Card style={{
              marginTop: "1vh",
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",
            }}>
              <h5>Publish</h5>
              <p style={{ textAlign: "left" }}>Review and publish the survey you have created</p>
              <Divider />
              <Card>
                <Row gutter={[16, 16]}>
                  <Col span={4}>
                    <p>Questions</p>
                    <p>{allValues.questions?.length}</p>
                  </Col>
                  <Col span={2}><Divider type='vertical' style={{ height: "12vh" }} /></Col>
                  <Col span={4}>
                    <p>Start Date</p>
                    <p>{allValues?.setup?.period[0]?.$d ? new Date(allValues.setup.period[0].$d).toLocaleDateString() : 'N/A'}</p>
                  </Col>
                  <Col span={2}><Divider type='vertical' style={{ height: "12vh" }} /></Col>
                  <Col span={4}>
                    <p>End Date</p>
                    <p>{allValues?.setup?.period[1]?.$d ? new Date(allValues.setup.period[1].$d).toLocaleDateString() : 'N/A'}</p>
                  </Col>
                  <Col span={2}><Divider type='vertical' style={{ height: "12vh" }} /></Col>
                  <Col span={6}>
                    <p>Repeats</p>
                    <p>{allValues?.setup?.surveyRepeats}</p>
                  </Col>
                </Row>
              </Card>
            </Card>

            {/* Single Publish Button */}
            <Form.Item style={{ textAlign: "center" }}>
              <Button onClick={handlePrevious} style={{ marginRight: "10px" }}>
                Previous
              </Button>
              <Button type="primary" onClick={handlePublishConfirm}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </div>
  );
};

export default Applicability;
