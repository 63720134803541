import React, { useState, useEffect, useRef } from "react";
import { Button, message, Steps, theme, notification } from "antd";
import EducationalForm from "./Educational";
import Employment from "./Employment";
import SelfData from "./SelfData";
import DocUpload from "./DocUpload";
import Signature from "./Signature";
import jsPDF from "jspdf";
import { url } from "../../url/url";
import axios from "axios";

const BGVStepMethod = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [formValues, setFormValues] = useState({
    basic: {},
    educational: [],
    employment: {},
    documents: {},
    acknowledgment: {},
  });
  const [jsonId, setJsonId] = useState("");
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const handleSave = (values, step) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [step]: values,
    }));
    console.log("Handlesave");
  };

  const handleSaveEMp = (values) => {
    const employmentRecords = values.EmploymentRecords || [];
    generatePDF(employmentRecords);
    message.success("PDF generated successfully");
  };

  useEffect(() => {
    if (jsonId) {
      console.log("Updated jsonId:", jsonId);
    }
  }, [jsonId]);

  const steps = [
    {
      title: "Personal Details",
      content: (
        <SelfData
          onSave={(values) => handleSave(values, "basic")}
          initialValues={formValues.basic}
        />
      ),
    },
    {
      title: "Educational Details",
      content: (
        <EducationalForm
          onSave={(values) => handleSave(values, "educational")}
          initialValues={formValues.educational}
        />
      ),
    },

    {
      title: "Employment Details",
      content: (
        <Employment
          onSave={(values) => handleSave(values, "employment")}
          initialValues={formValues.employment}
        />
      ),
    },
    {
      title: "Mandatory Documents",
      content: <DocUpload jsonId={jsonId} />,
    },
    {
      title: "Acknowledgment",
      content: (
        <Signature
          onSave={(values) => handleSave(values, "signature")}
          initialValues={formValues.signature}
        />
      ),
    },
  ];

  const handleEdit = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const email = localStorage.getItem("email");
      const value = "Form submitted";
      // const res = await axios.put(
      //   `${url}/api/updatestatus/${email}/${dbName}`,
      //   { value }
      // );
      // console.log("res.data.status", res.data.status);
      notification.success({ message: "BGV Initiated" });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const next = async () => {
    const currentStepKey = getCurrentStepKey();
    const currentStepData = formValues[currentStepKey];
    console.log(currentStepKey, "currentStepKey");
    if (currentStepData && Object.keys(currentStepData).length > 0) {
      // if (current === 4) {
      //   try {
      //     const dbName = localStorage.getItem("dbName");
      //     // Generate PDF
      //     const generatepdf = generatePDF(formValues);
      //     console.log(generatepdf, "generatePDF");
      //     const res = axios.post(
      //       `${url}/api/pdf/${displayName}/${dbName}`,
      //       generatePDF
      //     );
      //     setLoading(false);
      //     setCurrent(current + 1);
      //     handleEdit();
      //   } catch (error) {
      //     console.error("Error saving form:", error);
      //     console.log(
      //       "Detailed error response from the server:",
      //       error.response
      //     );
      //     message.error("Error saving form. Please try again.");
      //   }
      // } else {
      setCurrent(current + 1);
      // }
    } else {
      message.warning(
        `Please fill in the details and click on Save for ${steps[current].title}`
      );
    }
  };

  const done = async () => {
    try {
      // Generate PDF
      const generatepdf = generatePDF(formValues);
      console.log(generatepdf, "generatePDF");
      const dbName = localStorage.getItem("dbName");
      const displayName = `${formValues.basic.FName} ${formValues.basic.LName}`;
      const res = axios.post(
        `${url}/api/pdf/${displayName}/${dbName}`,
        { generatepdf } 
      );

      // // Reset form values and JSON ID
      setFormValues({
        basic: {},
        educational: [],
        employment: {},
        documents: {},
        acknowledgment: {},
      });
      setJsonId("");
      setCurrent(0);

      // Show success message
      message.success("Form submitted successfully!");
    } catch (error) {
      console.error("Error generating PDF:", error);
      message.error("Error generating PDF. Please try again.");
    }
  };

  const prev = () => {
    if (current > 0) {
      const currentStepKey = getCurrentStepKey();
      setFormValues((prevValues) => ({
        ...prevValues,
        [currentStepKey]: formValues[currentStepKey],
      }));
      setCurrent(current - 1);
    }
  };

  const isLastStep = current === steps.length - 1;
  const isDoneEnabled = isLastStep; // Simplified condition

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const getCurrentStepKey = () => {
    return Object.keys(formValues)[current];
  };

  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 26,
  };

  const generatePDF = (formValues) => {
    const doc = new jsPDF();
    console.log("formValues", formValues);

    // Helper function to format the date range in dd/mm/yyyy
    const formatDateRange = (range) => {
      if (!range || range.length < 2) return "";
      const [start, end] = range;
      return `${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}`;
    };

    // Set PDF title
    doc.setFontSize(14);

    const margin = 10; // Define margin
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const contentWidth = pageWidth - 2 * margin; // Ensure content fits within margins

    // Set colors
    const borderColor = [111, 143, 175]; // RGB
    const headerBackgroundColor = [115, 147, 179];
    const labelBackgroundColor = [200, 220, 255]; // Light blue for labels
    const labelValueLineColor = [111, 143, 175]; // Color for vertical line between label and value

    // Helper functions
    const drawBorder = () => {
      doc.setLineWidth(1);
      doc.setDrawColor(...borderColor);
      doc.rect(margin, margin, contentWidth, pageHeight - 2 * margin);
    };

    const fillHeaderBackground = () => {
      doc.setFillColor(...headerBackgroundColor);
      doc.rect(margin, margin, contentWidth, 25 - margin, "F");
    };

    const drawHeader = (title, positionY) => {
      doc.setFontSize(16);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0); // Black color for text
      doc.text(title, pageWidth / 2, positionY, { align: "center" });
    };

    const drawSeparatorLine = (yPosition) => {
      doc.setLineWidth(0.5); // Thin line
      doc.line(margin, yPosition, pageWidth - margin, yPosition);
    };

    const drawVerticalLine = (xPosition, startY, endY) => {
      doc.setLineWidth(0.5); // Thin line for vertical separation
      doc.line(xPosition, startY, xPosition, endY);
    };

    // Helper function with modifications for label positioning
    const drawLabelWithBackgroundAndLine = (
      label,
      value,
      position,
      isSecondColumn = false
    ) => {
      const labelX = position.x; // X position of the label
      const valueX = isSecondColumn ? labelX + 60 : labelX + 55; // Adjusted distance for the value
      const yPosition = position.y;

      // Fill background color for the entire label column (left part)
      doc.setFillColor(...labelBackgroundColor);
      doc.rect(labelX, yPosition - 9, 57, 13, "F"); // Full background width for label

      // Set font size and style for both label and value
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");

      // Measure the width of the label text to center it in the label box
      const labelTextWidth = doc.getTextWidth(label);
      const labelCenterX = labelX + 57 / 2 - labelTextWidth / 2; // Center align the label

      // Draw the label text, center-aligned
      doc.setTextColor(0, 0, 0); // Black text
      doc.text(label, labelCenterX, yPosition); // Add centered label text

      // Draw the value text next to the label, adjusted closer to the vertical line
      doc.text(value, valueX + 5, yPosition); // Value text

      // Draw horizontal line after the label-value pair if necessary
      if (!isSecondColumn) {
        drawSeparatorLine(yPosition + 5); // Only draw the separator if it's not a second-column item
      }
    };

    // Draw the page content
    drawBorder();
    fillHeaderBackground();
    drawHeader("Basic Details", 20);
    drawSeparatorLine(25);

    // Define positions for each label and value (removed "SSN No" entry)
    const basicDetails = [
      {
        label: "First Name:",
        value: formValues.basic?.FName || "N/A",
        position: { x: margin + 2, y: 35 },
      },
      {
        label: "Middle Name:",
        value: formValues.basic?.MName || "N/A",
        position: { x: margin + 2, y: 50 },
      },
      {
        label: "Last Name:",
        value: formValues.basic?.LName || "N/A",
        position: { x: margin + 2, y: 65 },
      },
      {
        label: "Father's First Name:",
        value: formValues.basic?.FatherFName || "N/A",
        position: { x: margin + 2, y: 80 },
      },
      {
        label: "Father's Last Name:",
        value: formValues.basic?.FatherLName || "N/A",
        position: { x: margin + 2, y: 95 },
      },
      // { label: "SSN No:", value: formValues.basic?.SSN || 'N/A', position: { x: margin + 2, y: 110 } }, // Removed
      {
        label: "Nationality:",
        value: formValues.basic?.Nationality || "N/A",
        position: { x: margin + 2, y: 110 },
      }, // Moved up
      {
        label: "Date of Birth:",
        value: formValues.basic?.DOB?.$d
          ? new Date(formValues.basic.DOB.$d).toLocaleDateString()
          : "N/A",
        position: { x: margin + 95, y: 110 },
        isSecondColumn: true,
      }, // Adjusted position
      {
        label: "StandardFormat(Name):",
        value: formValues.basic?.StandardFormat || "N/A",
        position: { x: margin + 2, y: 125 },
      },
      {
        label: "Gender:",
        value: formValues.basic?.Gender || "N/A",
        position: { x: margin + 2, y: 140 },
      },
      {
        label: "Marital Status:",
        value: formValues.basic?.MaritalStatus || "N/A",
        position: { x: margin + 95, y: 140 },
        isSecondColumn: true,
      }, // Adjusted position closer to vertical line
      {
        label: "Changed Name:",
        value: formValues.basic?.ChangedFormat || "No",
        position: { x: margin + 2, y: 155 },
      },
      {
        label: "Date of Change:",
        value: formValues.basic?.DChanged
          ? new Date(formValues.basic.DChanged).toLocaleDateString()
          : "N/A",
        position: { x: margin + 95, y: 155 },
        isSecondColumn: true,
      }, // Adjusted position closer to vertical line
    ];

    // Loop through basic details and add them to the page
    for (const detail of basicDetails) {
      drawLabelWithBackgroundAndLine(
        detail.label,
        detail.value,
        detail.position,
        detail.isSecondColumn
      );
    }

    // Draw vertical lines for separation
    drawVerticalLine(margin + 95, 100, 115); //
    drawVerticalLine(margin + 152, 100, 115);

    drawVerticalLine(margin + 95, 130, 135); // // Adjusted positions
    drawVerticalLine(margin + 152, 130, 135);

    drawVerticalLine(margin + 95, 135, 160); // Adjusted positions
    drawVerticalLine(margin + 152, 135, 160);

    drawVerticalLine(margin + 60, 20, 175);
    drawVerticalLine(margin + 60, 175, 285);

    // Draw 'If Yes, Enter Name' on the same line as the value
    const enterNameY = 175; // Y position for the 'If Yes, Enter Name' line

    const ifYesValue = formValues.basic?.ChangedName || "N/A"; // Pulling the correct value

    // Draw the label, centered
    doc.setFillColor(...labelBackgroundColor);
    doc.rect(margin + 2, enterNameY - 9, 57, 13, "F"); // Background for label

    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0, 0, 0);
    doc.text("If Yes, Enter Name:", margin + 12, enterNameY); // Centered label

    // Draw the value text near the vertical line
    doc.text(ifYesValue, margin + 62, enterNameY); // Value text on the same line

    // Draw 'Current Address and Contact Details' header
    const addressHeaderY = enterNameY + 20; // Y position for the new header
    doc.setFillColor(...headerBackgroundColor);
    doc.rect(margin, addressHeaderY - 10, contentWidth, 15, "F"); // Background for the new header
    doc.setFontSize(14);
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(0, 0, 0);
    doc.text(
      "Current Address and Contact Details",
      pageWidth / 2,
      addressHeaderY,
      { align: "center" }
    );

    // Define positions for the address and contact details
    const contactDetails = [
      {
        label: "Address & Postal Code:",
        value: formValues.basic?.Address || "N/A",
        position: { x: margin + 2, y: addressHeaderY + 15 },
      },
      {
        label: "Landmark:",
        value: formValues.basic?.LandMark || "N/A",
        position: { x: margin + 2, y: addressHeaderY + 30 },
      },
      {
        label: "LandLine/Telephone No:",
        value: formValues.basic?.LandLine || "N/A",
        position: { x: margin + 2, y: addressHeaderY + 45 },
      },
      {
        label: "Email:",
        value: formValues.basic?.BDEmail || "N/A",
        position: { x: margin + 2, y: addressHeaderY + 60 },
      },
      {
        label: "Mobile Number:",
        value: formValues.basic?.BDMobile || "N/A",
        position: { x: margin + 2, y: addressHeaderY + 75 },
      },
    ];

    // Loop through contact details and add them to the page
    for (const detail of contactDetails) {
      drawLabelWithBackgroundAndLine(
        detail.label,
        detail.value,
        detail.position,
        detail.isSecondColumn
      );
    }

    // Draw final separator line
    drawSeparatorLine(addressHeaderY + 80); // Adjusted position

    doc.addPage();

    //Educational  Details
    // PG Educational  Details
    const pgmargin = 10; // Define margin
    const pgpageWidth = doc.internal.pageSize.width;
    const pgpageHeight = doc.internal.pageSize.height;
    const pgcontentWidth = pgpageWidth - 2 * pgmargin; // Ensure content fits within margins

    // Set colors
    const pgborderColor = [111, 143, 175]; // RGB
    const pgheaderBackgroundColor = [115, 147, 179];
    const pglabelBackgroundColor = [200, 220, 255]; // Light blue for labels
    const pglabelValueLineColor = [111, 143, 175]; // Color for vertical line between label and value

    // Helper functions
    const pgdrawBorder = () => {
      doc.setLineWidth(1);
      doc.setDrawColor(...pgborderColor);
      doc.rect(pgmargin, pgmargin, pgcontentWidth, pgpageHeight - 2 * pgmargin);
    };

    const pgfillHeaderBackground = () => {
      doc.setFillColor(...pgheaderBackgroundColor);
      doc.rect(pgmargin, pgmargin, pgcontentWidth, 20, "F"); // Reduced height for header
    };

    const pgdrawHeader = (title, positionY) => {
      doc.setFontSize(14); // Adjusted font size for the header
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0); // Black color for text
      doc.text(title, pgpageWidth / 2, positionY, { align: "center" }); // Center aligned
    };

    const pgdrawVerticalLine = (xPosition, startY, endY) => {
      doc.setLineWidth(0.5); // Thin line for vertical separation
      doc.line(xPosition, startY, xPosition, endY);
    };

    // Function to draw table row with label and value in two columns, with horizontal lines extending across the page
    const pgdrawTableRowWithLines = (label, value, yPosition, rowHeight) => {
      const labelX = pgmargin + 1; // X position for the label
      const labelWidth = 63; // Fixed width for label column
      const valueX = labelX + labelWidth + 10; // X position for the value column
      const lineEndX = pageWidth - pgmargin; // End X position for horizontal lines

      // Ensure label and value are strings
      const labelText = label ? String(label) : ""; // Default to empty string if undefined
      const valueText = value ? String(value) : ""; // Default to empty string if undefined

      // Ensure yPosition is a valid number
      const yPos = typeof yPosition === "number" ? yPosition : 0;

      // Draw background for label cell
      doc.setFillColor(...pglabelBackgroundColor);
      doc.rect(labelX, yPos - rowHeight + 4, labelWidth, rowHeight, "F"); // Background for label cell

      // Set font and draw the label
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor(0, 0, 0); // Black color for label text
      doc.text(labelText, labelX + 2, yPos); // Draw label text inside the label cell

      // Set font and draw the value in the value column
      doc.text(valueText, valueX, yPos);

      // Draw horizontal line extending across the width of the page
      doc.setLineWidth(0.5);
      doc.setDrawColor(...pglabelValueLineColor);
      doc.line(pgmargin, yPos + 3, lineEndX, yPos + 3); // Horizontal line across the row
    };

    pgdrawBorder();
    pgfillHeaderBackground();

    // Educational Details
    if (
      formValues.educational &&
      formValues.educational.pgRecords &&
      formValues.educational.pgRecords.length > 0
    ) {
      const headerYPosition = 30; // Y position for header

      // Draw the table for each PG record
      formValues.educational.pgRecords.forEach((pgRecord, index) => {
        if (index > 0) {
          doc.addPage(); // Adds a new page for every PG record after the first one
          pgdrawBorder(); // Draw border on new page
          pgfillHeaderBackground(); // Draw the header background on new page
        }

        // Define row height
        const rowHeight = 15; // Define row height as per your requirements

        // Define starting position for labels directly below the header
        const yPositionStart = headerYPosition + 11; // Adjust this if the header height changes

        // Add header for each PG record
        pgdrawHeader(
          `Educational Details - PG Record ${index + 1}`,
          pgmargin + 10
        );

        // Set Y position for the first label directly after the header
        let labelYPosition = yPositionStart; // Start labels immediately below the header

        const fromToDate = pgRecord.eduDateRange
          ? formatDateRange(pgRecord.eduDateRange)
          : "";
        const graduationDate = pgRecord.graduationDate
          ? pgRecord.graduationDate.format("DD/MM/YYYY")
          : "";

        // Define labels and values
        const labels = [
          { label: "College Name", value: pgRecord.collegeName || "N/A" },
          { label: "College Address", value: pgRecord.collegeAddress || "N/A" },
          { label: "University Name", value: pgRecord.universityName || "N/A" },
          {
            label: "City/State/Country",
            value: pgRecord.universityCity || "N/A",
          },
          { label: "From-To", value: fromToDate || "N/A" },
          {
            label: "Registration/Roll No.",
            value: pgRecord.registrationRollNo || "N/A",
          },
          { label: "Program", value: pgRecord.program || "N/A" },
          { label: "Degree Type", value: pgRecord.degreeType || "N/A" },
          { label: "Subject Major", value: pgRecord.subjectMajor || "N/A" },
          {
            label: "Full Time/Part Time",
            value: pgRecord.fullTimePartTime || "N/A",
          },
          { label: "Graduation Date", value: graduationDate || "N/A" },
          {
            label: "Educated Overseas",
            value: pgRecord.educatedOverseas || "N/A",
          },
        ];

        // Loop through labels and values, and draw them in a table format with lines
        labels.forEach((item, i) => {
          const yPosition = labelYPosition + i * rowHeight; // Use rowHeight for spacing
          pgdrawTableRowWithLines(item.label, item.value, yPosition, rowHeight);
        });

        // Determine the Y position for additional fields based on whether 'Educated Overseas' is Yes or No
        const overseasYPosition = labelYPosition + labels.length * rowHeight;

        // Draw additional fields if 'Educated Overseas' is Yes, otherwise show N/A
        if (pgRecord.educatedOverseas === "Yes") {
          pgdrawTableRowWithLines(
            "Overseas Unique ID",
            pgRecord.overseasUniqueId,
            overseasYPosition,
            rowHeight
          );
          pgdrawTableRowWithLines(
            "Given Name at Overseas",
            pgRecord.givenNameOverseas,
            overseasYPosition + rowHeight,
            rowHeight
          );
        } else {
          pgdrawTableRowWithLines(
            "Overseas Unique ID",
            "N/A",
            overseasYPosition,
            rowHeight
          );
          pgdrawTableRowWithLines(
            "Given Name at Overseas",
            "N/A",
            overseasYPosition + rowHeight,
            rowHeight
          );
        }

        pgdrawVerticalLine(pgmargin + 65, 30, 240);
      });

      doc.addPage();
    } else {
      doc.text("No PG Records found.", pgmargin, 30);
    }

    // UG Educational  Details
    const ugmargin = 10; // Define margin
    const ugpageWidth = doc.internal.pageSize.width;
    const ugpageHeight = doc.internal.pageSize.height;
    const ugcontentWidth = ugpageWidth - 2 * ugmargin; // Ensure content fits within margins

    // Set colors
    const ugborderColor = [111, 143, 175]; // RGB
    const ugheaderBackgroundColor = [115, 147, 179];
    const uglabelBackgroundColor = [200, 220, 255]; // Light blue for labels
    const uglabelValueLineColor = [111, 143, 175]; // Color for vertical line between label and value

    // Helper functions
    const ugdrawBorder = () => {
      doc.setLineWidth(1);
      doc.setDrawColor(...ugborderColor);
      doc.rect(ugmargin, ugmargin, ugcontentWidth, ugpageHeight - 2 * ugmargin);
    };

    const ugfillHeaderBackground = () => {
      doc.setFillColor(...ugheaderBackgroundColor);
      doc.rect(ugmargin, ugmargin, ugcontentWidth, 20, "F"); // Reduced height for header
    };

    const ugdrawHeader = (title, positionY) => {
      doc.setFontSize(14); // Adjusted font size for the header
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0); // Black color for text
      doc.text(title, ugpageWidth / 2, positionY, { align: "center" }); // Center aligned
    };

    const ugdrawVerticalLine = (xPosition, startY, endY) => {
      doc.setLineWidth(0.5); // Thin line for vertical separation
      doc.line(xPosition, startY, xPosition, endY);
    };

    // Function to draw table row with label and value in two columns, with horizontal lines extending across the page
    const ugdrawTableRowWithLines = (label, value, yPosition, rowHeight) => {
      const labelX = ugmargin + 1; // X position for the label
      const labelWidth = 63; // Fixed width for label column
      const valueX = labelX + labelWidth + 10; // X position for the value column
      const lineEndX = pageWidth - ugmargin; // End X position for horizontal lines

      // Ensure label and value are strings
      const labelText = label ? String(label) : ""; // Default to empty string if undefined
      const valueText = value ? String(value) : ""; // Default to empty string if undefined

      // Ensure yPosition is a valid number
      const yPos = typeof yPosition === "number" ? yPosition : 0;

      // Draw background for label cell
      doc.setFillColor(...uglabelBackgroundColor);
      doc.rect(labelX, yPos - rowHeight + 4, labelWidth, rowHeight, "F"); // Background for label cell

      // Set font and draw the label
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor(0, 0, 0); // Black color for label text
      doc.text(labelText, labelX + 2, yPos); // Draw label text inside the label cell

      // Set font and draw the value in the value column
      doc.text(valueText, valueX, yPos);

      // Draw horizontal line extending across the width of the page
      doc.setLineWidth(0.5);
      doc.setDrawColor(...uglabelValueLineColor);
      doc.line(ugmargin, yPos + 3, lineEndX, yPos + 3); // Horizontal line across the row
    };

    ugdrawBorder();
    ugfillHeaderBackground();

    // Educational Details
    if (
      formValues.educational &&
      formValues.educational.ugRecords &&
      formValues.educational.ugRecords.length > 0
    ) {
      const headerYPosition = 30; // Y position for header

      // Draw the table for each PG record
      formValues.educational.ugRecords.forEach((ugRecord, index) => {
        if (index > 0) {
          doc.addPage(); // Adds a new page for every PG record after the first one
          ugdrawBorder(); // Draw border on new page
          ugfillHeaderBackground(); // Draw the header background on new page
        }

        // Define row height
        const rowHeight = 15; // Define row height as per your requirements

        // Define starting position for labels directly below the header
        const yPositionStart = headerYPosition + 11; // Adjust this if the header height changes

        // Add header for each PG record
        ugdrawHeader(
          `Educational Details - UG Record ${index + 1}`,
          ugmargin + 10
        );

        // Set Y position for the first label directly after the header
        let labelYPosition = yPositionStart; // Start labels immediately below the header

        const fromToDate = ugRecord.eduDateRange
          ? formatDateRange(ugRecord.eduDateRange)
          : "";
        const graduationDate = ugRecord.graduationDate
          ? ugRecord.graduationDate.format("DD/MM/YYYY")
          : "";

        // Define labels and values
        const labels = [
          { label: "College Name", value: ugRecord.collegeName || "N/A" },
          { label: "College Address", value: ugRecord.collegeAddress || "N/A" },
          { label: "University Name", value: ugRecord.universityName || "N/A" },
          {
            label: "City/State/Country",
            value: ugRecord.universityCity || "N/A",
          },
          { label: "From-To", value: fromToDate || "N/A" },
          {
            label: "Registration/Roll No.",
            value: ugRecord.registrationRollNo || "N/A",
          },
          { label: "Program", value: ugRecord.program || "N/A" },
          { label: "Degree Type", value: ugRecord.degreeType || "N/A" },
          { label: "Subject Major", value: ugRecord.subjectMajor || "N/A" },
          {
            label: "Full Time/Part Time",
            value: ugRecord.fullTimePartTime || "N/A",
          },
          { label: "Graduation Date", value: graduationDate || "N/A" },
          {
            label: "Educated Overseas",
            value: ugRecord.educatedOverseas || "N/A",
          },
        ];

        // Loop through labels and values, and draw them in a table format with lines
        labels.forEach((item, i) => {
          const yPosition = labelYPosition + i * rowHeight; // Use rowHeight for spacing
          ugdrawTableRowWithLines(item.label, item.value, yPosition, rowHeight);
        });

        // Determine the Y position for additional fields based on whether 'Educated Overseas' is Yes or No
        const overseasYPosition = labelYPosition + labels.length * rowHeight;

        // Draw additional fields if 'Educated Overseas' is Yes, otherwise show N/A
        if (ugRecord.educatedOverseas === "Yes") {
          ugdrawTableRowWithLines(
            "Overseas Unique ID",
            ugRecord.overseasUniqueId,
            overseasYPosition,
            rowHeight
          );
          ugdrawTableRowWithLines(
            "Given Name at Overseas",
            ugRecord.givenNameOverseas,
            overseasYPosition + rowHeight,
            rowHeight
          );
        } else {
          ugdrawTableRowWithLines(
            "Overseas Unique ID",
            "N/A",
            overseasYPosition,
            rowHeight
          );
          ugdrawTableRowWithLines(
            "Given Name at Overseas",
            "N/A",
            overseasYPosition + rowHeight,
            rowHeight
          );
        }

        ugdrawVerticalLine(ugmargin + 65, 30, 240);
      });
      doc.addPage();
    } else {
      doc.text("No UG Records found.", ugmargin, 30);
    }

    // HSC Educational  Details
    const hscmargin = 10; // Define margin
    const hscpageWidth = doc.internal.pageSize.width;
    const hscpageHeight = doc.internal.pageSize.height;
    const hsccontentWidth = hscpageWidth - 2 * hscmargin; // Ensure content fits within margins

    // Set colors
    const hscborderColor = [111, 143, 175]; // RGB
    const hscheaderBackgroundColor = [115, 147, 179];
    const hsclabelBackgroundColor = [200, 220, 255]; // Light blue for labels
    const hsclabelValueLineColor = [111, 143, 175]; // Color for vertical line between label and value

    // Helper functions
    const hscdrawBorder = () => {
      doc.setLineWidth(1);
      doc.setDrawColor(...hscborderColor);
      doc.rect(
        hscmargin,
        hscmargin,
        hsccontentWidth,
        hscpageHeight - 2 * hscmargin
      );
    };

    const hscfillHeaderBackground = () => {
      doc.setFillColor(...hscheaderBackgroundColor);
      doc.rect(hscmargin, hscmargin, hsccontentWidth, 20, "F"); // Reduced height for header
    };

    const hscdrawHeader = (title, positionY) => {
      doc.setFontSize(14); // Adjusted font size for the header
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0); // Black color for text
      doc.text(title, hscpageWidth / 2, positionY, { align: "center" }); // Center aligned
    };

    const hscdrawVerticalLine = (xPosition, startY, endY) => {
      doc.setLineWidth(0.5); // Thin line for vertical separation
      doc.line(xPosition, startY, xPosition, endY);
    };

    // Function to draw table row with label and value in two columns, with horizontal lines extending across the page
    const hscdrawTableRowWithLines = (label, value, yPosition, rowHeight) => {
      const labelX = hscmargin + 1; // X position for the label
      const labelWidth = 63; // Fixed width for label column
      const valueX = labelX + labelWidth + 10; // X position for the value column
      const lineEndX = pageWidth - hscmargin; // End X position for horizontal lines

      // Draw background for label cell
      doc.setFillColor(...hsclabelBackgroundColor);
      doc.rect(labelX, yPosition - rowHeight + 4, labelWidth, rowHeight, "F"); // Background for label cell

      // Set font and draw the label
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor(0, 0, 0); // Black color for label text
      doc.text(label, labelX + 2, yPosition); // Draw label text inside the label cell

      // Set font and draw the value in the value column
      doc.text(value, valueX, yPosition);

      // Draw horizontal line extending across the width of the page
      doc.setLineWidth(0.5);
      doc.setDrawColor(...hsclabelValueLineColor);
      doc.line(hscmargin, yPosition + 3, lineEndX, yPosition + 3); // Horizontal line across the row
    };

    hscdrawBorder();
    hscfillHeaderBackground();

    // Educational Details
    if (
      formValues.educational &&
      formValues.educational.hscRecords &&
      formValues.educational.hscRecords.length > 0
    ) {
      const headerYPosition = 30; // Y position for header

      // Draw the table for each PG record
      formValues.educational.hscRecords.forEach((hscRecord, index) => {
        if (index > 0) {
          doc.addPage(); // Adds a new page for every PG record after the first one
          hscdrawBorder(); // Draw border on new page
          hscfillHeaderBackground(); // Draw the header background on new page
        }

        // Define row height
        const rowHeight = 15; // Define row height as per your requirements

        // Define starting position for labels directly below the header
        const yPositionStart = headerYPosition + 11; // Adjust this if the header height changes

        // Add header for each PG record
        hscdrawHeader(`Educational Details - HSC Record`, hscmargin + 10);

        // Set Y position for the first label directly after the header
        let labelYPosition = yPositionStart; // Start labels immediately below the header

        const fromToDate = hscRecord.eduDateRange
          ? formatDateRange(hscRecord.eduDateRange)
          : "";

        // Define labels and values
        const labels = [
          { label: "School Name", value: hscRecord.schoolName || "N/A" },
          { label: "School Address", value: hscRecord.schoolAddress || "N/A" },
          { label: "City/State/Country", value: hscRecord.schoolCity || "N/A" },
          { label: "From-To", value: fromToDate || "N/A" },
          {
            label: "Registration/Roll No.",
            value: hscRecord.registrationRollNo || "N/A",
          },
          { label: "Board of Method", value: hscRecord.degreeType || "N/A" },
          { label: "Subject Major", value: hscRecord.subjectMajor || "N/A" },
          {
            label: "Full Time/Part Time",
            value: hscRecord.fullTimePartTime || "N/A",
          },
          {
            label: "Educated Overseas",
            value: hscRecord.educatedOverseas || "N/A",
          },
        ];

        // Loop through labels and values, and draw them in a table format with lines
        labels.forEach((item, i) => {
          const yPosition = labelYPosition + i * rowHeight; // Use rowHeight for spacing
          hscdrawTableRowWithLines(
            item.label,
            item.value,
            yPosition,
            rowHeight
          );
        });

        // Determine the Y position for additional fields based on whether 'Educated Overseas' is Yes or No
        const overseasYPosition = labelYPosition + labels.length * rowHeight;

        // Draw additional fields if 'Educated Overseas' is Yes, otherwise show N/A
        if (hscRecord.educatedOverseas === "Yes") {
          hscdrawTableRowWithLines(
            "Overseas Unique ID",
            hscRecord.overseasUniqueId,
            overseasYPosition,
            rowHeight
          );
          hscdrawTableRowWithLines(
            "Given Name at Overseas",
            hscRecord.givenNameOverseas,
            overseasYPosition + rowHeight,
            rowHeight
          );
        } else {
          hscdrawTableRowWithLines(
            "Overseas Unique ID",
            "N/A",
            overseasYPosition,
            rowHeight
          );
          hscdrawTableRowWithLines(
            "Given Name at Overseas",
            "N/A",
            overseasYPosition + rowHeight,
            rowHeight
          );
        }

        hscdrawVerticalLine(hscmargin + 65, 30, 195);
      });
      doc.addPage();
    } else {
      doc.text("No HSC Records found.", hscmargin, 30);
    }

    // Employment Records
    const empmargin = 10; // Define margin
    const emppageWidth = doc.internal.pageSize.width;
    const emppageHeight = doc.internal.pageSize.height;
    const empcontentWidth = emppageWidth - 2 * empmargin; // Ensure content fits within margins

    // Set colors
    const empborderColor = [111, 143, 175]; // RGB
    const empheaderBackgroundColor = [115, 147, 179];
    const emplabelBackgroundColor = [200, 220, 255]; // Light blue for labels
    const emplabelValueLineColor = [111, 143, 175]; // Color for vertical line between label and value

    // Helper functions
    const empdrawBorder = () => {
      doc.setLineWidth(1);
      doc.setDrawColor(...empborderColor);
      doc.rect(
        empmargin,
        empmargin,
        empcontentWidth,
        emppageHeight - 2 * empmargin
      );
    };

    const empfillHeaderBackground = () => {
      doc.setFillColor(...empheaderBackgroundColor);
      doc.rect(empmargin, empmargin, empcontentWidth, 20, "F"); // Reduced height for header
    };

    const empdrawHeader = (title, positionY) => {
      doc.setFontSize(14); // Adjusted font size for the header
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0); // Black color for text
      doc.text(title, emppageWidth / 2, positionY, { align: "center" }); // Center aligned
    };

    const empdrawVerticalLine = (xPosition, startY, endY) => {
      doc.setLineWidth(0.5); // Thin line for vertical separation
      doc.line(xPosition, startY, xPosition, endY);
    };

    // Function to draw table row with label and value in two columns, with horizontal lines extending across the page
    const empdrawTableRowWithLines = (label, value, yPosition, rowHeight) => {
      const labelX = empmargin + 1; // X position for the label
      const labelWidth = 63; // Fixed width for label column
      const valueX = labelX + labelWidth + 10; // X position for the value column
      const lineEndX = emppageWidth - empmargin; // Fixed page width

      // Draw background for label cell
      doc.setFillColor(...emplabelBackgroundColor);
      doc.rect(labelX, yPosition - rowHeight + 4, labelWidth, rowHeight, "F"); // Background for label cell

      // Set font and draw the label
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor(0, 0, 0); // Black color for label text
      doc.text(label, labelX + 2, yPosition); // Draw label text inside the label cell

      console.log("Label & Values", label, value);

      // Handle undefined values for text
      const safeValue = value || "N/A"; // Fallback if value is undefined or null

      // Set font and draw the value in the value column
      doc.text(safeValue, valueX, yPosition);

      // Draw horizontal line extending across the width of the page
      doc.setLineWidth(0.5);
      doc.setDrawColor(...emplabelValueLineColor);
      doc.line(empmargin, yPosition + 3, lineEndX, yPosition + 3); // Horizontal line across the row
    };

    empdrawBorder();
    empfillHeaderBackground();

    console.log("employment", formValues.employment);
    // Employment Records Section
    if (
      formValues.employment &&
      formValues.employment.EmploymentRecords &&
      formValues.employment.EmploymentRecords.length > 0
    ) {
      const headerYPosition = 30; // Y position for header
      console.log("employment", formValues.employment);
      // Loop through each employment record and print its details
      formValues.employment.EmploymentRecords.forEach((employment, index) => {
        if (index > 0) {
          // Add a new page for each employment record after the first
          doc.addPage();
          empddrawBorder();
          empdfillHeaderBackground();
        }
        console.log("Foreach", employment);
        // Draw header for each employment record
        empdrawHeader(`Employment Record ${index + 1}`, empmargin + 10);

        // Define row height
        const rowHeight = 12; // Define row height as per your requirements

        // Starting Y position for the employment records
        let yPosition = headerYPosition + 11; // Adjust this if the header height changes

        // Define labels and values
        const employmentLabels = [
          { label: "Company Name", value: employment.CompanyName || "N/A" },
          {
            label: "Company Address",
            value: employment.CompanyAddress || "N/A",
          },
          { label: "Company Website", value: employment.CompanyWeb || "N/A" },
          { label: "Company Phone", value: employment.CompanyPh || "N/A" },
          {
            label: "Date of Employment",
            value: employment.EmpDateS
              ? new Date(employment.EmpDateS).toLocaleDateString()
              : "N/A",
          },
          {
            label: "Is Current Employment",
            value: employment.isCurrentEmployment || "N/A",
          },

          { label: "Agency Name", value: employment.AgencyName || "N/A" },
          {
            label: "Employment Type (Agency)",
            value: employment.EmpTypeAgency || "N/A",
          },
          {
            label: "Starting Base Salary",
            value: employment.startingBaseSalary || "N/A",
          },
          {
            label: "Other Compensation",
            value: employment.otherCompensation || "N/A",
          },
          { label: "Total Compensation", value: employment.total || "N/A" },
          {
            label: "Final Base Salary",
            value: employment.finalBaseSalary || "N/A",
          },
          {
            label: "Final Other Compensation",
            value: employment.finalOtherCompensation || "N/A",
          },
          { label: "Position Held", value: employment.Position || "N/A" },
          { label: "Employee Code", value: employment.EmpCode || "N/A" },
          { label: "Employment Type", value: employment.EmpType || "N/A" },
          {
            label: "Reason for Leaving",
            value: employment.reasonForLeaving || "N/A",
          },
          { label: "Manager Name", value: employment.MName || "N/A" },
          { label: "Manager Contact", value: employment.MContact || "N/A" },
          { label: "Job Title", value: employment.JobTitle || "N/A" },
          { label: "Department", value: employment.Department || "N/A" },
        ];

        // Loop through employment labels and draw them in a table format with lines
        employmentLabels.forEach((item, i) => {
          const yPositionForRow = yPosition + i * rowHeight; // Use rowHeight for spacing
          empdrawTableRowWithLines(
            item.label,
            item.value,
            yPositionForRow,
            rowHeight
          );
        });

        // // Additional handling for current employment
        // if (employment.isCurrentEmployment === 'Yes') {
        //   const verificationInitiatedYPosition = yPosition + employmentLabels.length * rowHeight;
        //   doc.text(`Verification Initiated: ${employment.verificationInitiationDate ? new Date(employment.verificationInitiationDate).toLocaleDateString() : 'N/A'}`, 10, verificationInitiatedYPosition);
        // }

        // Extra space between records
        yPosition += (employmentLabels.length + 1) * rowHeight + 20; // Adjust the total height as necessary

        empdrawVerticalLine(empmargin + 65, 30, 287);
      });
    doc.addPage();
    } else {
      doc.text("No Employment Records", 10, 30);
    }


    // Employment Records
    const empdmargin = 10; // Define margin
    const empdpageWidth = doc.internal.pageSize.width;
    const empdpageHeight = doc.internal.pageSize.height;
    const empdcontentWidth = empdpageWidth - 2 * empdmargin; // Ensure content fits within margins

    // Set colors
    const empdborderColor = [111, 143, 175]; // RGB
    const empdheaderBackgroundColor = [115, 147, 179];
    const empdlabelBackgroundColor = [200, 220, 255]; // Light blue for labels
    const empdlabelValueLineColor = [111, 143, 175]; // Color for vertical line between label and value

    // Helper functions
    const empddrawBorder = () => {
      doc.setLineWidth(1);
      doc.setDrawColor(...empdborderColor);
      doc.rect(
        empdmargin,
        empdmargin,
        empdcontentWidth,
        empdpageHeight - 2 * empdmargin
      );
    };

    const empdfillHeaderBackground = () => {
      doc.setFillColor(...empdheaderBackgroundColor);
      doc.rect(empdmargin, empdmargin, empdcontentWidth, 20, "F"); // Reduced height for header
    };

    const empddrawHeader = (title, positionY) => {
      doc.setFontSize(14); // Adjusted font size for the header
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0); // Black color for text
      doc.text(title, empdpageWidth / 2, positionY, { align: "center" }); // Center aligned
    };

    const empddrawVerticalLine = (xPosition, startY, endY) => {
      doc.setLineWidth(0.5); // Thin line for vertical separation
      doc.line(xPosition, startY, xPosition, endY);
    };

    // Function to draw table row with label and value in two columns, with horizontal lines extending across the page
    const empddrawTableRowWithLines = (label, value, yPosition, rowHeight) => {
      const labelX = empdmargin + 1; // X position for the label
      const labelWidth = 63; // Fixed width for label column
      const valueX = labelX + labelWidth + 10; // X position for the value column
      const lineEndX = empdpageWidth - empdmargin; // Fixed page width

      // Draw background for label cell
      doc.setFillColor(...empdlabelBackgroundColor);
      doc.rect(labelX, yPosition - rowHeight + 4, labelWidth, rowHeight, "F"); // Background for label cell

      // Set font and draw the label
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor(0, 0, 0); // Black color for label text
      doc.text(label, labelX + 2, yPosition); // Draw label text inside the label cell

      console.log("Label & Values", label, value);

      // Handle undefined values for text
      const safeValue = value || "N/A"; // Fallback if value is undefined or null

      // Set font and draw the value in the value column
      doc.text(safeValue, valueX, yPosition);

      // Draw horizontal line extending across the width of the page
      doc.setLineWidth(0.5);
      doc.setDrawColor(...empdlabelValueLineColor);
      doc.line(empdmargin, yPosition + 3, lineEndX, yPosition + 3); // Horizontal line across the row
    };

    empddrawBorder();
    empdfillHeaderBackground();

    // Employment Details
    if (formValues.employment && typeof formValues.employment === "object") {
      const headerYPosition = 30; // Y position for header

      // Define row height
      const rowHeight = 15; // Define row height as per your requirements

      // Define starting position for labels directly below the header
      const yPositionStart = headerYPosition + 11; // Adjust this if the header height changes

      // Add header for each employment record
      empddrawHeader(`Current Company Reference Details`, empdmargin + 10);

      // Set Y position for the first label directly after the header
      let labelYPosition = yPositionStart; // Start labels immediately below the header

      // Define labels and values
      const labels = [
        {
          label: "Reference Name",
          value: formValues.employment.RName || "N/A",
        },
        {
          label: "Designation",
          value: formValues.employment.Designation || "N/A",
        },
        { label: "Company Name", value: formValues.employment.Cname || "N/A" },
        {
          label: "Contact Telephone",
          value: formValues.employment.contactTelephone || "N/A",
        },
        {
          label: "Company Email Address",
          value: formValues.employment.companyEmail || "N/A",
        },
        {
          label: "How do you know this person?",
          value: formValues.employment.relationship || "N/A",
        },
        {
          label: "Can the reference be contacted",
          value: formValues.employment.canContact || "N/A",
        },
        {
          label: "Reference linked to curr emp?",
          value: formValues.employment.linkedToCurrentEmployment || "N/A",
        },
        {
          label: "Additional Information",
          value: formValues.employment.additionalInformation || "N/A",
        },
      ];

      // Loop through labels and values, and draw them in a table format with lines
      labels.forEach((item, i) => {
        const yPosition = labelYPosition + i * rowHeight; // Use rowHeight for spacing
        empddrawTableRowWithLines(item.label, item.value, yPosition, rowHeight);
      });

      // // Always print the details with 'Date reference can be contacted' based on the canContact value
      // empddrawTableRowWithLines(
      //   'Date reference can be contacted',
      //   formValues.employment.canContact === 'Yes'
      //     ? 'Yes'
      //     : formValues.employment.canContact === 'No'
      //       ? 'No'
      //       : 'N/A',
      //   referenceYPosition,
      //   rowHeight
      // );
      // let referenceYPosition = labelYPosition + labels.length * rowHeight;

      // // Conditionally add 'Alternate Contact' if 'canContact' is 'No'
      // if (formValues.employment.canContact === 'No') {
      //   empddrawTableRowWithLines('Alternate Contact', formValues.employment.alternateContact || 'N/A', referenceYPosition, rowHeight);
      //   referenceYPosition += rowHeight;
      // }

      // // Conditionally add 'Date reference can be contacted' if 'linkedToCurrentEmployment' is 'Yes'
      // if (formValues.employment.linkedToCurrentEmployment === 'Yes') {
      //   empddrawTableRowWithLines(
      //     'Date reference can be contacted',
      //     formValues.employment.dateCanContact ? new Date(formValues.employment.dateCanContact).toLocaleDateString() : 'N/A',
      //     referenceYPosition,
      //     rowHeight
      //   );
      // }

      empddrawVerticalLine(empdmargin + 65, 30, 164);
    } else {
      doc.text("No Current Company Reference Details", empdmargin, 30);
    }

    // Add new page for Documents
    // doc.addPage();

    // // Documents
    // doc.text('Documents', 10, 20);
    // doc.text(`Documents Attached: ${formValues.documents?.attached || 'N/A'}`, 10, 30);
    // doc.addPage();

    // Define constants and variables at the top of the file to ensure they are in scope
    const imageFields = [
      { name: "AddressProof", header: "Address Proof", isSeparatePage: false },
      { name: "Aadhar", header: "Aadhar Card", isSeparatePage: false },
      {
        name: "ProvisionalCertificate",
        header: "Provisional Certificate",
        isSeparatePage: true,
      },
      {
        name: "ExpCertificate",
        header: "Experience Certificate",
        isSeparatePage: true,
      },
    ];

    const imgWidth = 160; // Set the width for all images
    const imgHeight = 160; // Set the height for all images

    // Set up page size and margin
    const docmargin = 10; // Set margin for the entire document
    const docpageWidth = doc.internal.pageSize.width;
    const docpageHeight = doc.internal.pageSize.height;
    const doccontentWidth = docpageWidth - 2 * docmargin; // Content width adjusted for margins

    // Set colors
    const docborderColor = [111, 143, 175]; // RGB for border color
    const docheaderBackgroundColor = [115, 147, 179]; // RGB for header background color

    // Helper functions

    // Function to draw border around the page
    const docdrawBorder = () => {
      doc.setLineWidth(1);
      doc.setDrawColor(...docborderColor);
      doc.rect(
        docmargin,
        docmargin,
        doccontentWidth,
        docpageHeight - 2 * docmargin
      ); // Draws rectangle within margins
    };

    // Function to fill the header background color
    const docfillHeaderBackground = () => {
      doc.setFillColor(...docheaderBackgroundColor);
      doc.rect(docmargin, docmargin, doccontentWidth, 20, "F"); // Draw header background rectangle
    };

    // Function to draw the header with a title
    const docdrawHeader = (title, positionY) => {
      doc.setFontSize(14); // Adjusted font size for the header
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0); // Set text color to black
      doc.text(title, docpageWidth / 2, positionY, { align: "center" }); // Center-aligned header text
    };

    // Function to add an image centered on the page
    const addImageCentered = (doc, imgData, imgWidth, imgHeight) => {
      const x = (docpageWidth - imgWidth) / 2;
      const y = (docpageHeight - imgHeight) / 2 + 10; // Adjusted y position to center image with some margin
      doc.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
    };

    // Loop through each field and add images to the PDF, each on a new page with header and margin
    imageFields.forEach(({ name, header }) => {
      // Check if the formValues contain the field and it has base64 data
      if (formValues.documents && formValues.documents[name]) {
        const imgData = formValues.documents[name]; // Assuming image data is base64 encoded

        try {
          // Check if the image data is a valid base64 string
          if (imgData && imgData.startsWith("data:image/")) {
            // Add a new page for each image, except the first one
            if (doc.getNumberOfPages() > 0) {
              doc.addPage();
            }

            // Draw border, header background, and header text
            docdrawBorder(); // Draw the border around the page
            docfillHeaderBackground(); // Fill the header background
            docdrawHeader(header, docmargin + 10); // Draw header with the field's header name

            // Center the image on the page with respect to margins
            addImageCentered(doc, imgData, imgWidth, imgHeight);
          } else {
            console.error(`Invalid image data for ${name}:`, imgData);
            message.error(
              `Invalid image data for ${name}. Please check the uploaded file.`
            );
          }
        } catch (imgError) {
          console.error(`Error adding ${name} image to PDF:`, imgError);
          message.error(`Failed to add ${header} to the PDF.`);
        }
      } else {
        console.log(`${name} is missing in form values.`);
        message.error(`No ${header} found to include in the PDF.`);
      }
    });

    doc.addPage(); // Add a final blank page at the end if needed

    //Signature
    const sigmargin = 10; // Define margin
    const sigpageWidth = doc.internal.pageSize.width;
    const sigpageHeight = doc.internal.pageSize.height;
    const sigborderWidth = 180; // Width of the border
    const sigborderHeight = 240; // Height of the border

    // Calculate the starting x and y positions to center the border
    const sigborderX = (sigpageWidth - sigborderWidth) / 2; // Center horizontally
    const sigborderY = (sigpageHeight - sigborderHeight) / 2; // Center vertically

    // Set colors
    const sigborderColor = [111, 143, 175]; // RGB
    const sigheaderBackgroundColor = [115, 147, 179];

    // Helper functions
    const sigdrawBorder = () => {
      doc.setLineWidth(1);
      doc.setDrawColor(...sigborderColor);
      // Draw centered border
      doc.rect(sigborderX, sigborderY, sigborderWidth, sigborderHeight); // x, y, width, height
    };

    const sigfillHeaderBackground = () => {
      doc.setFillColor(...sigheaderBackgroundColor);
      doc.rect(sigborderX, sigborderY, sigborderWidth, 25, "F"); // Adjusted to fit inside the border
    };

    const sigdrawHeader = (title, positionY) => {
      doc.setFontSize(18);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(0, 0, 0); // Black color for text
      doc.text(title, sigpageWidth / 2, positionY, { align: "center" });
    };

    // Draw the page content
    sigdrawBorder();
    sigfillHeaderBackground();
    sigdrawHeader("Acknowledgment", sigborderY + 14); // Adjust header position relative to border

    if (formValues.acknowledgment) {
      const imgData = formValues.acknowledgment.Signature;
      console.log("imgdata", imgData);
      try {
        const imgWidth = 35; // Adjust as necessary
        const imgHeight = 25; // Adjust as necessary

        // Add the signature image
        doc.addImage(imgData, "PNG", 20, 215, imgWidth, imgHeight); // x, y, width, height
      } catch (imgError) {
        console.error("Error adding image to PDF:", imgError);
        message.error("Failed to add the signature to the PDF");
      }
    } else {
      console.log("Signature is missing in form values.");
      message.error("No signature found to include in the PDF");
    }

    // Add acknowledgment text
    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0, 0, 0); // Black color for text

    // Acknowledgment text array
    const acknowledgmentText = [
      "Acknowledgment of Details", // The title that needs to be bold and larger
      "",
      formValues.acknowledgment.Name || "N/A", // Use the combined Name
      formValues.acknowledgment.Designation || "N/A", // Map Designation
      formValues.acknowledgment.CompanyName || "N/A", // Map Company Name
      formValues.acknowledgment.Date?.$d
        ? new Date(formValues.acknowledgment.Date.$d).toLocaleDateString()
        : "N/A",

      "", // Empty line for spacing
      "To Whom It May Concern,", // The text that needs to be bold and larger
      "", // Empty line for spacing
    ];

    // Long acknowledgment texts as separate variables
    const longText1 =
      "I hereby acknowledge that I have reviewed the details provided and confirm that all information is correct.";
    const longText2 =
      "If you have any further questions or require additional clarification, please do not hesitate to contact me.";
    const thankYouText = "Thank you.";
    const sincerelyText = "Sincerely,";

    // Set starting position for acknowledgment text
    let startY = sigborderY + 50; // Adjust position below the header

    // Render standard acknowledgment text
    acknowledgmentText.forEach((line) => {
      // Check if it's the line that needs to be bold and with a different font size
      if (
        line === "Acknowledgment of Details" ||
        line === "To Whom It May Concern,"
      ) {
        doc.setFont("Helvetica", "bold"); // Set font to bold
        doc.setFontSize(15); // Change font size for these specific texts
      } else {
        doc.setFont("Helvetica", "normal"); // Default font style for other lines
        doc.setFontSize(13); // Default font size for other lines
      }

      // Add text to PDF
      doc.text(line, sigborderX + sigmargin, startY); // x, y
      startY += 8; // Increment Y position for next line
    });

    // Set font back to normal for the long texts and specify the wrapping width
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(14);
    const wrapWidth = sigborderWidth - 2 * sigmargin; // Adjust the wrap width based on the border width and margins

    // Wrap and add long text to the PDF with word wrapping
    const wrappedText1 = doc.splitTextToSize(longText1, wrapWidth);
    doc.text(wrappedText1, sigborderX + sigmargin, startY); // x, y
    startY += wrappedText1.length * 8; // Increment Y position based on number of wrapped lines

    const wrappedText2 = doc.splitTextToSize(longText2, wrapWidth);
    doc.text(wrappedText2, sigborderX + sigmargin, startY); // x, y
    startY += wrappedText2.length * 8; // Increment Y position based on number of wrapped lines

    doc.text(thankYouText, 90, 195);
    doc.text(sincerelyText, 20, 210);
    // Save the PDF
    const pdfBase64 = doc.output("datauristring").split(",")[1]; // Remove the "data:application/pdf;base64," prefix if needed
    return pdfBase64;
  };

  return (
    <>
      <Steps
        current={current}
        items={items}
        style={{ marginTop: "40px", padding: "10px" }}
      />
      <div style={contentStyle} ref={formRef}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          React.cloneElement(steps[current].content, {
            onSave: (values) => handleSave(values, getCurrentStepKey()),
            initialValues: {
              ...formValues[getCurrentStepKey()],
              jsonId: jsonId,
            },
          })
        )}
      </div>

      <div
        style={{
          marginTop: 24,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Previous button on the left */}
        {current > 0 && (
          <Button
            type="primary"
            style={{ margin: "0 8px" }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}

        {/* Empty div to push Next button to the right side */}
        <div style={{ flex: 1 }}></div>

        {/* Next or Done button on the right */}
        {isLastStep ? (
          <Button
            type="primary"
            onClick={() => done()}
            disabled={!isDoneEnabled}
          >
            Done
          </Button>
        ) : (
          current < steps.length - 1 && (
            <Button
              type="primary"
              style={{ margin: "0 8px" }}
              onClick={() => next()}
            >
              Next
            </Button>
          )
        )}
      </div>
    </>
  );
};

export default BGVStepMethod;
