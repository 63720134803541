import React, { useState, useEffect } from 'react';
import { Layout, Menu, Form, Input, Button, Row, Col, Card, message, Typography } from 'antd';

const { Sider, Content } = Layout;
const { Text } = Typography;

const DocUpload = ({ onSave, initialValues }) => {
  const [form] = Form.useForm();
  const [fileData, setFileData] = useState({
    AddressProof: null,
    Aadhar: null,
    ProvisionalCertificate: null,
    ExpCertificate: null,
  });

  const [allFilesUploaded, setAllFilesUploaded] = useState(false);

  // Effect to check if all required files are uploaded
  useEffect(() => {
    const allFilesUploaded = Object.values(fileData).every(file => file !== null);
    setAllFilesUploaded(allFilesUploaded);
  }, [fileData]);

  const handleFileChange = (e, fieldName) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log(`Selected file: ${file.name}, type: ${file.type}, size: ${file.size} bytes`);

      // Check for valid file types (PNG, JPG, JPEG only)
      const validFileTypes = ['image/png', 'image/jpeg'];
      if (!validFileTypes.includes(file.type)) {
        message.error('Please upload a valid file (PNG, JPG, or JPEG only)');
        return;
      }

      // Set a file size limit (e.g., 5 MB = 5 * 1024 * 1024 bytes)
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
      if (file.size > maxSize) {
        message.error('File size exceeds the limit of 5 MB. Please upload a smaller file.');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(`File successfully read: ${file.name}, storing content...`);
        setFileData((prevData) => ({
          ...prevData,
          [fieldName]: reader.result, // Store base64 content of image
        }));
      };

      // Read image files as base64
      reader.readAsDataURL(file);
    }
  };

  const onFinish = (values) => {
    // Include fileData into the form submission values
    const submissionData = {
      ...values,
      ...fileData,
    };
    console.log('Submission Data:', submissionData);

    // Simulating API Call or Save Action
    onSave(submissionData);
    message.success('Saved your Details, You can move to the next step');
    form.resetFields();
    setFileData({
      AddressProof: null,
      Aadhar: null,
      ProvisionalCertificate: null,
      ExpCertificate: null,
    });
    setAllFilesUploaded(false); // Reset the upload status
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Layout style={{ minHeight: '70vh' }}>
        <Sider
          trigger={null}
          collapsible
          style={{ backgroundColor: 'white', width: '100%', minWidth: '100%' }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            style={{ borderRight: 0, marginTop: '80%' }}
            onClick={({ key }) => {
              if (key === '1') document.getElementById('address-details').scrollIntoView({ behavior: 'smooth' });
              if (key === '2') document.getElementById('educational-details').scrollIntoView({ behavior: 'smooth' });
              if (key === '3') document.getElementById('employment-details').scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <Menu.Item key="1">Address Proof</Menu.Item>
            <Menu.Item key="2">Educational Proof</Menu.Item>
            <Menu.Item key="3">Employment Proof</Menu.Item>
          </Menu>
        </Sider>
        <Content style={{ padding: 24, margin: 0 }}>
          <div style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', overflowX: 'hidden' }}>
            <Form
              form={form}
              name="DocumentUpload"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row>
                <Col span={10}>
                  {/* Card for Address Proof */}
                  <Card id="address-details" title="Address Proof" style={{ marginBottom: 16, width: '200%', marginLeft: '20%' }}>
                    <Form.Item label="Address Proof" name="AddressProof" labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}>
                      <Input type="file" accept=".png,.jpg,.jpeg" onChange={(e) => handleFileChange(e, 'AddressProof')} />
                      <Text type="secondary" style={{ fontSize: '12px' }}>
                        * Only PNG, JPG, or JPEG files (max 5 MB) allowed.
                      </Text>
                    </Form.Item>
                    <Form.Item label="Aadhar" name="Aadhar" labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}>
                      <Input type="file" accept=".png,.jpg,.jpeg" onChange={(e) => handleFileChange(e, 'Aadhar')} />
                      <Text type="secondary" style={{ fontSize: '12px' }}>
                        * Only PNG, JPG, or JPEG files (max 5 MB) allowed.
                      </Text>
                    </Form.Item>
                  </Card>

                  {/* Card for Educational Proof */}
                  <Card id="educational-details" title="Highest Educational Proof" style={{ marginBottom: 16, width: '200%', marginLeft: '20%' }}>
                    <Form.Item label="Provisional Certificate" name="ProvisionalCertificate" labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}>
                      <Input type="file" accept=".png,.jpg,.jpeg" onChange={(e) => handleFileChange(e, 'ProvisionalCertificate')} />
                      <Text type="secondary" style={{ fontSize: '12px' }}>
                        * Only PNG, JPG, or JPEG files (max 5 MB) allowed.
                      </Text>
                    </Form.Item>
                  </Card>

                  {/* Card for Employment Proof */}
                  <Card id="employment-details" title="Employment Proof" style={{ marginBottom: 16, width: '200%', marginLeft: '20%' }}>
                    <Form.Item label="Experience Certificate" name="ExpCertificate" labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}>
                      <Input type="file" accept=".png,.jpg,.jpeg" onChange={(e) => handleFileChange(e, 'ExpCertificate')} />
                      <Text type="secondary" style={{ fontSize: '12px' }}>
                        * Only PNG, JPG, or JPEG files (max 5 MB) allowed.
                      </Text>
                    </Form.Item>

                    {/* Save Button */}
                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button type="primary" htmlType="submit" disabled={!allFilesUploaded}>
                        Save
                      </Button>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default DocUpload;
