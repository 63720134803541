import React, { useState, useEffect } from 'react';
import { Form, Button, message, Row, Col, Card, Upload, Layout, Checkbox, Input, DatePicker } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import './selfdata.css';
import moment from 'moment';


const Content = Layout;
const { Dragger } = Upload;
const Signature = ({ onSave, initialValues }) => {
    const [form] = Form.useForm();
    const [logoFileList, setLogoFileList] = useState([]);
    const [checked, setChecked] = useState(false);
 

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

     const onFinish = async (values) => {
        console.log("Received Values:", values);
        try {
            const signatureFile = form.getFieldValue('Signature');

            // Check if a signature file is present
            if (!signatureFile) {
                message.error('Please upload a signature');
                return;
            }

            // Validate file type
            const fileType = signatureFile.type;
            if (!['image/png', 'image/jpeg'].includes(fileType)) {
                message.error('Please upload a valid image file (PNG or JPEG)');
                return;
            }

            // Convert the signature file to base64
            const reader = new FileReader();

            // Error handling for FileReader
            reader.onerror = () => {
                message.error('Failed to read the file. Please try again.');
            };

            reader.readAsDataURL(signatureFile);

            reader.onloadend = () => {
                const signatureBase64 = reader.result.split(",")[1];
                const imageDataURI = `data:image/png;base64,${signatureBase64}`;

                // Save the data with the Base64 signature
                onSave({ ...values, Signature: signatureBase64 });
                message.success('Saved your Details');
                
                // Reset the form
                form.resetFields();
            };
        } catch (error) {
            // Handle unexpected errors
            console.error('Error saving data:', error);
            message.error('Failed to save your details');
        }
    }
 
    // const onFinish = async (values, signatureData) => {
    //     try {
    //         // Combine form values with signature data
    //         const formData = {
    //             ...values,
    //             signature: signatureData
    //         };

    //         // Send data to the backend
    //         const response = await axios.post('http://127.0.0.1:5000/upload_logo', formData);

    //         // Handle success response
    //         console.log('Response from backend:', response.data);
    //         message.success('Data saved successfully!');
    //     } catch (error) {
    //         // Handle error
    //         console.error('Error saving data:', error);
    //         message.error('Failed to save your details');
    //     }
    // };

    const handleLogoChange = ({ fileList }) => {
        // Set the updated file list
        setLogoFileList(fileList);

        // Check if there is a file in the fileList
        if (fileList.length > 0) {
            // Update the form values with the file
            form.setFieldsValue({ Signature: fileList[0].originFileObj });
            // form.setFieldsValue({ Signature: fileList }); 
        }
    };

    // Handle the checkbox change event
    const handleCheckboxChange = e => {
        setChecked(e.target.checked);
    };

    const handleSubmit = () => {
        if (checked) {
            // Proceed with form submission if the checkbox is checked
            console.log('Form submitted with the following values:');
        } else {
            console.error('Please acknowledge the details before submitting.');
        }
    };



    return (
        <div>
            <Layout style={{ minHeight: '70vh' }}>
                <Content style={{ padding: 24, margin: 0 }}>
                    <Form
                        form={form}
                        name="Acknowledgment"
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 14 }} // Adjusted for better alignment
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        style={{ textAlign: 'center' }}
                    >
                        <Row gutter={24} justify="center">
                            <Col span={12}>
                                <Card title="Acknowledgment" style={{ margin: "20px auto", padding: '20px' }}>


                                    {/* Name Field */}
                                    <Form.Item
                                        label="Name"
                                        name="Name"
                                        rules={[{ required: true, message: 'Please input your name!' }]}
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 14 }} // Adjust the span to fit your layout
                                    >
                                        <Input />
                                    </Form.Item>

                                    {/* Designation Field */}
                                    <Form.Item
                                        label="Designation"
                                        name="Designation"
                                        rules={[{ required: true, message: 'Please input your designation!' }]}
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 14 }}
                                    >
                                        <Input />
                                    </Form.Item>

                                    {/* Company Name Field */}
                                    <Form.Item
                                        label="Company Name"
                                        name="CompanyName"
                                        rules={[{ required: true, message: 'Please input your company name!' }]}
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 14 }}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item label="Date" name='Date' labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} rules={[{ required: true, message: 'Please select the today date' }]}>
                                        <DatePicker style={{ width: "60%" }} />
                                    </Form.Item>

                                    

                                    {/* Signature Upload Field */}
                                    <Form.Item
                                        label='Signature'
                                        name='Signature'
                                        rules={[{ required: true, message: 'Please upload Signature' }]}
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 14 }}
                                        className="custom-form-item"
                                    >
                                        <Dragger
                                            name="Signature"
                                            fileList={logoFileList}
                                            onChange={handleLogoChange}
                                            beforeUpload={() => { return false; }}
                                            listType="picture"
                                            multiple={false}
                                        >
                                            <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        </Dragger>
                                    </Form.Item>


                                    {/* Acknowledgment Checkbox */}
                                    <Form.Item labelCol={{ span: 10 }} wrapperCol={{ span: 24 }}>
                                        <div style={{ border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px', backgroundColor: '#f9f9f9' }}>
                                            <Checkbox checked={checked} onChange={handleCheckboxChange} style={{ marginBottom: '10px' }}>
                                                <strong>Acknowledgment of Details:</strong>
                                            </Checkbox>
                                            <span style={{ display: 'block', padding: '10px 0', width: '100%' }}>
                                                I hereby acknowledge that I have reviewed the details provided and confirm that all information is correct.
                                                <br /><br />
                                                If you have any further questions or require additional clarification, please do not hesitate to contact me.
                                            </span>
                                        </div>
                                    </Form.Item>



                                    {/* Save Button */}
                                    <Form.Item wrapperCol={{ span: 24 }}>
                                        <Button type="primary" htmlType="submit" disabled={!checked}>
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>


                    </Form>
                </Content>
            </Layout>

        </div>
    )
}

export default Signature