// Import React and other necessary libraries
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import ProtectedRoute from "./protectedroutes";
import PrivateRoute from "./protectedroutes/privateroute";
import LoginRoute from "./protectedroutes/loginroute";
import NotFound from "./pages/error/error";
import Finallogin from "./pages/loginpage/Finallogin";
import BGVHome from "./pages/BGV/BGVHome";
import BGVStepMethod from "./pages/BGV/StepMethod";
import FilePicker from "./pages/filepicker";
import Dashboard2 from "./dashboard/dashboard2";
import Desc from "./pages/joboffer/Des";
import { decryptRole } from "./encrypt";
import Parseresume from "./pages/Resume Database/Parsed resume";

function RouterPage() {
  let role;
  const encryptedRoleFromStorage = localStorage.getItem("role");
  console.log("in router page", encryptedRoleFromStorage);
  if (encryptedRoleFromStorage !== null) {
    role = decryptRole(encryptedRoleFromStorage);
  }

  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route element={<LoginRoute />}>
            <Route
              exact
              path="/"
              element={role === "Employee" ? <Homepage /> : <Dashboard2 />}
            />
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/home/*" element={<Homepage />} />
        </Route>
        <Route path="/login" element={<Finallogin />} />
        <Route
          path="/bgv/:token1/:name/:dbName/:email"
          element={<BGVHome />}
        ></Route>
        <Route
          path={`/des/:role/:description/:jobId/:skills/:dbName`}
          element={<Desc />}
        ></Route>
        <Route path={`/Parseresume`} element={<Parseresume />}></Route>
        <Route
          path="/bgv-form"
          element={
            <PrivateRoute>
              <BGVStepMethod />
            </PrivateRoute>
          }
        />
        <Route path="/*" element={<NotFound />} />
        <Route path="/filepicker" element={<FilePicker />} />
      </Routes>
    </Router>
  );
}

export default RouterPage;
