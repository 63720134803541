/*Apply Leave when license is not premium - without compoff and report table */
import React, { useState, useEffect } from "react";
import Layout from "antd/es/layout/layout";
import moment from "moment";
import { Alert, Button, Modal, message, notification,Select, Row, Checkbox } from "antd";
import { Form, Input,DatePicker } from "antd";
import { UploadOutlined, DeleteFilled } from "@ant-design/icons";
import axios from "axios";
import "./empform.css";
import { url } from "../../../url/url";
import { Radio } from "antd";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 140,
  },
};

function LeaveApply(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const today = new Date().toISOString().slice(0, 10);
  const [date] = useState(today);
  const [form] = Form.useForm();
  const [image, setImage] = useState("");
  const [balance1, setAnnualbalance] = useState("");
  const [balance2, setCasualbalance] = useState("");
  const formRef = React.useRef(null);
  const [leaveType, setLeaveType] = useState([]);
  const [annualHoliday, setannualHoliday] = useState([]);
  const [casualHoliday, setCasualHoliday] = useState([]);
  const [leave, setLeave] = useState([]);
  const [managerInfo, setManagerInfo] = useState([]);
  const [halfDayLeave, setHalfDayLeave] = useState(false);
  const [count, setcount] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [leavedet, setLeavedet] = useState({
    reportingmanager: "",
    empCode: "",
    balance1: "",
    balance2: "",
  });
  //
  const [pdf, setPdf] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");
  const status = localStorage.getItem("status");

  //

  useEffect(() => {
    fetchData();
    // fetchData1();
  }, [count]);
  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");

      console.log("dbName in fetchData in empleaveapply", dbName);
      const resp = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );
      const empCode = resp.data.user.empCode;
      const manager = resp.data.user.manager;
      const annualLeave = resp.data.user.annualLeave;
      const casualLeave = resp.data.user.casualLeave;

      const leaveResponse = await axios.get(
        `${url}/leave/ereport/${empCode}/${dbName}`
      );

      const leaveData = leaveResponse.data; // Array of arrays
      console.log("leavedata in leave", leaveData, leavedet.empCode);
      const currentYear = new Date().getFullYear();

      // Filter out denied leaves
      const approvedCasualLeaves = leaveData.filter(
        (item) =>
          item.type === "casual" &&
          (item.status === "Submitted" || item.status === "Approved") && 
          new Date(item.sdate).getFullYear() === currentYear
      );
      const approvedAnnualLeaves = leaveData.filter(
        (item) =>
          item.type === "annual" &&
          (item.status === "Submitted" || item.status === "Approved") &&
          new Date(item.sdate).getFullYear() === currentYear
      );
      const casualleavedays = approvedCasualLeaves.map(
        (item) => item.leavedays
      ); // Extract the single value from each inner array
      const annualleavedays = approvedAnnualLeaves.map(
        (item) => item.leavedays
      ); // Extract the single value from each inner array

      console.log("casualleavedays", casualleavedays);
      console.log("annualleavedays", annualleavedays);
      console.log(annualLeave);
      console.log(casualLeave);
      const availableAnnualLeave =
        annualLeave -
        annualleavedays.reduce((acc, val) => acc + parseFloat(val), 0);
      const availableCasualLeave =
        casualLeave -
        casualleavedays.reduce((acc, val) => acc + parseFloat(val), 0);
      setAnnualbalance(availableAnnualLeave);
      setCasualbalance(availableCasualLeave);
      setannualHoliday(annualLeave);
      setCasualHoliday(casualLeave);
      const updatedValues = {
        empid: empCode,
        empName: user.displayName,
        casualLeaveBal: availableCasualLeave,
        annualLeaveBal: availableAnnualLeave,
      };
      const Send = axios
        .put(`${url}/api/leavebalance/${dbName}`, updatedValues)
        .then((response) => {
          console.log("successfully updated leave balance table");
        });
      console.log("after setbalance", availableAnnualLeave);
      console.log("after setbalance", availableCasualLeave);
      setLeavedet((prevLeaveData) => ({
        ...prevLeaveData,
        reportingmanager: manager,
        empCode: empCode,
        balance1: availableAnnualLeave,
        balance2: availableCasualLeave,
      }));
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenChange = (status) => {
    setOpen(status);
  };
  const handleOpenChange1 = (status) => {
    setOpen1(status);
  };

  // const fetchData1 = async () => {
  //   try {
  //     const dbName = localStorage.getItem("dbName");
  //     console.log("dbName in fetchData1 in empleaveapply", dbName);
  //     const response = await axios.get(`${url}/admin/get/Leavetype/${dbName}`);
  //     setLeaveType(response.data[0].type);
  //     console.log(response.data[0].type);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // const fetchData2 = async () => {
  //   try {
  //     const dbName = localStorage.getItem("dbName");
  //     console.log("dbName in fetchData2 in empleaveapply", dbName);
  //     const response = await axios.get(
  //       `${url}/admin/get/annualleave/${dbName}`
  //     );
  //     setHoliday(response.data[0].holiday);
  //     console.log("response.data[0].holiday", response.data[0].holiday);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const fetchDatacasual = async () => {
  //   try {
  //     const dbName = localStorage.getItem("dbName");
  //     console.log("dbName in fetchDatacasual in empleaveapply", dbName);
  //     const response = await axios.get(
  //       `${url}/admin/get/casualleave/${dbName}`
  //     );
  //     setCasualHoliday(response.data[0].casualHoliday);
  //     console.log("response.data[0].casualHoliday", response.data[0].casualHoliday);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  async function fetchPublicHolidays(dbName) {
    try {
      const response = await axios.get(
        `${url}/admin/date/get/officeholidays/${dbName}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const handleSubmit = async (values) => {
    const { startDate, endDate } = values;
    const halfDayLeave1 = values.halfDayLeave;
    //const halfDayLeave2 = true;
    const parsedStartDate = new Date(startDate);
    const parsedEndDate = new Date(endDate);
    console.log("submit", halfDayLeave1);
    console.log("submit", halfDayLeave);

    if (parsedStartDate > parsedEndDate) {
      message.error("Start date is greater than End date");
    } else {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in handlesubmit in empleaveapply", dbName);
      // Fetch public holidays
      const publicHolidays = await fetchPublicHolidays(dbName);
      axios
        .get(`${url}/employee/leaveDetails/${user.empCode}/${dbName}`)
        .then((response) => {
          const leaves = response.data;
          console.log("response.data", response.data);
          const filteredLeaves = leaves.filter((leave) => {
            const status = leave.status.toLowerCase(); // Convert status to lowercase for case-insensitive comparison
            const type = leave.type.toLowerCase();
            return status === "submitted" || status === "approved";
          });

          if (checkOverlap(startDate, endDate, filteredLeaves)) {
            message.error("These dates have already been applied for leave.");
          } else {
            // If no overlap, proceed with balance check
            const start = moment(startDate);
            const end = moment(endDate);
            let totalAnnualLeavedays;
            let totalCasualLeavedays;
            if (values.leaveType === "annual") {
              if (halfDayLeave === true) {
                // Calculate the total leavedays excluding weekends
                console.log("inside annual half");
                totalAnnualLeavedays = calculateLeavedays(
                  start,
                  end,
                  filteredLeaves,
                  publicHolidays,
                  true
                );
                console.log(halfDayLeave, "isHalfDay");
              } else {
                console.log("inside annual half else");
                totalAnnualLeavedays = calculateLeavedays(
                  start,
                  end,
                  filteredLeaves,
                  publicHolidays,
                  false
                );
              }
            } else if (values.leaveType === "casual") {
              if (halfDayLeave === true) {
                totalCasualLeavedays = calculateLeavedays(
                  start,
                  end,
                  filteredLeaves,
                  publicHolidays,
                  true
                );
                console.log(halfDayLeave, "isHalfDay");
              } else {
                totalCasualLeavedays = calculateLeavedays(
                  start,
                  end,
                  filteredLeaves,
                  publicHolidays,
                  false
                );
              }
            }
            // Calculate the available balance
            const availableAnnualBalance = balance1 - totalAnnualLeavedays;
            const availableCasualBalance = balance2 - totalCasualLeavedays;
            console.log(
              "in final submit after calculate",
              availableAnnualBalance,
              balance1,
              totalAnnualLeavedays
            );
            //console.log(balance, "leavebalance");
            if (values.leaveType === "annual" && totalAnnualLeavedays == 0) {
              message.error(
                "Zero Leave Days applied, please choose different Days"
              );
            } else if (
              values.leaveType === "casual" &&
              totalCasualLeavedays == 0
            ) {
              message.error(
                "Zero Leave Days applied, please choose different Days"
              );
            } else if (
              values.leaveType === "annual" &&
              availableAnnualBalance < 0
            ) {
              console.log(
                totalAnnualLeavedays,
                availableAnnualBalance,
                "balancedif"
              );
              message.error(
                "Applying for this leave exceeds the available annual leave balance."
              );
            } else if (
              values.leaveType === "casual" &&
              availableCasualBalance < 0
            ) {
              console.log(
                totalCasualLeavedays,
                availableCasualBalance,
                "balanced else if"
              );
              message.error(
                "Applying for this leave exceeds the available casual leave balance."
              );
            } else if (values.leaveType === "annual") {
              // If both checks pass, proceed with submission
              FinalSubmit(values, totalAnnualLeavedays);
            } else if (values.leaveType === "casual") {
              // If both checks pass, proceed with submission
              FinalSubmit(values, totalCasualLeavedays);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  function calculateLeavedays(
    startDate,
    endDate,
    leaves,
    publicHolidays,
    halfDayLeave
  ) {
    console.log("inside calculateleavedays");
    console.log(halfDayLeave, "halfDayLeave");
    let totalLeavedays = 0;
    const currentDate = startDate.clone();
    if (startDate.isSame(endDate) && halfDayLeave === true) {
      if (currentDate.day() !== 6 && currentDate.day() !== 0) {
        totalLeavedays += 0.5; // If it's a half day, set totalLeavedays to 0.5
      }
    } else {
      while (currentDate <= endDate) {
        // Check if the current date is not a Saturday (6) or Sunday (0)
        if (currentDate.day() !== 6 && currentDate.day() !== 0) {
          const formattedDate = currentDate.format("YYYY-MM-DD");
          const overlappingLeave = leaves.find(
            (leave) =>
              leave.sdate <= formattedDate && formattedDate <= leave.edate
          );

          if (!overlappingLeave) {
            // && !isPublicHoliday)
            totalLeavedays++;
          }
        }

        currentDate.add(1, "day");
      }
    }
    return totalLeavedays;
  }

  function checkOverlap(startDate, endDate, leaves) {
    console.log(startDate, endDate, leaves, "leavedetails");

    const newStart = moment(startDate);
    const newEnd = moment(endDate);

    // Calculate the days between start and end dates, including both
    const daysBetween = newEnd.diff(newStart, "days") + 1;

    for (let i = 0; i < leaves.length; i++) {
      const leave = leaves[i];
      const leaveStart = moment(leave.sdate);
      const leaveEnd = moment(leave.edate);

      console.log(leaveStart, leaveEnd, "inside for loop");

      // Check if there is any overlap between the date ranges
      if (
        newStart.isBetween(leaveStart, leaveEnd, null, "[]") ||
        newEnd.isBetween(leaveStart, leaveEnd, null, "[]") ||
        leaveStart.isBetween(newStart, newEnd, null, "[]") ||
        leaveEnd.isBetween(newStart, newEnd, null, "[]")
      ) {
        return true;
      }
    }
    return false;
  }

  const FinalSubmit = async (values, difference) => {
    try {
      const imageFile = image;
      const updatedValues = {
        ...values,
        image: imageFile || pdf,
        difference: difference,
        status: "Submitted",
        reportingManager: leavedet.reportingmanager,
        employeeId: leavedet.empCode,
        dateOfApply: date,
        employeeName: user.displayName,
      };
      console.log(
        "updatedValues in finalsubmit in empleaveapply ",
        updatedValues
      );
      const dbName = localStorage.getItem("dbName");

      // Make a POST request to submit the leave
      const leaveResponse = await axios.post(
        `${url}/Leave/${dbName}`,
        updatedValues
      );
      notification.success({message:"Successfully Applied"});

      handleReset();
      setcount(count + 1);

      // If the leave submission was successful, get manager email
      const managerEmail = user.managerEmail;

      // If both API calls are successful, trigger the mail
      const mailTriggerResponse = await axios.post(`${url}/mail`, {
        userName: user.displayName,
        email: user.email,
        manager: managerEmail,
        requestType: "leave",
      });

      notification.success({message:"Successfully Applied"});

      handleReset();
      setcount(count + 1);
    } catch (error) {
      console.error(error);
      message.error("Something Went Wrong");
    }
  };

  const handleReset = () => {
    setImage("");
    setPdf("");
    form.resetFields(); // resets the fields to the initial values
    form.setFieldsValue({
      // sets the fields to the initial values
      startDate: form.getFieldValue("startDate"),
      endDate: form.getFieldValue("endDate"),
      reason: form.getFieldValue("reason"),
      Leave: form.getFieldValue("Leave"),
    });
  };
  const validateYearFormat = (rule, value, callback) => {
    // Check if the value is a valid date with a 4-digit year
    const regex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    if (!regex.test(value)) {
      callback("Please enter a valid date in DD-MM-YYYY format.");
    } else {
      callback();
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      const maxSize = 5 * 1024 * 1024;

      if (allowedFileTypes.includes(file.type) && file.size <= maxSize) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (file.type.startsWith("image/")) {
            setImage(reader.result);
            setPdf(null); // Clear the PDF if an image is selected
          } else if (file.type === "application/pdf") {
            setImage(null); // Clear the image if PDF is selected
            setPdf(reader.result); // Set the PDF file for preview
          }
        };
        reader.readAsDataURL(file);
      } else {
        // Clear the file error message if no file is selected
        setImage(null);
        setPdf(null);
        formRef.current.setFieldsValue({ image: null });
        if (file.size > maxSize) {
          message.error("File size exceeds the 5MB limit.");
        } else {
          message.error(
            "Invalid file type. Please select a valid image or PDF file."
          );
        }
      }
    }
  };
  const handleDelete = () => {
    setImage("");
    setPdf("");
    form.setFieldsValue({
      ...form.getFieldsValue(),
      image: null,
    });
  };
  function openPdfInModal(pdfDataUrl) {
    fetch(pdfDataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setPdfBlobUrl(blobUrl);
        setModalVisible(true);
      });
  }

  return (
    <Layout className="Leave" style={{zoom:"90%"}}>
      <Modal
        title="PDF Document"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={800} // Adjust the width as needed
        footer={null} // Remove the default footer buttons
      >
        {pdfBlobUrl && (
          <iframe
            src={pdfBlobUrl}
            title="PDF Document"
            style={{ width: "100%", height: "500px" }}
          />
        )}
      </Modal>

      <h2 className="text-center ">Apply Leave</h2>
      <Row
        style={{
          margin: "20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="leave-type">
          <Alert
            style={{ marginBottom: "2vh" }}
            message={`Annual Leave : ${annualHoliday}`}
            type="warning"
            showIcon
          />
          <Alert
            message={`Casual Leave : ${casualHoliday}`}
            type="warning"
            showIcon
          />
        </div>
        <div className="available-leaves">
          <Alert
            style={{ marginBottom: "2vh" }}
            message={`Available Annual Leave : ${leavedet.balance1}`}
            type="info"
            showIcon
          />
          <Alert
            message={`Available Casual Leave : ${leavedet.balance2}`}
            type="info"
            showIcon
          />
        </div>
      </Row>
      <Form {...layout} form={form} onFinish={handleSubmit} ref={formRef}>
        <div className="row mb-4 ">
          <div className="col-lg-6 col-sm-12">
            <Form.Item label="Employee Name">
              <Input
                value={user.displayName}
                name="employeeName"
                style={{ height: "40px", fontSize: "14px" }}
                disabled
                className="disabled-input"
              />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-sm-12  ">
            <Form.Item label="Employee id">
              <Input
                value={leavedet.empCode}
                name="employeeId"
                style={{ height: "40px", fontSize: "14px" }}
                disabled
                className="disabled-input"
              />
            </Form.Item>
          </div>
        </div>
        <div className="row mb-4 ">
          <div className="col-lg-6 col-sm-12 ">
            <Form.Item label="Date of Apply">
              <Input
                value={date}
                name="dateOfApply"
                type="date"
                style={{ height: "40px", fontSize: "14px" }}
                disabled
                className="disabled-input"
              />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-sm-12  ">
            <Form.Item label="Reporting Manager">
              <Input
                value={leavedet.reportingmanager}
                name="reportingManager"
                style={{ height: "40px", fontSize: "14px" }}
                disabled
                className="disabled-input"
              />
            </Form.Item>
          </div>
        </div>
        <div className="row mb-4 ">
          <div className="col-lg-6 col-sm-12  ">
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: validateYearFormat, // Use the custom validation function
                },
              ]}
              required
            >
              <Input
                type="date"
                size="large"
                style={{ height: "40px", fontSize: "14px" }}
              />
              {/* <DatePicker
          style={{ width: '100%' }}
          format="YYYY-MM-DD"
          disabledDate={(current) => current && current.year() !== moment().year()}
          open={open1}
          onOpenChange={handleOpenChange1}
        /> */}
            </Form.Item>
          </div>
          <div className="col-lg-6 col-sm-12  ">
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: validateYearFormat, // Use the custom validation function
                },
              ]}
              required
            >
              <Input
                type="date"
                size="large"
                style={{ height: "40px", fontSize: "14px" }}
              />
              {/* <DatePicker
          style={{ width: '100%' }}
          format="YYYY-MM-DD"
          disabledDate={(current) => current && current.year() !== moment().year()}
          open={open}
          onOpenChange={handleOpenChange}
        /> */}

            </Form.Item>
          </div>
        </div>

        <div className="row mb-4 ">
          <div className="col-lg-6 col-sm-12  ">
            <Form.Item
              label="Type of Leave"
              name="leaveType"
              rules={[
                {
                  required: true,
                  message: "Please Select Type of Leave..!",
                },
              ]}
              required
            >
              <Select
                placeholder="Please select the Leave Type"
                size="large"
                options={[
                  {
                    value: "casual",
                    label: "Casual/Sick",
                  },
                  {
                    value: "annual",
                    label: "Annual",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-sm-12  ">
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.startDate !== currentValues.startDate ||
                prevValues.endDate !== currentValues.endDate
              }
            >
              {({ getFieldValue }) => {
                const startDate = getFieldValue("startDate");
                const endDate = getFieldValue("endDate");
                if (startDate === endDate) {
                  return (
                    <Form.Item label="" name="halfDayLeave">
                      <Checkbox
                        onChange={(e) => setHalfDayLeave(e.target.checked)}
                      >
                        Select for Half Day Leave
                      </Checkbox>
                    </Form.Item>
                  );
                } else {
                  return null; // Return null to hide the checkbox when start and end dates are not the same
                }
              }}
            </Form.Item>
          </div>
        </div>
        <div className="row mb-4 ">
          <div className="col-lg-6 col-sm-12  ">
            <Form.Item
              label="Reason"
              name="reason"
              rules={[
                {
                  required: false,
                  message: "Please enter Reason..!",
                },
              ]}
            >
              <Input style={{ height: "40px", fontSize: "14px" }} />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-sm-12  ">
            <Form.Item label="Document" name="image">
              <label className="custom-file-upload">
                <UploadOutlined />
                Upload
                <Input
                  type="file"
                  accept="image/jpeg, image/png, image/jpg, application/pdf"
                  onChange={(e) => handleImageChange(e, "image")}
                  style={{ display: "none" }}
                />
              </label>
              {pdf || image ? ( // Check if either a PDF or image is selected
                <div>
                  {image && (
                    <div>
                      <img src={image} width="200" />
                      <Button
                        danger
                        size="medium"
                        icon={<DeleteFilled />}
                        onClick={handleDelete}
                      />
                    </div>
                  )}
                  {pdf && (
                    <div>
                      <Button
                        danger
                        size="medium"
                        icon={<DeleteFilled />}
                        onClick={handleDelete}
                      />
                      <Button
                        type="primary"
                        size="medium"
                        onClick={() => openPdfInModal(pdf)}
                      >
                        Open PDF
                      </Button>
                    </div>
                  )}
                </div>
              ) : null}
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-12 col-sm-6  d-flex  justify-content-between">
          <Button type="primary" danger onClick={handleReset}>
            Reset
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={status === "disable"}
          >
            Submit
          </Button>
        </div>
      </Form>
    </Layout>
  );
}

export default LeaveApply;
