import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  Form,
  Modal,
  Col,
  Row,
  Checkbox,
  DatePicker,
  Input,
  notification,
  Dropdown,
  Menu,
} from "antd";
import axios from "axios";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CommentOutlined,
  DownOutlined,
  EnvironmentOutlined,
  FileSearchOutlined,
  ScheduleOutlined,
  SearchOutlined,
  SendOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { url } from "../../url/url";
const { Option } = Select;
const { TextArea } = Input;

const Application = () => {
  const [jobs, setJobs] = useState([]);
  const [roles, setRoles] = useState([]);
  const [status, setStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [applicants, setApplicants] = useState([]);
  const [candidate, setCandidate] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [columns, setColumns] = useState([]);
  const [isExpandable, setIsExpandable] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [form] = Form.useForm();
  const [isRemoteChecked, setIsRemoteChecked] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [selectedJobid, setSelectedJobid] = useState("");
  const [candidateAccepted, setCandidateAccepted] = useState(false);
  const [isLocationDisabled, setIsLocationDisabled] = useState(false);
  const dbName = localStorage.getItem("dbName");
  const users = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    fetchJobApplication();
  }, []);

  const fetchJobApplication = async () => {
    try {
      const response = await axios.get(
        `${url}/get/jobResume/${dbName}/${users.jobTitle}/${users.displayName}`
      );
      const jobsWithAppliedDate = response.data.map((job) => {
        // Find the creation date from the history array
        const appliedHistory = job.history.find(
          (entry) => entry.changeType === "Created"
        );
        const appliedDate = appliedHistory
          ? appliedHistory.date
          : job.history[0]?.date;

        // Find the last interview round name from the interviews array
        const lastInterviewRound =
          job.interviews?.[job.interviews.length - 1]?.roundName || "N/A";

        return { ...job, appliedDate, roundName: lastInterviewRound };
      });
      setJobs(jobsWithAppliedDate);
      setFilteredData(jobsWithAppliedDate);
      setColumns(getFullColumns(jobsWithAppliedDate)); // Pass jobsWithAppliedDate to getFullColumns
    } catch (error) {
      console.error("Error fetching job data:", error);
    }
  };

  const getFullColumns = (jobs) => {
    const uniqueFilterValues = (data, key) =>
      [...new Set(data.map((item) => item[key]))]
        .filter((value) => value)
        .map((value) => ({ text: value, value }));

    return [
      {
        title: "Job ID",
        dataIndex: "jobId",
        key: "jobId",
        align: "center",
        filters: uniqueFilterValues(jobs, "jobId"),
        onFilter: (value, record) => record.jobId === value,
      },
      {
        title: "Candidate ID",
        dataIndex: "candidateId",
        key: "candidateId",
        align: "center",
        filters: uniqueFilterValues(jobs, "candidateId"),
        onFilter: (value, record) => record.candidateId === value,
      },
      {
        title: "Candidate's Name",
        dataIndex: "candidateName",
        key: "candidateName",
        filters: uniqueFilterValues(jobs, "candidateName"),
        onFilter: (value, record) => record.candidateName === value,
      },
      {
        title: "Applied on",
        dataIndex: "appliedDate",
        key: "appliedDate",
      },
      {
        title: "Job Position",
        dataIndex: "role",
        key: "role",
        align: "center",
        filters: uniqueFilterValues(jobs, "role"),
        onFilter: (value, record) => record.role === value,
      },
      {
        title: "Interview Stage",
        dataIndex: "roundName",
        key: "roundName",
        align: "center",
        filters: uniqueFilterValues(jobs, "roundName"),
        onFilter: (value, record) => record.roundName === value,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        filters: uniqueFilterValues(jobs, "status"),
        onFilter: (value, record) => record.status === value,
      },
    ];
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = jobs.filter((item) => {
    // Convert fields to strings and check if search query is included
    return (
      item.jobId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.candidateName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.role?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.status?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // Handle search filter change
  const handleFilterChange = (filterType) => {
    let filteredList = [];

    if (filterType === "role") {
      filteredList = roles.map((role) => ({
        key: role,
        role,
        applicants: jobs.filter((job) => job.role === role),
        count: jobs.filter((job) => job.role === role).length,
      }));
      // Update table columns to show "Role" and "Count"
      setColumns([
        { title: "Role", dataIndex: "role", key: "role" },
        { title: "Count", dataIndex: "count", key: "count" },
      ]);
    } else if (filterType === "status") {
      filteredList = status.map((status) => ({
        key: status,
        status,
        applicants: jobs.filter((job) => job.status === status),
        count: jobs.filter((job) => job.status === status).length,
      }));
      // Update table columns to show "Status" and "Count"
      setColumns([
        { title: "Status", dataIndex: "status", key: "status" },
        { title: "Count", dataIndex: "count", key: "count" },
      ]);
    } else if (filterType === "applicant") {
      filteredList = applicants.map((applicant) => ({
        key: applicant,
        applicant,
        applicants: jobs.filter((job) => job.candidateId === applicant),
        count: jobs.filter((job) => job.candidateId === applicant).length,
      }));
      // Update table columns to show "Applicant" and "Count"
      setColumns([
        { title: "Applicant", dataIndex: "applicant", key: "applicant" },
        { title: "Count", dataIndex: "count", key: "count" },
      ]);
    } else if (filterType === "candidateName") {
      filteredList = candidate.map((candidate) => ({
        key: candidate,
        candidate,
        candidates: jobs.filter((job) => job.candidateName === candidate),
        count: jobs.filter((job) => job.candidateName === candidate).length,
      }));
      // Update table columns to show "Applicant" and "Count"
      setColumns([
        { title: "candidateName", dataIndex: "candidate", key: "candidate" },
        { title: "Count", dataIndex: "count", key: "count" },
      ]);
    }

    // Set filtered data and make it expandable
    setFilteredData(filteredList);
    setIsExpandable(true);
  };

  // Table expandable logic
  const expandableConfig = {
    expandedRowRender: (record) => (
      <Table
        dataSource={record.applicants}
        columns={getFullColumns()}
        pagination={false}
        rowKey="jobId"
      />
    ),
  };

  const handleCheckboxChange = (e) => {
    setIsLocationDisabled(e.target.checked);
  };

  const handleCandidateAcceptedChange = (e) => {
    setCandidateAccepted(e.target.checked);
  };

  const handleFormSubmit = async (values) => {
    try {
      console.log("Form values:", values);
      const jobId = selectedJobid;
      const name = selectedCandidate;
      const encodedJobId = encodeURIComponent(jobId);

      if (!encodedJobId || !name) {
        console.error("Job ID or Candidate Name is missing");
        return;
      }

      const interviewDate = values.interviewDate
        ? values.interviewDate.toISOString()
        : null;

      if (!interviewDate) {
        console.error("Interview date is missing");
        return;
      }

      const interviewData = {
        roundName: values.roundName,
        interviewStatus: values.interviewStatus || "Scheduled",
        interviewerName: values.interviewerName,
        location: values.location,
        isRemote: isRemoteChecked,
        interviewDate: values.interviewDate
          ? values.interviewDate.toISOString()
          : new Date(),
        comments: values.comments,
        candidateAccepted: values.candidateAccepted || false,
      };

      const response = await axios.put(
        `${url}/api/jobApplication/${dbName}/${encodedJobId}/${name}`,
        {
          interviewData,
          updatedBy: users.displayName,
        }
      );
      form.resetFields();
      handleCloseInterviewModal();
      notification.success({
        message: "Success",
        description: "Interview updated successfully",
        placement: "topRight",
      });
    } catch (error) {
      console.error("Error updating interview:", error);

      notification.error({
        message: "Error",
        description: "Failed to update interview. Please try again.",
        placement: "topRight",
      });
    }
  };

  const handleRowClick = (candidateId, Id) => {
    setSelectedCandidate(candidateId);
    setSelectedJobid(Id);

    form.setFieldsValue({ candidateId: candidateId });
    form.setFieldsValue({ jobId: Id });
    console.log("ID", candidateId);
  };

  const handleScheduleInterview = (record) => {
    setSelectedJob(record); // Set the selected job for the modal
    setIsModalVisible(true); // Show the modal
  };

  const handleRowOk = (record) => {
    setSelectedRecord(record);
    const encodedJobId = encodeURIComponent(record.jobId); // Encode jobId
    // Navigate to the Record Details page, passing the selected record as state
    navigate(`/home/ApplicantDetail/${encodedJobId}`, {
      state: { candidateId: record.candidateId, role: record.role },
    });
  };

  const menu = (
    <Menu
      onClick={({ key }) => handleFilterChange(key)}
      style={{ width: "30vh" }}
    >
      <Menu.Item
        icon={
          <img
            src={require("../../images/filter.png")}
            style={{ width: "4vh", height: "4vh" }}
          />
        }
        disabled
        style={{ fontWeight: "bold", color: "#1a1570" }}
      >
        Filter By
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="role"
        icon={<UserOutlined />}
        style={{ color: "#0c33f5" }}
      >
        Role
      </Menu.Item>
      <Menu.Item
        key="status"
        icon={<CheckCircleOutlined />}
        style={{ color: "#0c33f5" }}
      >
        Status
      </Menu.Item>
      <Menu.Item
        key="applicant"
        icon={<FileSearchOutlined />}
        style={{ color: "#0c33f5" }}
      >
        Applicant
      </Menu.Item>
    </Menu>
  );

  const handleCloseInterviewModal = () => setIsModalVisible(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "16px",
          padding: "20px",
          height: "80vh",
          // backgroundImage: `url(${require("../../images/profbackground.jpg")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          {/* Dropdown Search on the Left */}
          {/* <Dropdown overlay={menu} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Button style={{ marginBottom: "10vh", color: "#103e8f" }}>
                Search here...
                <img
                  src={require("../../images/down-arrow.png")}
                  style={{ width: "4vh", height: "4vh" }}
                />
              </Button>
            </a>
          </Dropdown> */}

          {/* Table on the Left */}
          {/* Search Box Positioned at the Right Top */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              size="small"
              style={{
                height: "40px",
                fontSize: "15px",
                width: "200px",
              }}
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Table
              dataSource={filterData}
              columns={columns}
              rowKey="key"
              pagination={{ pageSize: 5 }}
              expandable={isExpandable ? expandableConfig : false}
              onRow={(record) => ({
                onClick: (event) => {
                  if (event.target.closest(".schedule-button")) {
                    event.stopPropagation();
                    handleScheduleInterview(record);
                  } else {
                    handleRowOk(record);
                  }
                },
              })}
              className="custom-table"
            />
          </div>
        </div>
      </div>
      {/* <Modal
        title="Schedule Interview"
        visible={isModalVisible}
        onCancel={handleCloseInterviewModal}
        footer={null}
        width={850}
      >
        {selectedJob && (
          <Form
            onFinish={handleFormSubmit}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 16 }}
          >
            <Row
              gutter={[16, 16]}
              justify="space-between"
              style={{ marginTop: "5vh" }}
            >
              <Col span={11}>
                <Form.Item
                  label="Job ID"
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Input
                    prefix={<TeamOutlined style={{ marginBottom: "3vh" }} />}
                    value={selectedJobid}
                    size="small"
                    style={{ height: "6vh", fontSize: "14px" }}
                    readOnly
                  />
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 16 }}
                  label="Candidate Id"
                  rules={[
                    { required: true, message: "Please enter candidate name!" },
                  ]}
                >
                  <Input
                    size="small"
                    value={selectedCandidate}
                    prefix={<UserOutlined style={{ marginBottom: "3vh" }} />}
                    style={{ height: "6vh", fontSize: "14px" }}
                    readOnly
                  />
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 16 }}
                  label="Round Name"
                  name="roundName"
                  rules={[
                    { required: true, message: "Please select round name!" },
                  ]}
                >
                  <Input
                    suffixIcon={<TeamOutlined />}
                    style={{ width: "100%", fontSize: "14px" }}
                    placeholder="Enter Round Name"
                  />
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 16 }}
                  label="Interview Status"
                  name="interviewStatus"
                  rules={[
                    {
                      required: true,
                      message: "Please select interview status!",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={<CheckOutlined />}
                    style={{ width: "100%" }}
                    placeholder="Select Status"
                  >
                    <Option value="Scheduled">Scheduled</Option>
                    <Option value="Completed">Completed</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 16 }}
                  label="Interviewer Name"
                  name="interviewerName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter interviewer name!",
                    },
                  ]}
                >
                  <Input
                    size="small"
                    prefix={<UserOutlined style={{ marginBottom: "3vh" }} />}
                    style={{ height: "6vh", fontSize: "14px" }}
                  />
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  label="Location"
                  name="location"
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Select
                    style={{ width: "100%" }}
                    suffixIcon={<EnvironmentOutlined />}
                    disabled={isLocationDisabled}
                  >
                    <Option value="US">
                      Compass Building, Al Shohada Road, AL Hamra Industrial
                      Zone, UAE
                    </Option>
                    <Option value="Chennai">
                      Perungudi, Chennai, TamilNadu, India
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  label="Interview Date"
                  name="interviewDate"
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 16 }}
                  rules={[
                    {
                      required: true,
                      message: "Please select interview date!",
                    },
                  ]}
                >
                  <DatePicker showTime />
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  label="Comments"
                  name="comments"
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 16 }}
                >
                  <TextArea
                    prefix={<CommentOutlined />}
                    rows={1}
                    placeholder="Add comments"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="IsRemote"
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Checkbox onChange={handleCheckboxChange}>Remote</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Acceptance"
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Checkbox onChange={handleCandidateAcceptedChange}>
                    Candidate Accepted
                  </Checkbox>
                </Form.Item>
              </Col>
              <Button
                type="primary"
                danger
                htmlType="submit"
                style={{ left: "68vh" }}
              >
                Submit
              </Button>
            </Row>
          </Form>
        )}
      </Modal> */}
    </div>
  );
};

export default Application;
